import { useState } from 'react';

interface UseLocalStorageReturn {
  isSaved: boolean;
  getKeyFromLocalStorage: () => string | null;
  removeKeyFromLocalStorage: () => void;
  saveKeyInLocalStorage: (value: unknown) => void;
}

const useLocalStorage = (localStorageKey: string): UseLocalStorageReturn => {
  const [isSaved, setIsSaved] = useState<boolean>(
    !!localStorage.getItem(localStorageKey)
  );

  const getKeyFromLocalStorage = () => {
    const value = localStorage.getItem(localStorageKey);
    if (value) return JSON.parse(value);

    return null;
  };

  const saveKeyInLocalStorage = (value: unknown) => {
    try {
      localStorage.setItem(localStorageKey, JSON.stringify(value));
      setIsSaved(true);
    } catch {
      setIsSaved(false);
    }
  };

  const removeKeyFromLocalStorage = () => {
    try {
      localStorage.removeItem(localStorageKey);
      setIsSaved(false);
    } catch {
      setIsSaved(true);
    }
  };

  return {
    isSaved,
    getKeyFromLocalStorage,
    removeKeyFromLocalStorage,
    saveKeyInLocalStorage,
  };
};

export default useLocalStorage;
