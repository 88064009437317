import React from 'react';
import classNames from 'classnames/bind';
import { Icon } from '@thinkific/toga-react';
import { useTranslation } from 'react-i18next';

import styles from './AdminOnlyIndicator.module.scss';

const cx = classNames.bind(styles);

const AdminOnlyIndicator: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className={cx('admin-only-indicator')}>
      <div className={cx('admin-only-indicator__icon')}>
        <Icon name="eye" />
      </div>
      <div className={cx('admin-only-indicator__message')}>
        <span>
          {t(
            'components-adminOnlyIndicator-info_message',
            'Only admins can see this'
          )}
        </span>
      </div>
    </div>
  );
};

export default AdminOnlyIndicator;
