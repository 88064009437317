/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** Date as YYYY-MM-DD */
  Date: { input: any; output: any };
  /** Datetime in ISO8601 */
  DateTime: { input: any; output: any };
  /** A valid email address */
  EmailAddress: { input: any; output: any };
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: any; output: any };
  /** JSON type */
  JSON: { input: any; output: any };
  /** A string of Liquid markup */
  Liquid: { input: any; output: any };
  /** Represents a string except empty ones. Also removes any additional whitespace. */
  NonEmptyString: { input: any; output: any };
  /** Represents signed double-precision fractional values that are greater than 0. */
  NonNegativeFloat: { input: any; output: any };
  /** Represents non-fractional signed whole numeric values with a value of 0 or more */
  NonNegativeInt: { input: any; output: any };
  /** A valid URL, transported as a string */
  URL: { input: any; output: any };
};

/** The version of the messaging */
export enum AcceleratorMessagingVersionEnum {
  /** Buy a paid plan to gain access to the accelerator */
  Buy = 'buy',
  /** Join and enroll into the accelerator program */
  Join = 'join',
  /** Visit your accelerator program */
  Visit = 'visit',
}

/** Accelerator Visibility indicator */
export type AcceleratorVisibility = {
  __typename?: 'AcceleratorVisibility';
  id: Scalars['ID']['output'];
  /** Whether banner is visible or not */
  isBannerVisible: Scalars['Boolean']['output'];
  /** Accelerator messaging version */
  messagingVersion: AcceleratorMessagingVersionEnum;
};

/** Displays a tenant's connected account */
export type Account = {
  __typename?: 'Account';
  /** Individual, company, or customer facing business name */
  businessName: Maybe<Scalars['String']['output']>;
  businessType: Maybe<Scalars['String']['output']>;
  chargesEnabled: Maybe<Scalars['Boolean']['output']>;
  country: Maybe<Scalars['String']['output']>;
  countrySpec: Maybe<CountrySpec>;
  /** Currently selected payment currency. */
  currency: Maybe<Currency>;
  defaultCurrency: Maybe<Scalars['String']['output']>;
  detailsSubmitted: Maybe<Scalars['Boolean']['output']>;
  disabledReason: Maybe<Scalars['String']['output']>;
  externalAccounts: Maybe<Array<Maybe<AccountExternalAccount>>>;
  futureRequirements: Maybe<AccountRequirements>;
  id: Maybe<Scalars['String']['output']>;
  /** List of currencies a tenant can charge in. */
  paymentCurrencies: Maybe<Array<Maybe<Currency>>>;
  payoutDetailsStatus: Maybe<Scalars['String']['output']>;
  payoutSchedule: Maybe<PayoutSchedule>;
  payoutsEnabled: Maybe<Scalars['Boolean']['output']>;
  requirements: Maybe<AccountRequirements>;
  /** The source of the request for payment activation */
  source: Maybe<SourceEnum>;
  status: Maybe<Scalars['String']['output']>;
  taxRegistrationId: Maybe<Scalars['String']['output']>;
  taxRegistrationStatus: Maybe<TaxRegistrationStatus>;
};

/** Displays all balances for a tenant */
export type AccountBalance = {
  __typename?: 'AccountBalance';
  available: Maybe<Array<Maybe<Money>>>;
  connectReserved: Maybe<Array<Maybe<Money>>>;
  instantAvailable: Maybe<Array<Maybe<Money>>>;
  pending: Maybe<Array<Maybe<Money>>>;
};

/** External accounts currently attached to this account */
export type AccountExternalAccount = {
  __typename?: 'AccountExternalAccount';
  bankName: Maybe<Scalars['String']['output']>;
  brand: Maybe<Scalars['String']['output']>;
  country: Maybe<Scalars['String']['output']>;
  currency: Maybe<Scalars['String']['output']>;
  defaultForCurrency: Maybe<Scalars['Boolean']['output']>;
  id: Maybe<Scalars['String']['output']>;
  last4: Maybe<Scalars['String']['output']>;
  status: Maybe<Scalars['String']['output']>;
};

/** Notifications for accounts */
export type AccountNotification = {
  __typename?: 'AccountNotification';
  /** The description for the notification */
  description: Maybe<Scalars['String']['output']>;
  /** Whether this notification is enabled */
  isEnabled: Maybe<Scalars['Boolean']['output']>;
  /** The name of the notification */
  name: Maybe<Scalars['String']['output']>;
  /** The slug for the notification */
  slug: Maybe<AccountNotificationSlugType>;
};

/** The slug types for account notifications */
export enum AccountNotificationSlugType {
  /** Community comments */
  CommunityPostReply = 'community_post_reply',
}

/** Information about the requirements for the account, including what information needs to be collected, and by when. */
export type AccountRequirements = {
  __typename?: 'AccountRequirements';
  currentDeadline: Maybe<Scalars['Int']['output']>;
  currentlyDue: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  disabledReason: Maybe<Scalars['String']['output']>;
  eventuallyDue: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  pastDue: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  pendingVerification: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

/** Autogenerated input type of ActivateMfa */
export type ActivateMfaInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** One time password */
  otp: Scalars['String']['input'];
};

/** Autogenerated return type of ActivateMfa */
export type ActivateMfaPayload = {
  __typename?: 'ActivateMfaPayload';
  /** List of backup codes */
  backupCodes: Maybe<Array<Scalars['String']['output']>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Updated user */
  user: Maybe<User>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of ActivateProductLicenses */
export type ActivateProductLicensesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Source from which an event originated / modelHistory constants */
  eventSource?: InputMaybe<ProductLicenseEventSourceEnum>;
  /** Enrollment expiry date */
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Product IDs */
  productIds: Array<Scalars['ID']['input']>;
  /** User ID */
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of ActivateProductLicenses */
export type ActivateProductLicensesPayload = {
  __typename?: 'ActivateProductLicensesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of ActivateThemeInstallation */
export type ActivateThemeInstallationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The theme installation id to be activated */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of ActivateThemeInstallation */
export type ActivateThemeInstallationPayload = {
  __typename?: 'ActivateThemeInstallationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /**
   * Response data
   * @deprecated Backwards compatibility for old response data
   */
  data: Maybe<Scalars['String']['output']>;
  /**
   * Response errors
   * @deprecated Backwards compatibility for old response errors
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Tenant's active campaign that lives on Ihub::OAuthAppInstall */
export type ActiveCampaign = Node & {
  __typename?: 'ActiveCampaign';
  /** ActiveCampaign's api key */
  apiKey: Maybe<Scalars['String']['output']>;
  /** ActiveCampaign's api URL */
  apiURL: Maybe<Scalars['String']['output']>;
  /** ActiveCampaign's created at date */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /**
   * ActiveCampaign's event tracking is enabled?
   * @deprecated The field is not used directly on ActiveCampaign
   */
  domainsSafelisted: Maybe<Scalars['Boolean']['output']>;
  /** ActiveCampaign's event tracking ACTID */
  eventTrackingACTID: Maybe<Scalars['String']['output']>;
  /** ActiveCampaign's event tracking key */
  eventTrackingKey: Maybe<Scalars['String']['output']>;
  /** ActiveCampaign's events */
  events: Maybe<Array<Maybe<Event>>>;
  id: Scalars['ID']['output'];
  /** ActiveCampaign's site tracking snippet */
  siteTracking: Maybe<ActiveCampaignSiteTracking>;
  /** ActiveCampaign's site tracking snippet */
  siteTrackingSnippet: Maybe<Scalars['String']['output']>;
  /** ActiveCampaign's updated at date */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** Active Campaign event slug */
export enum ActiveCampaignEventSlug {
  /** ActiveCampaign event Course completed */
  CourseCompleted = 'course_completed',
  /** ActiveCampaign event New enrollment - full */
  NewEnrollmentFull = 'new_enrollment_full',
  /** ActiveCampaign event New enrollment - preview */
  NewEnrollmentTrial = 'new_enrollment_trial',
  /** ActiveCampaign event New order */
  NewOrder = 'new_order',
  /** ActiveCampaign event New sign up */
  NewSignUp = 'new_sign_up',
}

/** Input fields for a ActiveCampaign */
export type ActiveCampaignInput = {
  /** ActiveCampaign's api key */
  apiKey?: InputMaybe<Scalars['String']['input']>;
  /** ActiveCampaign's api URL */
  apiURL?: InputMaybe<Scalars['String']['input']>;
  /** ActiveCampaign's event tracking actid */
  eventTrackingACTID?: InputMaybe<Scalars['String']['input']>;
  /** ActiveCampaign's event tracking key */
  eventTrackingKey?: InputMaybe<Scalars['String']['input']>;
  /** ActiveCampaign's events */
  events?: InputMaybe<EventInput>;
  /** ActiveCampaign's site tracking snippet */
  siteTrackingSnippet?: InputMaybe<Scalars['String']['input']>;
};

/** Active Campaign's site tracking and domain safelisting status */
export type ActiveCampaignSiteTracking = {
  __typename?: 'ActiveCampaignSiteTracking';
  /** All tenant's domains are safelisted in Active Camapaign? */
  domainsSafelisted: Maybe<Scalars['Boolean']['output']>;
  /** is site tracking enabled? */
  enabled: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated return type of AddCourseAdmin */
export type AddCourseAdminPayload = {
  __typename?: 'AddCourseAdminPayload';
  /** A user that has access to the tenant */
  user: Maybe<User>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of AddItemToBundle */
export type AddItemToBundleInput = {
  /** The bundle's ID */
  bundleId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The product's ID */
  productId: Scalars['ID']['input'];
};

/** Autogenerated return type of AddItemToBundle */
export type AddItemToBundlePayload = {
  __typename?: 'AddItemToBundlePayload';
  /** The bundle */
  bundle: Maybe<Bundle>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The product */
  product: Maybe<Product>;
  userErrors: Maybe<Array<UserError>>;
};

/** Input field for adding an additional line item to a cart */
export type AddLineItemInput = {
  /** Token identifier of the cart to apply discount to */
  cartToken: Scalars['String']['input'];
  /** Id of the line item to add to the cart */
  itemId: Scalars['ID']['input'];
  /** Type of line item product to add to the cart (additional product or order bump, e.g.) */
  itemType: Scalars['String']['input'];
  /** Student location */
  location?: InputMaybe<BillingAddressInput>;
};

/** Result of adding an additional line item to a cart */
export type AddLineItemResult = ResponseInterface & {
  __typename?: 'AddLineItemResult';
  /**
   * Response Extra Data
   * @deprecated response fields should be well defined following a object structure
   */
  data: Maybe<Scalars['JSON']['output']>;
  errors: Maybe<Array<Maybe<Error>>>;
  /** Response Message */
  message: Maybe<Scalars['String']['output']>;
  /** Return status: SUCCESS, PARTIAL_SUCCESS or FAILED */
  status: Maybe<ResponseStatus>;
};

/** Autogenerated input type of AddMultiInstance */
export type AddMultiInstanceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Current user */
  from_user_id?: InputMaybe<Scalars['Int']['input']>;
  /** Target Thinkific subdomain */
  to_subdomain?: InputMaybe<Scalars['String']['input']>;
  /** Target user */
  to_user_email?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AddMultiInstance */
export type AddMultiInstancePayload = {
  __typename?: 'AddMultiInstancePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /**
   * Response data
   * @deprecated Backwards compatibility for old response
   */
  data: Maybe<Scalars['String']['output']>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AddProductAffiliates */
export type AddProductAffiliatesInput = {
  /** Affiliate ID, do not pass this argument if you need to add all the affiliates */
  affiliateIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The product ID */
  productId: Scalars['ID']['input'];
};

/** Autogenerated return type of AddProductAffiliates */
export type AddProductAffiliatesPayload = {
  __typename?: 'AddProductAffiliatesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of AddUsersToGroups */
export type AddUsersToGroupsInput = {
  /** Boolean for all users selected */
  allSelected?: InputMaybe<Scalars['Boolean']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Filter for users to be added to Groups */
  filter?: InputMaybe<UserFilter>;
  /** Group IDs */
  groupIds: Array<InputMaybe<Scalars['String']['input']>>;
  /** User IDs */
  userIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Autogenerated return type of AddUsersToGroups */
export type AddUsersToGroupsPayload = {
  __typename?: 'AddUsersToGroupsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /**
   * Response data
   * @deprecated Backwards compatibility for old response data
   */
  data: Maybe<Scalars['JSON']['output']>;
  /**
   * Response error
   * @deprecated Backwards compatibility for old response error
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
};

/** Notifications for admins */
export type AdminNotification = {
  __typename?: 'AdminNotification';
  /** The category of the notification */
  category: Maybe<Scalars['String']['output']>;
  /** The description for the notification */
  description: Maybe<Scalars['String']['output']>;
  /** Whether this notification is enabled */
  isEnabled: Maybe<Scalars['Boolean']['output']>;
  /** The name of the notification */
  name: Maybe<Scalars['String']['output']>;
  /** The section for the notification */
  section: Maybe<Scalars['String']['output']>;
  /** The slug for the notification */
  slug: Maybe<AdminNotificationSlugType>;
};

/** The type of the slug admin notification */
export enum AdminNotificationSlugType {
  /** Subscription cancelled */
  CancelledSubscription = 'cancelled_subscription',
  /** Reply */
  DiscussionQuestionAnswered = 'discussion_question_answered',
  /** Assignment submission */
  NewAssignmentSubmission = 'new_assignment_submission',
  /** Auto-follow new posts */
  NewCommunityActivity = 'new_community_activity',
  /** New posts */
  NewCommunityPost = 'new_community_post',
  /** New post */
  NewDiscussionQuestion = 'new_discussion_question',
  /** New lead */
  NewLead = 'new_lead',
  /** New order */
  NewOrder = 'new_order',
  /** New sign up */
  NewSignup = 'new_signup',
  /** New review */
  ReviewSubmission = 'review_submission',
}

/** Affiliate can refer users to sign up to the tenant */
export type Affiliate = Node &
  UserInterface & {
    __typename?: 'Affiliate';
    /** Affiliate's code */
    affiliateCode: Maybe<Scalars['String']['output']>;
    /** User's amount spent */
    amountSpent: Maybe<Scalars['Float']['output']>;
    /**
     * User's avatar url
     * @deprecated Use profile field instead
     */
    avatarUrl: Maybe<Scalars['String']['output']>;
    /** User's billing country */
    billingCountry: Maybe<Scalars['String']['output']>;
    /** User's billing postal code */
    billingPostalCode: Maybe<Scalars['String']['output']>;
    /** User's billing region */
    billingRegion: Maybe<Scalars['String']['output']>;
    /** User's ability to access sidekiq */
    canAccessSidekiq: Maybe<Scalars['Boolean']['output']>;
    /** Affiliate's commission */
    commission: Maybe<Scalars['Float']['output']>;
    /** Affiliate's commission type */
    commissionType: Maybe<Scalars['String']['output']>;
    /**
     * User's company
     * @deprecated Use profile field instead
     */
    company: Maybe<Scalars['String']['output']>;
    /** User's created date */
    createdAt: Maybe<Scalars['DateTime']['output']>;
    /** User's custom profile field */
    customProfileFields: Maybe<Array<Maybe<CustomField>>>;
    /** User's default payment card */
    defaultPaymentCard: Maybe<DefaultPaymentCard>;
    /** User's email */
    email: Maybe<Scalars['String']['output']>;
    /** A list of user's enrollments */
    enrollments: Maybe<EnrollmentConnection>;
    /** User's external source */
    externalSource: Maybe<Scalars['String']['output']>;
    /** User's first name */
    firstName: Maybe<Scalars['String']['output']>;
    /** User's full name */
    fullName: Maybe<Scalars['String']['output']>;
    /** A list of groups that user belongs */
    groups: Maybe<GroupConnection>;
    /** Whether this user has Payments tab enabled on User profile page */
    hasPaymentsTab: Scalars['Boolean']['output'];
    /** Whether user has set a thinkific password */
    hasThinkificPassword: Maybe<Scalars['Boolean']['output']>;
    id: Scalars['ID']['output'];
    /** Most recent identity provider user authenticated with */
    identityProvider: Maybe<IdentityProvider>;
    /** Users's IP address */
    ipAddress: Maybe<Scalars['String']['output']>;
    /** Whether the user is an admin */
    isAdmin: Maybe<Scalars['Boolean']['output']>;
    /** Whether the user is an unlimited admin ie. owner, site admin or partner */
    isUnlimitedAdmin: Maybe<Scalars['Boolean']['output']>;
    /** User's landing page */
    landingPage: Maybe<Scalars['String']['output']>;
    /** User's last name */
    lastName: Maybe<Scalars['String']['output']>;
    /** User's last sign in */
    lastSignIn: Maybe<Scalars['DateTime']['output']>;
    /** User's specified locale setting */
    locale: Maybe<Scalars['String']['output']>;
    /** MFA settings for user */
    mfaSettings: Maybe<MfaSettings>;
    /** User's total number of enrollments */
    numberOfEnrollments: Maybe<Scalars['Int']['output']>;
    /** Affiliate's payout email */
    payoutEmail: Maybe<Scalars['String']['output']>;
    /** User's sign in date for previous session */
    previousSignIn: Maybe<Scalars['DateTime']['output']>;
    /** User's profile */
    profile: Maybe<Profile>;
    /** A list of the user's quiz and survey responses */
    quizSurveyResponses: Maybe<UserQuizSurveyResponsesConnection>;
    /** User's subscriptions and payment plans */
    recurringPayments: Maybe<Array<RecurringPayment>>;
    /** Affiliate who has referred user */
    referredBy: Maybe<Affiliate>;
    /** User's referred from url */
    referredFrom: Maybe<Scalars['String']['output']>;
    /** User's roles */
    roles: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** User's sign in count */
    signInCount: Maybe<Scalars['Int']['output']>;
    /** User's updated date */
    updatedAt: Maybe<Scalars['DateTime']['output']>;
    /** A list of user's bundles */
    userBundles: Maybe<Bundle_EnrollmentConnection>;
  };

/** Affiliate can refer users to sign up to the tenant */
export type AffiliateDefaultPaymentCardArgs = {
  cartToken?: InputMaybe<Scalars['String']['input']>;
};

/** Affiliate can refer users to sign up to the tenant */
export type AffiliateEnrollmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserEnrollmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<EnrollmentOrderBy>;
};

/** Affiliate can refer users to sign up to the tenant */
export type AffiliateGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Affiliate can refer users to sign up to the tenant */
export type AffiliateQuizSurveyResponsesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ReportingOrderBy>;
  pagination: Pagination;
};

/** Affiliate can refer users to sign up to the tenant */
export type AffiliateUserBundlesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserBundlesOrderBy>;
};

/** The connection type for Affiliate. */
export type AffiliateConnection = {
  __typename?: 'AffiliateConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<AffiliateEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Affiliate>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AffiliateEdge = {
  __typename?: 'AffiliateEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Affiliate>;
};

/** After Purchase Flow information */
export type AfterPurchaseFlow = Node & {
  __typename?: 'AfterPurchaseFlow';
  /** A list of after purchase flow products */
  afterPurchaseFlowProducts: Maybe<AfterPurchaseFlowProductConnection>;
  /** Item */
  bundleItem: Maybe<BundleItem>;
  /** bundle item id */
  bundleItemId: Maybe<Scalars['String']['output']>;
  /** custom message heading */
  customMessageHeading: Maybe<Scalars['String']['output']>;
  /** custom message subheading */
  customMessageSubheading: Maybe<Scalars['String']['output']>;
  /** custom url */
  customUrl: Maybe<Scalars['String']['output']>;
  /** destination url */
  destinationUrl: Maybe<Scalars['String']['output']>;
  /** display custom messages */
  displayCustomMessages: Maybe<Scalars['Boolean']['output']>;
  /** display upsell products */
  displayUpsellProducts: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  /** name */
  name: Maybe<Scalars['String']['output']>;
  /** preview thank you page */
  previewThankYouPageUrl: Maybe<Scalars['String']['output']>;
  /** upsell products heading */
  upsellProductsHeading: Maybe<Scalars['String']['output']>;
};

/** After Purchase Flow information */
export type AfterPurchaseFlowAfterPurchaseFlowProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Input fields for bulk updating an after purchase flow products and product prices */
export type AfterPurchaseFlowBulkInput = {
  /** List of APF product ids to be bulk created */
  productIdsToAddOrUpdate?: InputMaybe<
    Array<InputMaybe<Scalars['ID']['input']>>
  >;
  /** List of APF product ids to be bulk removed */
  productIdsToRemove?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** List of product price ids to be bulk updated and add APF association */
  productPriceIdsToAdd?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** List of product price ids to be bulk updated and remove APF association */
  productPriceIdsToRemove?: InputMaybe<
    Array<InputMaybe<Scalars['ID']['input']>>
  >;
};

/** The connection type for AfterPurchaseFlow. */
export type AfterPurchaseFlowConnection = {
  __typename?: 'AfterPurchaseFlowConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<AfterPurchaseFlowEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<AfterPurchaseFlow>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AfterPurchaseFlowEdge = {
  __typename?: 'AfterPurchaseFlowEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<AfterPurchaseFlow>;
};

/** Input fields for an after purchase flow */
export type AfterPurchaseFlowInput = {
  bundleItemId?: InputMaybe<Scalars['String']['input']>;
  bundleItemType?: InputMaybe<Scalars['String']['input']>;
  /** The custom message heading of the after purchase flow */
  customMessageHeading?: InputMaybe<Scalars['String']['input']>;
  /** The custom message subheading of the after purchase flow */
  customMessageSubheading?: InputMaybe<Scalars['String']['input']>;
  /** The custom url of the after purchase flow */
  customUrl?: InputMaybe<Scalars['String']['input']>;
  /** The destination of the after purchase flow */
  destinationUrl?: InputMaybe<Scalars['String']['input']>;
  /** Truthy value when custom messages should be displayed */
  displayCustomMessages?: InputMaybe<Scalars['Boolean']['input']>;
  /** Truthy value when upsell products should be displayed */
  displayUpsellProducts?: InputMaybe<Scalars['Boolean']['input']>;
  /** The graphql_name of the after purchase flow */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The upsell products heading of the after purchase flow */
  upsellProductsHeading?: InputMaybe<Scalars['String']['input']>;
};

/** After purchase flow product */
export type AfterPurchaseFlowProduct = Node & {
  __typename?: 'AfterPurchaseFlowProduct';
  /** After Purchase Flow ID */
  afterPurchaseFlowId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  /** After purchase flow product position */
  position: Maybe<Scalars['Int']['output']>;
  /** Product ID */
  productId: Maybe<Scalars['ID']['output']>;
  /** After purchase flow product name */
  productName: Maybe<Scalars['String']['output']>;
};

/** The connection type for AfterPurchaseFlowProduct. */
export type AfterPurchaseFlowProductConnection = {
  __typename?: 'AfterPurchaseFlowProductConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<AfterPurchaseFlowProductEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<AfterPurchaseFlowProduct>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AfterPurchaseFlowProductEdge = {
  __typename?: 'AfterPurchaseFlowProductEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<AfterPurchaseFlowProduct>;
};

/** Attributes for agreement settings */
export type AgreementSettingsInput = {
  /** Agreement content */
  content?: InputMaybe<Scalars['String']['input']>;
  /** Agreement enabled */
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AnnouncementInterface = {
  /** Badge type for changelog */
  badgeType: Maybe<Scalars['String']['output']>;
  /** Category in which entry belongs */
  category: Maybe<Scalars['String']['output']>;
};

/** Announcements Type */
export type Announcements = AnnouncementInterface &
  Node & {
    __typename?: 'Announcements';
    /** Author of message */
    author: Maybe<Scalars['String']['output']>;
    /** Badge type for changelog */
    badgeType: Maybe<Scalars['String']['output']>;
    /** Category in which entry belongs */
    category: Maybe<Scalars['String']['output']>;
    /** Description of message */
    description: Maybe<Scalars['String']['output']>;
    /** Global unique ID of message */
    guid: Maybe<Scalars['String']['output']>;
    /** Announcement's ID */
    id: Scalars['ID']['output'];
    /** Thinkific Lab specific image */
    imageUrl: Maybe<Scalars['String']['output']>;
    /** Boolean to show if tenant has opted into Thinkific Lab */
    labOptedIn: Maybe<Scalars['Boolean']['output']>;
    /** Date published */
    publishDate: Maybe<Scalars['String']['output']>;
    /** Time published */
    publishTime: Maybe<Scalars['String']['output']>;
    /** Title of message */
    title: Maybe<Scalars['String']['output']>;
  };

/** The connection type for Announcements. */
export type AnnouncementsConnection = {
  __typename?: 'AnnouncementsConnection';
  /** all announcements count */
  allCount: Scalars['Int']['output'];
  /** A list of edges. */
  edges: Maybe<Array<Maybe<AnnouncementsEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Announcements>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AnnouncementsEdge = {
  __typename?: 'AnnouncementsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Announcements>;
};

/** An object that contains records of oauth app installs */
export type App = Node & {
  __typename?: 'App';
  /** API contact email for the OAuthApp */
  apiContactEmail: Maybe<Scalars['String']['output']>;
  /** ID for the OAuthApp */
  appId: Maybe<Scalars['ID']['output']>;
  /** Author for the OAuthApp */
  author: Maybe<Scalars['String']['output']>;
  /** Date the OAuthAppInstall was created at */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** Description for the OAuthApp */
  description: Maybe<Scalars['String']['output']>;
  /** App extensions settings */
  extensions: Maybe<ExtensionsType>;
  /** Feature key for the OAuthApp */
  featureKey: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Embeddable app url */
  iframeEmbedUrl: Maybe<Scalars['String']['output']>;
  /** Install id for the OAuthApp */
  installId: Maybe<Scalars['Int']['output']>;
  /** Keywords for the OAuthApp */
  keywords: Maybe<Array<Scalars['String']['output']>>;
  /** Logo for the OAuthApp */
  logo: Maybe<Scalars['String']['output']>;
  /** Is the app managed by Thinkific? */
  managedByThinkific: Maybe<Scalars['Boolean']['output']>;
  /** Minimum required plan for the OAuthApp */
  minimumRequiredPlan: Maybe<Scalars['String']['output']>;
  /** Name of the OAuthApp */
  name: Maybe<Scalars['String']['output']>;
  /** PartnerID for the OAuthApp */
  partnerId: Maybe<Scalars['String']['output']>;
  /** Scopes for the OAuthApp */
  scopes: Maybe<Array<Scalars['String']['output']>>;
  /** Scopes for the OAuthApp */
  scopesWithInfo: Maybe<Array<Maybe<Scope>>>;
  /** Settings for the OAuthAppInstall */
  settings: Maybe<Scalars['JSON']['output']>;
  /** Site scripts installed for the OAuthApp */
  siteScripts: Maybe<Array<Maybe<SiteScript>>>;
  /** Slug for the OAuthApp */
  slug: Maybe<Scalars['String']['output']>;
  /** State for the OAuthApp */
  state: Maybe<StateType>;
  /** Support contact form url for the OAuthApp */
  supportContactFormUrl: Maybe<Scalars['String']['output']>;
  /** Support documentation link */
  supportDocLink: Maybe<Scalars['String']['output']>;
  /** Support email for the OAuthApp */
  supportEmail: Maybe<Scalars['String']['output']>;
  /** Date the app was suspended */
  suspendedAt: Maybe<Scalars['DateTime']['output']>;
  /** Suspension reason for the OAuthApp */
  suspensionReason: Maybe<Scalars['String']['output']>;
  /** Date of last update to the OAuthAppInstall */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  /** URL for the OAuthApp */
  url: Maybe<Scalars['String']['output']>;
  /** Marketing website for the OAuthApp */
  website: Maybe<Scalars['String']['output']>;
};

/** App configuration */
export type AppConfiguration = {
  __typename?: 'AppConfiguration';
  /** App status */
  appStatus: Maybe<Scalars['String']['output']>;
  /** App connect */
  connect: Maybe<Connect>;
  id: Scalars['ID']['output'];
  /** App information */
  information: Maybe<Information>;
};

/** The connection type for App. */
export type AppConnection = {
  __typename?: 'AppConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<AppEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<App>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** App customization */
export type AppCustomization = {
  __typename?: 'AppCustomization';
  /** App primary background color */
  primaryBackgroundColor: Maybe<Scalars['String']['output']>;
  /** App primary button background color */
  primaryButtonBackgroundColor: Maybe<Scalars['String']['output']>;
  /** App primary button border color */
  primaryButtonBorderColor: Maybe<Scalars['String']['output']>;
  /** App primary button text color */
  primaryButtonTextColor: Maybe<Scalars['String']['output']>;
  /** App primary text color */
  primaryTextColor: Maybe<Scalars['String']['output']>;
};

/** An edge in a connection. */
export type AppEdge = {
  __typename?: 'AppEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<App>;
};

/** Possible fields to filter apps */
export type AppFilter = {
  /** Search by author */
  author?: InputMaybe<Scalars['String']['input']>;
  /** Search by keyword */
  keyword?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Search by whether the app is managed by Thinkific */
  managedByThinkific?: InputMaybe<Scalars['Boolean']['input']>;
  /** Search by name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Search by slug */
  slug?: InputMaybe<Scalars['String']['input']>;
  /** Search by state */
  state?: InputMaybe<StateType>;
};

/** Possible locations for App Frames */
export enum AppFrameLocationsEnum {
  /** Injects App Frame into app settings page */
  AppSettings = 'APP_SETTINGS',
  /** Injects App Frame in the Communities background */
  CommunitiesBackground = 'COMMUNITIES_BACKGROUND',
  /** Injects App Frame in the Course Player background */
  CoursePlayerBackground = 'COURSE_PLAYER_BACKGROUND',
  /** Injects App Frame in the Performance Checkout background */
  PerformanceCheckoutBackground = 'PERFORMANCE_CHECKOUT_BACKGROUND',
  /** Injects App Frame in the Theme background */
  ThemeBackground = 'THEME_BACKGROUND',
}

/** An object that contains app group information */
export type AppGroup = {
  __typename?: 'AppGroup';
  /** A list of apps belonging in the group */
  apps: Maybe<Array<AppInformation>>;
  /** Description of group */
  description: Maybe<Scalars['String']['output']>;
  /** Friendly name of group */
  friendlyName: Maybe<Scalars['String']['output']>;
  /** Unique name of group */
  name: Scalars['String']['output'];
};

/** An object that contains oauth app information */
export type AppInformation = Node & {
  __typename?: 'AppInformation';
  /** API contact email for the OAuthApp */
  apiContactEmail: Maybe<Scalars['String']['output']>;
  /** Author for the OAuthApp */
  author: Maybe<Scalars['String']['output']>;
  /** Description for the OAuthApp */
  description: Maybe<Scalars['String']['output']>;
  /** Feature key for the OAuthApp */
  featureKey: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Keywords for the OAuthApp */
  keywords: Maybe<Array<Scalars['String']['output']>>;
  /** Logo for the OAuthApp */
  logo: Maybe<Scalars['String']['output']>;
  /** Is the app managed by Thinkific? */
  managedByThinkific: Maybe<Scalars['Boolean']['output']>;
  /** Minimum required plan for the OAuthApp */
  minimumRequiredPlan: Maybe<Scalars['String']['output']>;
  /** Name of the OAuthApp */
  name: Maybe<Scalars['String']['output']>;
  /** PartnerID for the OAuthApp */
  partnerId: Maybe<Scalars['String']['output']>;
  /** Scopes for the OAuthApp */
  scopes: Maybe<Array<Scalars['String']['output']>>;
  /** Slug for the OAuthApp */
  slug: Maybe<Scalars['String']['output']>;
  /** State for the OAuthApp */
  state: Maybe<StateType>;
  /** Support contact form url for the OAuthApp */
  supportContactFormUrl: Maybe<Scalars['String']['output']>;
  /** Support documentation link */
  supportDocLink: Maybe<Scalars['String']['output']>;
  /** Support email for the OAuthApp */
  supportEmail: Maybe<Scalars['String']['output']>;
  /** Date the app was suspended */
  suspendedAt: Maybe<Scalars['DateTime']['output']>;
  /** Suspension reason for the OAuthApp */
  suspensionReason: Maybe<Scalars['String']['output']>;
  /** URL for the OAuthApp */
  url: Maybe<Scalars['String']['output']>;
  /** Marketing website for the OAuthApp */
  website: Maybe<Scalars['String']['output']>;
};

/** Represents information about an App installation. */
export type AppInstallation = Node & {
  __typename?: 'AppInstallation';
  id: Scalars['ID']['output'];
  /** Unique App installation ID */
  installationId: Scalars['ID']['output'];
  /** Returns metafields for current App installation */
  metafields: Array<AppInstallationMetafield>;
  /** Unique slug of the installed app */
  slug: Scalars['String']['output'];
  /** URL of the installed App */
  url: Scalars['String']['output'];
};

/** Possible fields to filter App installation */
export type AppInstallationFilter = {
  /** filter by location where metafields can be made available */
  location?: InputMaybe<Array<AppFrameLocationsEnum>>;
};

/** Represents metafield about an App installation. */
export type AppInstallationMetafield = Node & {
  __typename?: 'AppInstallationMetafield';
  id: Scalars['ID']['output'];
  /** Metafield key */
  key: Scalars['String']['output'];
  /** Metafield value */
  value: Maybe<Scalars['String']['output']>;
};

/** An app store listing */
export type AppListing = {
  __typename?: 'AppListing';
  /** Icon of the app listing */
  icon: Maybe<Scalars['String']['output']>;
  /** Name of the app listing */
  name: Scalars['String']['output'];
  /** Whether or not the app listing is newly listed */
  new: Scalars['Boolean']['output'];
  /** Unique slug for the app listing */
  slug: Scalars['String']['output'];
  /** Summary of the app listing */
  summary: Maybe<Scalars['String']['output']>;
};

/** The Thinkific App Store */
export type AppStore = {
  __typename?: 'AppStore';
  /** A list of app store categories */
  categories: AppStoreCategoryConnection;
  /** A single app store category by slug */
  categoryBySlug: Maybe<AppStoreCategory>;
  /** A single app store collection by slug */
  collectionBySlug: Maybe<AppStoreCollection>;
  /** A list of app store collections */
  collections: AppStoreCollectionConnection;
  /** A single app store group by slug */
  groupBySlug: Maybe<AppStoreGroup>;
  /** A list of app store groups */
  groups: AppStoreGroupConnection;
};

/** The Thinkific App Store */
export type AppStoreCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  slugs?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** The Thinkific App Store */
export type AppStoreCategoryBySlugArgs = {
  slug: Scalars['String']['input'];
};

/** The Thinkific App Store */
export type AppStoreCollectionBySlugArgs = {
  slug: Scalars['String']['input'];
};

/** The Thinkific App Store */
export type AppStoreCollectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  slugs?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** The Thinkific App Store */
export type AppStoreGroupBySlugArgs = {
  slug: Scalars['String']['input'];
};

/** The Thinkific App Store */
export type AppStoreGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  slugs?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** An app store category */
export type AppStoreCategory = {
  __typename?: 'AppStoreCategory';
  /** The listings associated with the category */
  appListings: Array<AppListing>;
  /** The description of the category */
  description: Maybe<Scalars['String']['output']>;
  /** The label of the category to be used when shown as a goal */
  goalLabel: Maybe<Scalars['String']['output']>;
  /** The label of the category */
  label: Scalars['String']['output'];
  /** The unique slug of the category */
  slug: Scalars['String']['output'];
};

/** The connection type for AppStoreCategory. */
export type AppStoreCategoryConnection = {
  __typename?: 'AppStoreCategoryConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<AppStoreCategoryEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<AppStoreCategory>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AppStoreCategoryEdge = {
  __typename?: 'AppStoreCategoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<AppStoreCategory>;
};

/** An app store collection */
export type AppStoreCollection = {
  __typename?: 'AppStoreCollection';
  /** The listings associated with the collection */
  appListings: Array<AppListing>;
  /** The description of the collection */
  description: Maybe<Scalars['String']['output']>;
  /** The label of the collection to be used when shown as a goal */
  goalLabel: Maybe<Scalars['String']['output']>;
  /** The label of the collection */
  label: Scalars['String']['output'];
  /** The unique slug of the collection */
  slug: Scalars['String']['output'];
};

/** The connection type for AppStoreCollection. */
export type AppStoreCollectionConnection = {
  __typename?: 'AppStoreCollectionConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<AppStoreCollectionEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<AppStoreCollection>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AppStoreCollectionEdge = {
  __typename?: 'AppStoreCollectionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<AppStoreCollection>;
};

/** An app store group is used to group related app listings */
export type AppStoreGroup = {
  __typename?: 'AppStoreGroup';
  /** The listings associated with the group */
  appListings: Array<AppListing>;
  /** The description of the group */
  description: Maybe<Scalars['String']['output']>;
  /** The label of the group to be used when shown as a goal */
  goalLabel: Maybe<Scalars['String']['output']>;
  /** The label of the group */
  label: Scalars['String']['output'];
  /** The unique slug of the group */
  slug: Scalars['String']['output'];
};

/** The connection type for AppStoreGroup. */
export type AppStoreGroupConnection = {
  __typename?: 'AppStoreGroupConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<AppStoreGroupEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<AppStoreGroup>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AppStoreGroupEdge = {
  __typename?: 'AppStoreGroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<AppStoreGroup>;
};

/** A query interface for the App Store */
export type AppStoreQueryInterface = {
  appStore: Maybe<AppStore>;
};

/** Apple App Store connect */
export type AppleAppStoreConnect = {
  __typename?: 'AppleAppStoreConnect';
  /** Apple API key file name */
  apiKeyFileName: Maybe<Scalars['String']['output']>;
  /** Apple app store issuer id */
  issuerId: Maybe<Scalars['String']['output']>;
  /** Apple app store key identifier */
  keyIdentifier: Maybe<Scalars['String']['output']>;
  /** Apple push notification API key file name */
  pushNotificationApiKeyFileName: Maybe<Scalars['String']['output']>;
};

/** App Store information */
export type AppleAppStoreInformation = {
  __typename?: 'AppleAppStoreInformation';
  /** Apple app store description */
  appDescription: Maybe<Scalars['String']['output']>;
  /** Apple app store keywords */
  appKeywords: Maybe<Scalars['String']['output']>;
  /** Apple app store name */
  appName: Maybe<Scalars['String']['output']>;
  /** Apple app store primary category */
  appPrimaryCategory: Maybe<Scalars['String']['output']>;
  /** Apple app store promotional text */
  appPromotionalText: Maybe<Scalars['String']['output']>;
  /** Apple app store secondary category */
  appSecondaryCategory: Maybe<Scalars['String']['output']>;
  /** Apple app store subtitle */
  appSubtitle: Maybe<Scalars['String']['output']>;
};

/** Input field for apply a coupon to a cart */
export type ApplyCouponInput = {
  /** Token identifier of the cart to apply discount to */
  cartToken: Scalars['String']['input'];
  /** Code of the coupon to apply to cart */
  couponCode: Scalars['String']['input'];
  /** Student location */
  location?: InputMaybe<BillingAddressInput>;
};

/** Result of applying a coupon to a cart */
export type ApplyCouponResult = ResponseInterface & {
  __typename?: 'ApplyCouponResult';
  /**
   * Response Extra Data
   * @deprecated response fields should be well defined following a object structure
   */
  data: Maybe<Scalars['JSON']['output']>;
  errors: Maybe<Array<Maybe<Error>>>;
  /** Response Message */
  message: Maybe<Scalars['String']['output']>;
  /** Return status: SUCCESS, PARTIAL_SUCCESS or FAILED */
  status: Maybe<ResponseStatus>;
};

/** Autogenerated input type of ApplySettingsToAll */
export type ApplySettingsToAllInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the Course */
  courseId: Scalars['ID']['input'];
  settingsInput: SettingsInput;
};

/** Autogenerated return type of ApplySettingsToAll */
export type ApplySettingsToAllPayload = {
  __typename?: 'ApplySettingsToAllPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Course with updated lesson settings */
  course: Maybe<Course>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of ApplyThemePreset */
export type ApplyThemePresetInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The preset's slug */
  presetSlug: Scalars['String']['input'];
  /** The CustomSiteTheme's Id */
  themeId: Scalars['ID']['input'];
};

/** Autogenerated return type of ApplyThemePreset */
export type ApplyThemePresetPayload = {
  __typename?: 'ApplyThemePresetPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of ApproveMultiInstanceRequest */
export type ApproveMultiInstanceRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Connection token */
  connection_token?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ApproveMultiInstanceRequest */
export type ApproveMultiInstanceRequestPayload = {
  __typename?: 'ApproveMultiInstanceRequestPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /**
   * Response data
   * @deprecated Backwards compatibility for old response
   */
  data: Maybe<Scalars['String']['output']>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
};

/** Archive Video Result */
export type ArchiveVideo = ResponseInterface & {
  __typename?: 'ArchiveVideo';
  /**
   * Response Extra Data
   * @deprecated response fields should be well defined following a object structure
   */
  data: Maybe<Scalars['JSON']['output']>;
  errors: Maybe<Array<Maybe<Error>>>;
  /** Response Message */
  message: Maybe<Scalars['String']['output']>;
  /** Return status: SUCCESS, PARTIAL_SUCCESS or FAILED */
  status: Maybe<ResponseStatus>;
};

/** Input field for archiving a video */
export type ArchiveVideoInput = {
  /** The video ID */
  videoId: Scalars['String']['input'];
};

/** Assignment submissions */
export type Assignment = Node & {
  __typename?: 'Assignment';
  /** Assignment's text content */
  assignmentContent: Maybe<Scalars['String']['output']>;
  /** Assignment's created at date */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** Assignment submission's file size limit */
  fileSizeLimit: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  /** Assignment's lesson */
  lesson: Maybe<LessonUnion>;
  /** Assignment submission's upload message */
  submissionUploadedMessage: Maybe<Scalars['String']['output']>;
  /** Assignment's title */
  title: Maybe<Scalars['String']['output']>;
  /** Assignment's updated at date */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** The connection type for Assignment. */
export type AssignmentConnection = {
  __typename?: 'AssignmentConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<AssignmentEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Assignment>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AssignmentEdge = {
  __typename?: 'AssignmentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Assignment>;
};

/** Possible fields to filter submissions */
export type AssignmentFilter = {
  /** Search by id */
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Assignment lesson information */
export type AssignmentLesson = LessonInterface &
  Node & {
    __typename?: 'AssignmentLesson';
    /** Assignment content */
    assignmentContent: Maybe<Scalars['String']['output']>;
    /** Assignment's submission uploaded message */
    assignmentSubmissionMessage: Maybe<Scalars['String']['output']>;
    /** Assignment's template */
    assignmentTemplate: Maybe<AssignmentTemplate>;
    /** Content's chapter */
    chapter: Chapter;
    /** contentable id */
    contentableId: Scalars['ID']['output'];
    /** Content's course */
    course: Course;
    /** Content's created at date */
    createdAt: Scalars['DateTime']['output'];
    /** Lesson default icon */
    defaultLessonTypeIcon: Scalars['String']['output'];
    /** Is discussion enabled */
    discussionsEnabled: Maybe<Scalars['Boolean']['output']>;
    /** Lesson draft status */
    draft: Scalars['Boolean']['output'];
    /** Lesson's associated Drip Schedule Item */
    dripScheduleItem: Maybe<DripScheduleItem>;
    /** lesson drip status */
    dripStatus: Maybe<Scalars['JSON']['output']>;
    /** Assignment's file size limit */
    fileSizeLimit: Maybe<Scalars['Int']['output']>;
    /** Is lesson free */
    free: Maybe<Scalars['Boolean']['output']>;
    /** Lesson html text */
    htmlDescription: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    /** Current user lesson status */
    lessonCompleted: Maybe<Scalars['Boolean']['output']>;
    /** lesson drip schedule */
    lessonHasDripSchedule: Maybe<Scalars['String']['output']>;
    /** Lesson integer ID */
    lessonId: Scalars['ID']['output'];
    /** Lesson icon */
    lessonTypeIcon: Maybe<LessonIconEnum>;
    /** Lesson label */
    lessonTypeLabel: Maybe<Scalars['String']['output']>;
    /** Lesson url */
    lessonUrl: Maybe<Scalars['String']['output']>;
    /** Lesson name */
    name: Scalars['String']['output'];
    /** Lesson position */
    position: Scalars['Int']['output'];
    /** Is lesson prerequisite */
    prerequisite: Scalars['Boolean']['output'];
    /** Lesson's slug */
    slug: Maybe<Scalars['String']['output']>;
    /** A list of the lesson's download files */
    submissions: Maybe<SubmissionConnection>;
    /** File type */
    templateFileType: Maybe<Scalars['String']['output']>;
    /** Display file name */
    templateLabel: Maybe<Scalars['String']['output']>;
    /** File size - e.g. 3028kb */
    templateSize: Maybe<Scalars['String']['output']>;
    /** Url */
    templateUrl: Maybe<Scalars['String']['output']>;
    /** Lesson Type */
    type: Scalars['String']['output'];
    /** Whether the lesson is still under construction */
    underConstruction: Scalars['Boolean']['output'];
    /** Content's updated at date */
    updatedAt: Scalars['DateTime']['output'];
    /** User content for lesson */
    userContents: Maybe<UserContentConnection>;
    /** User quizzes for lesson */
    userQuizzes: Maybe<UserQuizConnection>;
    /** User surveys for lesson */
    userSurveys: Maybe<UserSurveyConnection>;
  };

/** Assignment lesson information */
export type AssignmentLessonSubmissionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Assignment lesson information */
export type AssignmentLessonUserContentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserContentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserContentOrderByFilter>;
};

/** Assignment lesson information */
export type AssignmentLessonUserQuizzesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserQuizFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserQuizOrderBy>;
};

/** Assignment lesson information */
export type AssignmentLessonUserSurveysArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserSurveyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserSurveyOrderBy>;
};

/** Order for submissions */
export type AssignmentOrderBy = {
  direction: OrderDirection;
  field: AssignmentOrderField;
};

/** Possible fields to order assignments */
export enum AssignmentOrderField {
  /** order assignments by file size */
  CreatedAt = 'createdAt',
  /** order assignments by file size */
  FileSizeLimit = 'fileSizeLimit',
  /** order assignments by title */
  Title = 'title',
  /** order assignments by file size */
  UpdatedAt = 'updatedAt',
}

/** assignment template information */
export type AssignmentTemplate = Node & {
  __typename?: 'AssignmentTemplate';
  /** File type */
  fileType: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Label */
  label: Maybe<Scalars['String']['output']>;
  /** File size - e.g. 3028kb */
  size: Maybe<Scalars['String']['output']>;
  /** Url */
  url: Maybe<Scalars['String']['output']>;
};

/** audio lesson information */
export type AudioLesson = LessonInterface &
  Node & {
    __typename?: 'AudioLesson';
    /** Audio lesson autoplay */
    autoplay: Maybe<Scalars['Boolean']['output']>;
    /** Content's chapter */
    chapter: Chapter;
    /** contentable id */
    contentableId: Scalars['ID']['output'];
    /** Content's course */
    course: Course;
    /** Content's created at date */
    createdAt: Scalars['DateTime']['output'];
    /** Lesson default icon */
    defaultLessonTypeIcon: Scalars['String']['output'];
    /** Is discussion enabled */
    discussionsEnabled: Maybe<Scalars['Boolean']['output']>;
    /** Audio lesson downloadable */
    downloadable: Maybe<Scalars['Boolean']['output']>;
    /** Lesson draft status */
    draft: Scalars['Boolean']['output'];
    /** Lesson's associated Drip Schedule Item */
    dripScheduleItem: Maybe<DripScheduleItem>;
    /** lesson drip status */
    dripStatus: Maybe<Scalars['JSON']['output']>;
    /** Is expand enabled */
    expandEnabled: Maybe<Scalars['Boolean']['output']>;
    /** Is lesson free */
    free: Maybe<Scalars['Boolean']['output']>;
    /** Lesson html text */
    htmlDescription: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    /** Current user lesson status */
    lessonCompleted: Maybe<Scalars['Boolean']['output']>;
    /** lesson drip schedule */
    lessonHasDripSchedule: Maybe<Scalars['String']['output']>;
    /** Lesson integer ID */
    lessonId: Scalars['ID']['output'];
    /** Lesson icon */
    lessonTypeIcon: Maybe<LessonIconEnum>;
    /** Lesson label */
    lessonTypeLabel: Maybe<Scalars['String']['output']>;
    /** Lesson url */
    lessonUrl: Maybe<Scalars['String']['output']>;
    /** Lesson name */
    name: Scalars['String']['output'];
    /** Lesson position */
    position: Scalars['Int']['output'];
    /** Is lesson prerequisite */
    prerequisite: Scalars['Boolean']['output'];
    /** Lesson's slug */
    slug: Maybe<Scalars['String']['output']>;
    /** Lesson Type */
    type: Scalars['String']['output'];
    /** Whether the lesson is still under construction */
    underConstruction: Scalars['Boolean']['output'];
    /** Content's updated at date */
    updatedAt: Scalars['DateTime']['output'];
    /** Audio lesson url */
    url: Maybe<Scalars['String']['output']>;
    /** User content for lesson */
    userContents: Maybe<UserContentConnection>;
    /** User quizzes for lesson */
    userQuizzes: Maybe<UserQuizConnection>;
    /** User surveys for lesson */
    userSurveys: Maybe<UserSurveyConnection>;
  };

/** audio lesson information */
export type AudioLessonUserContentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserContentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserContentOrderByFilter>;
};

/** audio lesson information */
export type AudioLessonUserQuizzesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserQuizFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserQuizOrderBy>;
};

/** audio lesson information */
export type AudioLessonUserSurveysArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserSurveyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserSurveyOrderBy>;
};

export enum AutoRecordingEnum {
  /** auto recording cloud type */
  Cloud = 'cloud',
  /** auto recording local type */
  Local = 'local',
  /** auto recording empty type */
  None = 'none',
}

/** Possible sizes to retrieve an avatar image */
export enum Avatar {
  /** Medium (540x540 px) */
  Medium = 'medium',
  /** Small (220x220 px) */
  Small = 'small',
  /** Thumb (50x50 px) */
  Thumb = 'thumb',
}

/** Currency for a connected bank account along with countries it's supported in. */
export type BankAccountCurrency = {
  __typename?: 'BankAccountCurrency';
  currency: Maybe<Currency>;
  /** List of ISO 3166-1 alpha-2 country codes. Examples: US, CA, AU. */
  supportedCountries: Maybe<Array<Maybe<Country>>>;
};

/** Banner image thumbnail and image urls */
export type BannerImage = {
  __typename?: 'BannerImage';
  /** Onboarding image alt text */
  altText: Maybe<Scalars['String']['output']>;
  /** Onboarding image URL */
  imageUrl: Maybe<Scalars['String']['output']>;
  /** Onboarding thumbnail URL */
  thumbnailUrl: Maybe<Scalars['String']['output']>;
};

/** Input field for student location */
export type BillingAddressInput = {
  /** Student country */
  country?: InputMaybe<Scalars['String']['input']>;
  /** Student postal code */
  postalCode?: InputMaybe<Scalars['String']['input']>;
};

/** Fields relating to BillingInfo */
export type BillingInfo = {
  __typename?: 'BillingInfo';
  /** Tenant's address, line 1 */
  addressLine1: Maybe<Scalars['String']['output']>;
  /** Tenant's address, line 2 */
  addressLine2: Maybe<Scalars['String']['output']>;
  /** Tenant's billing city */
  city: Maybe<Scalars['String']['output']>;
  /** Whether tenant has completed billing info form */
  completedBillingInfoForm: Maybe<Scalars['Boolean']['output']>;
  /** Whether the address has been confirmed by customer */
  confirmedByCustomer: Maybe<Scalars['Boolean']['output']>;
  /** Tenant's country code */
  countryCode: Maybe<Scalars['String']['output']>;
  /** Whether the country requires the zip code for determining tax */
  countryRequiresZipCode: Maybe<Scalars['Boolean']['output']>;
  /** Currency for the tenant */
  currency: Scalars['String']['output'];
  /** Whether the tenant has an existing subscription */
  hasExistingSubscription: Maybe<Scalars['Boolean']['output']>;
  /** Tenant's billing name */
  name: Maybe<Scalars['String']['output']>;
  /** Whether Thinkific VAT number should be shown */
  showThinkificVatNumber: Maybe<Scalars['Boolean']['output']>;
  /** Tenant's billing state */
  state: Maybe<Scalars['String']['output']>;
  /** Whether the tenant is tax exempt */
  taxExempt: Maybe<Scalars['Boolean']['output']>;
  /** Reason tenant gives for being tax exempt */
  taxExemptReason: Maybe<Scalars['String']['output']>;
  /** Tenant tax rate based on billing zip code */
  taxRate: Maybe<Scalars['Float']['output']>;
  /** Thinkific VAT number */
  thinkificVatNumber: Maybe<Scalars['String']['output']>;
  /** Whether tenant has a Stripe customer account */
  validStripeCustomer: Maybe<Scalars['Boolean']['output']>;
  /** Tenant VAT number */
  vatNumber: Maybe<VatNumber>;
  /** Whether tenant has VAT reverse charge enabled */
  vatReverseChargeEnabled: Maybe<Scalars['Boolean']['output']>;
  /** Tenant's billing zip code */
  zipCode: Maybe<Scalars['String']['output']>;
};

/** Validation error from saving the billing info */
export type BillingInfoValidationError = Node & {
  __typename?: 'BillingInfoValidationError';
  /** Billing info field that failed validation */
  field: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Validation error message */
  message: Maybe<Scalars['String']['output']>;
};

/** Branded Mobile Visibility indicator */
export type BrandedMobileVisibility = {
  __typename?: 'BrandedMobileVisibility';
  id: Scalars['ID']['output'];
  /** Whether banner is visible or not */
  isBannerVisible: Scalars['Boolean']['output'];
};

/** Autogenerated input type of BuildTenantBrandedApp */
export type BuildTenantBrandedAppInput = {
  /** ID of branded app configuration */
  appConfigurationId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of BuildTenantBrandedApp */
export type BuildTenantBrandedAppPayload = {
  __typename?: 'BuildTenantBrandedAppPayload';
  /** Tenant branded app configuration */
  brandedAppConfiguration: Maybe<AppConfiguration>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

export enum BulkAffiliatesActionEnum {
  /** add all affiliates from a product */
  Add = 'ADD',
  /** remove all affiliates from a product */
  Remove = 'REMOVE',
}

/** Tenant's bulk enrollment */
export type BulkEnrollment = Node & {
  __typename?: 'BulkEnrollment';
  /** Bulk enrollment's type(enroll|unenroll) */
  enrollType: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Bulk enrollment's progress percentage */
  progressPercentage: Maybe<Scalars['Int']['output']>;
};

/** The connection type for BulkEnrollment. */
export type BulkEnrollmentConnection = {
  __typename?: 'BulkEnrollmentConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<BulkEnrollmentEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<BulkEnrollment>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type BulkEnrollmentEdge = {
  __typename?: 'BulkEnrollmentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<BulkEnrollment>;
};

/** Possible fields to filter bulk enrollments */
export type BulkEnrollmentFilter = {
  /** Search by id */
  id?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Search the one in progress */
  inProgress?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Tenant's bulk live lesson */
export type BulkLiveLesson = Node & {
  __typename?: 'BulkLiveLesson';
  /** Lesson agenda */
  agenda: Maybe<Scalars['String']['output']>;
  /** Duration for zoom item in minutes */
  duration: Scalars['Int']['output'];
  /** End date for the recurring meeting */
  endDateTime: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** Day of the month for the recurring meeting */
  monthlyDay: Maybe<Scalars['Int']['output']>;
  /** Week of the month for the recurring meeting */
  monthlyWeek: Maybe<MonthlyWeekEnum>;
  /** Day of the week for the monthly recurring meeting */
  monthlyWeekDay: Maybe<MonthlyWeekDayEnum>;
  /** The number of occurrences */
  numberOfOccurrences: Maybe<Scalars['Int']['output']>;
  /** Live Lessons recurrence type */
  recurrenceType: RecurrenceTypeEnum;
  /** Live Lessons repeat interval */
  repeatInterval: Scalars['Int']['output'];
  /** start time of zoom item */
  startTime: Scalars['DateTime']['output'];
  /** Topic of zoom item */
  topic: Scalars['String']['output'];
  /** Days for the Weekly recurring meeting */
  weeklyDays: Maybe<Array<MonthlyWeekDayEnum>>;
};

/** Autogenerated input type of BulkUpdatePositions */
export type BulkUpdatePositionsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the Course */
  courseId: Scalars['ID']['input'];
  positionsInput: Array<PositionsInput>;
};

/** Autogenerated return type of BulkUpdatePositions */
export type BulkUpdatePositionsPayload = {
  __typename?: 'BulkUpdatePositionsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of BulkUpdateProducts */
export type BulkUpdateProductsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Products to be updated */
  products: Array<ProductBulkUpdateInput>;
};

/** Autogenerated return type of BulkUpdateProducts */
export type BulkUpdateProductsPayload = {
  __typename?: 'BulkUpdateProductsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Bundle information */
export type Bundle = Node &
  ProductableInterface & {
    __typename?: 'Bundle';
    /**
     * A list of after purchase flows that belong to the product
     * @deprecated Access via the product field instead
     */
    afterPurchaseFlows: Maybe<AfterPurchaseFlowConnection>;
    /** Bundle id */
    bundleId: Maybe<Scalars['Int']['output']>;
    /** Product's card image url */
    cardImageUrl: Maybe<Scalars['String']['output']>;
    /**
     * The number of courses in the bundle
     * @deprecated Use product_count instead
     */
    courseCount: Scalars['Int']['output'];
    /** Product's created date */
    createdAt: Maybe<Scalars['DateTime']['output']>;
    /** Default bundle card image url */
    defaultBundleCardImageUrl: Scalars['String']['output'];
    /** Product's description */
    description: Maybe<Scalars['String']['output']>;
    /** Whether new affiliates is excluded for a product */
    excludeNewAffiliates: Maybe<Scalars['Boolean']['output']>;
    id: Scalars['ID']['output'];
    /** Whether the product is hidden */
    isHidden: Maybe<Scalars['Boolean']['output']>;
    /** Whether the product is presell */
    isPresell: Maybe<Scalars['Boolean']['output']>;
    /** Whether the product is private */
    isPrivate: Maybe<Scalars['Boolean']['output']>;
    /** Whether the product is published */
    isPublished: Maybe<Scalars['Boolean']['output']>;
    /** A list of Items in a Bundle */
    items: Maybe<Array<BundleItem>>;
    /** Product's keywords */
    keywords: Maybe<Scalars['String']['output']>;
    /** Product's Page footer code */
    landingPageLiquidMarkup: Maybe<Scalars['String']['output']>;
    /** The landing page URL */
    landingPageUrl: Scalars['String']['output'];
    /** Product's name */
    name: Maybe<Scalars['String']['output']>;
    /** The product position for ordering */
    position: Maybe<Scalars['Int']['output']>;
    /** Product associated with productable */
    product: Maybe<Product>;
    /** The number of products in the bundle */
    productCount: Scalars['Int']['output'];
    /**
     * Product's id
     * @deprecated Access product id via the product field instead
     */
    productId: Maybe<Scalars['String']['output']>;
    /**
     * A list of product prices that belongs to the product
     * @deprecated Access via the product field instead
     */
    productPrices: Maybe<ProductPriceConnection>;
    /** Product's SEO description */
    seoDescription: Maybe<Scalars['String']['output']>;
    /** Product's SEO title */
    seoTitle: Maybe<Scalars['String']['output']>;
    /** Product's Site Builder Link */
    siteBuilderLink: Maybe<Scalars['String']['output']>;
    /** Product's slug */
    slug: Maybe<Scalars['String']['output']>;
    /** Product's status */
    status: Maybe<Scalars['String']['output']>;
    /** Bundle tagline */
    tagline: Maybe<Scalars['String']['output']>;
    /** Product's title */
    title: Maybe<Scalars['String']['output']>;
    /** Is this a Bundle intended to be used for Memberships? */
    usedForMemberships: Maybe<Scalars['Boolean']['output']>;
  };

/** Bundle information */
export type BundleAfterPurchaseFlowsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Bundle information */
export type BundleItemsArgs = {
  includeDraft?: InputMaybe<Scalars['Boolean']['input']>;
  itemTypeFilter?: InputMaybe<Array<BundleItemEnum>>;
};

/** Bundle information */
export type BundleProductPricesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for Bundle. */
export type BundleConnection = {
  __typename?: 'BundleConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<BundleEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Bundle>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type BundleEdge = {
  __typename?: 'BundleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Bundle>;
};

/** A union of the Bundle item types */
export type BundleItem = Community | Course;

export enum BundleItemEnum {
  /** Search for Communities */
  Community = 'Community',
  /** Search for Courses */
  Course = 'Course',
}

/** Input fields for a bundle */
export type BundlePatch = {
  /** Bundle Card Image Url */
  cardImageUrl?: InputMaybe<Scalars['String']['input']>;
  /** Bundle description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Bundle Hidden status */
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  /** Bundle Private status */
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  /** Bundle keywords */
  keywords?: InputMaybe<Scalars['String']['input']>;
  /** Bundle page code */
  landingPageLiquidMarkup?: InputMaybe<Scalars['String']['input']>;
  /** The name of the bundle */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Bundle SEO description */
  seoDescription?: InputMaybe<Scalars['String']['input']>;
  /** Bundle SEO title */
  seoTitle?: InputMaybe<Scalars['String']['input']>;
  /** The slug of the bundle */
  slug?: InputMaybe<Scalars['String']['input']>;
  /** Bundle tagline */
  tagline?: InputMaybe<Scalars['String']['input']>;
};

/** Bundle welcome email design and state */
export type BundleWelcomeEmail = {
  __typename?: 'BundleWelcomeEmail';
  /** The body of the notification */
  body: Maybe<Scalars['String']['output']>;
  /** Whether the email is enabled */
  isEnabled: Maybe<Scalars['Boolean']['output']>;
  /** The subject of the notification */
  subject: Maybe<Scalars['String']['output']>;
};

/** User bundle enrollments */
export type Bundle_Enrollment = {
  __typename?: 'Bundle_Enrollment';
  /** User bundle activated date */
  activatedAt: Scalars['DateTime']['output'];
  /** Bundle related to the enrollment */
  bundle: Bundle;
  /** User bundle expiry date */
  expiryDate: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** Is subscription active? */
  isSubscriptionActive: Scalars['Boolean']['output'];
};

/** The connection type for Bundle_Enrollment. */
export type Bundle_EnrollmentConnection = {
  __typename?: 'Bundle_EnrollmentConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<Bundle_EnrollmentEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Bundle_Enrollment>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Bundle_EnrollmentEdge = {
  __typename?: 'Bundle_EnrollmentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Bundle_Enrollment>;
};

/** Input field for calculating tax */
export type CalculateTaxInput = {
  /** Token identifier of the cart */
  cartToken: Scalars['String']['input'];
  /** Student location */
  location?: InputMaybe<BillingAddressInput>;
};

/** Result of calculating tax */
export type CalculateTaxResult = ResponseInterface & {
  __typename?: 'CalculateTaxResult';
  /**
   * Response Extra Data
   * @deprecated response fields should be well defined following a object structure
   */
  data: Maybe<Scalars['JSON']['output']>;
  errors: Maybe<Array<Maybe<Error>>>;
  /** Response Message */
  message: Maybe<Scalars['String']['output']>;
  /** Return status: SUCCESS, PARTIAL_SUCCESS or FAILED */
  status: Maybe<ResponseStatus>;
};

/** Autogenerated input type of CancelSiteSubscription */
export type CancelSiteSubscriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** CancelSiteSubscription mutation's input */
  input: SiteSubscriptionInput;
};

/** Autogenerated return type of CancelSiteSubscription */
export type CancelSiteSubscriptionPayload = {
  __typename?: 'CancelSiteSubscriptionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of CancelSubscription */
export type CancelSubscriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Subscription ID */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of CancelSubscription */
export type CancelSubscriptionPayload = {
  __typename?: 'CancelSubscriptionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Credit card info */
export type Card = {
  __typename?: 'Card';
  /** Card brand used in this transaction */
  brand: Maybe<Scalars['String']['output']>;
  /** Expiry month of the card used in this transaction */
  expiryMonth: Maybe<Scalars['Int']['output']>;
  /** Expiry year of the card used in this transaction */
  expiryYear: Maybe<Scalars['Int']['output']>;
  /** Last 4 digits of the card used in this transaction */
  last4Digits: Maybe<Scalars['String']['output']>;
};

/** Carts */
export type Cart = Node & {
  __typename?: 'Cart';
  /** Shows whether the bulk sale option is available for this cart */
  bulkSellable: Scalars['Boolean']['output'];
  /** Can the user enroll in the first product on the cart? */
  canUserEnrollInProduct: Maybe<Scalars['Boolean']['output']>;
  /** Cart's id */
  cartId: Maybe<Scalars['Int']['output']>;
  /** Payments account country */
  country: Maybe<Scalars['String']['output']>;
  /** Coupon applied to this cart */
  coupon: Maybe<CartCoupon>;
  /** Cart's created at datetime */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** Cart's currency code */
  currencyCode: Maybe<Scalars['String']['output']>;
  /** Cart's currency */
  currencySymbol: Maybe<Scalars['String']['output']>;
  /**
   * Indicates whether the given cart is a gift
   * @deprecated Backwards compatibility for old response data
   */
  giftRedemption: Maybe<Scalars['Boolean']['output']>;
  /** Shows whether this cart can be purchased as a gift */
  giftable: Scalars['Boolean']['output'];
  /** Indicates whether the given cart has entered the bulk-sale state */
  hasBulkSale: Scalars['Boolean']['output'];
  /** Is the user associated to Cart */
  hasUser: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether the cart price should be hidden */
  hidePrice: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** Cart line items */
  lineItems: Maybe<LineItemConnection>;
  /** Order associated with the cart */
  order: Maybe<Order>;
  /** Order bumps available to the cart */
  orderBumps: Maybe<OrderBumpConnection>;
  /** Cart's payment provider */
  paymentProvider: Maybe<Scalars['String']['output']>;
  /** Is payment required to complete checkout */
  paymentRequired: Maybe<Scalars['Boolean']['output']>;
  /** A list of Products belonging to the Cart */
  products: Maybe<ProductConnection>;
  /** Is cart purchased? */
  purchased: Maybe<Scalars['Boolean']['output']>;
  /** Cart's token */
  token: Maybe<Scalars['String']['output']>;
  /** Cart's total price */
  totalPrice: Maybe<Scalars['Float']['output']>;
  /** Sum of tax items grouped per tax code */
  totalTaxes: Maybe<TaxItemConnection>;
  /** Cart's updated at datetime */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** Carts */
export type CartLineItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Carts */
export type CartOrderBumpsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Carts */
export type CartProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Carts */
export type CartTotalTaxesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for Cart. */
export type CartConnection = {
  __typename?: 'CartConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<CartEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Cart>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

export type CartCoupon = {
  __typename?: 'CartCoupon';
  /** Coupon amount in the currency set on the card */
  amountInCurrency: Maybe<Scalars['Float']['output']>;
  /** Coupon code */
  code: Maybe<Scalars['String']['output']>;
  /** Promotion discount type */
  discountType: Maybe<Scalars['String']['output']>;
  /** Promotion duration */
  duration: Maybe<Scalars['Int']['output']>;
  /** Promotion discount amount as entered */
  promotionAmount: Maybe<Scalars['Float']['output']>;
  /** Promotion name */
  promotionName: Maybe<Scalars['String']['output']>;
};

/** An edge in a connection. */
export type CartEdge = {
  __typename?: 'CartEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Cart>;
};

/** Possible fields to filter pages */
export type CartFilter = {
  /** Cart's token */
  token: Scalars['String']['input'];
};

export type ChangelogAnnouncementInterface = {
  /** Badge type for changelog */
  badgeType: Maybe<Scalars['String']['output']>;
  /** Category in which entry belongs */
  category: Maybe<Scalars['String']['output']>;
};

/** App Changelog Entry */
export type ChangelogEntry = ChangelogAnnouncementInterface &
  Node & {
    __typename?: 'ChangelogEntry';
    /** Author of entry */
    author: Maybe<Scalars['String']['output']>;
    /** Badge type for changelog */
    badgeType: Maybe<Scalars['String']['output']>;
    /** Category in which entry belongs */
    category: Maybe<Scalars['String']['output']>;
    /** Description of entry */
    description: Maybe<Scalars['String']['output']>;
    /** Global unique ID of entry */
    guid: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    /** Date published */
    publishDate: Maybe<Scalars['String']['output']>;
    /** Time published */
    publishTime: Maybe<Scalars['String']['output']>;
    /** Title of entry */
    title: Maybe<Scalars['String']['output']>;
    /** Visibility of entry */
    visibility: Maybe<ChangelogEntryVisibilities>;
  };

/** The connection type for ChangelogEntry. */
export type ChangelogEntryConnection = {
  __typename?: 'ChangelogEntryConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ChangelogEntryEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<ChangelogEntry>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ChangelogEntryEdge = {
  __typename?: 'ChangelogEntryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<ChangelogEntry>;
};

/** Possible fields to filter changelog entries */
export type ChangelogEntryFilter = {
  /** Changelog Entry ID */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Scope to fetched published entries only */
  published?: InputMaybe<Scalars['Boolean']['input']>;
  /** Scope visibility of entries */
  visibility?: InputMaybe<Array<ChangelogEntryVisibilities>>;
};

/** Available types for Visibility option */
export enum ChangelogEntryVisibilities {
  /** Entry shows both internally and externally */
  All = 'all',
  /** Entry only shows on public Changelog page (changelog.thinkific.com) */
  External = 'external',
  /** Entry only shows in Tenant Dashboard area (AnnouncementModal, Internal Changelog) */
  Internal = 'internal',
}

/** Changelog mutation interface */
export type ChangelogMutationInterface = {
  /** Deleting an existing changelog entry */
  deleteChangelogEntry: Maybe<DeleteEntryResult>;
  /** Save a new/existing changelog entry */
  saveChangelogEntry: Maybe<SaveEntryResult>;
};

/** Changelog mutation interface */
export type ChangelogMutationInterfaceDeleteChangelogEntryArgs = {
  input: DeleteEntryInput;
};

/** Changelog mutation interface */
export type ChangelogMutationInterfaceSaveChangelogEntryArgs = {
  input: SaveEntryInput;
};

/** Changelog Query Interface */
export type ChangelogQueryInterface = {
  /** App Changelog entries */
  changelogEntry: Maybe<ChangelogEntryConnection>;
};

/** Changelog Query Interface */
export type ChangelogQueryInterfaceChangelogEntryArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ChangelogEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Chapters with contents */
export type Chapter = Node & {
  __typename?: 'Chapter';
  /** Chapter's course */
  course: Maybe<Course>;
  id: Scalars['ID']['output'];
  /** Lessons that belongs to this chapter */
  lessons: Maybe<Array<LessonUnion>>;
  /** Chapter's name */
  name: Maybe<Scalars['String']['output']>;
  /** Chapter's position */
  position: Maybe<Scalars['Int']['output']>;
  /** Set lessons to draft */
  setNewLessonsToDraft: Maybe<Scalars['Boolean']['output']>;
};

/** Chapters with contents */
export type ChapterLessonsArgs = {
  includeDraft?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The connection type for Chapter. */
export type ChapterConnection = {
  __typename?: 'ChapterConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ChapterEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Chapter>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ChapterEdge = {
  __typename?: 'ChapterEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Chapter>;
};

/** Autogenerated input type of CheckAllUsersInGroups */
export type CheckAllUsersInGroupsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Filter for Users to be checked against Groups */
  filter?: InputMaybe<UserFilter>;
};

/** Autogenerated return type of CheckAllUsersInGroups */
export type CheckAllUsersInGroupsPayload = {
  __typename?: 'CheckAllUsersInGroupsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /**
   * Response data
   * @deprecated Backwards compatibility for old response data
   */
  data: Maybe<Scalars['JSON']['output']>;
  /**
   * Response error
   * @deprecated Backwards compatibility for old response error
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
};

/** An object with checklist progress and tab title for each checklist and checklist items */
export type Checklist = Node & {
  __typename?: 'Checklist';
  /** Checklist items */
  checklistItems: Array<ChecklistItem>;
  id: Scalars['ID']['output'];
  /** Tenant checklist progress */
  progress: Scalars['Float']['output'];
  /** Checklist tab title */
  tabTitle: Scalars['String']['output'];
  /** Checklist title */
  title: Scalars['String']['output'];
  /** Checklist type */
  type: Scalars['String']['output'];
  /** Checklist version */
  version: Scalars['String']['output'];
};

/** Content for a checklist appcues button */
export type ChecklistAppcuesButton = {
  __typename?: 'ChecklistAppcuesButton';
  /** Checklist itemable button text */
  buttonText: Maybe<Scalars['String']['output']>;
  /** Checklist itemable description */
  description: Maybe<Scalars['String']['output']>;
  /** Checklist itemable flow ID */
  flowId: Maybe<Scalars['String']['output']>;
  /** Indicator of error state */
  hasError: Maybe<Scalars['Boolean']['output']>;
  /** Checklist itemable image */
  image: Maybe<ChecklistItemContentImage>;
  /** Checklist itemable secondary description */
  secondaryDescription: Maybe<Scalars['String']['output']>;
  /** Checklist itemable url */
  url: Maybe<Scalars['String']['output']>;
};

/** Checklist item for a given checklist */
export type ChecklistItem = {
  __typename?: 'ChecklistItem';
  /** Checklist item content */
  content: ChecklistItemContentUnion;
  /** Checklist item id */
  id: Scalars['ID']['output'];
  /** Indicator for if checklist item is complete or not */
  isComplete: Maybe<Scalars['Boolean']['output']>;
  /** Checklist item position */
  position: Scalars['Int']['output'];
  /** Checklist item slug */
  slug: Scalars['String']['output'];
  /** Checklist item title */
  title: Scalars['String']['output'];
};

/** Content for a given checklist item image */
export type ChecklistItemContentImage = {
  __typename?: 'ChecklistItemContentImage';
  /** Checklist item image alt text */
  alt: Maybe<Scalars['String']['output']>;
  /** Checklist item image src */
  src: Maybe<Scalars['String']['output']>;
  /** Checklist item image width */
  width: Maybe<Scalars['Int']['output']>;
};

/** Content for a given checklist item */
export type ChecklistItemContentUnion =
  | ChecklistAppcuesButton
  | ChecklistLink
  | ChecklistVideo;

/** Content for a checklist link */
export type ChecklistLink = {
  __typename?: 'ChecklistLink';
  /** Checklist itemable button text */
  buttonText: Maybe<Scalars['String']['output']>;
  /** Checklist itemable description */
  description: Maybe<Scalars['String']['output']>;
  /** Indicator of whether checklist itemable is downloadable */
  downloadable: Maybe<Scalars['Boolean']['output']>;
  /** Indicator of error state */
  hasError: Maybe<Scalars['Boolean']['output']>;
  /** Checklist itemable image */
  image: Maybe<ChecklistItemContentImage>;
  /** Checklist itemable secondary description */
  secondaryDescription: Maybe<Scalars['String']['output']>;
  /** Checklist itemable url */
  url: Maybe<Scalars['String']['output']>;
};

/** Checklist type */
export enum ChecklistTypesEnum {
  /** Communities onboarding checklist */
  Communities = 'communities',
  /** Courses onboarding checklist */
  Courses = 'courses',
  /** Get started onboarding checklist */
  GetStarted = 'get_started',
  /** Launch onboarding checklist */
  Launch = 'launch',
}

/** Content for a checklist video */
export type ChecklistVideo = {
  __typename?: 'ChecklistVideo';
  /** Checklist itemable button overlay text */
  buttonOverlayText: Maybe<Scalars['String']['output']>;
  /** Checklist itemable content label */
  contentLabel: Maybe<Scalars['String']['output']>;
  /** Checklist itemable description */
  description: Maybe<Scalars['String']['output']>;
  /** Checklist itemable secondary description */
  secondaryDescription: Maybe<Scalars['String']['output']>;
  /** Checklist itemable thumbnail image */
  thumbnailImage: Maybe<ChecklistItemContentImage>;
  /** Checklist itemable video url */
  videoUrl: Maybe<Scalars['String']['output']>;
};

/** Fields relating to BillingInfo on the in-app checkout page */
export type CheckoutBillingInfo = {
  __typename?: 'CheckoutBillingInfo';
  /** Tenant's address, line 1 */
  addressLine1: Maybe<Scalars['String']['output']>;
  /** Tenant's address, line 2 */
  addressLine2: Maybe<Scalars['String']['output']>;
  /** Tenant's billing city */
  city: Maybe<Scalars['String']['output']>;
  /** Whether tenant has completed billing info form */
  completedBillingInfoForm: Maybe<Scalars['Boolean']['output']>;
  /** Whether the address has been confirmed by customer */
  confirmedByCustomer: Maybe<Scalars['Boolean']['output']>;
  /** Tenant's country code */
  countryCode: Maybe<Scalars['String']['output']>;
  /** Whether the country requires the zip code for determining tax */
  countryRequiresZipCode: Maybe<Scalars['Boolean']['output']>;
  /** Google Places API key for billing info form */
  googlePlacesApiKey: Maybe<Scalars['String']['output']>;
  /** Whether the tenant has an existing subscription */
  hasExistingSubscription: Maybe<Scalars['Boolean']['output']>;
  /** Tenant's billing name */
  name: Maybe<Scalars['String']['output']>;
  /** Whether Thinkific VAT number should be shown */
  showThinkificVatNumber: Maybe<Scalars['Boolean']['output']>;
  /** Tenant's billing state */
  state: Maybe<Scalars['String']['output']>;
  /** Stripe publishable key for billing info form */
  stripePublishableKey: Maybe<Scalars['String']['output']>;
  /** Whether the tenant is tax exempt */
  taxExempt: Maybe<Scalars['Boolean']['output']>;
  /** Reason tenant gives for being tax exempt */
  taxExemptReason: Maybe<Scalars['String']['output']>;
  /** Tenant tax rate based on billing zip code */
  taxRate: Maybe<Scalars['Float']['output']>;
  /** Taxable country codes, including CA/US, EU/UK */
  taxableCountryCodes: Maybe<Array<Scalars['String']['output']>>;
  /** Thinkific VAT number */
  thinkificVatNumber: Maybe<Scalars['String']['output']>;
  /** Whether tenant has a Stripe customer account */
  validStripeCustomer: Maybe<Scalars['Boolean']['output']>;
  /** VAT eligible country codes */
  vatCountryCodes: Maybe<Array<Scalars['String']['output']>>;
  /** Tenant VAT number */
  vatNumber: Maybe<VatNumber>;
  /** Whether tenant has VAT reverse charge enabled */
  vatReverseChargeEnabled: Maybe<Scalars['Boolean']['output']>;
  /** Tenant's billing zip code */
  zipCode: Maybe<Scalars['String']['output']>;
};

/** Quiz question choice information */
export type Choice = Node & {
  __typename?: 'Choice';
  /** Whether the choice is an acceptable answer */
  credited: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  /** The position of the choice */
  position: Maybe<Scalars['Int']['output']>;
  /** The choice text */
  text: Maybe<Scalars['String']['output']>;
};

/** The connection type for Choice. */
export type ChoiceConnection = {
  __typename?: 'ChoiceConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ChoiceEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Choice>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ChoiceEdge = {
  __typename?: 'ChoiceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Choice>;
};

/** Inputs for claiming promotional deal */
export type ClaimPromotionalDeal = {
  /** Tenant first name */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Tenant last name */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Tenant mixpanel ID */
  mixpanelId?: InputMaybe<Scalars['String']['input']>;
  /** Onboarding Flow ID */
  onboardingFlowId: Scalars['ID']['input'];
  /** Tenant password */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Tenant affiliate code */
  referringAffiliateCode?: InputMaybe<Scalars['String']['input']>;
  /** Tenant referring PartnerStack key */
  referringPartnerKey?: InputMaybe<Scalars['String']['input']>;
  /** Tenant share a sale SSCID */
  shareASaleSscid?: InputMaybe<Scalars['String']['input']>;
  /** Tenant subdomain */
  subdomain?: InputMaybe<Scalars['String']['input']>;
  /** Tenant details */
  tenantBillingInfo: TenantBillingInfoInput;
};

/** Autogenerated input type of ClaimPromotionalDeal */
export type ClaimPromotionalDealInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  input: ClaimPromotionalDeal;
};

/** Autogenerated return type of ClaimPromotionalDeal */
export type ClaimPromotionalDealPayload = {
  __typename?: 'ClaimPromotionalDealPayload';
  /** Flag to determine if promotion was claimed */
  claimSuccessful: Maybe<Scalars['Boolean']['output']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Error messages when failed to claim promotion */
  errorMessage: Maybe<Scalars['String']['output']>;
  /** Flag to determine if the user is new/existing */
  newUser: Maybe<Scalars['Boolean']['output']>;
  /** Object to determine the payment's status */
  paymentIntent: Maybe<PaymentIntent>;
  /** Redirect URL address */
  redirectUrl: Maybe<Scalars['String']['output']>;
  /** Tenant associated with onboarding flow */
  tenant: Maybe<VerifiedTenants>;
  /** Flag to determine if a new account was created successfully */
  userCreationSuccess: Maybe<Scalars['Boolean']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Tenant's collection */
export type Collection = Node & {
  __typename?: 'Collection';
  /** Collections's created at date */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** Collections's default */
  default: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** Collections's name */
  name: Scalars['String']['output'];
  /** Collections's position */
  position: Scalars['Int']['output'];
  /** A list of products in this collection */
  products: ProductConnection;
  /** Collections's raw id */
  rawId: Scalars['Int']['output'];
  /** Collections's slug */
  slug: Scalars['String']['output'];
  /** Collections's updated at date */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** Tenant's collection */
export type CollectionProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CollectionProductFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for Collection. */
export type CollectionConnection = {
  __typename?: 'CollectionConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<CollectionEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Collection>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CollectionEdge = {
  __typename?: 'CollectionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Collection>;
};

/** Possible fields to filter collections */
export type CollectionFilter = {
  /** Exclude collections with only draft or hidden products */
  excludeEmptyCollections?: InputMaybe<Scalars['Boolean']['input']>;
  /** Search by id */
  id?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Search by rawId */
  rawId?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Search by slug */
  slugs?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Possible fields to filter collection products */
export type CollectionProductFilter = {
  /** Search by publication status */
  publicationStatus?: InputMaybe<Array<ProductStatus>>;
};

/** Communities course query interface */
export type CommunitiesCourseQueryInterface = {
  /** The community product relation */
  communityProductRelation: Maybe<CommunityProductRelation>;
};

/** Communities feature */
export type CommunitiesFeature = FeatureInterface & {
  __typename?: 'CommunitiesFeature';
  /** Feature checkout path */
  checkoutPath: Maybe<Scalars['String']['output']>;
  /** Flag to indicate feature is enabled */
  isEnabled: Maybe<Scalars['Boolean']['output']>;
  /** Maximum number of communities */
  maxCommunities: Scalars['Int']['output'];
  /** Maximum number of community spaces */
  maxCommunitySpaces: Scalars['Int']['output'];
  /** The name of the minimum plan required to access this feature */
  minimumPlanName: Maybe<Scalars['String']['output']>;
};

/** Communities mutation interface */
export type CommunitiesMutationInterface = {
  /** Create a Community */
  createCommunity: Maybe<CreateCommunityPayload>;
  /** Create a Community Space */
  createCommunitySpace: Maybe<CreateCommunitySpacePayload>;
  /** Create a post */
  createPost: Maybe<CreatePostPayload>;
  /** Create a reply */
  createReply: Maybe<CreateReplyPayload>;
  /** Delete a Space */
  deleteCommunitySpace: Maybe<DeleteCommunitySpacePayload>;
  /** Delete a Post */
  deletePost: Maybe<DeletePostPayload>;
  /** Delete a reply */
  deleteReply: Maybe<DeleteReplyPayload>;
  /** Edit a post */
  editPost: Maybe<EditPostPayload>;
  /** Edit a reply */
  editReply: Maybe<EditReplyPayload>;
  /** Follows a post */
  followPost: Maybe<FollowPostPayload>;
  /** Follows a reply */
  followReply: Maybe<FollowReplyPayload>;
  /** Pin a post */
  pinPost: Maybe<PinPostPayload>;
  /** Reorder a community_space */
  reorderCommunitySpace: Maybe<ReorderCommunitySpacePayload>;
  /** Toggle Reaction on a Post */
  toggleReactionOnPost: Maybe<ToggleReactionOnPostPayload>;
  /** Reacts on a Reply */
  toggleReactionOnReply: Maybe<ToggleReactionOnReplyPayload>;
  /** Unfollows a post */
  unfollowPost: Maybe<UnfollowPostPayload>;
  /** Unfollows a reply */
  unfollowReply: Maybe<UnfollowReplyPayload>;
  /** Unpin a post */
  unpinPost: Maybe<UnpinPostPayload>;
  /** Update a Community */
  updateCommunity: Maybe<UpdateCommunityPayload>;
  /** Update a community_space */
  updateCommunitySpace: Maybe<UpdateCommunitySpacePayload>;
};

/** Communities mutation interface */
export type CommunitiesMutationInterfaceCreateCommunityArgs = {
  input: CreateCommunityInput;
};

/** Communities mutation interface */
export type CommunitiesMutationInterfaceCreateCommunitySpaceArgs = {
  input: CreateCommunitySpaceInput;
};

/** Communities mutation interface */
export type CommunitiesMutationInterfaceCreatePostArgs = {
  input: CreatePostInput;
};

/** Communities mutation interface */
export type CommunitiesMutationInterfaceCreateReplyArgs = {
  input: CreateReplyInput;
};

/** Communities mutation interface */
export type CommunitiesMutationInterfaceDeleteCommunitySpaceArgs = {
  input: DeleteCommunitySpaceInput;
};

/** Communities mutation interface */
export type CommunitiesMutationInterfaceDeletePostArgs = {
  input: DeletePostInput;
};

/** Communities mutation interface */
export type CommunitiesMutationInterfaceDeleteReplyArgs = {
  input: DeleteReplyInput;
};

/** Communities mutation interface */
export type CommunitiesMutationInterfaceEditPostArgs = {
  input: EditPostInput;
};

/** Communities mutation interface */
export type CommunitiesMutationInterfaceEditReplyArgs = {
  input: EditReplyInput;
};

/** Communities mutation interface */
export type CommunitiesMutationInterfaceFollowPostArgs = {
  input: FollowPostInput;
};

/** Communities mutation interface */
export type CommunitiesMutationInterfaceFollowReplyArgs = {
  input: FollowReplyInput;
};

/** Communities mutation interface */
export type CommunitiesMutationInterfacePinPostArgs = {
  input: PinPostInput;
};

/** Communities mutation interface */
export type CommunitiesMutationInterfaceReorderCommunitySpaceArgs = {
  input: ReorderCommunitySpaceInput;
};

/** Communities mutation interface */
export type CommunitiesMutationInterfaceToggleReactionOnPostArgs = {
  input: ToggleReactionOnPostInput;
};

/** Communities mutation interface */
export type CommunitiesMutationInterfaceToggleReactionOnReplyArgs = {
  input: ToggleReactionOnReplyInput;
};

/** Communities mutation interface */
export type CommunitiesMutationInterfaceUnfollowPostArgs = {
  input: UnfollowPostInput;
};

/** Communities mutation interface */
export type CommunitiesMutationInterfaceUnfollowReplyArgs = {
  input: UnfollowReplyInput;
};

/** Communities mutation interface */
export type CommunitiesMutationInterfaceUnpinPostArgs = {
  input: UnpinPostInput;
};

/** Communities mutation interface */
export type CommunitiesMutationInterfaceUpdateCommunityArgs = {
  input: UpdateCommunityInput;
};

/** Communities mutation interface */
export type CommunitiesMutationInterfaceUpdateCommunitySpaceArgs = {
  input: UpdateCommunitySpaceInput;
};

/** Communities query interface */
export type CommunitiesQueryInterface = {
  communities: Maybe<CommunityConnection>;
  community: Maybe<Community>;
};

/** Communities query interface */
export type CommunitiesQueryInterfaceCommunitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommunityFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CommunityOrderBy>;
};

/** Communities query interface */
export type CommunitiesQueryInterfaceCommunityArgs = {
  id: Scalars['ID']['input'];
};

/** Communities tenant query interface */
export type CommunitiesTenantQueryInterface = {
  communities: Maybe<CommunityConnection>;
  community: Community;
};

/** Communities tenant query interface */
export type CommunitiesTenantQueryInterfaceCommunitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommunityFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CommunityOrderBy>;
};

/** Communities tenant query interface */
export type CommunitiesTenantQueryInterfaceCommunityArgs = {
  id: Scalars['ID']['input'];
};

/** Communities user query interface */
export type CommunitiesUserQueryInterface = {
  /** A list of community users */
  communityUsers: Maybe<CommunityUserConnection>;
  /** User's total number of communities */
  numberOfCommunities: Maybe<Scalars['Int']['output']>;
};

/** Communities user query interface */
export type CommunitiesUserQueryInterfaceCommunityUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommunityUserFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CommunityUserOrderBy>;
};

/** A community */
export type Community = Node & {
  __typename?: 'Community';
  /** A list of Groups that students must be a part of to be able to access the community */
  accessGroups: Maybe<GroupConnection>;
  /** A list of Products that students must be enrolled in to be able to access the community */
  accessProducts: Maybe<ProductConnection>;
  /** Community access type */
  accessType: Maybe<Scalars['String']['output']>;
  /** The card image displayed on the community */
  cardImageUrl: Maybe<Scalars['String']['output']>;
  /** List of a community's product relations */
  communityProductRelations: Maybe<CommunityProductRelationConnection>;
  /** A list of the sections on the community */
  communitySections: Maybe<CommunitySectionConnection>;
  /** Find a community_space by ID */
  communitySpace: Maybe<CommunitySpace>;
  /** Find a community user by user id */
  communityUser: Maybe<CommunityUser>;
  /** List of users in a community */
  communityUsers: Maybe<CommunityUserConnection>;
  /** A description of the community */
  description: Maybe<Scalars['String']['output']>;
  /** The font used on the community */
  font: Maybe<Scalars['String']['output']>;
  /**
   * A list of Groups that students must be a part of to be able to access the community
   * @deprecated use access_groups instead
   */
  groups: Maybe<GroupConnection>;
  id: Scalars['ID']['output'];
  /** The community banner image */
  imageUrl: Maybe<Scalars['String']['output']>;
  /** The keywords of the product associated with the community */
  keywords: Maybe<Scalars['String']['output']>;
  /** The URL pointing to the Community's landing page */
  landingPageUrl: Scalars['String']['output'];
  /** List of mentionable users in a community */
  mentionableUsers: Maybe<CommunityUserConnection>;
  /** The name of the community */
  name: Maybe<Scalars['String']['output']>;
  /** Find a post by ID */
  post: Maybe<Post>;
  /** List of a community's posts */
  posts: Maybe<PostConnection>;
  /** The primary color of the community */
  primaryColor: Maybe<Scalars['String']['output']>;
  /** The product community is associated with */
  product: Product;
  /**
   * A list of Products that students must be enrolled in to be able to access the community
   * @deprecated use access_products instead
   */
  products: Maybe<ProductConnection>;
  /** The published state of the community */
  published: Maybe<Scalars['Boolean']['output']>;
  /** Find a reply by ID */
  reply: Maybe<Reply>;
  /** The AWS S3 path prefix for uploaded files */
  s3UploadPath: Maybe<Scalars['String']['output']>;
  /** Search on posts within a community */
  searchableCommunityPosts: Maybe<SearchablePostConnection>;
  /** The SEO description of the product associated with the community */
  seoDescription: Maybe<Scalars['String']['output']>;
  /** The SEO title of the product associated with the community */
  seoTitle: Maybe<Scalars['String']['output']>;
  /** Community's slug (Used for site landing page URL) */
  slug: Scalars['String']['output'];
  /** The status of the product associated with the community */
  status: Scalars['String']['output'];
};

/** A community */
export type CommunityAccessGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A community */
export type CommunityAccessProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A community */
export type CommunityCommunityProductRelationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  relatableId?: InputMaybe<Scalars['ID']['input']>;
  relatableType?: InputMaybe<Scalars['String']['input']>;
};

/** A community */
export type CommunityCommunitySectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A community */
export type CommunityCommunitySpaceArgs = {
  id: Scalars['ID']['input'];
};

/** A community */
export type CommunityCommunityUserArgs = {
  userId: Scalars['ID']['input'];
};

/** A community */
export type CommunityCommunityUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommunityUserFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CommunityUserOrderBy>;
};

/** A community */
export type CommunityGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A community */
export type CommunityMentionableUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

/** A community */
export type CommunityPostArgs = {
  id: Scalars['ID']['input'];
};

/** A community */
export type CommunityPostsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PostFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PostOrderBy>;
};

/** A community */
export type CommunityProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A community */
export type CommunityReplyArgs = {
  id: Scalars['ID']['input'];
};

/** A community */
export type CommunitySearchableCommunityPostsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  searchAuthor?: InputMaybe<Scalars['String']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

/** The connection type for Community. */
export type CommunityConnection = {
  __typename?: 'CommunityConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<CommunityEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Community>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CommunityEdge = {
  __typename?: 'CommunityEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Community>;
};

/** Possible fields to filter communities */
export type CommunityFilter = {
  /** Filter by community name */
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Possible fields to order communities by */
export type CommunityOrderBy = {
  direction?: InputMaybe<OrderDirection>;
  field?: InputMaybe<CommunityOrderField>;
};

/** Possible fields to order communities */
export enum CommunityOrderField {
  /** Order communities by created_at */
  CreatedAt = 'CREATED_AT',
  /** Order communities by product_position */
  ProductPosition = 'PRODUCT_POSITION',
}

/** CommunityProductRelation information */
export type CommunityProductRelation = Node & {
  __typename?: 'CommunityProductRelation';
  /** The associated community */
  community: Maybe<Community>;
  id: Scalars['ID']['output'];
  /** The associated product */
  product: Maybe<Product>;
  /** The related community aspect */
  relation: Maybe<CommunityProductRelationUnion>;
};

/** The connection type for CommunityProductRelation. */
export type CommunityProductRelationConnection = {
  __typename?: 'CommunityProductRelationConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<CommunityProductRelationEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<CommunityProductRelation>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CommunityProductRelationEdge = {
  __typename?: 'CommunityProductRelationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<CommunityProductRelation>;
};

/** The community product relation union */
export type CommunityProductRelationUnion = CommunitySpace;

/** CommunitySection information */
export type CommunitySection = Node & {
  __typename?: 'CommunitySection';
  /** A list of the spaces in the section */
  communitySpaces: Maybe<CommunitySpaceConnection>;
  id: Scalars['ID']['output'];
  /** Section name */
  name: Maybe<Scalars['String']['output']>;
};

/** CommunitySection information */
export type CommunitySectionCommunitySpacesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for CommunitySection. */
export type CommunitySectionConnection = {
  __typename?: 'CommunitySectionConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<CommunitySectionEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<CommunitySection>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CommunitySectionEdge = {
  __typename?: 'CommunitySectionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<CommunitySection>;
};

/** Space information */
export type CommunitySpace = Node & {
  __typename?: 'CommunitySpace';
  /** The Community associated with the Space */
  community: Maybe<Community>;
  /** The Product Relation for this space */
  communityProductRelation: Maybe<CommunityProductRelation>;
  /** Space section */
  communitySection: Maybe<CommunitySection>;
  /** Space description */
  description: Maybe<Scalars['String']['output']>;
  /** Space icon */
  iconName: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Space image alt text */
  imageAltText: Maybe<Scalars['String']['output']>;
  /** Space image url */
  imageUrl: Maybe<Scalars['String']['output']>;
  /** Whether the space is exclusive or not */
  isExclusive: Maybe<Scalars['Boolean']['output']>;
  /** Space image name */
  name: Maybe<Scalars['String']['output']>;
  /** Space image position */
  position: Maybe<Scalars['Int']['output']>;
  /** Space posts */
  posts: Maybe<PostConnection>;
  /** The AWS S3 path prefix for uploaded files */
  s3UploadPath: Maybe<Scalars['String']['output']>;
};

/** Space information */
export type CommunitySpacePostsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PostFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PostOrderBy>;
};

/** The connection type for CommunitySpace. */
export type CommunitySpaceConnection = {
  __typename?: 'CommunitySpaceConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<CommunitySpaceEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<CommunitySpace>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CommunitySpaceEdge = {
  __typename?: 'CommunitySpaceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<CommunitySpace>;
};

/** Community User information */
export type CommunityUser = Node & {
  __typename?: 'CommunityUser';
  /** Community user activated date */
  activatedAt: Scalars['DateTime']['output'];
  /** Community associated with the community user */
  community: Community;
  /** Community user expiry date */
  expiresAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** Is subscription active? */
  isSubscriptionActive: Scalars['Boolean']['output'];
  /** User */
  user: Maybe<User>;
};

/** The connection type for CommunityUser. */
export type CommunityUserConnection = {
  __typename?: 'CommunityUserConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<CommunityUserEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<CommunityUser>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CommunityUserEdge = {
  __typename?: 'CommunityUserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<CommunityUser>;
};

/** Possible fields to filter community users */
export type CommunityUserFilter = {
  /** Filter by active community users */
  active?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Possible fields and directions to order community users */
export type CommunityUserOrderBy = {
  direction?: InputMaybe<OrderDirection>;
  field?: InputMaybe<CommunityUserOrderField>;
};

/** Possible fields to order community users */
export enum CommunityUserOrderField {
  /** Order community users by community_name */
  CommunityName = 'COMMUNITY_NAME',
  /** Order community users by created_at */
  CreatedAt = 'CREATED_AT',
  /** Order community users by expires_at */
  ExpiresAt = 'EXPIRES_AT',
}

/** Input field for completing a paid checkout */
export type CompleteCheckoutInput = {
  /** The customer's country */
  billingCountry?: InputMaybe<Scalars['String']['input']>;
  /** The customer's postal or zip code */
  billingPostCode?: InputMaybe<Scalars['String']['input']>;
  /** Checkout's associated cart */
  cartToken: Scalars['String']['input'];
  gift?: InputMaybe<GiftInput>;
  /** Order id */
  orderId?: InputMaybe<Scalars['Int']['input']>;
  /** Provider specific payment data */
  paymentData: Scalars['JSON']['input'];
  /** Checkout's associated product price */
  productPriceId: Scalars['String']['input'];
  /** Checkout's associated product prices */
  productPriceIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Provider of payment data */
  provider: Scalars['String']['input'];
  /** The token returned from checkout's invisible Recaptcha widget */
  recaptchaToken?: InputMaybe<Scalars['String']['input']>;
  /** Is payment purchased with a new card? */
  usingNewCard?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Result of completing a paid checkout */
export type CompleteCheckoutResult = ResponseInterface & {
  __typename?: 'CompleteCheckoutResult';
  /** URL to go to after a successful purchase */
  afterPurchaseUrl: Maybe<Scalars['String']['output']>;
  /**
   * Response Extra Data
   * @deprecated response fields should be well defined following a object structure
   */
  data: Maybe<Scalars['JSON']['output']>;
  errors: Maybe<Array<Maybe<Error>>>;
  /** Response Message */
  message: Maybe<Scalars['String']['output']>;
  /** Additional data relating to payment status */
  metadata: Maybe<Scalars['JSON']['output']>;
  /** The order transaction id */
  orderTransactionId: Maybe<Scalars['String']['output']>;
  /** The status of the payment attempt */
  paymentStatus: Maybe<Scalars['String']['output']>;
  /** Return status: SUCCESS, PARTIAL_SUCCESS or FAILED */
  status: Maybe<ResponseStatus>;
};

/** Input field for completing a free checkout */
export type CompleteCheckoutWithoutPaymentInput = {
  /** Checkout's associated cart */
  cartToken: Scalars['String']['input'];
  /** Checkout's associated product price */
  productPriceId: Scalars['String']['input'];
};

/** Result of completing a free checkout */
export type CompleteCheckoutWithoutPaymentResult = ResponseInterface & {
  __typename?: 'CompleteCheckoutWithoutPaymentResult';
  /**
   * Response Extra Data
   * @deprecated response fields should be well defined following a object structure
   */
  data: Maybe<Scalars['JSON']['output']>;
  errors: Maybe<Array<Maybe<Error>>>;
  /** Response Message */
  message: Maybe<Scalars['String']['output']>;
  /** Return status: SUCCESS, PARTIAL_SUCCESS or FAILED */
  status: Maybe<ResponseStatus>;
};

/** Autogenerated input type of CompleteDashboardPromptByPrompt */
export type CompleteDashboardPromptByPromptInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  promptType: Scalars['String']['input'];
};

/** Autogenerated return type of CompleteDashboardPromptByPrompt */
export type CompleteDashboardPromptByPromptPayload = {
  __typename?: 'CompleteDashboardPromptByPromptPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The dashboard prompt */
  dashboardPrompt: Maybe<DashboardPrompt>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Scalars['String']['output'];
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Scalars['String']['output'];
};

/** Autogenerated return type of CompleteDashboardPrompt */
export type CompleteDashboardPromptPayload = {
  __typename?: 'CompleteDashboardPromptPayload';
  /** The dashboard prompt */
  dashboardPrompt: Maybe<DashboardPrompt>;
  /**
   * Response error
   * @deprecated Backwards compatibility for old response error
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Scalars['String']['output'];
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Scalars['String']['output'];
};

/** Autogenerated input type of CompleteLesson */
export type CompleteLessonInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the lesson (AKA content) */
  lessonId: Scalars['ID']['input'];
};

/** Autogenerated return type of CompleteLesson */
export type CompleteLessonPayload = {
  __typename?: 'CompleteLessonPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The lesson */
  lesson: Maybe<LessonUnion>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of CompleteTenantChecklistItem */
export type CompleteTenantChecklistItemInput = {
  /** Checklist Item Slug */
  checklistItemSlug: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CompleteTenantChecklistItem */
export type CompleteTenantChecklistItemPayload = {
  __typename?: 'CompleteTenantChecklistItemPayload';
  /** Checklist ID */
  checklistId: Maybe<Scalars['String']['output']>;
  /** Tenant Checklist Progress */
  checklistProgress: Maybe<Scalars['Int']['output']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** New Tenant Checklist Item */
  tenantChecklistItem: Maybe<TenantChecklistItem>;
  userErrors: Maybe<Array<UserError>>;
};

/** Inputs for confirming payment once strong customer authentication is successful */
export type ConfirmPurchase = {
  /** Onboarding Flow ID */
  onboardingFlowId: Scalars['ID']['input'];
  /** Payment Intent from Stripe result */
  paymentIntent: Scalars['ID']['input'];
};

/** Autogenerated input type of ConfirmPurchase */
export type ConfirmPurchaseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  input: ConfirmPurchase;
};

/** Autogenerated return type of ConfirmPurchase */
export type ConfirmPurchasePayload = {
  __typename?: 'ConfirmPurchasePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** App Connect */
export type Connect = {
  __typename?: 'Connect';
  /** App Store connect */
  appleAppStore: Maybe<AppleAppStoreConnect>;
  /** Google Store connect */
  googleAppStore: Maybe<GoogleAppStoreConnect>;
};

/** A Convertkit configuration */
export type ConvertkitConfiguration = Node & {
  __typename?: 'ConvertkitConfiguration';
  /** The configurations's id */
  configurationId: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  /** Whether or not to send purchases to convertkit account */
  purchaseSync: Maybe<Scalars['Boolean']['output']>;
};

/** A Convertkit item */
export type ConvertkitItem = Node & {
  __typename?: 'ConvertkitItem';
  id: Scalars['ID']['output'];
  /** The item's id */
  itemId: Scalars['Int']['output'];
  /** The item's item type */
  itemType: Maybe<Scalars['String']['output']>;
  /** The item's name */
  name: Scalars['String']['output'];
  /** The item's tags */
  tags: Maybe<Array<ConvertkitTag>>;
};

/** Convertkit queries */
export type ConvertkitQueryInterface = {
  __typename?: 'ConvertkitQueryInterface';
  /** Convertkit configuration */
  configuration: ConvertkitConfiguration;
  /** Convertkit items */
  items: Array<ConvertkitItem>;
  /** Convertkit tags */
  tags: Array<ConvertkitTag>;
};

/** Convertkit queries */
export type ConvertkitQueryInterfaceItemsArgs = {
  eventName: Scalars['String']['input'];
};

/** Convertkit queries */
export type ConvertkitQueryInterfaceTagsArgs = {
  eventName?: InputMaybe<Scalars['String']['input']>;
  itemId?: InputMaybe<Scalars['Int']['input']>;
  itemType?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of ConvertkitSaveTags */
export type ConvertkitSaveTagsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The event name */
  eventName: Scalars['String']['input'];
  /** The item id */
  itemId?: InputMaybe<Scalars['Int']['input']>;
  /** The item type */
  itemType?: InputMaybe<Scalars['String']['input']>;
  /** The tag ids */
  tagIds: Array<Scalars['Int']['input']>;
};

/** Autogenerated return type of ConvertkitSaveTags */
export type ConvertkitSaveTagsPayload = {
  __typename?: 'ConvertkitSaveTagsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** A Convertkit tag */
export type ConvertkitTag = Node & {
  __typename?: 'ConvertkitTag';
  id: Scalars['ID']['output'];
  /** The tag's name */
  name: Scalars['String']['output'];
  /** The tag's id */
  tagId: Scalars['Int']['output'];
};

/** Autogenerated input type of ConvertkitUpdateConfiguration */
export type ConvertkitUpdateConfigurationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The purchase sync value */
  purchaseSync: Scalars['Boolean']['input'];
};

/** Autogenerated return type of ConvertkitUpdateConfiguration */
export type ConvertkitUpdateConfigurationPayload = {
  __typename?: 'ConvertkitUpdateConfigurationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of CopyCourse */
export type CopyCourseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The course's ID */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of CopyCourse */
export type CopyCoursePayload = {
  __typename?: 'CopyCoursePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /**
   * Response error
   * @deprecated Backwards compatibility for old response error
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of CopyLesson */
export type CopyLessonInput = {
  /** The chapter ID of the Course */
  chapterId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the lesson (AKA content) */
  lessonId: Scalars['ID']['input'];
};

/** Autogenerated return type of CopyLesson */
export type CopyLessonPayload = {
  __typename?: 'CopyLessonPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Created copy of the given lesson */
  lesson: Maybe<LessonUnion>;
  userErrors: Maybe<Array<UserError>>;
};

/** Country ISO code along with a user-friendly name. */
export type Country = {
  __typename?: 'Country';
  /** Two-letter ISO country code. Examples: CA, US, etc. */
  code: Maybe<Scalars['String']['output']>;
  /** User-friendly country name. Examples: Canada, United States, etc. */
  name: Maybe<Scalars['String']['output']>;
};

/** Tenant's country monetary details */
export type CountrySpec = {
  __typename?: 'CountrySpec';
  bankAccountCurrencies: Maybe<Array<Maybe<BankAccountCurrency>>>;
  /** The default currency for tenant's country. It applies to both payment methods and bank accounts. */
  defaultCurrency: Maybe<Currency>;
};

/** Coupon */
export type Coupon = Node & {
  __typename?: 'Coupon';
  /** Coupon code */
  code: Scalars['String']['output'];
  /** Coupon created date */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** Coupon quantity created */
  quantity: Maybe<Scalars['Int']['output']>;
  /** Coupon quantity used */
  quantityUsed: Maybe<Scalars['Int']['output']>;
};

/** The connection type for Coupon. */
export type CouponConnection = {
  __typename?: 'CouponConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<CouponEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Coupon>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CouponEdge = {
  __typename?: 'CouponEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Coupon>;
};

/** Possible fields to filter coupons */
export type CouponFilter = {
  /** Limit of how many coupon records to fetch */
  limit?: InputMaybe<Scalars['Int']['input']>;
};

/** Input fields for a coupon */
export type CouponInput = {
  /** The coupon code */
  code: Scalars['String']['input'];
  /** Determines if the coupon should be deleted */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** The coupon ID */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** The number of coupons available */
  quantity?: InputMaybe<Scalars['Int']['input']>;
};

/** Course information */
export type Course = CommunitiesCourseQueryInterface &
  CourseInterface &
  Node &
  ProductableInterface & {
    __typename?: 'Course';
    /** Accredible certificate expiry date */
    accredibleCertificateExpiryDate: Maybe<Scalars['Date']['output']>;
    /** Accredible certificate template name */
    accredibleCertificateTemplateName: Maybe<Scalars['String']['output']>;
    /**
     * A list of after purchase flows that belong to the product
     * @deprecated Access via the product field instead
     */
    afterPurchaseFlows: Maybe<AfterPurchaseFlowConnection>;
    /** Video lesson autoplay */
    autoplayAllVideos: Maybe<Scalars['Boolean']['output']>;
    /** Course has no enrollments and can be destroyed */
    canBeDestroyed: Maybe<Scalars['Boolean']['output']>;
    /** Product's card image url */
    cardImageUrl: Maybe<Scalars['String']['output']>;
    /** A list of chapters that belongs to the course */
    chapters: ChapterConnection;
    /** The community product relation */
    communityProductRelation: Maybe<CommunityProductRelation>;
    /** Completion Page html */
    completionPageHtml: Maybe<Scalars['String']['output']>;
    /** A list of course admins for this course */
    courseAdmins: CourseAdminConnection;
    /**
     * Course id
     * @deprecated Avoid using the decoded ID, use the encoded id instead
     */
    courseId: Maybe<Scalars['Int']['output']>;
    /** Course player style font */
    coursePlayerFont: Maybe<Scalars['String']['output']>;
    /** Course player style primary color */
    coursePlayerPrimaryColor: Maybe<Scalars['String']['output']>;
    /** Course player theme */
    coursePlayerTheme: Maybe<Scalars['String']['output']>;
    /** Course Settings Social Share Message */
    courseSettingsSocialShareText: Maybe<Scalars['String']['output']>;
    /** Product's created date */
    createdAt: Maybe<Scalars['DateTime']['output']>;
    /** Days until Accredible Certificate Expiry */
    daysUntilAccredibleCertificateExpiry: Maybe<Scalars['Int']['output']>;
    /** Default course card image */
    defaultCourseCardImageUrl: Scalars['String']['output'];
    /** Product's description */
    description: Maybe<Scalars['String']['output']>;
    /** Disable chapter completed modal */
    disableChapterCompletedModal: Maybe<Scalars['Boolean']['output']>;
    /** disable text selection */
    disableTextSelection: Maybe<Scalars['Boolean']['output']>;
    /** Course Drip Schedule */
    dripSchedule: Maybe<DripSchedule>;
    /** The number of enrollments for this course */
    enrollmentsCount: Maybe<Scalars['Int']['output']>;
    /** Whether new affiliates is excluded for a product */
    excludeNewAffiliates: Maybe<Scalars['Boolean']['output']>;
    /** A list of exlcuded affiliates for this course */
    excludedAffiliates: AffiliateConnection;
    /** Use Custom Completion page */
    hasCustomCompletionPage: Maybe<Scalars['Boolean']['output']>;
    /** Hide Lesson Type Icon */
    hideLessonTypeIcon: Maybe<Scalars['Boolean']['output']>;
    /** Hide Lesson Type Icon and label for a course */
    hideLessonTypeLabelAndIcon: Maybe<Scalars['Boolean']['output']>;
    id: Scalars['ID']['output'];
    /** The instructor for this course */
    instructor: Maybe<Instructor>;
    /** Whether the course is used for channels */
    isChannel: Maybe<Scalars['Boolean']['output']>;
    /** Whether the course is editable */
    isEditable: Maybe<Scalars['Boolean']['output']>;
    /** Whether the product is hidden */
    isHidden: Maybe<Scalars['Boolean']['output']>;
    /** Whether the product is presell */
    isPresell: Maybe<Scalars['Boolean']['output']>;
    /** Whether the product is private */
    isPrivate: Maybe<Scalars['Boolean']['output']>;
    /** Whether the product is published */
    isPublished: Maybe<Scalars['Boolean']['output']>;
    /** Video completion required */
    isVideoCompletionRequired: Maybe<Scalars['Boolean']['output']>;
    /** Product's keywords */
    keywords: Maybe<Scalars['String']['output']>;
    /** Product's Page footer code */
    landingPageLiquidMarkup: Maybe<Scalars['String']['output']>;
    /** The landing page URL */
    landingPageUrl: Maybe<Scalars['String']['output']>;
    /** A list of lessons that belong to the tenant */
    lessons: Maybe<Array<LessonUnion>>;
    /** Course logo */
    logo: Maybe<Scalars['String']['output']>;
    /** Course name */
    name: Maybe<Scalars['String']['output']>;
    /** The percentage completed for the current user */
    percentageCompleted: Maybe<Scalars['Float']['output']>;
    /** The product position for ordering */
    position: Maybe<Scalars['Int']['output']>;
    /**
     * Is product private
     * @deprecated Use isPrivate instead
     */
    private: Maybe<Scalars['Boolean']['output']>;
    /** Product associated with productable */
    product: Maybe<Product>;
    /**
     * Product's id
     * @deprecated Access product id via the product field instead
     */
    productId: Maybe<Scalars['String']['output']>;
    /** Course's product payees */
    productPayees: Maybe<ProductPayeeConnection>;
    /**
     * A list of product prices that belongs to the product
     * @deprecated Access via the product field instead
     */
    productPrices: Maybe<ProductPriceConnection>;
    /** The url by which one can be directed to continue the course */
    resumeCourseUrl: Maybe<Scalars['String']['output']>;
    /** Course review information */
    reviews: Maybe<CourseReviewConnection>;
    /** Product's SEO description */
    seoDescription: Maybe<Scalars['String']['output']>;
    /** Product's SEO title */
    seoTitle: Maybe<Scalars['String']['output']>;
    /** Show course logo */
    showLogo: Maybe<Scalars['Boolean']['output']>;
    /** Product's Site Builder Link */
    siteBuilderLink: Maybe<Scalars['String']['output']>;
    /** Product's slug */
    slug: Maybe<Scalars['String']['output']>;
    /** Course settings social share at chapter completion is overridden */
    socialShareChapterCompletionOverridden: Maybe<Scalars['Boolean']['output']>;
    /** Course settings social share at course completion is overridden */
    socialShareCourseCompletionOverridden: Maybe<Scalars['Boolean']['output']>;
    /** Product's status */
    status: Maybe<Scalars['String']['output']>;
    /** Product's title */
    title: Maybe<Scalars['String']['output']>;
    /** Vieo completion percentage */
    videoCompletionPercentage: Maybe<Scalars['Int']['output']>;
  };

/** Course information */
export type CourseAfterPurchaseFlowsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Course information */
export type CourseChaptersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Course information */
export type CourseCourseAdminsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Course information */
export type CourseExcludedAffiliatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Course information */
export type CourseLessonsArgs = {
  filter?: InputMaybe<LessonsFilter>;
};

/** Course information */
export type CourseProductPayeesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Course information */
export type CourseProductPricesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Course information */
export type CourseReviewsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CourseReviewFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Course admin fields */
export type CourseAdmin = Node &
  UserInterface & {
    __typename?: 'CourseAdmin';
    /** User's amount spent */
    amountSpent: Maybe<Scalars['Float']['output']>;
    /**
     * User's avatar url
     * @deprecated Use profile field instead
     */
    avatarUrl: Maybe<Scalars['String']['output']>;
    /** User's billing country */
    billingCountry: Maybe<Scalars['String']['output']>;
    /** User's billing postal code */
    billingPostalCode: Maybe<Scalars['String']['output']>;
    /** User's billing region */
    billingRegion: Maybe<Scalars['String']['output']>;
    /** User's ability to access sidekiq */
    canAccessSidekiq: Maybe<Scalars['Boolean']['output']>;
    /**
     * User's company
     * @deprecated Use profile field instead
     */
    company: Maybe<Scalars['String']['output']>;
    /** User's created date */
    createdAt: Maybe<Scalars['DateTime']['output']>;
    /** User's custom profile field */
    customProfileFields: Maybe<Array<Maybe<CustomField>>>;
    /** User's default payment card */
    defaultPaymentCard: Maybe<DefaultPaymentCard>;
    /** User's email */
    email: Maybe<Scalars['String']['output']>;
    /** A list of user's enrollments */
    enrollments: Maybe<EnrollmentConnection>;
    /** User's external source */
    externalSource: Maybe<Scalars['String']['output']>;
    /** User's first name */
    firstName: Maybe<Scalars['String']['output']>;
    /** User's full name */
    fullName: Maybe<Scalars['String']['output']>;
    /** A list of groups that user belongs */
    groups: Maybe<GroupConnection>;
    /** Whether this user has Payments tab enabled on User profile page */
    hasPaymentsTab: Scalars['Boolean']['output'];
    /** Whether user has set a thinkific password */
    hasThinkificPassword: Maybe<Scalars['Boolean']['output']>;
    id: Scalars['ID']['output'];
    /** Most recent identity provider user authenticated with */
    identityProvider: Maybe<IdentityProvider>;
    /** Users's IP address */
    ipAddress: Maybe<Scalars['String']['output']>;
    /** Whether the user is an admin */
    isAdmin: Maybe<Scalars['Boolean']['output']>;
    /** Whether the user is an unlimited admin ie. owner, site admin or partner */
    isUnlimitedAdmin: Maybe<Scalars['Boolean']['output']>;
    /** User's landing page */
    landingPage: Maybe<Scalars['String']['output']>;
    /** User's last name */
    lastName: Maybe<Scalars['String']['output']>;
    /** User's last sign in */
    lastSignIn: Maybe<Scalars['DateTime']['output']>;
    /** User's specified locale setting */
    locale: Maybe<Scalars['String']['output']>;
    /** MFA settings for user */
    mfaSettings: Maybe<MfaSettings>;
    /** User's total number of enrollments */
    numberOfEnrollments: Maybe<Scalars['Int']['output']>;
    /** User's sign in date for previous session */
    previousSignIn: Maybe<Scalars['DateTime']['output']>;
    /** User's profile */
    profile: Maybe<Profile>;
    /** A list of the user's quiz and survey responses */
    quizSurveyResponses: Maybe<UserQuizSurveyResponsesConnection>;
    /** User's subscriptions and payment plans */
    recurringPayments: Maybe<Array<RecurringPayment>>;
    /** Affiliate who has referred user */
    referredBy: Maybe<Affiliate>;
    /** User's referred from url */
    referredFrom: Maybe<Scalars['String']['output']>;
    /** User's roles */
    roles: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** User's sign in count */
    signInCount: Maybe<Scalars['Int']['output']>;
    /** User's updated date */
    updatedAt: Maybe<Scalars['DateTime']['output']>;
    /** A list of user's bundles */
    userBundles: Maybe<Bundle_EnrollmentConnection>;
  };

/** Course admin fields */
export type CourseAdminDefaultPaymentCardArgs = {
  cartToken?: InputMaybe<Scalars['String']['input']>;
};

/** Course admin fields */
export type CourseAdminEnrollmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserEnrollmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<EnrollmentOrderBy>;
};

/** Course admin fields */
export type CourseAdminGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Course admin fields */
export type CourseAdminQuizSurveyResponsesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ReportingOrderBy>;
  pagination: Pagination;
};

/** Course admin fields */
export type CourseAdminUserBundlesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserBundlesOrderBy>;
};

/** The connection type for CourseAdmin. */
export type CourseAdminConnection = {
  __typename?: 'CourseAdminConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<CourseAdminEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<CourseAdmin>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CourseAdminEdge = {
  __typename?: 'CourseAdminEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<CourseAdmin>;
};

/** Input fields for creating or removing a course administrator */
export type CourseAdminInput = {
  /** The administered course id */
  courseId: Scalars['ID']['input'];
  /** The user ID of a course admin */
  userId: Scalars['ID']['input'];
};

/** Course admins feature */
export type CourseAdminsFeature = FeatureInterface & {
  __typename?: 'CourseAdminsFeature';
  /** Feature checkout path */
  checkoutPath: Maybe<Scalars['String']['output']>;
  /** Flag to indicate feature is enabled */
  isEnabled: Maybe<Scalars['Boolean']['output']>;
  /** Maximum number of course admins */
  maxCourseAdmins: Scalars['Int']['output'];
  /** The name of the minimum plan required to access this feature */
  minimumPlanName: Maybe<Scalars['String']['output']>;
};

/** Course completion email design and state */
export type CourseCompletionEmail = {
  __typename?: 'CourseCompletionEmail';
  /** The body of the notification */
  body: Maybe<Scalars['String']['output']>;
  /** Whether the email is enabled */
  isEnabled: Maybe<Scalars['Boolean']['output']>;
  /** The subject of the notification */
  subject: Maybe<Scalars['String']['output']>;
};

/** The connection type for Course. */
export type CourseConnection = {
  __typename?: 'CourseConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<CourseEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Course>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CourseEdge = {
  __typename?: 'CourseEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Course>;
};

/** Course enrollment email design and state */
export type CourseEnrollmentEmail = {
  __typename?: 'CourseEnrollmentEmail';
  /** The body of the notification */
  body: Maybe<Scalars['String']['output']>;
  /** Whether the email is enabled */
  isEnabled: Maybe<Scalars['Boolean']['output']>;
  /** The subject of the notification */
  subject: Maybe<Scalars['String']['output']>;
};

/** CoursseEnrollmentTotals */
export type CourseEnrollmentTotals = {
  __typename?: 'CourseEnrollmentTotals';
  /** Totals for current period */
  current: Maybe<Scalars['JSON']['output']>;
  /** End of date range */
  endDate: Maybe<Scalars['String']['output']>;
  /** Totals for previous period */
  past: Maybe<Scalars['JSON']['output']>;
  /** Start of date range */
  startDate: Maybe<Scalars['String']['output']>;
};

/** Possible fields to filter courses */
export type CourseEnrollmentTotalsFilter = {
  /** Search by course id */
  courseIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Searcy by course name */
  courseName?: InputMaybe<Scalars['String']['input']>;
  /** Students enrolled up to this date */
  enrollmentDateEnd?: InputMaybe<Scalars['String']['input']>;
  /** Students enrolled since this date */
  enrollmentDateStart?: InputMaybe<Scalars['String']['input']>;
  /** Search by group id */
  groupIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Search by chapter/content name */
  nameContains?: InputMaybe<Scalars['String']['input']>;
  /** Search by student type */
  studentType?: InputMaybe<Scalars['String']['input']>;
};

/** Possible fields to filter courses */
export type CourseFilter = {
  /** Filter by collection IDs */
  collectionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Search by id */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Filter by instructor IDs */
  instructorIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter hidden courses */
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter private courses */
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by course name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Filter by payment type */
  paymentTypes?: InputMaybe<Array<InputMaybe<PaymentType>>>;
  /** Filter by status */
  status?: InputMaybe<Array<InputMaybe<ProductStatus>>>;
};

/** Course interface */
export type CourseInterface = {
  /** Accredible certificate expiry date */
  accredibleCertificateExpiryDate: Maybe<Scalars['Date']['output']>;
  /** Accredible certificate template name */
  accredibleCertificateTemplateName: Maybe<Scalars['String']['output']>;
  /** Video lesson autoplay */
  autoplayAllVideos: Maybe<Scalars['Boolean']['output']>;
  /** Course has no enrollments and can be destroyed */
  canBeDestroyed: Maybe<Scalars['Boolean']['output']>;
  /** Completion Page html */
  completionPageHtml: Maybe<Scalars['String']['output']>;
  /**
   * Course id
   * @deprecated Avoid using the decoded ID, use the encoded id instead
   */
  courseId: Maybe<Scalars['Int']['output']>;
  /** Course player style font */
  coursePlayerFont: Maybe<Scalars['String']['output']>;
  /** Course player style primary color */
  coursePlayerPrimaryColor: Maybe<Scalars['String']['output']>;
  /** Course player theme */
  coursePlayerTheme: Maybe<Scalars['String']['output']>;
  /** Course Settings Social Share Message */
  courseSettingsSocialShareText: Maybe<Scalars['String']['output']>;
  /** Days until Accredible Certificate Expiry */
  daysUntilAccredibleCertificateExpiry: Maybe<Scalars['Int']['output']>;
  /** Disable chapter completed modal */
  disableChapterCompletedModal: Maybe<Scalars['Boolean']['output']>;
  /** disable text selection */
  disableTextSelection: Maybe<Scalars['Boolean']['output']>;
  /** The number of enrollments for this course */
  enrollmentsCount: Maybe<Scalars['Int']['output']>;
  /** Use Custom Completion page */
  hasCustomCompletionPage: Maybe<Scalars['Boolean']['output']>;
  /** Hide Lesson Type Icon */
  hideLessonTypeIcon: Maybe<Scalars['Boolean']['output']>;
  /** Hide Lesson Type Icon and label for a course */
  hideLessonTypeLabelAndIcon: Maybe<Scalars['Boolean']['output']>;
  /** The instructor for this course */
  instructor: Maybe<Instructor>;
  /** Whether the course is used for channels */
  isChannel: Maybe<Scalars['Boolean']['output']>;
  /** Whether the course is editable */
  isEditable: Maybe<Scalars['Boolean']['output']>;
  /** Video completion required */
  isVideoCompletionRequired: Maybe<Scalars['Boolean']['output']>;
  /** The landing page URL */
  landingPageUrl: Maybe<Scalars['String']['output']>;
  /** Course logo */
  logo: Maybe<Scalars['String']['output']>;
  /** Course name */
  name: Maybe<Scalars['String']['output']>;
  /** The percentage completed for the current user */
  percentageCompleted: Maybe<Scalars['Float']['output']>;
  /**
   * Is product private
   * @deprecated Use isPrivate instead
   */
  private: Maybe<Scalars['Boolean']['output']>;
  /** The url by which one can be directed to continue the course */
  resumeCourseUrl: Maybe<Scalars['String']['output']>;
  /** Show course logo */
  showLogo: Maybe<Scalars['Boolean']['output']>;
  /** Course settings social share at chapter completion is overridden */
  socialShareChapterCompletionOverridden: Maybe<Scalars['Boolean']['output']>;
  /** Course settings social share at course completion is overridden */
  socialShareCourseCompletionOverridden: Maybe<Scalars['Boolean']['output']>;
  /** Vieo completion percentage */
  videoCompletionPercentage: Maybe<Scalars['Int']['output']>;
};

/** Order for courses */
export type CourseOrderBy = {
  direction: OrderDirection;
  field: CourseOrderField;
};

/** Possible fields to order courses */
export enum CourseOrderField {
  /** Order courses by created at time */
  CreatedAt = 'createdAt',
}

/** Input fields for a course */
export type CoursePatch = {
  /** Accredible certificate - expiry date */
  accredibleCertificateExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  /** Accredible certificate - template name */
  accredibleCertificateTemplateName?: InputMaybe<Scalars['String']['input']>;
  /** Video lesson autoplay */
  autoplayAllVideos?: InputMaybe<Scalars['Boolean']['input']>;
  /** add / remove all affiliates */
  bulkAffiliatesAction?: InputMaybe<BulkAffiliatesActionEnum>;
  /** Course Card Image Url */
  cardImageUrl?: InputMaybe<Scalars['String']['input']>;
  /** Custom completion page html */
  completionPageHtml?: InputMaybe<Scalars['String']['input']>;
  /** List of Course Admin Ids to add, course admin ids not a part of this array will be destroyed for the course */
  courseAdminsIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Course player style font */
  coursePlayerFont?: InputMaybe<Scalars['String']['input']>;
  /** Course player style primary color */
  coursePlayerPrimaryColor?: InputMaybe<Scalars['String']['input']>;
  /** Course player theme (light or dark) */
  coursePlayerTheme?: InputMaybe<Scalars['String']['input']>;
  /** Course Settings Social Share Message */
  courseSettingsSocialShareText?: InputMaybe<Scalars['String']['input']>;
  /** Accredible certificate - Days until certificate expiry */
  daysUntilAccredibleCertificateExpiry?: InputMaybe<Scalars['Int']['input']>;
  /** Course description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Disable chapter completion message */
  disableChapterCompletedModal?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disable Copy Text */
  disableTextSelection?: InputMaybe<Scalars['Boolean']['input']>;
  /** Exclude New Affiliates */
  excludeNewAffiliates?: InputMaybe<Scalars['Boolean']['input']>;
  /** Custom completion page enabled */
  hasCustomCompletionPage?: InputMaybe<Scalars['Boolean']['input']>;
  /** Hide Lesson Type Icon for a course */
  hideLessonTypeIcon?: InputMaybe<Scalars['Boolean']['input']>;
  /** Hide Lesson Type Icon and label for a course */
  hideLessonTypeLabelAndIcon?: InputMaybe<Scalars['Boolean']['input']>;
  /** instructor id for the course */
  instructorId?: InputMaybe<Scalars['ID']['input']>;
  /** Course Hidden status */
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  /** Course Private status */
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  /** Is video completion required */
  isVideoCompletionRequired?: InputMaybe<Scalars['Boolean']['input']>;
  /** Course keywords */
  keywords?: InputMaybe<Scalars['String']['input']>;
  /** Course landing page footer code */
  landingPageLiquidMarkup?: InputMaybe<Scalars['String']['input']>;
  /** Course logo */
  logo?: InputMaybe<Scalars['String']['input']>;
  /** The name of the course */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Revenue partners input */
  revenuePartners?: InputMaybe<Array<ProductPayeesInput>>;
  /** Course SEO description */
  seoDescription?: InputMaybe<Scalars['String']['input']>;
  /** Course SEO title */
  seoTitle?: InputMaybe<Scalars['String']['input']>;
  /** Show Course logo */
  showLogo?: InputMaybe<Scalars['Boolean']['input']>;
  /** The slug of the course */
  slug?: InputMaybe<Scalars['String']['input']>;
  /** Course settings social share at chapter completion is overridden */
  socialShareChapterCompletionOverridden?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  /** Course settings social share at course completion is overridden */
  socialShareCourseCompletionOverridden?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  /** Video Completion percentage value */
  videoCompletionPercentage?: InputMaybe<Scalars['Int']['input']>;
};

/** Course review information */
export type CourseReview = Node & {
  __typename?: 'CourseReview';
  /** Whether the course review has been approved */
  approved: Maybe<Scalars['Boolean']['output']>;
  /** Course Review Course */
  course: Maybe<ReviewCourse>;
  /** Course review created at */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** Course rating */
  rating: Maybe<Scalars['String']['output']>;
  /** Course Review ID */
  reviewId: Maybe<Scalars['Int']['output']>;
  /** Course Review Title */
  title: Maybe<Scalars['String']['output']>;
  /** Course review updated at */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** The connection type for CourseReview. */
export type CourseReviewConnection = {
  __typename?: 'CourseReviewConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<CourseReviewEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<CourseReview>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CourseReviewEdge = {
  __typename?: 'CourseReviewEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<CourseReview>;
};

/** Possible fields to filter reviews */
export type CourseReviewFilter = {
  /** Search by approval */
  approved?: InputMaybe<Scalars['Boolean']['input']>;
  /** Search by id */
  id?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Search by reviewId (Site Builder - Manifest) */
  reviewId?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** Course templates with course chapters */
export type CourseTemplate = Node & {
  __typename?: 'CourseTemplate';
  /** Chapters from course templates */
  chapters: Maybe<ChapterConnection>;
  /** CourseTemplate's course */
  courseId: Maybe<Scalars['ID']['output']>;
  /** CourseTemplate's id */
  courseTemplateId: Scalars['ID']['output'];
  /** CourseTemplate's description */
  description: Scalars['String']['output'];
  /** CourseTemplate's icon */
  icon: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** CourseTemplate's name */
  name: Scalars['String']['output'];
};

/** Course templates with course chapters */
export type CourseTemplateChaptersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for CourseTemplate. */
export type CourseTemplateConnection = {
  __typename?: 'CourseTemplateConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<CourseTemplateEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<CourseTemplate>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CourseTemplateEdge = {
  __typename?: 'CourseTemplateEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<CourseTemplate>;
};

/** Possible fields to filter course templates */
export type CourseTemplateFilter = {
  /** Search by id */
  id?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

/** Courses feature */
export type CoursesFeature = FeatureInterface & {
  __typename?: 'CoursesFeature';
  /** Feature checkout path */
  checkoutPath: Maybe<Scalars['String']['output']>;
  /** Flag to indicate feature is enabled */
  isEnabled: Maybe<Scalars['Boolean']['output']>;
  /** Maximum number of courses */
  maxCourses: Scalars['Int']['output'];
  /** The name of the minimum plan required to access this feature */
  minimumPlanName: Maybe<Scalars['String']['output']>;
};

/** Create a new active campaign */
export type CreateActiveCampaign = ResponseInterface & {
  __typename?: 'CreateActiveCampaign';
  /**
   * Response Extra Data
   * @deprecated response fields should be well defined following a object structure
   */
  data: Maybe<Scalars['JSON']['output']>;
  errors: Maybe<Array<Maybe<Error>>>;
  /** Response Message */
  message: Maybe<Scalars['String']['output']>;
  /** Return status: SUCCESS, PARTIAL_SUCCESS or FAILED */
  status: Maybe<ResponseStatus>;
};

/** Create an event in Active Campaign */
export type CreateActiveCampaignEvent = ResponseInterface & {
  __typename?: 'CreateActiveCampaignEvent';
  /**
   * Response Extra Data
   * @deprecated response fields should be well defined following a object structure
   */
  data: Maybe<Scalars['JSON']['output']>;
  errors: Maybe<Array<Maybe<Error>>>;
  /** Response Message */
  message: Maybe<Scalars['String']['output']>;
  /** Return status: SUCCESS, PARTIAL_SUCCESS or FAILED */
  status: Maybe<ResponseStatus>;
};

/** Autogenerated input type of CreateActiveCampaign */
export type CreateActiveCampaignInput = {
  /** Active Campaign's fields */
  activeCampaign: ActiveCampaignInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of CreateAdditionalProductPrice */
export type CreateAdditionalProductPriceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Attributes to be updated */
  patch: ProductPriceInput;
  /** Product ID that is associated with price */
  productId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateAdditionalProductPrice */
export type CreateAdditionalProductPricePayload = {
  __typename?: 'CreateAdditionalProductPricePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /**
   * Response errors
   * @deprecated Backwards compatibility for old errors response
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /** Update primary or additional product price field */
  productPrice: Maybe<ProductPrice>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of CreateAfterPurchaseFlow */
export type CreateAfterPurchaseFlowInput = {
  /** Attributes to be created */
  afterPurchaseFlow: AfterPurchaseFlowInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Product ID that is associated with after purchase flow */
  productId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateAfterPurchaseFlow */
export type CreateAfterPurchaseFlowPayload = {
  __typename?: 'CreateAfterPurchaseFlowPayload';
  afterPurchaseFlow: Maybe<AfterPurchaseFlow>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /**
   * Response error
   * @deprecated Backwards compatibility for old response error
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of CreateAfterPurchaseFlowProduct */
export type CreateAfterPurchaseFlowProductInput = {
  /** After purchase flow ID that is associated with after purchase flow product */
  afterPurchaseFlowId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Product ID that is associated with after purchase flow product */
  productId: Scalars['ID']['input'];
  /** Product Name that is associated with after purchase flow product */
  productName: Scalars['String']['input'];
  /** Product position that is associated with after purchase flow product */
  productPosition: Scalars['Int']['input'];
};

/** Autogenerated return type of CreateAfterPurchaseFlowProduct */
export type CreateAfterPurchaseFlowProductPayload = {
  __typename?: 'CreateAfterPurchaseFlowProductPayload';
  /** After purchase flow product */
  afterPurchaseFlowProduct: Maybe<AfterPurchaseFlowProduct>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /**
   * Response error
   * @deprecated Backwards compatibility for old response error
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of CreateAiFunnelPrompt */
export type CreateAiFunnelPromptInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The name of the course */
  courseName?: InputMaybe<Scalars['String']['input']>;
  /** The type of download resource */
  downloadResourceType?: InputMaybe<Scalars['String']['input']>;
  /** The type of funnel */
  funnelType: Scalars['String']['input'];
  /** Knowledge Level of Target Audience */
  knowledgeLevel?: InputMaybe<Scalars['String']['input']>;
  /** Learning Outcomes from Course */
  outcomes?: InputMaybe<Scalars['String']['input']>;
  /** Problems Solved by Course Offering */
  problemSolved?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the product the funnel will be for */
  productId?: InputMaybe<Scalars['ID']['input']>;
  /** More Information about Target Audience */
  targetAudience?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateAiFunnelPrompt */
export type CreateAiFunnelPromptPayload = {
  __typename?: 'CreateAiFunnelPromptPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The prompt record */
  prompt: Maybe<Prompt>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of CreateAssignmentLesson */
export type CreateAssignmentLessonInput = {
  assignmentTemplateInput?: InputMaybe<CreateAssignmentTemplateInput>;
  /** The chapter ID of the Course */
  chapterId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  lessonInput: LessonInput;
  lessonWithAssignmentInput: LessonWithAssignmentInput;
};

/** Autogenerated return type of CreateAssignmentLesson */
export type CreateAssignmentLessonPayload = {
  __typename?: 'CreateAssignmentLessonPayload';
  /** Created assignment_template */
  assignmentTemplate: Maybe<AssignmentTemplate>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Created assignment lesson */
  lesson: Maybe<AssignmentLesson>;
  userErrors: Maybe<Array<UserError>>;
};

/** Input for creating assignment template */
export type CreateAssignmentTemplateInput = {
  /** AWS key of file */
  awsKey: Scalars['String']['input'];
  /** Actual filename of file */
  fileName: Scalars['String']['input'];
  /** File size in bytes */
  fileSize: Scalars['Int']['input'];
  /** File MIME type */
  fileType: Scalars['String']['input'];
  /** Creator inputted filename of file */
  label: Scalars['String']['input'];
  /** Url of assignment template file */
  url: Scalars['String']['input'];
};

/** Autogenerated input type of CreateAttendance */
export type CreateAttendanceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the Live Event */
  eventId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateAttendance */
export type CreateAttendancePayload = {
  __typename?: 'CreateAttendancePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The live event */
  liveEvent: Maybe<LiveEvent>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of CreateAudioLesson */
export type CreateAudioLessonInput = {
  /** The chapter ID of the Course */
  chapterId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  lessonInput: LessonInput;
  lessonWithAudioInput: LessonWithAudioInput;
};

/** Autogenerated return type of CreateAudioLesson */
export type CreateAudioLessonPayload = {
  __typename?: 'CreateAudioLessonPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Created audio lesson */
  lesson: Maybe<AudioLesson>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated return type of CreateBlueprint */
export type CreateBlueprintPayload = {
  __typename?: 'CreateBlueprintPayload';
  /**
   * Response errors
   * @deprecated Backwards compatibility for old response status
   */
  errors: Maybe<Array<Error>>;
  /** The ID of the created blueprint */
  id: Maybe<Scalars['ID']['output']>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response status
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of CreateBulkEnrollment */
export type CreateBulkEnrollmentInput = {
  /** Boolean for all users selected */
  allSelected: Scalars['Boolean']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Enrollment type of the the bulk enrollment(enroll|unenroll) */
  enrollType: Scalars['String']['input'];
  /** Filter for users to be included in the bulk enrollment */
  filter?: InputMaybe<UserFilter>;
  /** Product id that for the bulk enrollment */
  productId: Scalars['ID']['input'];
  /** List of user ids to be bulk enrolled */
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Autogenerated return type of CreateBulkEnrollment */
export type CreateBulkEnrollmentPayload = {
  __typename?: 'CreateBulkEnrollmentPayload';
  /** The bulk enrollment id */
  bulkEnrollmentId: Maybe<Scalars['ID']['output']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /**
   * Response data
   * @deprecated Backwards compatibility for old response
   */
  data: Maybe<Scalars['String']['output']>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response status
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Input for bulk live lesson */
export type CreateBulkLiveLessonInput = {
  /** End date for the recurring meeting */
  endDateTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Day of the month for the recurring meeting */
  monthlyDay?: InputMaybe<Scalars['Int']['input']>;
  monthlyWeek?: InputMaybe<MonthlyWeekEnum>;
  /** Day of the week for the monthly recurring meeting */
  monthlyWeekDay?: InputMaybe<MonthlyWeekDayEnum>;
  /** The number of occurrences */
  numberOfOccurrences?: InputMaybe<Scalars['Int']['input']>;
  recurrenceType: RecurrenceTypeEnum;
  /** Live Lessons repeat interval */
  repeatInterval: Scalars['Int']['input'];
  /** Days for the Weekly recurring meeting */
  weeklyDays?: InputMaybe<Array<MonthlyWeekDayEnum>>;
};

/** Autogenerated input type of CreateBundle */
export type CreateBundleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** A name for the bundle */
  newName: Scalars['NonEmptyString']['input'];
  /** Set bundle as membership */
  usedForMemberships?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of CreateBundle */
export type CreateBundlePayload = {
  __typename?: 'CreateBundlePayload';
  /** New bundle */
  bundle: Maybe<Bundle>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated return type of CreateCertificateMapping */
export type CreateCertificateMappingPayload = {
  __typename?: 'CreateCertificateMappingPayload';
  /** The created certificate mapping */
  certificateMapping: Maybe<StudentCertificatesCertificateMapping>;
  /**
   * Response errors
   * @deprecated Backwards compatibility for old response status
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response status
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
};

/** Autogenerated return type of CreateCertificateMappings */
export type CreateCertificateMappingsPayload = {
  __typename?: 'CreateCertificateMappingsPayload';
  /** The list of created certificate mappings */
  certificateMappings: Maybe<Array<StudentCertificatesCertificateMapping>>;
  /**
   * Response errors
   * @deprecated Backwards compatibility for old response status
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response status
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of CreateChapter */
export type CreateChapterInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The course ID */
  courseId: Scalars['ID']['input'];
  /** The name of the chapter */
  name: Scalars['String']['input'];
  /** Position of Chapter */
  position?: InputMaybe<Scalars['Int']['input']>;
  /** Set the new lessons for the chapter to draft */
  setNewLessonsToDraft?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of CreateChapter */
export type CreateChapterPayload = {
  __typename?: 'CreateChapterPayload';
  /** The chapter */
  chapter: Maybe<Chapter>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of CreateCommunity */
export type CreateCommunityInput = {
  /** Group IDs that have access to communities */
  accessGroupIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Product IDs that students must be enrolled in to be able to access the community */
  accessProductIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Community access type */
  accessType?: InputMaybe<Scalars['String']['input']>;
  /** The community card image url */
  cardImageUrl?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The description of the community */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The font to be used on the community */
  font?: InputMaybe<Scalars['String']['input']>;
  /** The identifier for the image in Filestack */
  imageFilestackId?: InputMaybe<Scalars['String']['input']>;
  /** The name of the S3 bucket where the image is stored */
  imageS3Bucket?: InputMaybe<Scalars['String']['input']>;
  /** The S3 key for the image */
  imageS3Key?: InputMaybe<Scalars['String']['input']>;
  /** The name of the community */
  name: Scalars['String']['input'];
  /** The primary color of the community */
  primaryColor?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateCommunity */
export type CreateCommunityPayload = {
  __typename?: 'CreateCommunityPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The community */
  community: Maybe<Community>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of CreateCommunitySpace */
export type CreateCommunitySpaceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The encrypted community_space section ID for this community_space */
  communitySectionId: Scalars['ID']['input'];
  /** The description of the community_space */
  description: Scalars['String']['input'];
  /** The icon image for the community_space */
  iconName?: InputMaybe<Scalars['String']['input']>;
  /** Space image */
  image?: InputMaybe<ImageInput>;
  /** Set the space to exclusive */
  isExclusive?: InputMaybe<Scalars['Boolean']['input']>;
  /** The members to assign to the space */
  memberIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The name of the community_space */
  name: Scalars['String']['input'];
  /** The encrypted product_id that this space is related to */
  relatedProductId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of CreateCommunitySpace */
export type CreateCommunitySpacePayload = {
  __typename?: 'CreateCommunitySpacePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The returned community_space record */
  communitySpace: Maybe<CommunitySpace>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of CreateCourse */
export type CreateCourseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of a course template to use */
  courseTemplateId?: InputMaybe<Scalars['ID']['input']>;
  /** A name for the course */
  name: Scalars['String']['input'];
  /** A prompt ID for an AI generated course outline */
  promptId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of CreateCourse */
export type CreateCoursePayload = {
  __typename?: 'CreateCoursePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Created Course */
  course: Maybe<Course>;
  /**
   * Response error
   * @deprecated Backwards compatibility for old response error
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of CreateCustomPage */
export type CreateCustomPageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Denotes if this page should be set as the homepage redirect */
  homePage?: InputMaybe<Scalars['Boolean']['input']>;
  /** Custom Page's name */
  name: Scalars['String']['input'];
  /** Custom Page is paid? */
  paidCustomPage?: InputMaybe<Scalars['Boolean']['input']>;
  /** Custom Page is private? */
  private?: InputMaybe<Scalars['Boolean']['input']>;
  /** Custom Page is published? */
  published?: InputMaybe<Scalars['Boolean']['input']>;
  /** Custom Page's slug */
  slug?: InputMaybe<Scalars['String']['input']>;
  /** Custom Page's theme data */
  themeData?: InputMaybe<Scalars['JSON']['input']>;
};

/** Autogenerated return type of CreateCustomPage */
export type CreateCustomPagePayload = {
  __typename?: 'CreateCustomPagePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The custom page */
  customPage: Maybe<CustomPage>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of CreateDownloadLesson */
export type CreateDownloadLessonInput = {
  /** The chapter ID of the Course */
  chapterId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  lessonInput: LessonInput;
  lessonWithDownloadInput?: InputMaybe<Array<LessonWithDownloadInput>>;
  lessonWithTextInput?: InputMaybe<LessonWithTextInput>;
};

/** Autogenerated return type of CreateDownloadLesson */
export type CreateDownloadLessonPayload = {
  __typename?: 'CreateDownloadLessonPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Created download lesson */
  lesson: Maybe<DownloadLesson>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of CreateDripSchedule */
export type CreateDripScheduleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dripScheduleInput: DripScheduleInput;
  /** @deprecated Handled by CreateDripScheduleItems mutation */
  lessonsWithScheduleInput?: InputMaybe<Array<LessonWithScheduleInput>>;
  /** product id */
  productId: Scalars['ID']['input'];
};

/** Autogenerated input type of CreateDripScheduleItems */
export type CreateDripScheduleItemsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Drip schedule id */
  dripScheduleId: Scalars['ID']['input'];
  lessonsWithScheduleInput: Array<LessonWithScheduleInput>;
};

/** Autogenerated return type of CreateDripScheduleItems */
export type CreateDripScheduleItemsPayload = {
  __typename?: 'CreateDripScheduleItemsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** created drip schedule items */
  dripScheduleItems: Maybe<Array<DripScheduleItem>>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated return type of CreateDripSchedule */
export type CreateDripSchedulePayload = {
  __typename?: 'CreateDripSchedulePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Created Drip Schedule */
  dripSchedule: DripSchedule;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of CreateEvent */
export type CreateEventInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Active Campaign's event slug */
  slug: ActiveCampaignEventSlug;
};

/** Autogenerated input type of CreateExamLesson */
export type CreateExamLessonInput = {
  /** The chapter ID of the Course */
  chapterId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  lessonInput: LessonInput;
  lessonWithExamInput: LessonWithExamInput;
};

/** Autogenerated return type of CreateExamLesson */
export type CreateExamLessonPayload = {
  __typename?: 'CreateExamLessonPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Created exam lesson */
  lesson: Maybe<ExamLesson>;
  userErrors: Maybe<Array<UserError>>;
};

/** Enroll existing user in free trial */
export type CreateFreeTrialEnrollment = ResponseInterface & {
  __typename?: 'CreateFreeTrialEnrollment';
  /**
   * Response Extra Data
   * @deprecated response fields should be well defined following a object structure
   */
  data: Maybe<Scalars['JSON']['output']>;
  errors: Maybe<Array<Maybe<Error>>>;
  /** Response Message */
  message: Maybe<Scalars['String']['output']>;
  /** Return status: SUCCESS, PARTIAL_SUCCESS or FAILED */
  status: Maybe<ResponseStatus>;
};

/** Input field for CreateFreeTrialEnrollment mutation */
export type CreateFreeTrialEnrollmentInput = {
  /** The token of the user cart */
  cartToken: Scalars['String']['input'];
  /** The ID of the product price associated with the cart */
  productPriceId: Scalars['String']['input'];
};

/** Autogenerated input type of CreateFunnel */
export type CreateFunnelInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Discount amount for the cross sell product */
  crossSellDiscountAmount?: InputMaybe<Scalars['Float']['input']>;
  /** Discount type for the cross sell product: Fixed or Percentage */
  crossSellDiscountType?: InputMaybe<DiscountEnum>;
  /** The ID of the cross sell product of the funnel */
  crossSellProductId?: InputMaybe<Scalars['ID']['input']>;
  /** Discount amount */
  discountAmount?: InputMaybe<Scalars['Float']['input']>;
  /** Discount type: Fixed or Percentage */
  discountType?: InputMaybe<DiscountEnum>;
  /** The URL of the download file */
  downloadFileUrl?: InputMaybe<Scalars['String']['input']>;
  /** The type of download resource */
  downloadResourceType?: InputMaybe<DownloadResourceTypeEnum>;
  /** The type of funnel */
  funnelType: FunnelTypeEnum;
  /** The ID of the product the funnel will be for */
  productId?: InputMaybe<Scalars['ID']['input']>;
  /** The ID of the OpenAI prompt for AI content generation */
  promptId?: InputMaybe<Scalars['ID']['input']>;
  /** Show offer outside funnel? */
  showOfferOutsideFunnel?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of CreateFunnel */
export type CreateFunnelPayload = {
  __typename?: 'CreateFunnelPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The funnel */
  funnel: Maybe<Funnel>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of CreateGroup */
export type CreateGroupInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Group's fields */
  group: GroupInput;
};

/** Autogenerated return type of CreateGroup */
export type CreateGroupPayload = {
  __typename?: 'CreateGroupPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /**
   * Response data
   * @deprecated Backwards compatibility for old response data
   */
  data: Maybe<Scalars['JSON']['output']>;
  /**
   * Response error
   * @deprecated Backwards compatibility for old response error
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of CreateInAppPurchaseProduct */
export type CreateInAppPurchaseProductInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Product ID that is associated with the in-app purchase product */
  productId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateInAppPurchaseProduct */
export type CreateInAppPurchaseProductPayload = {
  __typename?: 'CreateInAppPurchaseProductPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Product informations */
  product: Maybe<Product>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of CreateLessonFromUpload */
export type CreateLessonFromUploadInput = {
  /** The chapter ID of the Course */
  chapterId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Filename with extension */
  fileNameWithExt: Scalars['String']['input'];
  /** The file size in bytes */
  fileSizeInBytes: Scalars['Int']['input'];
  /** The file asset url */
  url: Scalars['String']['input'];
};

/** Autogenerated return type of CreateLessonFromUpload */
export type CreateLessonFromUploadPayload = {
  __typename?: 'CreateLessonFromUploadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The created lesson */
  lesson: Maybe<LessonUnion>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of CreateLibraryThemeInstallation */
export type CreateLibraryThemeInstallationInput = {
  /** Immediately activate the theme installation */
  activateImmediately?: InputMaybe<Scalars['Boolean']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The theme from which the manifests will be copied */
  copyManifestsFromThemeId?: InputMaybe<Scalars['ID']['input']>;
  /** The theme library item's id to be installed */
  themeLibraryItemId: Scalars['ID']['input'];
  /** Theme preset to be installed */
  themePresetSlug?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateLibraryThemeInstallation */
export type CreateLibraryThemeInstallationPayload = {
  __typename?: 'CreateLibraryThemeInstallationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /**
   * Response data
   * @deprecated Backwards compatibility for old response data
   */
  data: Maybe<Scalars['String']['output']>;
  /**
   * Response errors
   * @deprecated Backwards compatibility for old response errors
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of CreateLiveEvent */
export type CreateLiveEventInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The description of the event */
  description: Scalars['String']['input'];
  /** The end time of the event (UTC) */
  endTime: Scalars['DateTime']['input'];
  /** The encoded ID of the event source */
  eventSourceId: Scalars['ID']['input'];
  /** The type of event source */
  eventSourceType: Scalars['String']['input'];
  /** Image input */
  image?: InputMaybe<EventImageInput>;
  /** The location of the event */
  location?: InputMaybe<Scalars['String']['input']>;
  /** The meetingUrl of the event */
  meetingUrl?: InputMaybe<Scalars['URL']['input']>;
  /** Should users be notified of this event? */
  sendEmails?: InputMaybe<Scalars['Boolean']['input']>;
  /** The start time of the event (UTC) */
  startTime: Scalars['DateTime']['input'];
  /** The timezone of the event */
  timezone: Scalars['String']['input'];
  /** The title of the event */
  title: Scalars['String']['input'];
};

/** Autogenerated return type of CreateLiveEvent */
export type CreateLiveEventPayload = {
  __typename?: 'CreateLiveEventPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The live event */
  liveEvent: Maybe<LiveEvent>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of CreateLiveLesson */
export type CreateLiveLessonInput = {
  /** The chapter ID of the Course */
  chapterId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  lessonInput: LessonInput;
  lessonWithLiveInput: LessonWithLiveInput;
  webinarItemInput: ZoomItemInput;
};

/** Autogenerated return type of CreateLiveLesson */
export type CreateLiveLessonPayload = {
  __typename?: 'CreateLiveLessonPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Created Live lesson */
  lesson: Maybe<LiveLesson>;
  userErrors: Maybe<Array<UserError>>;
  /** Created zoom item */
  zoomItem: Maybe<ZoomItem>;
};

/** Autogenerated input type of CreateMultimediaLesson */
export type CreateMultimediaLessonInput = {
  /** The chapter ID of the Course */
  chapterId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  lessonInput: LessonInput;
  lessonWithMultimediaInput: LessonWithMultimediaInput;
};

/** Autogenerated return type of CreateMultimediaLesson */
export type CreateMultimediaLessonPayload = {
  __typename?: 'CreateMultimediaLessonPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** ID for multimedia import Sidekiq job */
  jobId: Maybe<Scalars['String']['output']>;
  /** Created multimedia lesson */
  lesson: Maybe<MultimediaLesson>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of CreateNewPlusTenant */
export type CreateNewPlusTenantInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Password for new tenant's site owner */
  password: Scalars['String']['input'];
  /** From tenant ID */
  tenantId: Scalars['Int']['input'];
  /** Name of new tenant */
  tenantName: Scalars['String']['input'];
};

/** Autogenerated return type of CreateNewPlusTenant */
export type CreateNewPlusTenantPayload = {
  __typename?: 'CreateNewPlusTenantPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Input fields for updating a tenant lab */
export type CreateOrUpdateTenantLabInput = {
  /** Lab's fields to be updated */
  patch: TenantLabInput;
  /** Lab's slug */
  slug: FeatureLabsSlug;
};

/** Autogenerated return type of CreateOrUpdateTenantLab */
export type CreateOrUpdateTenantLabPayload = {
  __typename?: 'CreateOrUpdateTenantLabPayload';
  /**
   * Response Extra Data
   * @deprecated Backwards compatibility for old response data
   */
  data: Maybe<Scalars['JSON']['output']>;
  /**
   * Response errors
   * @deprecated Backwards compatibility for old response status
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response status
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of CreateOrderBump */
export type CreateOrderBumpInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Previous price for this product */
  compareAtPriceInCents?: InputMaybe<Scalars['NonNegativeFloat']['input']>;
  /** Order bump description */
  description: Scalars['String']['input'];
  /** Order bump name */
  name: Scalars['String']['input'];
  /** List of products where order bump is offered */
  productIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Attributes to be updated */
  productPriceData?: InputMaybe<ProductPriceInput>;
  /** Product ID being sold */
  productSoldId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateOrderBump */
export type CreateOrderBumpPayload = {
  __typename?: 'CreateOrderBumpPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The order bump */
  orderBump: Maybe<OrderBump>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of CreatePdfLesson */
export type CreatePdfLessonInput = {
  /** The chapter ID of the Course */
  chapterId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  lessonInput: LessonInput;
  lessonWithPdfInput: LessonWithPdfInput;
};

/** Autogenerated return type of CreatePdfLesson */
export type CreatePdfLessonPayload = {
  __typename?: 'CreatePdfLessonPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Created pdf lesson */
  lesson: Maybe<PdfLesson>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of CreatePost */
export type CreatePostInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The encrypted community_space ID for this post */
  communitySpaceId?: InputMaybe<Scalars['ID']['input']>;
  /** The content of the post */
  content?: InputMaybe<Scalars['String']['input']>;
  /** The encrypted discussionable ID for this post */
  discussionableId: Scalars['ID']['input'];
  /** The IDs of mentioned users */
  mentionedUserIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  postAttachmentInput?: InputMaybe<PostAttachmentInput>;
  /** The title of the post */
  title: Scalars['String']['input'];
};

/** Autogenerated return type of CreatePost */
export type CreatePostPayload = {
  __typename?: 'CreatePostPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The post */
  post: Maybe<Post>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of CreatePresentationLesson */
export type CreatePresentationLessonInput = {
  /** The chapter ID of the Course */
  chapterId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  lessonInput: LessonInput;
  lessonWithPresentationInput?: InputMaybe<LessonWithPresentationInput>;
};

/** Autogenerated return type of CreatePresentationLesson */
export type CreatePresentationLessonPayload = {
  __typename?: 'CreatePresentationLessonPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Created presentation lesson */
  lesson: Maybe<PresentationLesson>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of CreateProductPayee */
export type CreateProductPayeeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Product ID that is associated with with Product payee */
  productId: Scalars['ID']['input'];
  /** User ID that is associated with Product payee */
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateProductPayee */
export type CreateProductPayeePayload = {
  __typename?: 'CreateProductPayeePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /**
   * Response errors
   * @deprecated Backwards compatibility for old errors response
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /** The created product payee */
  productPayee: Maybe<ProductPayee>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of CreatePromotion */
export type CreatePromotionInput = {
  /** Discount amount */
  amount: Scalars['Float']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  coupons: Array<CouponInput>;
  /** Discount type: Fixed or Percentage */
  discountType: DiscountEnum;
  /** How long the coupon should be applied to products with a subscription in months */
  duration?: InputMaybe<Scalars['Int']['input']>;
  /** Expiration date */
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Promotion name */
  name: Scalars['String']['input'];
  /** The products this promotion should apply to */
  productPriceIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of CreatePromotion */
export type CreatePromotionPayload = {
  __typename?: 'CreatePromotionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The promotion */
  promotion: Maybe<Promotion>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of CreateQuizLesson */
export type CreateQuizLessonInput = {
  /** The chapter ID of the Course */
  chapterId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  lessonInput: LessonInput;
  lessonWithQuizInput: LessonWithQuizInput;
  /** The ID of the AI prompt used to generate questions */
  promptId?: InputMaybe<Scalars['ID']['input']>;
  questions?: InputMaybe<Array<QuizQuestionInput>>;
};

/** Autogenerated return type of CreateQuizLesson */
export type CreateQuizLessonPayload = {
  __typename?: 'CreateQuizLessonPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Created quiz lesson */
  lesson: Maybe<QuizLesson>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of CreateReply */
export type CreateReplyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The content of the reply */
  content?: InputMaybe<Scalars['String']['input']>;
  /** The encrypted discussionable ID for this post */
  discussionableId: Scalars['ID']['input'];
  /** The IDs of mentioned users */
  mentionedUserIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The ID of the post the reply is associated with */
  parentPostId?: InputMaybe<Scalars['ID']['input']>;
  /** The ID of the parent reply the reply is associated with */
  parentReplyId?: InputMaybe<Scalars['ID']['input']>;
  postAttachmentInput?: InputMaybe<PostAttachmentInput>;
};

/** Autogenerated return type of CreateReply */
export type CreateReplyPayload = {
  __typename?: 'CreateReplyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The reply */
  reply: Maybe<Reply>;
  userErrors: Maybe<Array<UserError>>;
};

/** Input field for creating a SearchBookmark */
export type CreateSearchBookmarkInput = {
  /** SearchBookmark's fields */
  search_bookmark: SearchBookmarkInput;
};

/** Autogenerated return type of CreateSearchBookmark */
export type CreateSearchBookmarkPayload = {
  __typename?: 'CreateSearchBookmarkPayload';
  /**
   * Response errors
   * @deprecated Backwards compatibility for old response status
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response status
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of CreateSitePage */
export type CreateSitePageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the course page to be created */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateSitePage */
export type CreateSitePagePayload = {
  __typename?: 'CreateSitePagePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The created site page */
  pageId: Maybe<Scalars['ID']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of CreateSurveyLesson */
export type CreateSurveyLessonInput = {
  /** The chapter ID of the Course */
  chapterId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  lessonInput: LessonInput;
  questions?: InputMaybe<Array<SurveyQuestionInput>>;
};

/** Autogenerated return type of CreateSurveyLesson */
export type CreateSurveyLessonPayload = {
  __typename?: 'CreateSurveyLessonPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Created survey lesson */
  lesson: Maybe<SurveyLesson>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of CreateTenantChecklist */
export type CreateTenantChecklistInput = {
  /** Checklist ID */
  checklistId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Visible */
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of CreateTenantChecklist */
export type CreateTenantChecklistPayload = {
  __typename?: 'CreateTenantChecklistPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** New Tenant Checklist */
  tenantChecklist: Maybe<TenantChecklist>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of CreateTextLesson */
export type CreateTextLessonInput = {
  /** The chapter ID of the Course */
  chapterId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  lessonInput: LessonInput;
  lessonWithTextInput?: InputMaybe<LessonWithTextInput>;
};

/** Autogenerated return type of CreateTextLesson */
export type CreateTextLessonPayload = {
  __typename?: 'CreateTextLessonPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Created text lesson */
  lesson: Maybe<TextLesson>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of CreateUserImport */
export type CreateUserImportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The graphql_name of the file to be used for the import */
  fileName: Scalars['String']['input'];
  /** The AWS S3 key of the file to be used for the import */
  s3Key: Scalars['String']['input'];
};

/** Autogenerated return type of CreateUserImport */
export type CreateUserImportPayload = {
  __typename?: 'CreateUserImportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /**
   * Response data
   * @deprecated Backwards compatibility for old response data
   */
  data: Maybe<Scalars['JSON']['output']>;
  /**
   * Response error
   * @deprecated Backwards compatibility for old response error
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
};

/** Create Video Result */
export type CreateVideo = ResponseInterface & {
  __typename?: 'CreateVideo';
  /**
   * Response Extra Data
   * @deprecated response fields should be well defined following a object structure
   */
  data: Maybe<Scalars['JSON']['output']>;
  errors: Maybe<Array<Maybe<Error>>>;
  /** Response Message */
  message: Maybe<Scalars['String']['output']>;
  /** Return status: SUCCESS, PARTIAL_SUCCESS or FAILED */
  status: Maybe<ResponseStatus>;
};

/** Input field for creating a video */
export type CreateVideoInput = {
  /** AWS S3 bucket for the video */
  s3Bucket: Scalars['String']['input'];
  /** AWS S3 key for the video */
  s3Key: Scalars['String']['input'];
  /** The video's mimetype */
  videoContentType: Scalars['String']['input'];
  /** The video filename */
  videoFileName: Scalars['String']['input'];
  /** The video's file size */
  videoFileSize: Scalars['Int']['input'];
};

/** Autogenerated input type of CreateVideoLesson */
export type CreateVideoLessonInput = {
  /** The chapter ID of the Course */
  chapterId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  lessonInput: LessonInput;
  lessonWithDownloadInput?: InputMaybe<Array<LessonWithDownloadInput>>;
  lessonWithVideoInput?: InputMaybe<LessonWithVideoInput>;
};

/** Autogenerated return type of CreateVideoLesson */
export type CreateVideoLessonPayload = {
  __typename?: 'CreateVideoLessonPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Created video lesson */
  lesson: Maybe<VideoLesson>;
  userErrors: Maybe<Array<UserError>>;
};

/** Create a new webhook */
export type CreateWebhook = ResponseInterface & {
  __typename?: 'CreateWebhook';
  /**
   * Response Extra Data
   * @deprecated response fields should be well defined following a object structure
   */
  data: Maybe<Scalars['JSON']['output']>;
  errors: Maybe<Array<Maybe<Error>>>;
  /** Response Message */
  message: Maybe<Scalars['String']['output']>;
  /** Return status: SUCCESS, PARTIAL_SUCCESS or FAILED */
  status: Maybe<ResponseStatus>;
};

/** Input fields to create a webhook */
export type CreateWebhookInput = {
  /** Webhook's fields */
  webhook: WebhookInput;
};

/** Columns to show in the export */
export type CsvExport = {
  /** Column name */
  name: Scalars['String']['input'];
};

/** Columns to show in the export */
export type CsvExportColumn = {
  /** Custom field id */
  customFieldId?: InputMaybe<Scalars['Int']['input']>;
  /** Column name */
  name: Scalars['String']['input'];
};

/** Currency ISO code along with a user-friendly name. */
export type Currency = {
  __typename?: 'Currency';
  /** Three-letter ISO currency code. Examples: usd, cad, etc. */
  code: Maybe<Scalars['String']['output']>;
  /** User-friendly currency name. Examples: Unites States Dollar, Canadian Dollar, etc. */
  name: Maybe<Scalars['String']['output']>;
  /** Currency symbol */
  symbol: Maybe<Scalars['String']['output']>;
};

/** A custom field */
export type CustomField = {
  __typename?: 'CustomField';
  /** The custom field id */
  id: Maybe<Scalars['String']['output']>;
  /** Is the custom field required */
  required: Maybe<Scalars['Boolean']['output']>;
  /** The custom field type */
  type: Maybe<Scalars['String']['output']>;
  /** The custom field type id */
  typeId: Maybe<Scalars['String']['output']>;
  /** The custom field value */
  value: Maybe<Scalars['String']['output']>;
};

/** Custom pages */
export type CustomPage = Node & {
  __typename?: 'CustomPage';
  /** Custom Page's created at datetime */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** Custom Page's template (View) */
  customThemeTemplate: Maybe<CustomSiteThemeView>;
  id: Scalars['ID']['output'];
  /** Custom Page's markup */
  liquidMarkup: Maybe<Scalars['String']['output']>;
  /** Custom Page's name */
  name: Maybe<Scalars['String']['output']>;
  /** Custom Page's id */
  pageId: Maybe<Scalars['Int']['output']>;
  /** Custom page is paid page? */
  paidCustomPage: Maybe<Scalars['Boolean']['output']>;
  /** Custom Page is private? */
  private: Maybe<Scalars['Boolean']['output']>;
  /** A list of Products belonging to the Custom Page */
  products: Maybe<ProductConnection>;
  /** Custom Page is published? */
  published: Maybe<Scalars['Boolean']['output']>;
  /** Custom Page's builder URL */
  siteBuilderUrl: Scalars['String']['output'];
  /** Custom Page's slug */
  slug: Maybe<Scalars['String']['output']>;
  /** Custom Page's theme data */
  themeData: Maybe<Scalars['JSON']['output']>;
  /** Custom Page's updated at datetime */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  /** Custom Page's url */
  url: Maybe<Scalars['String']['output']>;
};

/** Custom pages */
export type CustomPageCustomThemeTemplateArgs = {
  themeId?: InputMaybe<Scalars['String']['input']>;
};

/** Custom pages */
export type CustomPageProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for CustomPage. */
export type CustomPageConnection = {
  __typename?: 'CustomPageConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<CustomPageEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<CustomPage>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CustomPageEdge = {
  __typename?: 'CustomPageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<CustomPage>;
};

/** Possible fields to filter pages */
export type CustomPageFilter = {
  /** CustomPage's id */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Fetch default custom pages */
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  /** Search by name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** CustomPage's integer id */
  pageId?: InputMaybe<Scalars['Int']['input']>;
};

/** Input fields for a CustomPage */
export type CustomPageInput = {
  /** Custom Page's name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Custom Page is paid? */
  paidCustomPage?: InputMaybe<Scalars['Boolean']['input']>;
  /** Custom Page is private? */
  private?: InputMaybe<Scalars['Boolean']['input']>;
  /** Custom Page is published? */
  published?: InputMaybe<Scalars['Boolean']['input']>;
  /** Custom Page's slug */
  slug?: InputMaybe<Scalars['String']['input']>;
  /** Custom Page's theme data */
  themeData?: InputMaybe<Scalars['JSON']['input']>;
};

/** Possible fields to order custom pages */
export type CustomPageOrderBy = {
  direction: OrderDirection;
  field: CustomPageOrderField;
};

/** Possible fields to order custom pages */
export enum CustomPageOrderField {
  /** Order custom pages by editedAt for the active theme */
  EditedAt = 'EDITED_AT',
  /** Order custom pages by id */
  Id = 'ID',
  /** Order custom pages by name */
  Name = 'NAME',
}

/** CustomProfileFieldDefinitions */
export type CustomProfileFieldDefinition = Node & {
  __typename?: 'CustomProfileFieldDefinition';
  /** CustomProfileFieldDefinition's id */
  customProfileFieldDefinitionId: Maybe<Scalars['Int']['output']>;
  /** CustomProfileFieldDefinition's type */
  fieldType: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** CustomProfileFieldDefinition's label */
  label: Maybe<Scalars['String']['output']>;
  /** Is custom profile field definition required? */
  required: Maybe<Scalars['Boolean']['output']>;
};

/** The connection type for CustomProfileFieldDefinition. */
export type CustomProfileFieldDefinitionConnection = {
  __typename?: 'CustomProfileFieldDefinitionConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<CustomProfileFieldDefinitionEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<CustomProfileFieldDefinition>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CustomProfileFieldDefinitionEdge = {
  __typename?: 'CustomProfileFieldDefinitionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<CustomProfileFieldDefinition>;
};

/** Input field for CustomProfileField */
export type CustomProfileFieldInput = {
  /** CustomProfileField's Definition id */
  customProfileFieldDefinitionId?: InputMaybe<Scalars['String']['input']>;
  /** CustomProfileField's value */
  value?: InputMaybe<Scalars['String']['input']>;
};

/** The custom site themes of the tenant */
export type CustomSiteTheme = Node & {
  __typename?: 'CustomSiteTheme';
  /** Is the theme active? */
  active: Maybe<Scalars['Boolean']['output']>;
  /** Created at date */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** Does the theme have a coming soon page? */
  hasComingSoonPage: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  /** Theme logo */
  logoUrl: Maybe<Scalars['String']['output']>;
  /** Theme's manifest */
  manifest: Maybe<CustomSiteThemeManifest>;
  /** Theme's default settings */
  manifestDefaults: Maybe<Scalars['JSON']['output']>;
  /** Theme's manifest_schema */
  manifestSchema: Maybe<Scalars['JSON']['output']>;
  /** Theme's name */
  name: Maybe<Scalars['String']['output']>;
  /** A preset for a CustomSiteTheme */
  presets: Maybe<ThemePresetConnection>;
  /** Themes's Id */
  themeId: Maybe<Scalars['Int']['output']>;
  /** Theme library item assigned to theme */
  themeLibraryItem: Maybe<ThemeLibraryItem>;
  /** Updated at date */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  /** Theme's version */
  version: Maybe<CustomSiteThemeVersion>;
  /** All of the pages that belong to the theme */
  views: Maybe<CustomSiteThemeViewConnection>;
};

/** The custom site themes of the tenant */
export type CustomSiteThemePresetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ThemePresetFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ThemePresetOrderByFilter>;
};

/** The custom site themes of the tenant */
export type CustomSiteThemeViewsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomSiteThemeViewFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for CustomSiteTheme. */
export type CustomSiteThemeConnection = {
  __typename?: 'CustomSiteThemeConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<CustomSiteThemeEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<CustomSiteTheme>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CustomSiteThemeEdge = {
  __typename?: 'CustomSiteThemeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<CustomSiteTheme>;
};

/** Possible fields to filter themes */
export type CustomSiteThemeFilter = {
  /** Search by activation status */
  active?: InputMaybe<Scalars['Boolean']['input']>;
  /** Search by the theme id */
  id?: InputMaybe<Scalars['String']['input']>;
};

/** The manifest of a theme */
export type CustomSiteThemeManifest = ManifestInterface & {
  __typename?: 'CustomSiteThemeManifest';
  /** Manifest's content */
  content: Maybe<Scalars['JSON']['output']>;
  /** Manifest's created at */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** Manifest's edited at */
  editedAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** Manifest's updated at */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** Field and direction to order custom site themes */
export type CustomSiteThemeOrderByFilter = {
  direction: OrderDirection;
  field: CustomSiteThemeOrderFieldFilter;
};

/** Possible fields to order custom site themes */
export enum CustomSiteThemeOrderFieldFilter {
  /** Order custom site themes by id */
  Id = 'ID',
}

/** The version of a custom site theme */
export type CustomSiteThemeVersion = Node & {
  __typename?: 'CustomSiteThemeVersion';
  /** Created at date */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** Custom site theme's major version */
  majorVersion: Maybe<Scalars['Int']['output']>;
  /** Custom site theme's minor version */
  minorVersion: Maybe<Scalars['Int']['output']>;
  /** Custom site theme's patch version */
  patchVersion: Maybe<Scalars['Int']['output']>;
  /** Updated at date */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** The views of a custom site theme */
export type CustomSiteThemeView = Node & {
  __typename?: 'CustomSiteThemeView';
  /** The view's related app sections */
  appSections: Maybe<IhubOAuthAppViewConnection>;
  /** View's category */
  category: Maybe<Scalars['String']['output']>;
  /** View's content */
  content: Maybe<Scalars['String']['output']>;
  /** View's created at */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** View's default? */
  default: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  /** View's layout */
  layout: Maybe<CustomSiteThemeView>;
  /** View's manifest */
  manifest: Maybe<CustomSiteThemeViewManifest>;
  /** The view's default settings */
  manifestDefaults: Maybe<Scalars['JSON']['output']>;
  /** View's manifest schema */
  manifestSchema: Maybe<Scalars['JSON']['output']>;
  /** All of the manifests related to the view */
  manifests: Maybe<CustomSiteThemeViewManifestConnection>;
  /** View's name */
  name: Maybe<Scalars['String']['output']>;
  /** The URL the view redirects to */
  redirectsToUrl: Maybe<Scalars['String']['output']>;
  /** The view's related sections. Only views of type site page will yield sections */
  sections: Maybe<CustomSiteThemeViewConnection>;
  /** Default builder URL for the page */
  siteBuilderUrl: Scalars['String']['output'];
  /** An array of static section names in the order in which they are declared in the template */
  staticSectionNames: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** View's theme data */
  themeData: Maybe<Scalars['JSON']['output']>;
  /** View's title */
  title: Maybe<Scalars['String']['output']>;
  /** View's updated at */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  /** View's url */
  url: Maybe<Scalars['String']['output']>;
  /** View's type */
  viewType: Maybe<Scalars['String']['output']>;
};

/** The views of a custom site theme */
export type CustomSiteThemeViewAppSectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The views of a custom site theme */
export type CustomSiteThemeViewManifestArgs = {
  customPageId?: InputMaybe<Scalars['ID']['input']>;
  productId?: InputMaybe<Scalars['ID']['input']>;
};

/** The views of a custom site theme */
export type CustomSiteThemeViewManifestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  manifest_resolver?: InputMaybe<CustomSiteThemeViewManifestResolverFilter>;
};

/** The views of a custom site theme */
export type CustomSiteThemeViewSectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The views of a custom site theme */
export type CustomSiteThemeViewThemeDataArgs = {
  customPageId?: InputMaybe<Scalars['ID']['input']>;
  productId?: InputMaybe<Scalars['ID']['input']>;
};

/** The views of a custom site theme */
export type CustomSiteThemeViewUrlArgs = {
  bundleId?: InputMaybe<Scalars['ID']['input']>;
  courseId?: InputMaybe<Scalars['ID']['input']>;
  customPageId?: InputMaybe<Scalars['ID']['input']>;
  productPriceId?: InputMaybe<Scalars['ID']['input']>;
};

/** The connection type for CustomSiteThemeView. */
export type CustomSiteThemeViewConnection = {
  __typename?: 'CustomSiteThemeViewConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<CustomSiteThemeViewEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<CustomSiteThemeView>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CustomSiteThemeViewEdge = {
  __typename?: 'CustomSiteThemeViewEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<CustomSiteThemeView>;
};

/** Possible fields to filter views */
export type CustomSiteThemeViewFilter = {
  /** Search by category */
  category?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Search by id */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Search by name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Search only for specific types for page picker */
  pagePicker?: InputMaybe<Scalars['Boolean']['input']>;
  /** Search by previewable views */
  previewable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Search by view_type */
  viewType?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** The manifest of a view */
export type CustomSiteThemeViewManifest = ManifestInterface &
  Node & {
    __typename?: 'CustomSiteThemeViewManifest';
    /** Manifest's content */
    content: Maybe<Scalars['JSON']['output']>;
    /** Manifest's created at */
    createdAt: Maybe<Scalars['DateTime']['output']>;
    /** Manifest's edited at */
    editedAt: Maybe<Scalars['DateTime']['output']>;
    id: Scalars['ID']['output'];
    /** Manifest's metadata */
    metadata: Maybe<Scalars['JSON']['output']>;
    /** Manifest's updated at */
    updatedAt: Maybe<Scalars['DateTime']['output']>;
  };

/** The connection type for CustomSiteThemeViewManifest. */
export type CustomSiteThemeViewManifestConnection = {
  __typename?: 'CustomSiteThemeViewManifestConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<CustomSiteThemeViewManifestEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<CustomSiteThemeViewManifest>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CustomSiteThemeViewManifestEdge = {
  __typename?: 'CustomSiteThemeViewManifestEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<CustomSiteThemeViewManifest>;
};

/** Fields to update CustomSiteThemeViewManifest */
export type CustomSiteThemeViewManifestPatch = {
  /** Manifest's content */
  content?: InputMaybe<Scalars['JSON']['input']>;
  /** Manifest's metadata */
  metadata?: InputMaybe<Scalars['JSON']['input']>;
};

/** Filter to resolve to a single manifest via a related model */
export type CustomSiteThemeViewManifestResolverFilter = {
  /** The model's id */
  id?: InputMaybe<Scalars['String']['input']>;
  /** The model through which to resolve */
  type?: InputMaybe<CustomSiteThemeViewManifestResolverTypeFilter>;
};

/** The type of model through which to resolve the manifests */
export enum CustomSiteThemeViewManifestResolverTypeFilter {
  /** Custom page type */
  CustomPage = 'CustomPage',
  /** Product type */
  Product = 'Product',
}

/** Fields to update CustomSiteThemeView */
export type CustomSiteThemeViewPatchInput = {
  /** CustomSiteThemeView's theme data */
  themeData?: InputMaybe<Scalars['JSON']['input']>;
};

/** An onboarding prompt that appears on the tenant dashboard */
export type DashboardPrompt = Node & {
  __typename?: 'DashboardPrompt';
  /** The prompt's categories */
  category: Maybe<Array<Scalars['String']['output']>>;
  /** DashboardPrompt's created at date */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The prompt's CTA text */
  ctaLabel: Maybe<Scalars['String']['output']>;
  /** The prompt's link type */
  ctaType: Maybe<Scalars['String']['output']>;
  /** The prompt's CTA URL */
  ctaUrl: Maybe<Scalars['String']['output']>;
  /** DashboardPrompt's numeric id */
  dashboardPromptId: Maybe<Scalars['Int']['output']>;
  /** The prompt's icon */
  icon: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** If the tenant has completed the prompt or not */
  isCompleted: Maybe<Scalars['Boolean']['output']>;
  /** Is the prompt mandatory for completion? */
  isMandatory: Maybe<Scalars['Boolean']['output']>;
  /** The prompt's message */
  message: Maybe<Scalars['String']['output']>;
  /** The type of prompt */
  promptType: Maybe<Scalars['String']['output']>;
  /** The prompt's title */
  title: Maybe<Scalars['String']['output']>;
  /** DashboardPrompt's updated at date */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** The connection type for DashboardPrompt. */
export type DashboardPromptConnection = {
  __typename?: 'DashboardPromptConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<DashboardPromptEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<DashboardPrompt>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DashboardPromptEdge = {
  __typename?: 'DashboardPromptEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<DashboardPrompt>;
};

/** Possible fields to filter dashboard prompts */
export type DashboardPromptFilter = {
  /** Search dashboard prompt by id */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Search dashboard prompt by promptType */
  promptType?: InputMaybe<Scalars['String']['input']>;
  /** Search dashboard prompt by visible */
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Date filter - send both field to search between dates */
export type DateFilter = {
  /** Search after the specified date */
  after?: InputMaybe<Scalars['DateTime']['input']>;
  /** Search before the specified date */
  before?: InputMaybe<Scalars['DateTime']['input']>;
  /** Search for special never case */
  never?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated input type of DeactivateMfaForUser */
export type DeactivateMfaForUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Note for why target user MFA needs to be deactivated */
  note: Scalars['String']['input'];
  /** Tenant ID */
  tenantId: Scalars['ID']['input'];
  /** User ID */
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeactivateMfaForUser */
export type DeactivateMfaForUserPayload = {
  __typename?: 'DeactivateMfaForUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Updated user */
  user: Maybe<User>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of DeactivateMfa */
export type DeactivateMfaInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** One time password or backup Code */
  otpAttempt: Scalars['String']['input'];
  /** User account password */
  password: Scalars['String']['input'];
};

/** Autogenerated return type of DeactivateMfa */
export type DeactivateMfaPayload = {
  __typename?: 'DeactivateMfaPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Updated user */
  user: Maybe<User>;
  userErrors: Maybe<Array<UserError>>;
};

/** User Default Card */
export type DefaultPaymentCard = {
  __typename?: 'DefaultPaymentCard';
  /** Brand of Payment Card */
  brand: Maybe<Scalars['String']['output']>;
  /** Last 4 digits of Payment Card */
  last4: Maybe<Scalars['String']['output']>;
  /** Card payment method id */
  paymentMethodId: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of DeleteAdditionalProductPrice */
export type DeleteAdditionalProductPriceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The price's ID */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteAdditionalProductPrice */
export type DeleteAdditionalProductPricePayload = {
  __typename?: 'DeleteAdditionalProductPricePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /**
   * Response errors
   * @deprecated Backwards compatibility for old errors response
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of DeleteAfterPurchaseFlow */
export type DeleteAfterPurchaseFlowInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The After Purchase Flow's ID */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteAfterPurchaseFlow */
export type DeleteAfterPurchaseFlowPayload = {
  __typename?: 'DeleteAfterPurchaseFlowPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /**
   * Response error
   * @deprecated Backwards compatibility for old response error
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of DeleteAfterPurchaseFlowProduct */
export type DeleteAfterPurchaseFlowProductInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The After Purchase Flow Product's ID */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteAfterPurchaseFlowProduct */
export type DeleteAfterPurchaseFlowProductPayload = {
  __typename?: 'DeleteAfterPurchaseFlowProductPayload';
  /** After purchase flow */
  afterPurchaseFlow: Maybe<AfterPurchaseFlow>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /**
   * Response error
   * @deprecated Backwards compatibility for old response error
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of DeleteAttendance */
export type DeleteAttendanceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the Live Event */
  eventId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteAttendance */
export type DeleteAttendancePayload = {
  __typename?: 'DeleteAttendancePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The live event */
  liveEvent: Maybe<LiveEvent>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated return type of DeleteBlueprint */
export type DeleteBlueprintPayload = {
  __typename?: 'DeleteBlueprintPayload';
  /**
   * Response errors
   * @deprecated Backwards compatibility for old response status
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response status
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of DeleteBulkLiveLesson */
export type DeleteBulkLiveLessonInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The Bulk Live Lesson ID */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteBulkLiveLesson */
export type DeleteBulkLiveLessonPayload = {
  __typename?: 'DeleteBulkLiveLessonPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated return type of DeleteCertificateMapping */
export type DeleteCertificateMappingPayload = {
  __typename?: 'DeleteCertificateMappingPayload';
  /**
   * Response errors
   * @deprecated Backwards compatibility for old response status
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response status
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of DeleteCommunitySpace */
export type DeleteCommunitySpaceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The community space ID */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteCommunitySpace */
export type DeleteCommunitySpacePayload = {
  __typename?: 'DeleteCommunitySpacePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of DeleteCourse */
export type DeleteCourseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteCourse */
export type DeleteCoursePayload = {
  __typename?: 'DeleteCoursePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Delete a custom page */
export type DeleteCustomPage = ResponseInterface & {
  __typename?: 'DeleteCustomPage';
  /**
   * Response Extra Data
   * @deprecated response fields should be well defined following a object structure
   */
  data: Maybe<Scalars['JSON']['output']>;
  errors: Maybe<Array<Maybe<Error>>>;
  /** Response Message */
  message: Maybe<Scalars['String']['output']>;
  /** Return status: SUCCESS, PARTIAL_SUCCESS or FAILED */
  status: Maybe<ResponseStatus>;
};

/** Autogenerated input type of DeleteCustomPage */
export type DeleteCustomPageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** CustomPage's id */
  id: Scalars['String']['input'];
};

/** Autogenerated input type of DeleteDripSchedule */
export type DeleteDripScheduleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the drip schedule */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteDripSchedule */
export type DeleteDripSchedulePayload = {
  __typename?: 'DeleteDripSchedulePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Input field to delete an existing changelog entry */
export type DeleteEntryInput = {
  /** ID of entry */
  id: Scalars['String']['input'];
};

/** Result of deleting an existing changelog entry */
export type DeleteEntryResult = ResponseInterface & {
  __typename?: 'DeleteEntryResult';
  /**
   * Response Extra Data
   * @deprecated response fields should be well defined following a object structure
   */
  data: Maybe<Scalars['JSON']['output']>;
  errors: Maybe<Array<Maybe<Error>>>;
  /** Response Message */
  message: Maybe<Scalars['String']['output']>;
  /** Return status: SUCCESS, PARTIAL_SUCCESS or FAILED */
  status: Maybe<ResponseStatus>;
};

/** Autogenerated input type of DeleteFunnel */
export type DeleteFunnelInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The Funnel ID */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteFunnel */
export type DeleteFunnelPayload = {
  __typename?: 'DeleteFunnelPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of DeleteGroup */
export type DeleteGroupInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The existing Group ID */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteGroup */
export type DeleteGroupPayload = {
  __typename?: 'DeleteGroupPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /**
   * Response Extra Data
   * @deprecated Backwards compatibility for old data response
   */
  data: Maybe<Scalars['JSON']['output']>;
  /** @deprecated Backwards compatibility for old errors response */
  errors: Maybe<Array<Error>>;
  /**
   * Response Message
   * @deprecated Backwards compatibility for old message response
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Return status: SUCCESS, PARTIAL_SUCCESS or FAILED
   * @deprecated Backwards compatibility for old status response
   */
  status: Maybe<ResponseStatus>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of DeleteLesson */
export type DeleteLessonInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the lesson */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteLesson */
export type DeleteLessonPayload = {
  __typename?: 'DeleteLessonPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of DeleteLiveEvent */
export type DeleteLiveEventInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the live event */
  id: Scalars['ID']['input'];
  /** Should users be notified of updates? */
  sendEmails?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of DeleteLiveEvent */
export type DeleteLiveEventPayload = {
  __typename?: 'DeleteLiveEventPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of DeleteOrderBump */
export type DeleteOrderBumpInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteOrderBump */
export type DeleteOrderBumpPayload = {
  __typename?: 'DeleteOrderBumpPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of DeletePost */
export type DeletePostInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The post ID */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeletePost */
export type DeletePostPayload = {
  __typename?: 'DeletePostPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of DeleteProductPayee */
export type DeleteProductPayeeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Product payee ID */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteProductPayee */
export type DeleteProductPayeePayload = {
  __typename?: 'DeleteProductPayeePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /**
   * Response errors
   * @deprecated Backwards compatibility for old errors response
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of DeleteReply */
export type DeleteReplyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The post ID */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteReply */
export type DeleteReplyPayload = {
  __typename?: 'DeleteReplyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Input field for updating a SearchBookmark */
export type DeleteSearchBookmarkInput = {
  /** SearchBookmark's id */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteSearchBookmark */
export type DeleteSearchBookmarkPayload = {
  __typename?: 'DeleteSearchBookmarkPayload';
  /**
   * Response errors
   * @deprecated Backwards compatibility for old response status
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response status
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of DeleteThemeInstallation */
export type DeleteThemeInstallationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The theme installation id to be deleted */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteThemeInstallation */
export type DeleteThemeInstallationPayload = {
  __typename?: 'DeleteThemeInstallationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /**
   * Response data
   * @deprecated Backwards compatibility for old response data
   */
  data: Maybe<Scalars['String']['output']>;
  /**
   * Response errors
   * @deprecated Backwards compatibility for old response errors
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Delete Users response */
export type DeleteUsers = ResponseInterface & {
  __typename?: 'DeleteUsers';
  /**
   * Response Extra Data
   * @deprecated response fields should be well defined following a object structure
   */
  data: Maybe<Scalars['JSON']['output']>;
  errors: Maybe<Array<Maybe<Error>>>;
  /** Response Message */
  message: Maybe<Scalars['String']['output']>;
  /** Return status: SUCCESS, PARTIAL_SUCCESS or FAILED */
  status: Maybe<ResponseStatus>;
  /** Amount of users pending deletion */
  usersPendingDeletion: Maybe<Scalars['Int']['output']>;
};

/** Delete Video Result */
export type DeleteVideo = ResponseInterface & {
  __typename?: 'DeleteVideo';
  /**
   * Response Extra Data
   * @deprecated response fields should be well defined following a object structure
   */
  data: Maybe<Scalars['JSON']['output']>;
  errors: Maybe<Array<Maybe<Error>>>;
  /** Response Message */
  message: Maybe<Scalars['String']['output']>;
  /** Return status: SUCCESS, PARTIAL_SUCCESS or FAILED */
  status: Maybe<ResponseStatus>;
};

/** Input field for deleting video */
export type DeleteVideoInput = {
  /** The video ID */
  videoId: Scalars['String']['input'];
};

/** Delete a Webhook */
export type DeleteWebhook = ResponseInterface & {
  __typename?: 'DeleteWebhook';
  /**
   * Response Extra Data
   * @deprecated response fields should be well defined following a object structure
   */
  data: Maybe<Scalars['JSON']['output']>;
  errors: Maybe<Array<Maybe<Error>>>;
  /** Response Message */
  message: Maybe<Scalars['String']['output']>;
  /** Return status: SUCCESS, PARTIAL_SUCCESS or FAILED */
  status: Maybe<ResponseStatus>;
};

/** Input fields to delete a webhook */
export type DeleteWebhookInput = {
  /** Webhook's id */
  id: Scalars['String']['input'];
};

/** Tenant's webhook delivery */
export type Delivery = Node & {
  __typename?: 'Delivery';
  /** Delivery's error */
  error: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Delivery's payload */
  requestBody: Maybe<Scalars['String']['output']>;
  /** Delivery's success */
  responseStatus: Maybe<Scalars['String']['output']>;
  /** Delivery's sent at date */
  sentAt: Maybe<Scalars['DateTime']['output']>;
  /** Delivery's success */
  success: Maybe<Scalars['String']['output']>;
};

/** A list of webhook deliveries owned by the tenant */
export type DeliveryConnection = {
  __typename?: 'DeliveryConnection';
  /** List of deliveries */
  edges: Maybe<Array<DeliveryEdge>>;
  /** Page info object */
  pageInfo: Maybe<DeliveryPageInfo>;
  /** Total number of webhooks */
  totalCount: Scalars['Int']['output'];
};

/** A delivery edge */
export type DeliveryEdge = {
  __typename?: 'DeliveryEdge';
  /** The ID of the delivery node */
  cursor: Maybe<Scalars['String']['output']>;
  /** A delivery node */
  node: Maybe<Delivery>;
};

/** Page info for the delivery */
export type DeliveryPageInfo = {
  __typename?: 'DeliveryPageInfo';
  /** End cursor */
  endCursor: Maybe<Scalars['String']['output']>;
  /**
   * If next page is available
   * @deprecated Is not to be used
   */
  hasNextPage: Maybe<Scalars['Boolean']['output']>;
  /**
   * If previous page is available
   * @deprecated Is not to be used
   */
  hasPreviousPage: Maybe<Scalars['Boolean']['output']>;
  /** Start cursor */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of DenyMultiInstanceRequest */
export type DenyMultiInstanceRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Connection token */
  connection_token?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DenyMultiInstanceRequest */
export type DenyMultiInstanceRequestPayload = {
  __typename?: 'DenyMultiInstanceRequestPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /**
   * Response data
   * @deprecated Backwards compatibility for old response
   */
  data: Maybe<Scalars['String']['output']>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of DisableAllBundleWelcomeEmail */
export type DisableAllBundleWelcomeEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DisableAllBundleWelcomeEmail */
export type DisableAllBundleWelcomeEmailPayload = {
  __typename?: 'DisableAllBundleWelcomeEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Response message */
  message: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of DisableAllCourseCompletionEmail */
export type DisableAllCourseCompletionEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DisableAllCourseCompletionEmail */
export type DisableAllCourseCompletionEmailPayload = {
  __typename?: 'DisableAllCourseCompletionEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Response message */
  message: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of DisableAllCourseEnrollmentEmail */
export type DisableAllCourseEnrollmentEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DisableAllCourseEnrollmentEmail */
export type DisableAllCourseEnrollmentEmailPayload = {
  __typename?: 'DisableAllCourseEnrollmentEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Response message */
  message: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of DisableSiteScriptsByAppId */
export type DisableSiteScriptsByAppIdInput = {
  /** The OAuth App Id */
  appId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DisableSiteScriptsByAppId */
export type DisableSiteScriptsByAppIdPayload = {
  __typename?: 'DisableSiteScriptsByAppIdPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Possible discount types */
export enum DiscountEnum {
  /** takes a fixed amount off of the price */
  Fixed = 'fixed',
  /** takes a percentage off of the price */
  Percentage = 'percentage',
}

/** Disqus lesson information */
export type DisqusLesson = LessonInterface &
  Node & {
    __typename?: 'DisqusLesson';
    /** Content's chapter */
    chapter: Chapter;
    /** contentable id */
    contentableId: Scalars['ID']['output'];
    /** Content's course */
    course: Course;
    /** Content's created at date */
    createdAt: Scalars['DateTime']['output'];
    /** Lesson default icon */
    defaultLessonTypeIcon: Scalars['String']['output'];
    /** Is discussion enabled */
    discussionsEnabled: Maybe<Scalars['Boolean']['output']>;
    /** Lesson draft status */
    draft: Scalars['Boolean']['output'];
    /** Lesson's associated Drip Schedule Item */
    dripScheduleItem: Maybe<DripScheduleItem>;
    /** lesson drip status */
    dripStatus: Maybe<Scalars['JSON']['output']>;
    /** Is expand enabled */
    expandEnabled: Maybe<Scalars['Boolean']['output']>;
    /** Is lesson free */
    free: Maybe<Scalars['Boolean']['output']>;
    /** Lesson html text */
    htmlDescription: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    /** Current user lesson status */
    lessonCompleted: Maybe<Scalars['Boolean']['output']>;
    /** lesson drip schedule */
    lessonHasDripSchedule: Maybe<Scalars['String']['output']>;
    /** Lesson integer ID */
    lessonId: Scalars['ID']['output'];
    /** Lesson icon */
    lessonTypeIcon: Maybe<LessonIconEnum>;
    /** Lesson label */
    lessonTypeLabel: Maybe<Scalars['String']['output']>;
    /** Lesson url */
    lessonUrl: Maybe<Scalars['String']['output']>;
    /** Lesson name */
    name: Scalars['String']['output'];
    /** Lesson position */
    position: Scalars['Int']['output'];
    /** Is lesson prerequisite */
    prerequisite: Scalars['Boolean']['output'];
    /** Lesson's slug */
    slug: Maybe<Scalars['String']['output']>;
    /** Lesson Type */
    type: Scalars['String']['output'];
    /** Whether the lesson is still under construction */
    underConstruction: Scalars['Boolean']['output'];
    /** Content's updated at date */
    updatedAt: Scalars['DateTime']['output'];
    /** User content for lesson */
    userContents: Maybe<UserContentConnection>;
    /** User quizzes for lesson */
    userQuizzes: Maybe<UserQuizConnection>;
    /** User surveys for lesson */
    userSurveys: Maybe<UserSurveyConnection>;
  };

/** Disqus lesson information */
export type DisqusLessonUserContentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserContentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserContentOrderByFilter>;
};

/** Disqus lesson information */
export type DisqusLessonUserQuizzesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserQuizFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserQuizOrderBy>;
};

/** Disqus lesson information */
export type DisqusLessonUserSurveysArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserSurveyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserSurveyOrderBy>;
};

/** Custom domains */
export type DomainListing = {
  __typename?: 'DomainListing';
  /** If the listing is available for purchase */
  available: Maybe<Scalars['Boolean']['output']>;
  /** Currency of price listed for domain purchase */
  currency: Maybe<Scalars['String']['output']>;
  /** Listing's domain name */
  domainName: Maybe<Scalars['String']['output']>;
  /** Number of years the price covers for a listing */
  period: Maybe<Scalars['Int']['output']>;
  /** Price to purchase domain in cents */
  price: Maybe<Scalars['Int']['output']>;
};

/** download file information */
export type DownloadFile = Node & {
  __typename?: 'DownloadFile';
  /** Name of file upload */
  fileName: Maybe<Scalars['String']['output']>;
  /** File type - e.g. image/png */
  fileType: Maybe<Scalars['String']['output']>;
  /** Human readable file size */
  humanReadableFileSize: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Label to display for file */
  label: Maybe<Scalars['String']['output']>;
  /** The order of the download relative to other downloads */
  position: Maybe<Scalars['Int']['output']>;
  /** Download file size in bytes */
  rawSize: Maybe<Scalars['Int']['output']>;
  /** Download file url */
  url: Maybe<Scalars['String']['output']>;
};

/** The connection type for DownloadFile. */
export type DownloadFileConnection = {
  __typename?: 'DownloadFileConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<DownloadFileEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<DownloadFile>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DownloadFileEdge = {
  __typename?: 'DownloadFileEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<DownloadFile>;
};

/** download lesson information */
export type DownloadLesson = LessonInterface &
  Node & {
    __typename?: 'DownloadLesson';
    /** Content's chapter */
    chapter: Chapter;
    /** contentable id */
    contentableId: Scalars['ID']['output'];
    /** Content's course */
    course: Course;
    /** Content's created at date */
    createdAt: Scalars['DateTime']['output'];
    /** Lesson default icon */
    defaultLessonTypeIcon: Scalars['String']['output'];
    /** Is discussion enabled */
    discussionsEnabled: Maybe<Scalars['Boolean']['output']>;
    /** A list of the lesson's download files */
    downloadFiles: Maybe<DownloadFileConnection>;
    /** Lesson draft status */
    draft: Scalars['Boolean']['output'];
    /** Lesson's associated Drip Schedule Item */
    dripScheduleItem: Maybe<DripScheduleItem>;
    /** lesson drip status */
    dripStatus: Maybe<Scalars['JSON']['output']>;
    /** Is expand enabled */
    expandEnabled: Maybe<Scalars['Boolean']['output']>;
    /** Is lesson free */
    free: Maybe<Scalars['Boolean']['output']>;
    /** Lesson html text */
    htmlDescription: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    /** Current user lesson status */
    lessonCompleted: Maybe<Scalars['Boolean']['output']>;
    /** lesson drip schedule */
    lessonHasDripSchedule: Maybe<Scalars['String']['output']>;
    /** Lesson integer ID */
    lessonId: Scalars['ID']['output'];
    /** Lesson icon */
    lessonTypeIcon: Maybe<LessonIconEnum>;
    /** Lesson label */
    lessonTypeLabel: Maybe<Scalars['String']['output']>;
    /** Lesson url */
    lessonUrl: Maybe<Scalars['String']['output']>;
    /** Lesson name */
    name: Scalars['String']['output'];
    /** Lesson position */
    position: Scalars['Int']['output'];
    /** Is lesson prerequisite */
    prerequisite: Scalars['Boolean']['output'];
    /** Lesson's slug */
    slug: Maybe<Scalars['String']['output']>;
    /** Lesson Type */
    type: Scalars['String']['output'];
    /** Whether the lesson is still under construction */
    underConstruction: Scalars['Boolean']['output'];
    /** Content's updated at date */
    updatedAt: Scalars['DateTime']['output'];
    /** User content for lesson */
    userContents: Maybe<UserContentConnection>;
    /** User quizzes for lesson */
    userQuizzes: Maybe<UserQuizConnection>;
    /** User surveys for lesson */
    userSurveys: Maybe<UserSurveyConnection>;
  };

/** download lesson information */
export type DownloadLessonDownloadFilesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** download lesson information */
export type DownloadLessonUserContentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserContentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserContentOrderByFilter>;
};

/** download lesson information */
export type DownloadLessonUserQuizzesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserQuizFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserQuizOrderBy>;
};

/** download lesson information */
export type DownloadLessonUserSurveysArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserSurveyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserSurveyOrderBy>;
};

/** Possible download resource types */
export enum DownloadResourceTypeEnum {
  /** Checklist */
  Checklist = 'Checklist',
  /** Other */
  Download = 'Download',
  /** Ebook */
  Ebook = 'Ebook',
  /** Framework */
  Framework = 'Framework',
  /** Guide */
  Guide = 'Guide',
  /** Resource List */
  ResourceList = 'ResourceList',
  /** Template */
  Template = 'Template',
  /** Whitepaper */
  Whitepaper = 'Whitepaper',
  /** Worksheet */
  Worksheet = 'Worksheet',
}

/** Download Video Result */
export type DownloadVideo = ResponseInterface & {
  __typename?: 'DownloadVideo';
  /**
   * Response Extra Data
   * @deprecated response fields should be well defined following a object structure
   */
  data: Maybe<Scalars['JSON']['output']>;
  errors: Maybe<Array<Maybe<Error>>>;
  /** Response Message */
  message: Maybe<Scalars['String']['output']>;
  /** Return status: SUCCESS, PARTIAL_SUCCESS or FAILED */
  status: Maybe<ResponseStatus>;
};

/** Input field for downloading a video */
export type DownloadVideoInput = {
  /** The video ID */
  videoId: Scalars['String']['input'];
};

/** Drip schedule for course */
export type DripSchedule = Node & {
  __typename?: 'DripSchedule';
  dripScheduleItems: Maybe<DripScheduleItemConnection>;
  id: Scalars['ID']['output'];
  /** Release Date */
  releaseDate: Maybe<Scalars['DateTime']['output']>;
  /** Release Strategy */
  releaseStrategy: Maybe<Scalars['String']['output']>;
};

/** Drip schedule for course */
export type DripScheduleDripScheduleItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Input fields for a drip schedule */
export type DripScheduleInput = {
  /** The release date of the drip schedule */
  releaseDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** The release strategy of the drip schedule */
  releaseStrategy: DripScheduleStrategyEnum;
};

/** Drip schedule item */
export type DripScheduleItem = Node & {
  __typename?: 'DripScheduleItem';
  /** Days until release */
  daysUntilRelease: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  /** Lesson */
  lesson: LessonUnion;
};

/** The connection type for DripScheduleItem. */
export type DripScheduleItemConnection = {
  __typename?: 'DripScheduleItemConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<DripScheduleItemEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<DripScheduleItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DripScheduleItemEdge = {
  __typename?: 'DripScheduleItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<DripScheduleItem>;
};

/** Possible strategies for drip schedule */
export enum DripScheduleStrategyEnum {
  /** Strategy is relative to student enrollment date */
  RelativeToEnrollmentDate = 'relative_to_enrollment_date',
  /** Strategy is relative to release date */
  RelativeToReleaseDate = 'relative_to_release_date',
  /** Strategy is relative to student start course date */
  RelativeToStudentStartCourseDate = 'relative_to_student_start_course_date',
}

/** Autogenerated return type of DuplicateBlueprint */
export type DuplicateBlueprintPayload = {
  __typename?: 'DuplicateBlueprintPayload';
  /**
   * Response errors
   * @deprecated Backwards compatibility for old response status
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response status
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of DuplicateLiveEvent */
export type DuplicateLiveEventInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The description of the event */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The end time of the event (UTC) */
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** The ID of the live event to duplicate */
  eventId: Scalars['ID']['input'];
  /** Image input */
  image?: InputMaybe<EventImageInput>;
  /** The location of the event */
  location?: InputMaybe<Scalars['String']['input']>;
  /** The meetingUrl of the event */
  meetingUrl?: InputMaybe<Scalars['URL']['input']>;
  /** Should users be notified of this event? */
  sendEmails?: InputMaybe<Scalars['Boolean']['input']>;
  /** The start time of the event (UTC) */
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** The timezone of the event */
  timezone?: InputMaybe<Scalars['String']['input']>;
  /** The title of the event */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DuplicateLiveEvent */
export type DuplicateLiveEventPayload = {
  __typename?: 'DuplicateLiveEventPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The live event */
  liveEvent: Maybe<LiveEvent>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of DuplicateThemeInstallation */
export type DuplicateThemeInstallationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The theme installation to be duplicated */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DuplicateThemeInstallation */
export type DuplicateThemeInstallationPayload = {
  __typename?: 'DuplicateThemeInstallationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /**
   * Response data
   * @deprecated Backwards compatibility for old response data
   */
  data: Maybe<Scalars['String']['output']>;
  /**
   * Response errors
   * @deprecated Backwards compatibility for old response errors
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of EditPost */
export type EditPostInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The encrypted community_space ID for this post */
  communitySpaceId?: InputMaybe<Scalars['ID']['input']>;
  /** The content of the post */
  content?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the post */
  id: Scalars['ID']['input'];
  /** The IDs of mentioned users */
  mentionedUserIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  postAttachmentInput?: InputMaybe<PostAttachmentInput>;
  /** The title of the post */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of EditPost */
export type EditPostPayload = {
  __typename?: 'EditPostPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The post */
  post: Maybe<Post>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of EditPromotion */
export type EditPromotionInput = {
  /** Discount amount */
  amount?: InputMaybe<Scalars['Float']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  coupons?: InputMaybe<Array<CouponInput>>;
  /** Discount type: Fixed or Percentage */
  discountType?: InputMaybe<DiscountEnum>;
  /** How long the coupon should be applied to products with a subscription in months */
  duration?: InputMaybe<Scalars['Int']['input']>;
  /** Expiration date */
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** The ID of the promotion */
  id: Scalars['ID']['input'];
  /** Promotion name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The products this promotion should apply to */
  productPriceIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Autogenerated return type of EditPromotion */
export type EditPromotionPayload = {
  __typename?: 'EditPromotionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The promotion */
  promotion: Maybe<Promotion>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of EditReply */
export type EditReplyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The content of the reply */
  content?: InputMaybe<Scalars['String']['input']>;
  /** The post ID */
  id: Scalars['ID']['input'];
  /** The IDs of mentioned users */
  mentionedUserIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  postAttachmentInput?: InputMaybe<PostAttachmentInput>;
};

/** Autogenerated return type of EditReply */
export type EditReplyPayload = {
  __typename?: 'EditReplyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The reply */
  reply: Maybe<Reply>;
  userErrors: Maybe<Array<UserError>>;
};

/** Post information from ElasticSearch */
export type ElasticPost = Node & {
  __typename?: 'ElasticPost';
  /** The content name */
  contentName: Maybe<Scalars['String']['output']>;
  /** The course name if discussionable is content */
  courseName: Maybe<Scalars['String']['output']>;
  /** When the post was created */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The discussionable type */
  discussionableType: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** The post id */
  postId: Scalars['ID']['output'];
  /** How many replies the post has */
  replyCount: Maybe<Scalars['Int']['output']>;
  /** The post title */
  title: Maybe<Scalars['String']['output']>;
  /** When the post was updated */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** The connection type for ElasticPost. */
export type ElasticPostConnection = {
  __typename?: 'ElasticPostConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ElasticPostEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<ElasticPost>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ElasticPostEdge = {
  __typename?: 'ElasticPostEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<ElasticPost>;
};

/** Possible fields to filter posts */
export type ElasticPostFilter = {
  /** Search by approval */
  approved?: InputMaybe<Scalars['Boolean']['input']>;
  /** Search by discussionable/content id */
  discussionableId?: InputMaybe<Scalars['Int']['input']>;
  /** Search by discussionable type */
  discussionableType?: InputMaybe<Scalars['String']['input']>;
  /** Search by either course name or post title */
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

/** Possible fields to order posts */
export type ElasticPostOrderBy = {
  direction?: InputMaybe<OrderDirection>;
  field?: InputMaybe<ElasticPostOrderField>;
};

/** Possible fields to order posts */
export enum ElasticPostOrderField {
  /** Order posts by content_name */
  ContentName = 'CONTENT_NAME',
  /** Order posts by course_name */
  CourseName = 'COURSE_NAME',
  /** Order posts by created_at */
  CreatedAt = 'CREATED_AT',
  /** Order posts by last_interaction_at */
  LastInteractionAt = 'LAST_INTERACTION_AT',
  /** Order posts by pinned_at */
  PinnedAt = 'PINNED_AT',
  /** Order posts by reply_count */
  ReplyCount = 'REPLY_COUNT',
  /** Order posts by title */
  Title = 'TITLE',
  /** Order posts by updated_at */
  UpdatedAt = 'UPDATED_AT',
}

/** Submission information from ElasticSearch */
export type ElasticSubmission = Node & {
  __typename?: 'ElasticSubmission';
  /** Submission's assignment */
  assignment: Assignment;
  /** Submission's created at date */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** Submission's file name */
  fileName: Maybe<Scalars['String']['output']>;
  /** Submission's file size */
  fileSize: Maybe<Scalars['Int']['output']>;
  /** Submission's file type */
  fileType: Maybe<Scalars['String']['output']>;
  /** Submission's human readable file size */
  humanSize: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Submission's reviewed at time */
  reviewedAt: Maybe<Scalars['DateTime']['output']>;
  /** Submission's status */
  status: Maybe<SubmissionStatus>;
  /** Submission's updated at date */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  /** Submission's url */
  url: Maybe<Scalars['String']['output']>;
  /** Submission's user */
  user: Maybe<User>;
};

/** The connection type for ElasticSubmission. */
export type ElasticSubmissionConnection = {
  __typename?: 'ElasticSubmissionConnection';
  /** The cursor for the last hit in the results */
  cursor: Array<Scalars['String']['output']>;
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ElasticSubmissionEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<ElasticSubmission>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ElasticSubmissionEdge = {
  __typename?: 'ElasticSubmissionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<ElasticSubmission>;
};

/** Possible fields to filter submissions */
export type ElasticSubmissionFilter = {
  /** Search by id */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Search by submission name, student name or email */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Search by submission status */
  status?: InputMaybe<StatusFilter>;
};

/** Autogenerated input type of EnableAllBundleWelcomeEmail */
export type EnableAllBundleWelcomeEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of EnableAllBundleWelcomeEmail */
export type EnableAllBundleWelcomeEmailPayload = {
  __typename?: 'EnableAllBundleWelcomeEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Response message */
  message: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of EnableAllCourseCompletionEmail */
export type EnableAllCourseCompletionEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of EnableAllCourseCompletionEmail */
export type EnableAllCourseCompletionEmailPayload = {
  __typename?: 'EnableAllCourseCompletionEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Response message */
  message: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of EnableAllCourseEnrollmentEmail */
export type EnableAllCourseEnrollmentEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of EnableAllCourseEnrollmentEmail */
export type EnableAllCourseEnrollmentEmailPayload = {
  __typename?: 'EnableAllCourseEnrollmentEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Response message */
  message: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of EnableSiteScriptsByAppId */
export type EnableSiteScriptsByAppIdInput = {
  /** The OAuth App Id */
  appId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of EnableSiteScriptsByAppId */
export type EnableSiteScriptsByAppIdPayload = {
  __typename?: 'EnableSiteScriptsByAppIdPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of EnableThinkificPayments */
export type EnableThinkificPaymentsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ISO 3166-1 alpha-2 country code. Examples: US, CA, AU. */
  country: Scalars['String']['input'];
  /** The source of the request for payment activation */
  source?: InputMaybe<SourceEnum>;
};

/** Autogenerated return type of EnableThinkificPayments */
export type EnableThinkificPaymentsPayload = {
  __typename?: 'EnableThinkificPaymentsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of EnrollIntoAccelerator */
export type EnrollIntoAcceleratorInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of EnrollIntoAccelerator */
export type EnrollIntoAcceleratorPayload = {
  __typename?: 'EnrollIntoAcceleratorPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Success indicator of whether the enrollment occurred */
  success: Scalars['Boolean']['output'];
};

/** Enrollment information */
export type Enrollment = Node & {
  __typename?: 'Enrollment';
  /** Enrollment activated date */
  activatedAt: Maybe<Scalars['DateTime']['output']>;
  /** Certificate url */
  certificateUrl: Maybe<Scalars['String']['output']>;
  /** Enrollment completed at */
  completedAt: Maybe<Scalars['DateTime']['output']>;
  /** Course related to the enrollment */
  course: Maybe<Course>;
  /** Enrollment date */
  enrolledAt: Maybe<Scalars['DateTime']['output']>;
  /** Enrollment expiry date */
  expiryDate: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** Is subscription active? */
  isSubscriptionActive: Scalars['Boolean']['output'];
  /** Percentage completed by user in the course */
  progress: Maybe<Scalars['Int']['output']>;
  /** Enrollment started at */
  startedAt: Maybe<Scalars['DateTime']['output']>;
  /** Enrollment certificate URL (accredible or thinkific) as applicable */
  studentCertificateUrl: Maybe<Scalars['URL']['output']>;
  /** Enrollment updated at */
  updatedAt: Scalars['DateTime']['output'];
};

/** The connection type for Enrollment. */
export type EnrollmentConnection = {
  __typename?: 'EnrollmentConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<EnrollmentEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Enrollment>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EnrollmentEdge = {
  __typename?: 'EnrollmentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Enrollment>;
};

/** Possible fields to order enrollments */
export type EnrollmentOrderBy = {
  direction?: InputMaybe<OrderDirection>;
  field?: InputMaybe<EnrollmentOrderField>;
};

/** Possible fields to order enrollments */
export enum EnrollmentOrderField {
  /** Order enrollments by activated_at */
  ActivatedAt = 'ACTIVATED_AT',
  /** Order enrollments by completed_at */
  CompletedAt = 'COMPLETED_AT',
  /** Order enrollments by course_name */
  CourseName = 'COURSE_NAME',
  /** Order enrollments by expiry_date */
  ExpiryDate = 'EXPIRY_DATE',
  /** Order enrollments by percentage_completed */
  PercentageCompleted = 'PERCENTAGE_COMPLETED',
}

/** Enrollment status types */
export enum EnrollmentStatus {
  /** Users with active enrollment in courses */
  Active = 'ACTIVE',
  /** Users with expired enrollment in courses */
  Expired = 'EXPIRED',
  /** Users who never enrollment in courses */
  Never = 'NEVER',
}

/** Enrollment types */
export enum EnrollmentType {
  /** Active enrollment in courses */
  Active = 'ACTIVE',
  /** Free trial enrollment in courses */
  FreeTrial = 'FREE_TRIAL',
}

/** Common error type */
export type Error = {
  __typename?: 'Error';
  /** Error Code */
  code: Maybe<Scalars['String']['output']>;
  /** Error Extra Data */
  data: Maybe<Scalars['JSON']['output']>;
  /** Object Id related to the error */
  id: Maybe<Scalars['String']['output']>;
  /** Error Message */
  message: Maybe<Scalars['String']['output']>;
};

/** Active campaign event */
export type Event = {
  __typename?: 'Event';
  /** ActiveCampaign's Event description */
  description: Scalars['String']['output'];
  /** ActiveCampaign's Event enabled value */
  enabled: Scalars['Boolean']['output'];
  /** ActiveCampaign's Event slug */
  slug: ActiveCampaignEventSlug;
  /** ActiveCampaign's Event title */
  title: Scalars['String']['output'];
};

/** Shared Image input fields */
export type EventImageInput = {
  /** The alt text for the image */
  altText?: InputMaybe<Scalars['String']['input']>;
  /** The name of the bucket where the image is stored */
  bucket?: InputMaybe<Scalars['String']['input']>;
  /** The key for the image */
  key?: InputMaybe<Scalars['String']['input']>;
  /** The identifier for the image */
  uploadId?: InputMaybe<Scalars['String']['input']>;
};

/** Input fields for Active Campaign's Event */
export type EventInput = {
  /** ActiveCampaign's Event course_completed */
  course_completed?: InputMaybe<Scalars['Boolean']['input']>;
  /** ActiveCampaign's Event new_enrollment_full */
  new_enrollment_full?: InputMaybe<Scalars['Boolean']['input']>;
  /** ActiveCampaign's Event new_enrollment_trial */
  new_enrollment_trial?: InputMaybe<Scalars['Boolean']['input']>;
  /** ActiveCampaign's Event new_order */
  new_order?: InputMaybe<Scalars['Boolean']['input']>;
  /** ActiveCampaign's Event new_sign_up */
  new_sign_up?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of Event */
export type EventPayload = {
  __typename?: 'EventPayload';
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
};

/** Possible Event Source types */
export enum EventSourceTypes {
  /** Filter by Community Event Source Type */
  Community = 'Community',
}

/** Possible Event Status types */
export enum EventStatusTypes {
  /** View expired events */
  Expired = 'EXPIRED',
  /** View upcoming events */
  Upcoming = 'UPCOMING',
}

/** Filter options for live events */
export type EventsFilter = {
  /** Filter by Events before this End Time */
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by Upcoming events (including in progress) or Past events */
  eventStatus?: InputMaybe<EventStatusTypes>;
  /** Filter by Events after this Start Time */
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Events mutation interface */
export type EventsMutationInterface = {
  /** Create Attendance for a Live Event for the current User */
  createAttendance: Maybe<CreateAttendancePayload>;
  /** Create a Live Event */
  createLiveEvent: Maybe<CreateLiveEventPayload>;
  /** Delete Attendance for a Live Event for the current User */
  deleteAttendance: Maybe<DeleteAttendancePayload>;
  /** Delete a Live Event */
  deleteLiveEvent: Maybe<DeleteLiveEventPayload>;
  /** Duplicate a Live Event */
  duplicateLiveEvent: Maybe<DuplicateLiveEventPayload>;
  /** Update a Live Event */
  updateLiveEvent: Maybe<UpdateLiveEventPayload>;
};

/** Events mutation interface */
export type EventsMutationInterfaceCreateAttendanceArgs = {
  input: CreateAttendanceInput;
};

/** Events mutation interface */
export type EventsMutationInterfaceCreateLiveEventArgs = {
  input: CreateLiveEventInput;
};

/** Events mutation interface */
export type EventsMutationInterfaceDeleteAttendanceArgs = {
  input: DeleteAttendanceInput;
};

/** Events mutation interface */
export type EventsMutationInterfaceDeleteLiveEventArgs = {
  input: DeleteLiveEventInput;
};

/** Events mutation interface */
export type EventsMutationInterfaceDuplicateLiveEventArgs = {
  input: DuplicateLiveEventInput;
};

/** Events mutation interface */
export type EventsMutationInterfaceUpdateLiveEventArgs = {
  input: UpdateLiveEventInput;
};

/** Possible fields to order live events by */
export type EventsOrderBy = {
  direction?: InputMaybe<OrderDirection>;
  field?: InputMaybe<EventsOrderField>;
};

/** Possible fields to order events */
export enum EventsOrderField {
  /** Order events by end_time */
  EndTime = 'end_time',
  /** Order events by start_time */
  StartTime = 'start_time',
}

/** Events query interface */
export type EventsQueryInterface = {
  liveEvent: Maybe<LiveEvent>;
  /** A list of events */
  liveEvents: Maybe<LiveEventConnection>;
};

/** Events query interface */
export type EventsQueryInterfaceLiveEventArgs = {
  id: Scalars['ID']['input'];
};

/** Events query interface */
export type EventsQueryInterfaceLiveEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  eventSourceId: Scalars['ID']['input'];
  eventSourceType: EventSourceTypes;
  filter?: InputMaybe<EventsFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<EventsOrderBy>;
};

/** Events tenant query interface */
export type EventsTenantQueryInterface = {
  liveEvent: LiveEvent;
  /** A list of events */
  liveEvents: Maybe<LiveEventConnection>;
};

/** Events tenant query interface */
export type EventsTenantQueryInterfaceLiveEventArgs = {
  id: Scalars['ID']['input'];
};

/** Events tenant query interface */
export type EventsTenantQueryInterfaceLiveEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  eventSourceId: Scalars['ID']['input'];
  eventSourceType: EventSourceTypes;
  filter?: InputMaybe<EventsFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<EventsOrderBy>;
};

/** Exam lesson information */
export type ExamLesson = LessonInterface &
  Node & {
    __typename?: 'ExamLesson';
    /** HTML to display before the exam is taken */
    beforeTakeHtml: Maybe<Scalars['String']['output']>;
    /** The Brillium root URL */
    brilliumRootUrl: Maybe<Scalars['String']['output']>;
    /** Content's chapter */
    chapter: Chapter;
    /** The text for the CTA to claim the certificate */
    claimCertificateCtaText: Maybe<Scalars['String']['output']>;
    /** contentable id */
    contentableId: Scalars['ID']['output'];
    /** Content's course */
    course: Course;
    /** Content's created at date */
    createdAt: Scalars['DateTime']['output'];
    /** Lesson default icon */
    defaultLessonTypeIcon: Scalars['String']['output'];
    /** Is discussion enabled */
    discussionsEnabled: Maybe<Scalars['Boolean']['output']>;
    /** Lesson draft status */
    draft: Scalars['Boolean']['output'];
    /** Lesson's associated Drip Schedule Item */
    dripScheduleItem: Maybe<DripScheduleItem>;
    /** lesson drip status */
    dripStatus: Maybe<Scalars['JSON']['output']>;
    /** The entry hash password */
    entryHashPassword: Maybe<Scalars['String']['output']>;
    /** The exam ID */
    examId: Maybe<Scalars['String']['output']>;
    /** The exam password */
    examPassword: Maybe<Scalars['String']['output']>;
    /** HTML to display after the exam is failed */
    failedHtml: Maybe<Scalars['String']['output']>;
    /** Is lesson free */
    free: Maybe<Scalars['Boolean']['output']>;
    /** Lesson html text */
    htmlDescription: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    /** Current user lesson status */
    lessonCompleted: Maybe<Scalars['Boolean']['output']>;
    /** lesson drip schedule */
    lessonHasDripSchedule: Maybe<Scalars['String']['output']>;
    /** Lesson integer ID */
    lessonId: Scalars['ID']['output'];
    /** Lesson icon */
    lessonTypeIcon: Maybe<LessonIconEnum>;
    /** Lesson label */
    lessonTypeLabel: Maybe<Scalars['String']['output']>;
    /** Lesson url */
    lessonUrl: Maybe<Scalars['String']['output']>;
    /** Lesson name */
    name: Scalars['String']['output'];
    /** HTML to display after the exam is passed */
    passedHtml: Maybe<Scalars['String']['output']>;
    /** The passing grade for the exam */
    passingGrade: Maybe<Scalars['Int']['output']>;
    /** Lesson position */
    position: Scalars['Int']['output'];
    /** Is lesson prerequisite */
    prerequisite: Scalars['Boolean']['output'];
    /** Whether the retake is always on */
    retakeAlwaysOn: Maybe<Scalars['Boolean']['output']>;
    /** The text for the CTA to retake the exam */
    retakeExamCtaText: Maybe<Scalars['String']['output']>;
    /** Lesson's slug */
    slug: Maybe<Scalars['String']['output']>;
    /** The text for the CTA to take the exam */
    takeExamCtaText: Maybe<Scalars['String']['output']>;
    /** Lesson Type */
    type: Scalars['String']['output'];
    /** Whether the lesson is still under construction */
    underConstruction: Scalars['Boolean']['output'];
    /** Content's updated at date */
    updatedAt: Scalars['DateTime']['output'];
    /** User content for lesson */
    userContents: Maybe<UserContentConnection>;
    /** User quizzes for lesson */
    userQuizzes: Maybe<UserQuizConnection>;
    /** User surveys for lesson */
    userSurveys: Maybe<UserSurveyConnection>;
  };

/** Exam lesson information */
export type ExamLessonUserContentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserContentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserContentOrderByFilter>;
};

/** Exam lesson information */
export type ExamLessonUserQuizzesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserQuizFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserQuizOrderBy>;
};

/** Exam lesson information */
export type ExamLessonUserSurveysArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserSurveyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserSurveyOrderBy>;
};

/** Autogenerated input type of ExportContents */
export type ExportContentsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Array of contentable IDs */
  contentableIds: Array<InputMaybe<Scalars['ID']['input']>>;
  /** End of date range in UTC for exporting content */
  endDate: Scalars['Date']['input'];
  /** Start of date range in UTC for exporting content */
  startDate: Scalars['Date']['input'];
};

/** Autogenerated return type of ExportContents */
export type ExportContentsPayload = {
  __typename?: 'ExportContentsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /**
   * Response Extra Data
   * @deprecated Backwards compatibility for old response data
   */
  data: Maybe<Scalars['JSON']['output']>;
  /**
   * Response error
   * @deprecated Backwards compatibility for old response error
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
};

/** Fields to grab quiz survey responses for a user */
export type ExportFilter = {
  /** Student id */
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of ExportOrders */
export type ExportOrdersInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Columns to show in the export */
  columns: Array<CsvExport>;
  /** OrderTransaction filter to be exported */
  filter?: InputMaybe<OrderTransactionFilter>;
};

/** Autogenerated return type of ExportOrders */
export type ExportOrdersPayload = {
  __typename?: 'ExportOrdersPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Response message */
  message: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of ExportPayoutCsv */
export type ExportPayoutCsvInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Search by created at  */
  createdAt: DateFilter;
};

/** Autogenerated return type of ExportPayoutCsv */
export type ExportPayoutCsvPayload = {
  __typename?: 'ExportPayoutCsvPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of ExportTransactions */
export type ExportTransactionsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Columns to show in the export */
  columns: Array<CsvExport>;
  /** OrderTransactionDetail filter to be exported */
  filter: OrderTransactionDetailFilter;
};

/** Autogenerated return type of ExportTransactions */
export type ExportTransactionsPayload = {
  __typename?: 'ExportTransactionsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Response message */
  message: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Metadata about an export job for user quiz survey data. */
export type ExportUserQuizSurveyResponses = ResponseInterface & {
  __typename?: 'ExportUserQuizSurveyResponses';
  /**
   * Response Extra Data
   * @deprecated response fields should be well defined following a object structure
   */
  data: Maybe<Scalars['JSON']['output']>;
  errors: Maybe<Array<Maybe<Error>>>;
  /** ID of the export job */
  jobId: Maybe<Scalars['String']['output']>;
  /** Response Message */
  message: Maybe<Scalars['String']['output']>;
  /** Return status: SUCCESS, PARTIAL_SUCCESS or FAILED */
  status: Maybe<ResponseStatus>;
};

/** Export Users response */
export type ExportUsers = ResponseInterface & {
  __typename?: 'ExportUsers';
  /**
   * Response Extra Data
   * @deprecated response fields should be well defined following a object structure
   */
  data: Maybe<Scalars['JSON']['output']>;
  errors: Maybe<Array<Maybe<Error>>>;
  /** Response Message */
  message: Maybe<Scalars['String']['output']>;
  /** Return status: SUCCESS, PARTIAL_SUCCESS or FAILED */
  status: Maybe<ResponseStatus>;
};

/** Export Users' input fields */
export type ExportUsersInput = {
  /** The columns in the export */
  columns: Array<InputMaybe<CsvExportColumn>>;
  /** User filter to be exported */
  filter?: InputMaybe<UserFilter>;
};

/** Possible extensions for third-party oauth app */
export type ExtensionsType = {
  __typename?: 'ExtensionsType';
  /** Does the oauth app extend app settings? */
  appSettings: Maybe<Scalars['Boolean']['output']>;
};

/** Tenant feature */
export type Feature = FeatureInterface & {
  __typename?: 'Feature';
  /** Feature checkout path */
  checkoutPath: Maybe<Scalars['String']['output']>;
  /** Flag to indicate feature is enabled */
  isEnabled: Maybe<Scalars['Boolean']['output']>;
  /** The name of the minimum plan required to access this feature */
  minimumPlanName: Maybe<Scalars['String']['output']>;
};

/** Fields for a tenant feature */
export type FeatureInterface = {
  /** Feature checkout path */
  checkoutPath: Maybe<Scalars['String']['output']>;
  /** Flag to indicate feature is enabled */
  isEnabled: Maybe<Scalars['Boolean']['output']>;
  /** The name of the minimum plan required to access this feature */
  minimumPlanName: Maybe<Scalars['String']['output']>;
};

/** Possible fields to compare timestamps */
export enum FeatureLabsComparisonField {
  /** filter field by after given date */
  After = 'after',
  /** filter field by before given date */
  Before = 'before',
}

/** Possible fields to order lab features */
export enum FeatureLabsOrderField {
  /** order user contents by createdAt date */
  CreatedAt = 'createdAt',
  /** order user contents by expiresAt date */
  ExpiresAt = 'expiresAt',
  /** order user contents by createdAt date */
  HighlightedAt = 'highlightedAt',
}

/** List of lab slugs */
export enum FeatureLabsSlug {
  /**
   * Lab slug for Site Pages feature
   * @deprecated No longer a feature in labs
   */
  SitePages = 'site_pages',
}

/** Tenant features */
export type Features = {
  __typename?: 'Features';
  /** Feature details for Additional Course Prices */
  additionalPricing: Maybe<Feature>;
  /** Feature details for Advanced Reporting */
  advancedReporting: Maybe<Feature>;
  /** Feature details for Affiliate Reporting */
  affiliates: Maybe<Feature>;
  /** Feature details for After Purchase Flows */
  afterPurchaseFlows: Maybe<Feature>;
  /** Feature details for AI Course Outline */
  aiCourseOutline: Maybe<Feature>;
  /** Feature details for AI Quiz Generator */
  aiQuizGenerator: Maybe<Feature>;
  /** Feature details for Enrollments API Write Actions */
  apiEnrollmentsWrite: Maybe<Feature>;
  /** Feature details for Apps */
  apps: Maybe<Feature>;
  /** Feature details for Assignments */
  assignments: Maybe<Feature>;
  /** Feature details for Brillium Exams Integration */
  brilliumExams: Maybe<Feature>;
  /** Feature details for Bulk Enrollment */
  bulkEnrollment: Maybe<Feature>;
  /** Feature details for Course Bundles */
  bundles: Maybe<Feature>;
  /** Feature details for Accredible Certificates */
  certificates: Maybe<Feature>;
  /** Feature details for Communities */
  communities: Maybe<CommunitiesFeature>;
  /** Feature details for Community Event Notifications */
  communityEventNotifications: Maybe<Feature>;
  /** Feature details for Community Events */
  communityEvents: Maybe<Feature>;
  /** Feature details for Course Admins */
  courseAdmins: Maybe<CourseAdminsFeature>;
  /** Feature details for Course Appearance */
  courseAppearance: Maybe<Feature>;
  /** Feature details for Course Player Customization */
  coursePlayerCustomizations: Maybe<Feature>;
  /** Feature details for Courses */
  courses: Maybe<CoursesFeature>;
  /** Feature details for Custom Domain */
  customDomains: Maybe<Feature>;
  /** Feature details for Custom SSL Certificates */
  customSslCertificates: Maybe<Feature>;
  /** Feature details for Customize Lesson Icon and Label */
  customizeLessonType: Maybe<Feature>;
  /** Feature details for Drip (Scheduled) Content */
  dripContent: Maybe<Feature>;
  /** Feature details for Advanced HTML/CSS Editing */
  editThemeHtml: Maybe<Feature>;
  /** Feature details for Analytics and Insights */
  embeddedAnalytics: Maybe<Feature>;
  /** Feature details for Course Admin Access to Engagement Analytics */
  embeddedAnalyticsCourseAdmin: Maybe<Feature>;
  /** Feature details for Group Analyst Access to Engagement Analytics */
  embeddedAnalyticsGroupAnalysts: Maybe<Feature>;
  /** Feature details for Plus Analytics - Quiz, Student Progress */
  embeddedAnalyticsPlus: Maybe<Feature>;
  /** Feature details for Filter Courses */
  filterCourses: Maybe<Feature>;
  /** Feature details for Funnels */
  funnels: Maybe<FunnelsFeature>;
  /** Feature details for Purchase a Product as a Gift */
  gifting: Maybe<Feature>;
  /** Feature details for Group Analysts */
  groupAnalysts: Maybe<GroupAnalystsFeature>;
  /** Feature details for Group Orders */
  groupOrders: Maybe<Feature>;
  /** Feature details for Groups */
  groups: Maybe<GroupsFeature>;
  /** Feature details for Hidden Courses */
  hiddenProducts: Maybe<Feature>;
  /** Feature details for Custom Theme Import */
  importTheme: Maybe<Feature>;
  /** Feature details for Infusionsoft Integration */
  infusionsoft: Maybe<Feature>;
  /** Feature details for Lead Dyno Integration */
  leadDyno: Maybe<Feature>;
  /** Feature details for Managed Affiliates */
  managedAffiliates: Maybe<Feature>;
  /** Feature details for Manual Student Enrollment */
  manualEnrollment: Maybe<Feature>;
  /** Feature details for Branded Mobile App */
  mobileBranded: Maybe<Feature>;
  /** Feature details for Generic Mobile App */
  mobileGeneric: Maybe<Feature>;
  /** Feature details for Multimedia Upload */
  multimediaUpload: Maybe<Feature>;
  /** Feature details for Edit Notification Email Templates */
  notificationEmailEditing: Maybe<Feature>;
  /** Feature details for One Time Pricing */
  oneTimePricing: Maybe<Feature>;
  /** Feature details for OpenID Connect Client */
  openidConnectClient: Maybe<Feature>;
  /** Feature details for Paid Custom Pages */
  paidCustomPages: Maybe<Feature>;
  /** Feature details for Partners */
  partners: Maybe<PartnersFeature>;
  /** Feature details for Payment Plans */
  paymentPlans: Maybe<Feature>;
  /** Feature details for Payments with PayPal */
  paypal: Maybe<PaypalFeature>;
  /** Feature details for Prerequisite Lessons */
  prerequisiteLessons: Maybe<Feature>;
  /** Feature details for Presentation Completion Required */
  presentationCompletionRequired: Maybe<Feature>;
  /** Feature details for Private Courses */
  privateCourses: Maybe<Feature>;
  /** Feature details for Product Payees */
  productPayees: Maybe<ProductPayeesFeature>;
  /** Feature details for Coupons & Promotions */
  promotions: Maybe<PromotionsFeature>;
  /** Feature details for Public API */
  publicApiAccess: Maybe<Feature>;
  /** Feature details for Quiz Question Bank */
  quizQuestionBank: Maybe<Feature>;
  /** Feature details for Site Admins */
  siteAdmins: Maybe<SiteAdminsFeature>;
  /** Feature details for Site Footer Code */
  siteFooterCode: Maybe<Feature>;
  /** Feature details for Thinkific Certificates */
  studentCertificates: Maybe<Feature>;
  /** Feature details for Monthly Subscriptions */
  subscriptionPricing: Maybe<Feature>;
  /** Feature details for Thinkific Custom SSO */
  thinkificCustomSso: Maybe<Feature>;
  /** Feature details for Advanced User Search */
  userAdvancedSearch: Maybe<Feature>;
  /** Feature details for Bulk Student Emailer */
  userBulkEmail: Maybe<UserBulkEmailFeature>;
  /** Feature details for User Exports */
  userExports: Maybe<Feature>;
  /** Feature details for User Imports */
  userImports: Maybe<Feature>;
  /** Feature details for Single User Email */
  userSingleEmail: Maybe<Feature>;
  /** Feature details for Video Completion Required */
  videoCompletionRequired: Maybe<Feature>;
  /** Feature details for Video Engagement Reporting */
  videoEngagementReporting: Maybe<Feature>;
  /** Feature details for Webhooks */
  webhooks: Maybe<Feature>;
  /** Feature details for Site White Labeling */
  whiteLabelling: Maybe<Feature>;
  /** Feature details for Wistia Integration */
  wistia: Maybe<Feature>;
  /** Feature details for Zapier Actions */
  zapierActions: Maybe<Feature>;
  /** Feature details for Zapier Triggers */
  zapierTriggers: Maybe<Feature>;
  /** Feature details for Zoom Meetings */
  zoomMeetings: Maybe<Feature>;
  /** Feature details for Zoom Webinars */
  zoomWebinars: Maybe<Feature>;
};

/** In-app Announcement data */
export type FeaturesAnnouncementInterface = {
  /** Badge type for changelog */
  badgeType: Maybe<Scalars['String']['output']>;
  /** Category in which entry belongs */
  category: Maybe<Scalars['String']['output']>;
};

/** Possible fields to filter design presets by */
export type FeaturesLabsFilters = {
  /** Search by highlighted at state for the tenant */
  expiresAt?: InputMaybe<FeaturesLabsTimestampFilter>;
  /** Search by highlighted at state for the tenant */
  highlightedAt?: InputMaybe<FeaturesLabsTimestampFilter>;
  /** Search by string */
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** Order for labs features */
export type FeaturesLabsOrderBy = {
  options: Array<InputMaybe<FeaturesLabsOrderByOption>>;
};

/** Order by option for labs */
export type FeaturesLabsOrderByOption = {
  direction: OrderDirection;
  field: FeatureLabsOrderField;
};

/** Timestamp filter options */
export type FeaturesLabsTimestampFilter = {
  comparison: FeatureLabsComparisonField;
  datetime: Scalars['DateTime']['input'];
};

/** Mutation interface for Features */
export type FeaturesMutationInterface = {
  /** Create or update tenant lab's attributes */
  createOrUpdateTenantLab: Maybe<CreateOrUpdateTenantLabPayload>;
};

/** Mutation interface for Features */
export type FeaturesMutationInterfaceCreateOrUpdateTenantLabArgs = {
  input: CreateOrUpdateTenantLabInput;
};

/** An object lab features data. */
export type FeaturesTenantLab = FeaturesAnnouncementInterface &
  LabInterface &
  Node & {
    __typename?: 'FeaturesTenantLab';
    /** Badge type for changelog */
    badgeType: Maybe<Scalars['String']['output']>;
    /** Category in which entry belongs */
    category: Maybe<Scalars['String']['output']>;
    /** Lab description */
    description: Maybe<Scalars['String']['output']>;
    /** Date that the lab expires */
    expiresAt: Maybe<Scalars['DateTime']['output']>;
    /** Lab global unique ID for Announcements */
    guid: Maybe<Scalars['String']['output']>;
    /** Date that the lab appears in the highlight modal for tenants */
    highlightedAt: Maybe<Scalars['DateTime']['output']>;
    id: Scalars['ID']['output'];
    /** Lab sale image */
    imageUrl: Maybe<Scalars['String']['output']>;
    /** Is the lab feature available to the tenant? */
    isAvailable: Maybe<Scalars['Boolean']['output']>;
    /** Did the tenant turn on the lab feature? */
    isOptedIn: Maybe<Scalars['Boolean']['output']>;
    /** Lab manifest */
    manifest: Maybe<Scalars['JSON']['output']>;
    /** Lab publish date data needed for Announcements */
    publishDate: Maybe<Scalars['String']['output']>;
    /** Lab schema */
    schema: Maybe<Scalars['JSON']['output']>;
    /** Lab slug */
    slug: Maybe<Scalars['String']['output']>;
    /** Lab title */
    title: Maybe<Scalars['String']['output']>;
  };

/** The connection type for FeaturesTenantLab. */
export type FeaturesTenantLabConnection = {
  __typename?: 'FeaturesTenantLabConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<FeaturesTenantLabEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<FeaturesTenantLab>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type FeaturesTenantLabEdge = {
  __typename?: 'FeaturesTenantLabEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<FeaturesTenantLab>;
};

/** A Tenant interface for Features */
export type FeaturesTenantQueryInterface = {
  /** A list of tenant TenantLabs */
  labs: Maybe<FeaturesTenantLabConnection>;
};

/** A Tenant interface for Features */
export type FeaturesTenantQueryInterfaceLabsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<FeaturesLabsFilters>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<FeaturesLabsOrderBy>;
};

/** Shared File input fields */
export type FileInput = {
  /** The name of the file */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The size of the file */
  size?: InputMaybe<Scalars['Int']['input']>;
  /** The type of the file */
  type?: InputMaybe<Scalars['String']['input']>;
  /** The url of the file */
  url: Scalars['String']['input'];
};

/** Filestack security data */
export type FilestackSecurity = {
  __typename?: 'FilestackSecurity';
  /** Filestack's api key */
  apiKey: Maybe<Scalars['String']['output']>;
  /** Filestack's policy */
  policy: Maybe<Scalars['String']['output']>;
  /** Filestack's signature */
  signature: Maybe<Scalars['String']['output']>;
};

/** Funnel flow metadata */
export type FlowMetadata = {
  __typename?: 'FlowMetadata';
  /** Denotes if the cross-sell thank you page has been reviewed */
  reviewedCrossSellThankYouPage: Scalars['Boolean']['output'];
  /** Denotes if the free lesson preview has been reviewed */
  reviewedFreeLesson: Scalars['Boolean']['output'];
  /** Denotes if the landing page content has been reviewed */
  reviewedLandingPage: Scalars['Boolean']['output'];
  /** Denotes if the purchase prompt content has been reviewed */
  reviewedPurchasePrompt: Scalars['Boolean']['output'];
  /** Denotes if the recapture purchase prompt content has been reviewed */
  reviewedRecapturePurchasePrompt: Scalars['Boolean']['output'];
  /** Denotes if the main thank you page has been reviewed */
  reviewedThankYouPage: Scalars['Boolean']['output'];
};

/** Flow metadata input */
export type FlowMetadataInput = {
  /** Denotes if the cross-sell thank you page has been reviewed */
  reviewedCrossSellThankYouPage?: InputMaybe<Scalars['Boolean']['input']>;
  /** Denotes if the free lesson preview has been reviewed */
  reviewedFreeLesson?: InputMaybe<Scalars['Boolean']['input']>;
  /** Denotes if the landing page content has been reviewed */
  reviewedLandingPage?: InputMaybe<Scalars['Boolean']['input']>;
  /** Denotes if the purchase prompt content has been reviewed */
  reviewedPurchasePrompt?: InputMaybe<Scalars['Boolean']['input']>;
  /** Denotes if the recapture purchase prompt content has been reviewed */
  reviewedRecapturePurchasePrompt?: InputMaybe<Scalars['Boolean']['input']>;
  /** Denotes if the main thank you page has been reviewed */
  reviewedThankYouPage?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated input type of FollowPost */
export type FollowPostInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the post the user is following */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of FollowPost */
export type FollowPostPayload = {
  __typename?: 'FollowPostPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The post */
  post: Maybe<Post>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of FollowReply */
export type FollowReplyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The post ID */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of FollowReply */
export type FollowReplyPayload = {
  __typename?: 'FollowReplyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The reply */
  reply: Maybe<Reply>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of ForkThemeInstallation */
export type ForkThemeInstallationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The theme installation id to be forked */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of ForkThemeInstallation */
export type ForkThemeInstallationPayload = {
  __typename?: 'ForkThemeInstallationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /**
   * Response data
   * @deprecated Backwards compatibility for old response data
   */
  data: Maybe<Scalars['String']['output']>;
  /**
   * Response errors
   * @deprecated Backwards compatibility for old response errors
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  themeInstallation: Maybe<ThemeInstallation>;
  userErrors: Maybe<Array<UserError>>;
};

/** Variables relating to Fullstory */
export type Fullstory = {
  __typename?: 'Fullstory';
  /** Fullstory Debug Boolean */
  debug: Maybe<Scalars['String']['output']>;
  /** Whether FullStory will run in this tenant website */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** Fullstory Org Id */
  orgId: Maybe<Scalars['String']['output']>;
  /** Whether FullStory will run for the student */
  studentTrackingEnabled: Maybe<Scalars['Boolean']['output']>;
};

/** Funnel */
export type Funnel = Node & {
  __typename?: 'Funnel';
  /** Created At */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Cross Sell Offer */
  crossSellOffer: Maybe<OneTimeOffer>;
  /** Download File URL */
  downloadFileUrl: Maybe<Scalars['String']['output']>;
  /** Download Resource Type */
  downloadResourceType: Maybe<DownloadResourceTypeEnum>;
  /** Fallback Offer */
  fallbackOffer: Maybe<OneTimeOffer>;
  /** Date Published */
  firstPublishedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Funnel Type */
  funnelType: FunnelTypeEnum;
  id: Scalars['ID']['output'];
  /** Initial Offer */
  initialOffer: Maybe<OneTimeOffer>;
  /** Metadata */
  metadata: Metadata;
  /** Name */
  name: Scalars['String']['output'];
  /** Funnel's Offers */
  oneTimeOffers: Maybe<OneTimeOfferConnection>;
  /** Product */
  product: Maybe<Product>;
  /** Lead Capture Page */
  sitePage: Maybe<TemplatePages>;
  /** The published status of the Funnel */
  status: FunnelStatusEnum;
  /** Thank You Page */
  thankYouPage: Maybe<TemplatePages>;
  /** Last Updated */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Funnel */
export type FunnelOneTimeOffersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for Funnel. */
export type FunnelConnection = {
  __typename?: 'FunnelConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<FunnelEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Funnel>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type FunnelEdge = {
  __typename?: 'FunnelEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Funnel>;
};

/** Possible funnel statuses */
export enum FunnelStatusEnum {
  /** The funnel has never been published */
  Draft = 'Draft',
  /** The funnel is published and live */
  Published = 'Published',
  /** The funnel was previously published, but is now unpublished */
  Unpublished = 'Unpublished',
}

/** Possible funnel types */
export enum FunnelTypeEnum {
  /** Offers a free download file */
  FreeDownloadFunnel = 'FreeDownloadFunnel',
  /** Offers a free preview lesson */
  FreeLessonFunnel = 'FreeLessonFunnel',
  /** Offers a unique sales page for a product */
  SalesPageFunnel = 'SalesPageFunnel',
}

/** Funnels feature */
export type FunnelsFeature = FeatureInterface & {
  __typename?: 'FunnelsFeature';
  /** Feature checkout path */
  checkoutPath: Maybe<Scalars['String']['output']>;
  /** Flag to indicate feature is enabled */
  isEnabled: Maybe<Scalars['Boolean']['output']>;
  /** Maximum number of Funnels */
  maxFunnels: Scalars['Int']['output'];
  /** The name of the minimum plan required to access this feature */
  minimumPlanName: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of GenerateCourseOutline */
export type GenerateCourseOutlineInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of a course template to use */
  courseTemplateId?: InputMaybe<Scalars['ID']['input']>;
  /** The expected outcomes of a student taking your course */
  expectedOutcomes?: InputMaybe<Scalars['String']['input']>;
  /** The knowledge level your course will be geared towards */
  knowledgeLevel?: InputMaybe<Scalars['String']['input']>;
  /** A name for the course */
  name: Scalars['String']['input'];
  /** The problem that your course is trying to solve */
  problemToSolve?: InputMaybe<Scalars['String']['input']>;
  /** The ideal audience your course will be targeting */
  targetAudience?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of GenerateCourseOutline */
export type GenerateCourseOutlinePayload = {
  __typename?: 'GenerateCourseOutlinePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Created Prompt */
  prompt: Maybe<Prompt>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of GenerateOtpBackupCodes */
export type GenerateOtpBackupCodesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** One time password or Backup Code */
  otpAttempt: Scalars['String']['input'];
  /** User account password */
  password: Scalars['String']['input'];
};

/** Autogenerated return type of GenerateOtpBackupCodes */
export type GenerateOtpBackupCodesPayload = {
  __typename?: 'GenerateOtpBackupCodesPayload';
  /** List of backup codes */
  backupCodes: Maybe<Array<Scalars['String']['output']>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Input fields for gift receiver details */
export type GiftInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
};

/** Tenant global styles */
export type GlobalStyles = {
  __typename?: 'GlobalStyles';
  /** The accent background color global style for the site */
  accentBgColor: Scalars['String']['output'];
  /** The accent text color global style for the site */
  accentTextColor: Scalars['String']['output'];
  /** The body font weight global style for the site */
  bodyFontWeight: Scalars['String']['output'];
  /** The button border radius global style for the site */
  buttonBorderRadius: Scalars['String']['output'];
  /** The button font weight global style for the site */
  buttonFontWeight: Scalars['String']['output'];
  /** The CTA background color global style for the site */
  ctaBgColor: Scalars['String']['output'];
  /** The CTA text color global style for the site */
  ctaTextColor: Scalars['String']['output'];
  /** The input border color global style for the site */
  inputBorderColor: Scalars['String']['output'];
  /** The input border radius global style for the site */
  inputBorderRadius: Scalars['String']['output'];
  /** The input error color global style for the site */
  inputErrorColor: Scalars['String']['output'];
  /** The input text color global style for the site */
  inputTextColor: Scalars['String']['output'];
  /** The primary background color global style for the site */
  primaryBgColor: Scalars['String']['output'];
  /** The primary font family global style for the site */
  primaryFontFamily: Scalars['String']['output'];
  /** The primary text color global style for the site */
  primaryTextColor: Scalars['String']['output'];
  /** The promotion badge background color global style for the site */
  promotionBadgeBgColor: Scalars['String']['output'];
  /** The promotion badge text color global style for the site */
  promotionBadgeTextColor: Scalars['String']['output'];
  /** The promotion text color global style for the site */
  promotionTextColor: Scalars['String']['output'];
  /** The secondary background color global style for the site */
  secondaryBgColor: Scalars['String']['output'];
  /** The secondary CTA background color global style for the site */
  secondaryCtaBgColor: Scalars['String']['output'];
  /** The secondary CTA text color global style for the site */
  secondaryCtaTextColor: Scalars['String']['output'];
  /** The secondary font family global style for the site */
  secondaryFontFamily: Scalars['String']['output'];
  /** The secondary text color global style for the site */
  secondaryTextColor: Scalars['String']['output'];
  /** The subtitle font weight global style for the site */
  subtitleFontWeight: Scalars['String']['output'];
  /** The surface border radius global style for the site */
  surfaceBorderRadius: Scalars['String']['output'];
  /** The title font weight global style for the site */
  titleFontWeight: Scalars['String']['output'];
};

/** Google App Store connect */
export type GoogleAppStoreConnect = {
  __typename?: 'GoogleAppStoreConnect';
  /** Google API key file name */
  apiKeyFileName: Maybe<Scalars['String']['output']>;
};

/** Google App Store information */
export type GoogleAppStoreInformation = {
  __typename?: 'GoogleAppStoreInformation';
  /** Google app store category */
  appCategory: Maybe<Scalars['String']['output']>;
  /** Google app store full description */
  appFullDescription: Maybe<Scalars['String']['output']>;
  /** Google app store name */
  appName: Maybe<Scalars['String']['output']>;
  /** Google app store short description */
  appShortDescription: Maybe<Scalars['String']['output']>;
  /** Google app store tags */
  appTags: Maybe<Scalars['String']['output']>;
};

/** Tenant's groups */
export type Group = Node & {
  __typename?: 'Group';
  /** Number of admins of the group */
  adminCount: Maybe<Scalars['Int']['output']>;
  /** Group created date */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Group name */
  name: Maybe<Scalars['String']['output']>;
  /** Number of remaining users to be added to group */
  remainingUsersCount: Maybe<Scalars['Int']['output']>;
  /** Group token created */
  token: Maybe<Scalars['String']['output']>;
  /** Group updated date */
  updatedAt: Scalars['DateTime']['output'];
  /** User ids */
  userIds: Maybe<Array<Scalars['String']['output']>>;
  /** Number of users in the group */
  usersCount: Scalars['Int']['output'];
};

/** Group analysts feature */
export type GroupAnalystsFeature = FeatureInterface & {
  __typename?: 'GroupAnalystsFeature';
  /** Feature checkout path */
  checkoutPath: Maybe<Scalars['String']['output']>;
  /** Flag to indicate feature is enabled */
  isEnabled: Maybe<Scalars['Boolean']['output']>;
  /** Maximum number of group analysts */
  maxGroupAnalysts: Scalars['Int']['output'];
  /** The name of the minimum plan required to access this feature */
  minimumPlanName: Maybe<Scalars['String']['output']>;
};

/** The connection type for Group. */
export type GroupConnection = {
  __typename?: 'GroupConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<GroupEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Group>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type GroupEdge = {
  __typename?: 'GroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Group>;
};

/** Possible fields to filter groups */
export type GroupFilter = {
  /** Search by id */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Search by name */
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Input fields for a Group */
export type GroupInput = {
  /** Group's name */
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Groups feature */
export type GroupsFeature = FeatureInterface & {
  __typename?: 'GroupsFeature';
  /** Feature checkout path */
  checkoutPath: Maybe<Scalars['String']['output']>;
  /** Flag to indicate feature is enabled */
  isEnabled: Maybe<Scalars['Boolean']['output']>;
  /** Maximum number of users per group */
  maxUsersPerGroup: Scalars['Int']['output'];
  /** The name of the minimum plan required to access this feature */
  minimumPlanName: Maybe<Scalars['String']['output']>;
};

/** Autogenerated return type of HideBookmarkPreset */
export type HideBookmarkPresetPayload = {
  __typename?: 'HideBookmarkPresetPayload';
  /**
   * Response errors
   * @deprecated Backwards compatibility for old response status
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response status
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Possible identity providers for a given user */
export enum IdentityProvider {
  /** OpenId Connect */
  OpenidConnect = 'OPENID_CONNECT',
  /** Partner Portal */
  PartnerPortal = 'PARTNER_PORTAL',
  /** Single sign on */
  Sso = 'SSO',
  /** Apple */
  Apple = 'apple',
  /** Facebook */
  Facebook = 'facebook',
  /** Google */
  Google = 'google',
  /** Linked In */
  Linkedin = 'linkedin',
  /** Thinkific Accounts */
  ThinkificAccounts = 'thinkific_accounts',
}

export type IhubMutationInterface = {
  /** safelist all site's domains in active campaign */
  activeCampaignSafelistDomains: Maybe<SafelistDomains>;
  /** Save Convertkit tags */
  convertkitSaveTags: Maybe<ConvertkitSaveTagsPayload>;
  /** Update Convertkit configuration */
  convertkitUpdateConfiguration: Maybe<ConvertkitUpdateConfigurationPayload>;
  /** Create an Active Campaign */
  createActiveCampaign: Maybe<CreateActiveCampaign>;
  /** Create an event in Active Campaign */
  createActiveCampaignEvent: Maybe<CreateActiveCampaignEvent>;
  /** Save Infusionsoft tags */
  infusionsoftSaveTags: Maybe<InfusionsoftSaveTagsPayload>;
  /** Update Infusionsoft configuration */
  infusionsoftUpdateConfiguration: Maybe<
    InfusionsoftUpdateConfigurationPayload
  >;
  /** Uninstall an oauth app */
  uninstallApp: Maybe<UninstallAppResult>;
  /** Update an Active Campaign */
  updateActiveCampaign: Maybe<UpdateActiveCampaign>;
  /** Update settings for OAuthAppInstall */
  updateAppSettings: Maybe<UpdateAppSettingsPayload>;
  /** Update settings for the OpenID Connect Client App */
  updateOpenIdConnectClientSettings: Maybe<
    UpdateOpenIdConnectClientSettingsPayload
  >;
};

export type IhubMutationInterfaceConvertkitSaveTagsArgs = {
  input: ConvertkitSaveTagsInput;
};

export type IhubMutationInterfaceConvertkitUpdateConfigurationArgs = {
  input: ConvertkitUpdateConfigurationInput;
};

export type IhubMutationInterfaceCreateActiveCampaignArgs = {
  input: CreateActiveCampaignInput;
};

export type IhubMutationInterfaceCreateActiveCampaignEventArgs = {
  input: CreateEventInput;
};

export type IhubMutationInterfaceInfusionsoftSaveTagsArgs = {
  input: InfusionsoftSaveTagsInput;
};

export type IhubMutationInterfaceInfusionsoftUpdateConfigurationArgs = {
  input: InfusionsoftUpdateConfigurationInput;
};

export type IhubMutationInterfaceUninstallAppArgs = {
  input: UninstallAppInput;
};

export type IhubMutationInterfaceUpdateActiveCampaignArgs = {
  input: UpdateActiveCampaignInput;
};

export type IhubMutationInterfaceUpdateAppSettingsArgs = {
  input: UpdateAppSettingsInput;
};

export type IhubMutationInterfaceUpdateOpenIdConnectClientSettingsArgs = {
  input: UpdateOpenIdConnectClientSettingsInput;
};

/** The views of a custom site theme */
export type IhubOAuthAppView = Node & {
  __typename?: 'IhubOAuthAppView';
  /** Ihub::OAuthApp ID */
  appId: Maybe<Scalars['Int']['output']>;
  /** Name of the OAuthApp the view belongs to */
  appName: Maybe<Scalars['String']['output']>;
  /** OAuthAppView's content */
  content: Maybe<Scalars['String']['output']>;
  /** OAuthAppView's created at */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** The OAuthAppview's default settings */
  manifestDefaults: Maybe<Scalars['JSON']['output']>;
  /** OAuthAppView's manifest schema */
  manifestSchema: Maybe<Scalars['JSON']['output']>;
  /** All of the manifests related to the OAuthAppView */
  manifests: Maybe<CustomSiteThemeViewManifestConnection>;
  /** OAuthAppView's name */
  name: Maybe<Scalars['String']['output']>;
  /** OAuthAppView's title */
  title: Maybe<Scalars['String']['output']>;
  /** OAuthAppView's updated at */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  /** OAuthAppView's type */
  viewType: Maybe<Scalars['String']['output']>;
};

/** The views of a custom site theme */
export type IhubOAuthAppViewManifestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  manifest_resolver?: InputMaybe<CustomSiteThemeViewManifestResolverFilter>;
};

/** The connection type for IhubOAuthAppView. */
export type IhubOAuthAppViewConnection = {
  __typename?: 'IhubOAuthAppViewConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<IhubOAuthAppViewEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<IhubOAuthAppView>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IhubOAuthAppViewEdge = {
  __typename?: 'IhubOAuthAppViewEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<IhubOAuthAppView>;
};

export type IhubQueryInterface = {
  /** App group information */
  appGroup: Maybe<Array<AppGroup>>;
  /** OAuth app information */
  appInformation: Maybe<AppInformation>;
  /** OAuth app installs */
  apps: Maybe<AppConnection>;
  /** Convertkit queries */
  convertkit: Maybe<ConvertkitQueryInterface>;
  /** Infusionsoft queries */
  infusionsoft: Maybe<InfusionsoftQueryInterface>;
};

export type IhubQueryInterfaceAppGroupArgs = {
  groups: Array<Scalars['String']['input']>;
};

export type IhubQueryInterfaceAppInformationArgs = {
  slug: Scalars['String']['input'];
};

export type IhubQueryInterfaceAppsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AppFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Ihub tenant query interface */
export type IhubTenantQueryInterface = {
  /** OAuth App Install's active campaign */
  activeCampaign: Maybe<ActiveCampaign>;
  activeCampaignCode: Maybe<Scalars['String']['output']>;
};

/** Shared Image imput fields */
export type ImageInput = {
  /** The alt text for the space image */
  altText?: InputMaybe<Scalars['String']['input']>;
  /** The name of the bucket where the image is stored */
  bucket?: InputMaybe<Scalars['String']['input']>;
  /** The key for the image */
  key?: InputMaybe<Scalars['String']['input']>;
  /** The identifier for the image */
  uploadId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of ImportQuiz */
export type ImportQuizInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the lesson */
  lessonId: Scalars['ID']['input'];
  /** The file asset url */
  url: Scalars['String']['input'];
};

/** Autogenerated return type of ImportQuiz */
export type ImportQuizPayload = {
  __typename?: 'ImportQuizPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Unencoded created question ids */
  questionIds: Maybe<Array<Scalars['ID']['output']>>;
  /** Errors with row number indicated */
  sheetErrors: Maybe<Array<Scalars['String']['output']>>;
  userErrors: Maybe<Array<UserError>>;
};

/** Represents information about the product's in-app purchase */
export type InAppPurchaseProduct = {
  __typename?: 'InAppPurchaseProduct';
  /** The in-app purchase product identifier */
  identifier: Maybe<Scalars['ID']['output']>;
  /** The in-app purchase product identifier with base_plan_id */
  identifierWithBasePlanId: Maybe<Scalars['ID']['output']>;
};

/** Autogenerated input type of IncompleteLesson */
export type IncompleteLessonInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the lesson (AKA content) */
  lessonId: Scalars['ID']['input'];
};

/** Autogenerated return type of IncompleteLesson */
export type IncompleteLessonPayload = {
  __typename?: 'IncompleteLessonPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The lession */
  lesson: Maybe<LessonUnion>;
  userErrors: Maybe<Array<UserError>>;
};

/** App information */
export type Information = {
  __typename?: 'Information';
  /** App Store Information */
  appleAppStore: Maybe<AppleAppStoreInformation>;
  /** Google Store Information */
  googleAppStore: Maybe<GoogleAppStoreInformation>;
  /** Personal Information */
  personal: Maybe<PersonalContactInformation>;
};

/** An Infusionsoft configuration */
export type InfusionsoftConfiguration = Node & {
  __typename?: 'InfusionsoftConfiguration';
  /** The configurations's id */
  configurationId: Scalars['Int']['output'];
  /** Whether or not the configuration is connected */
  connected: Scalars['Boolean']['output'];
  /** The configurations's error notification email group */
  errorNotificationEmail: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Whether or not the send the user a welcome email */
  sendUserWelcomeEmail: Maybe<Scalars['Boolean']['output']>;
};

/** An Infusionsoft item */
export type InfusionsoftItem = Node & {
  __typename?: 'InfusionsoftItem';
  id: Scalars['ID']['output'];
  /** The item's id */
  itemId: Scalars['Int']['output'];
  /** The item's item type */
  itemType: Maybe<Scalars['String']['output']>;
  /** The item's name */
  name: Scalars['String']['output'];
  /** The item's tags */
  tags: Maybe<Array<InfusionsoftTag>>;
};

/** Infusionsoft queries */
export type InfusionsoftQueryInterface = {
  __typename?: 'InfusionsoftQueryInterface';
  /** Infusionsoft configuration */
  configuration: Maybe<InfusionsoftConfiguration>;
  /** Infusionsoft items */
  items: Array<InfusionsoftItem>;
  /** Infusionsoft tags */
  tags: Array<InfusionsoftTag>;
};

/** Infusionsoft queries */
export type InfusionsoftQueryInterfaceItemsArgs = {
  eventName: Scalars['String']['input'];
};

/** Infusionsoft queries */
export type InfusionsoftQueryInterfaceTagsArgs = {
  eventName?: InputMaybe<Scalars['String']['input']>;
  itemId?: InputMaybe<Scalars['Int']['input']>;
  itemType?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of InfusionsoftSaveTags */
export type InfusionsoftSaveTagsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The event name */
  eventName: Scalars['String']['input'];
  /** The item id */
  itemId?: InputMaybe<Scalars['Int']['input']>;
  /** The item type */
  itemType?: InputMaybe<Scalars['String']['input']>;
  /** The tag ids */
  tagIds: Array<Scalars['Int']['input']>;
};

/** Autogenerated return type of InfusionsoftSaveTags */
export type InfusionsoftSaveTagsPayload = {
  __typename?: 'InfusionsoftSaveTagsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** An Infusionsoft tag */
export type InfusionsoftTag = Node & {
  __typename?: 'InfusionsoftTag';
  id: Scalars['ID']['output'];
  /** The tag's name */
  name: Scalars['String']['output'];
  /** The tag's id */
  tagId: Scalars['Int']['output'];
};

/** Autogenerated input type of InfusionsoftUpdateConfiguration */
export type InfusionsoftUpdateConfigurationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The error notification email */
  errorNotificationEmail: Scalars['String']['input'];
  /** Whether or not to send the user welcome email */
  sendUserWelcomeEmail: Scalars['Boolean']['input'];
};

/** Autogenerated return type of InfusionsoftUpdateConfiguration */
export type InfusionsoftUpdateConfigurationPayload = {
  __typename?: 'InfusionsoftUpdateConfigurationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Initialize a provider payment method */
export type InitializePaymentMethod = ResponseInterface & {
  __typename?: 'InitializePaymentMethod';
  /**
   * Response Extra Data
   * @deprecated response fields should be well defined following a object structure
   */
  data: Maybe<Scalars['JSON']['output']>;
  errors: Maybe<Array<Maybe<Error>>>;
  /** Response Message */
  message: Maybe<Scalars['String']['output']>;
  /** Return status: SUCCESS, PARTIAL_SUCCESS or FAILED */
  status: Maybe<ResponseStatus>;
};

/** Input field for initialize a provider payment method */
export type InitializePaymentMethodInput = {
  /** Payment's associated card */
  cartToken: Scalars['String']['input'];
  /** Payment's encoded product price id */
  productPriceId: Scalars['String']['input'];
  /** Provider of the payment method */
  provider: Scalars['String']['input'];
};

/** Instance */
export type Instance = Node & {
  __typename?: 'Instance';
  /** Instance status */
  active: Maybe<Scalars['Boolean']['output']>;
  /**
   * Tenant creation date
   * @deprecated use created_at instead
   */
  created: Maybe<Scalars['DateTime']['output']>;
  /** Tenant creation date */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** Instance domain */
  domain: Maybe<Scalars['String']['output']>;
  /** Instance email */
  email: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Is instance sandbox */
  isSandbox: Maybe<Scalars['Boolean']['output']>;
  /**
   * Is instance sandbox
   * @deprecated use isSandbox instead
   */
  is_sandbox: Maybe<Scalars['Boolean']['output']>;
  /** Instance name */
  name: Maybe<Scalars['String']['output']>;
  /** OrganizationUserUser id */
  organizationUserUserId: Maybe<Scalars['Int']['output']>;
  /**
   * OrganizationUserUser id
   * @deprecated use organizationUserUserId instead
   */
  organization_user_user_id: Maybe<Scalars['Int']['output']>;
  /** User ID */
  userId: Maybe<Scalars['Int']['output']>;
  /**
   * User ID
   * @deprecated use userId instead
   */
  user_id: Maybe<Scalars['Int']['output']>;
};

/** The connection type for Instance. */
export type InstanceConnection = {
  __typename?: 'InstanceConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<InstanceEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Instance>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** Instance Connection Data */
export type InstanceConnectionData = {
  __typename?: 'InstanceConnectionData';
  /** Email address */
  email: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** User's name */
  name: Maybe<Scalars['String']['output']>;
  /** User role */
  role: Maybe<Scalars['String']['output']>;
  /** Site URL */
  site: Maybe<Scalars['String']['output']>;
};

/** An edge in a connection. */
export type InstanceEdge = {
  __typename?: 'InstanceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Instance>;
};

/** Tenant's instructors */
export type Instructor = Node & {
  __typename?: 'Instructor';
  /** Instructor's avatar url */
  avatar: Maybe<Scalars['String']['output']>;
  /** Instructor's bio */
  bio: Maybe<Scalars['String']['output']>;
  /** Instructor's created at date */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** Instructor's email */
  email: Maybe<Scalars['String']['output']>;
  /** Instructor's name */
  firstName: Maybe<Scalars['String']['output']>;
  /** Instructor's full name */
  fullName: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Instructor's id */
  instructorId: Maybe<Scalars['Int']['output']>;
  /** Instructor's last name */
  lastName: Maybe<Scalars['String']['output']>;
  /** Instructor's avatar */
  title: Maybe<Scalars['String']['output']>;
  /** Instructor's updated at date */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** Tenant's instructors */
export type InstructorAvatarArgs = {
  size?: InputMaybe<Avatar>;
};

/** The connection type for Instructor. */
export type InstructorConnection = {
  __typename?: 'InstructorConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<InstructorEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Instructor>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type InstructorEdge = {
  __typename?: 'InstructorEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Instructor>;
};

/** Possible fields to filter instructors */
export type InstructorFilter = {
  /** Search by id */
  id?: InputMaybe<Scalars['String']['input']>;
};

/** Tenant integrations */
export type Integrations = {
  __typename?: 'Integrations';
  /** Facebook pixel code */
  facebookPixelCode: Maybe<Scalars['String']['output']>;
  /** GA4 measurement ID */
  gaMeasurementId: Maybe<Scalars['String']['output']>;
  /** GTM container ID */
  googleTagManagerKey: Maybe<Scalars['String']['output']>;
  /** Mixpanel token */
  mixpanelToken: Maybe<Scalars['String']['output']>;
  /** Sumome snippet */
  sumomeCode: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of IssueRefund */
export type IssueRefundInput = {
  /** The order transaction amount to be refunded */
  amount?: InputMaybe<Scalars['Float']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The order transaction ID to be refunded */
  id: Scalars['ID']['input'];
  /** Reason for the refund */
  reason?: InputMaybe<RefundReason>;
  /** Total amount refunded so far */
  refundedAmount?: InputMaybe<Scalars['Int']['input']>;
  /** If only taxes are being refunded */
  taxOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of IssueRefund */
export type IssueRefundPayload = {
  __typename?: 'IssueRefundPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Inteface for a lab */
export type LabInterface = {
  /** Lab description */
  description: Maybe<Scalars['String']['output']>;
  /** Date that the lab expires */
  expiresAt: Maybe<Scalars['DateTime']['output']>;
  /** Lab global unique ID for Announcements */
  guid: Maybe<Scalars['String']['output']>;
  /** Date that the lab appears in the highlight modal for tenants */
  highlightedAt: Maybe<Scalars['DateTime']['output']>;
  /** Lab sale image */
  imageUrl: Maybe<Scalars['String']['output']>;
  /** Lab manifest */
  manifest: Maybe<Scalars['JSON']['output']>;
  /** Lab publish date data needed for Announcements */
  publishDate: Maybe<Scalars['String']['output']>;
  /** Lab schema */
  schema: Maybe<Scalars['JSON']['output']>;
  /** Lab slug */
  slug: Maybe<Scalars['String']['output']>;
  /** Lab title */
  title: Maybe<Scalars['String']['output']>;
};

/** Possible fields to filter lessons */
export type LessonFilter = {
  /** Search user lessons completed at or after */
  completedAfter?: InputMaybe<Scalars['Date']['input']>;
  /** Search user lessons completed at or before */
  completedBefore?: InputMaybe<Scalars['Date']['input']>;
  /** Search by course ID */
  courseId?: InputMaybe<Scalars['ID']['input']>;
  /** Search by id */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Search by lessons type */
  type?: InputMaybe<Array<LessonTypeEnum>>;
};

export enum LessonIconEnum {
  /** assignment contentable type icon */
  Assignment = 'assignment',
  /** audio contentable type icon */
  Audio = 'audio',
  /** discussions contentable type icon */
  Discussions = 'discussions',
  /** download contentable type icon */
  Download = 'download',
  /** exam contentable type icon */
  Exam = 'exam',
  /** live lesson contentable type icon */
  Live = 'live',
  /** Iframe contentable type icon */
  Multimedia = 'multimedia',
  /** pdf lesson contentable type icon */
  Pdf = 'pdf',
  /** presentation lesson contentable type icon */
  Presentation = 'presentation',
  /** quiz lesson contentable type icon */
  Quiz = 'quiz',
  /** survey lesson contentable type icon */
  Survey = 'survey',
  /** HtmlItem contentable type icon */
  Text = 'text',
  /** video lesson contentable type icon */
  Video = 'video',
}

/** Includes all the shared inputs between all lessons for the general lesson settings. */
export type LessonInput = {
  /** Is lesson set to draft */
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  /** is it a free preview lesson */
  free?: InputMaybe<Scalars['Boolean']['input']>;
  /** Lesson icon */
  lessonTypeIcon?: InputMaybe<LessonIconEnum>;
  /** Lesson type's label */
  lessonTypeLabel?: InputMaybe<Scalars['String']['input']>;
  /** The name of the lesson */
  name: Scalars['String']['input'];
  /** Options */
  options?: InputMaybe<OptionsInput>;
  /** Lesson's position */
  position?: InputMaybe<Scalars['Int']['input']>;
  /** Is this lesson a prerequisite lesson */
  prerequisite?: InputMaybe<Scalars['Boolean']['input']>;
};

/** An interface for fetching shared lesson details */
export type LessonInterface = {
  /** Content's chapter */
  chapter: Chapter;
  /** contentable id */
  contentableId: Scalars['ID']['output'];
  /** Content's course */
  course: Course;
  /** Content's created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Lesson default icon */
  defaultLessonTypeIcon: Scalars['String']['output'];
  /** Is discussion enabled */
  discussionsEnabled: Maybe<Scalars['Boolean']['output']>;
  /** Lesson draft status */
  draft: Scalars['Boolean']['output'];
  /** Lesson's associated Drip Schedule Item */
  dripScheduleItem: Maybe<DripScheduleItem>;
  /** lesson drip status */
  dripStatus: Maybe<Scalars['JSON']['output']>;
  /** Is lesson free */
  free: Maybe<Scalars['Boolean']['output']>;
  /** Lesson html text */
  htmlDescription: Maybe<Scalars['String']['output']>;
  /** Current user lesson status */
  lessonCompleted: Maybe<Scalars['Boolean']['output']>;
  /** lesson drip schedule */
  lessonHasDripSchedule: Maybe<Scalars['String']['output']>;
  /** Lesson integer ID */
  lessonId: Scalars['ID']['output'];
  /** Lesson icon */
  lessonTypeIcon: Maybe<LessonIconEnum>;
  /** Lesson label */
  lessonTypeLabel: Maybe<Scalars['String']['output']>;
  /** Lesson url */
  lessonUrl: Maybe<Scalars['String']['output']>;
  /** Lesson name */
  name: Scalars['String']['output'];
  /** Lesson position */
  position: Scalars['Int']['output'];
  /** Is lesson prerequisite */
  prerequisite: Scalars['Boolean']['output'];
  /** Lesson's slug */
  slug: Maybe<Scalars['String']['output']>;
  /** Lesson Type */
  type: Scalars['String']['output'];
  /** Whether the lesson is still under construction */
  underConstruction: Scalars['Boolean']['output'];
  /** Content's updated at date */
  updatedAt: Scalars['DateTime']['output'];
  /** User content for lesson */
  userContents: Maybe<UserContentConnection>;
  /** User quizzes for lesson */
  userQuizzes: Maybe<UserQuizConnection>;
  /** User surveys for lesson */
  userSurveys: Maybe<UserSurveyConnection>;
};

/** An interface for fetching shared lesson details */
export type LessonInterfaceUserContentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserContentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserContentOrderByFilter>;
};

/** An interface for fetching shared lesson details */
export type LessonInterfaceUserQuizzesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserQuizFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserQuizOrderBy>;
};

/** An interface for fetching shared lesson details */
export type LessonInterfaceUserSurveysArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserSurveyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserSurveyOrderBy>;
};

/** The connection type for LessonInterface. */
export type LessonInterfaceConnection = {
  __typename?: 'LessonInterfaceConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<LessonInterfaceEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<LessonInterface>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
  /** Total quiz count */
  totalQuizCount: Scalars['Int']['output'];
  /** Total survey count */
  totalSurveyCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type LessonInterfaceEdge = {
  __typename?: 'LessonInterfaceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<LessonInterface>;
};

/** Order for lessons */
export type LessonOrderBy = {
  direction: OrderDirection;
  field: OrderFieldFilter;
};

/** Possible lesson types */
export enum LessonTypeEnum {
  /** Assignment Lesson */
  Assignment = 'Assignment',
  /** Audio Lesson */
  Audio = 'Audio',
  /** BrilliumExam Lesson */
  BrilliumExam = 'BrilliumExam',
  /** Discussion Lesson */
  Disqus = 'Disqus',
  /** Download Lesson */
  Download = 'Download',
  /** Text Lesson */
  HtmlItem = 'HtmlItem',
  /** Multimedia Lesson */
  Iframe = 'Iframe',
  /** Video Lesson */
  Lesson = 'Lesson',
  /** LiveLesson Lesson */
  LiveLesson = 'LiveLesson',
  /** PDF Lesson */
  Pdf = 'Pdf',
  /** Presentation Lesson */
  Presentation = 'Presentation',
  /** Quiz Lesson */
  Quiz = 'Quiz',
  /** Survey Lesson */
  Survey = 'Survey',
}

/** A lesson */
export type LessonUnion =
  | AssignmentLesson
  | AudioLesson
  | DisqusLesson
  | DownloadLesson
  | ExamLesson
  | LiveLesson
  | MultimediaLesson
  | PdfLesson
  | PresentationLesson
  | QuizLesson
  | SurveyLesson
  | TextLesson
  | VideoLesson;

/** Input for Assignment specific Lesson */
export type LessonWithAssignmentInput = {
  /** Submission file size limit in MB */
  fileSizeLimit?: InputMaybe<Scalars['Int']['input']>;
  /** Assignment Description */
  htmlDescription: Scalars['String']['input'];
  /** The assignment id */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Message to confirm submission successfully uploaded */
  submissionMessage?: InputMaybe<Scalars['String']['input']>;
};

/** Input for Audio specific Lesson */
export type LessonWithAudioInput = {
  /** Audio lesson autoplay */
  autoplay: Scalars['Boolean']['input'];
  /** Is Audio lesson downloadable  */
  downloadable: Scalars['Boolean']['input'];
  /** Html description  */
  htmlDescription?: InputMaybe<Scalars['String']['input']>;
  /** Url of Audio lesson uploaded */
  url: Scalars['String']['input'];
};

/** Input for Downloads attached to a Lesson */
export type LessonWithDownloadInput = {
  /** Whether to destroy the question */
  destroy?: InputMaybe<Scalars['Boolean']['input']>;
  /** Name of file upload */
  fileName?: InputMaybe<Scalars['String']['input']>;
  /** The download id */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Label to display for file */
  label?: InputMaybe<Scalars['String']['input']>;
  /** The order of the download relative to other downloads */
  position?: InputMaybe<Scalars['Int']['input']>;
  /** The file size in bytes */
  rawSize?: InputMaybe<Scalars['Int']['input']>;
  /** Url of video lesson uploaded */
  url?: InputMaybe<Scalars['String']['input']>;
};

/** Input for Exam Lessons */
export type LessonWithExamInput = {
  /** HTML to display before the exam is taken */
  beforeTakeHtml?: InputMaybe<Scalars['String']['input']>;
  /** The Brillium root URL */
  brilliumRootUrl?: InputMaybe<Scalars['String']['input']>;
  /** The text for the CTA to claim the certificate */
  claimCertificateCtaText?: InputMaybe<Scalars['String']['input']>;
  /** The entry hash password */
  entryHashPassword?: InputMaybe<Scalars['String']['input']>;
  /** The exam ID */
  examId?: InputMaybe<Scalars['String']['input']>;
  /** The exam password */
  examPassword?: InputMaybe<Scalars['String']['input']>;
  /** HTML to display after the exam is failed */
  failedHtml?: InputMaybe<Scalars['String']['input']>;
  /** HTML to display after the exam is passed */
  passedHtml?: InputMaybe<Scalars['String']['input']>;
  /** The passing grade for the exam */
  passingGrade?: InputMaybe<Scalars['Int']['input']>;
  /** Whether the retake is always on */
  retakeAlwaysOn?: InputMaybe<Scalars['Boolean']['input']>;
  /** The text for the CTA to retake the exam */
  retakeExamCtaText?: InputMaybe<Scalars['String']['input']>;
  /** The text for the CTA to take the exam */
  takeExamCtaText?: InputMaybe<Scalars['String']['input']>;
};

/** Input for Live specific Lesson */
export type LessonWithLiveInput = {
  /** Instructor name */
  instructorName?: InputMaybe<Scalars['String']['input']>;
  /** Provider source of live lesson */
  providerSource: WebinarProviderEnum;
};

/** Input for multimedia lessons */
export type LessonWithMultimediaInput = {
  /** Whether dynamic variables are enabled */
  dynamicVariablesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** The file to output for uploaded types */
  outputFile?: InputMaybe<Scalars['String']['input']>;
  /** The source type */
  sourceType?: InputMaybe<MultimediaSourceTypeEnum>;
  /** The external or upload url */
  url?: InputMaybe<Scalars['String']['input']>;
};

/** Input for Pdf specific Lesson */
export type LessonWithPdfInput = {
  /** Is pdf lesson downloadable  */
  downloadable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Url of pdf lesson uploaded */
  url?: InputMaybe<Scalars['String']['input']>;
};

/** Input for Presentation specific Lesson */
export type LessonWithPresentationInput = {
  /** Student required to complete lessons */
  completionRequired?: InputMaybe<Scalars['Boolean']['input']>;
  /** Is Audio lesson downloadable  */
  downloadable?: InputMaybe<Scalars['Boolean']['input']>;
  /** The source file content type */
  sourceFileContentType?: InputMaybe<Scalars['String']['input']>;
  /** The source file name */
  sourceFileName?: InputMaybe<Scalars['String']['input']>;
  /** The source file size */
  sourceFileSize?: InputMaybe<Scalars['Int']['input']>;
  /** The source file url */
  sourceFileUrl?: InputMaybe<Scalars['String']['input']>;
};

/** Input for Quiz specific Lesson */
export type LessonWithQuizInput = {
  /** The number of question bank questions selected */
  numberOfSelectedQuizQuestions?: InputMaybe<Scalars['Int']['input']>;
  /** Score required to pass the quiz */
  passingScore?: InputMaybe<Scalars['Int']['input']>;
  /** The quiz type */
  quizType?: InputMaybe<QuizTypeEnum>;
  /** Show the question explanation to students? */
  showExplanation?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Input to create drip schedule items */
export type LessonWithScheduleInput = {
  /** Days until lesson release */
  daysUntilRelease: Scalars['Int']['input'];
  /** The encoded lesson ID (decoded as Content-1234) */
  lessonId: Scalars['ID']['input'];
};

/**
 * Includes all the shared inputs between all lessons that have a HTML text content
 * and the various settings that go along with that text content
 */
export type LessonWithTextInput = {
  /** Html description  */
  htmlDescription: Scalars['String']['input'];
};

/** Input for Video specific Lesson */
export type LessonWithVideoInput = {
  /** Start video automatically (deprecated) */
  autoplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** Is video lesson downloadable  */
  downloadable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filename */
  fileName?: InputMaybe<Scalars['String']['input']>;
  /** Html description  */
  htmlDescription?: InputMaybe<Scalars['String']['input']>;
  /** The file size in bytes */
  rawSize?: InputMaybe<Scalars['Int']['input']>;
  /** Url of video lesson uploaded */
  url?: InputMaybe<Scalars['String']['input']>;
  /** The ID of a existing video */
  videoId?: InputMaybe<Scalars['ID']['input']>;
};

/** Possible fields to filter Lessons */
export type LessonsFilter = {
  /** Search lessons with draft status */
  byDraft?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Line item of a cart */
export type LineItem = Node & {
  __typename?: 'LineItem';
  /** Line item created at */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** Custom first payment period associated to this item */
  customFirstPrice: Maybe<Scalars['Float']['output']>;
  /** Days until line item expires */
  daysUntilExpiry: Maybe<Scalars['Int']['output']>;
  /** Description of line item */
  description: Maybe<Scalars['String']['output']>;
  /** Enrollment type associated to this item */
  enrollmentType: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Interval associated to this item */
  interval: Maybe<Scalars['String']['output']>;
  /** Interval count associated to this item */
  intervalCount: Maybe<Scalars['Int']['output']>;
  /** LineItem Id */
  lineItemId: Maybe<Scalars['Int']['output']>;
  /** Number of payments associated to this item */
  numberOfPayments: Maybe<Scalars['Int']['output']>;
  /** Order Bump Id associated to this item */
  orderBumpId: Maybe<Scalars['ID']['output']>;
  /** Payment type */
  paymentType: Maybe<Scalars['String']['output']>;
  /** Price */
  price: Maybe<Scalars['Float']['output']>;
  /** Line item product */
  product: Maybe<Product>;
  /** ProductPrice Id */
  productPriceId: Maybe<Scalars['ID']['output']>;
  /** Line item quantity */
  quantity: Scalars['Int']['output'];
  /** Used for subscription price, values are day and month */
  trialInterval: Maybe<Scalars['String']['output']>;
  /** Number of days/months of the trial */
  trialIntervalCount: Maybe<Scalars['Int']['output']>;
  /** Line item updated at */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** The connection type for LineItem. */
export type LineItemConnection = {
  __typename?: 'LineItemConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<LineItemEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<LineItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type LineItemEdge = {
  __typename?: 'LineItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<LineItem>;
};

/** Live event information */
export type LiveEvent = Node & {
  __typename?: 'LiveEvent';
  /** The description of the event */
  description: Maybe<Scalars['String']['output']>;
  /** The end time of the event (UTC) */
  endTime: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the event source */
  eventSourceId: Scalars['String']['output'];
  /** The type of the event source */
  eventSourceType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** The event image alt text */
  imageAltText: Maybe<Scalars['String']['output']>;
  /** The event image url */
  imageUrl: Maybe<Scalars['String']['output']>;
  /** List of users planning on attending the event */
  interestedUsers: Maybe<UserConnection>;
  /** Whether the user is planning to attend the event */
  isInterested: Maybe<Scalars['Boolean']['output']>;
  /** The location of the event */
  location: Maybe<Scalars['String']['output']>;
  /** The meetingUrl of the event */
  meetingUrl: Maybe<Scalars['URL']['output']>;
  /** The start time of the event (UTC) */
  startTime: Maybe<Scalars['DateTime']['output']>;
  /** The timezone of the event */
  timezone: Maybe<Scalars['String']['output']>;
  /** The title of the event */
  title: Maybe<Scalars['String']['output']>;
};

/** Live event information */
export type LiveEventInterestedUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for LiveEvent. */
export type LiveEventConnection = {
  __typename?: 'LiveEventConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<LiveEventEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<LiveEvent>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type LiveEventEdge = {
  __typename?: 'LiveEventEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<LiveEvent>;
};

/** Live lesson information */
export type LiveLesson = LessonInterface &
  Node & {
    __typename?: 'LiveLesson';
    /** Bulk live lesson associated with the live lesson */
    bulkLiveLesson: Maybe<BulkLiveLesson>;
    /** Content's chapter */
    chapter: Chapter;
    /** contentable id */
    contentableId: Scalars['ID']['output'];
    /** Content's course */
    course: Course;
    /** Content's created at date */
    createdAt: Scalars['DateTime']['output'];
    /** Lesson default icon */
    defaultLessonTypeIcon: Scalars['String']['output'];
    /** Is discussion enabled */
    discussionsEnabled: Maybe<Scalars['Boolean']['output']>;
    /** Lesson draft status */
    draft: Scalars['Boolean']['output'];
    /** Lesson's associated Drip Schedule Item */
    dripScheduleItem: Maybe<DripScheduleItem>;
    /** lesson drip status */
    dripStatus: Maybe<Scalars['JSON']['output']>;
    /** Is lesson free */
    free: Maybe<Scalars['Boolean']['output']>;
    /** Lesson html text */
    htmlDescription: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    /** Instructor name */
    instructorName: Maybe<Scalars['String']['output']>;
    /** Current user lesson status */
    lessonCompleted: Maybe<Scalars['Boolean']['output']>;
    /** lesson drip schedule */
    lessonHasDripSchedule: Maybe<Scalars['String']['output']>;
    /** Lesson integer ID */
    lessonId: Scalars['ID']['output'];
    /** Lesson icon */
    lessonTypeIcon: Maybe<LessonIconEnum>;
    /** Lesson label */
    lessonTypeLabel: Maybe<Scalars['String']['output']>;
    /** Lesson url */
    lessonUrl: Maybe<Scalars['String']['output']>;
    /** Lesson name */
    name: Scalars['String']['output'];
    /** Lesson position */
    position: Scalars['Int']['output'];
    /** Is lesson prerequisite */
    prerequisite: Scalars['Boolean']['output'];
    /** Provider source */
    providerSource: Maybe<Scalars['String']['output']>;
    /** Lesson's slug */
    slug: Maybe<Scalars['String']['output']>;
    /** Lesson Type */
    type: Scalars['String']['output'];
    /** Whether the lesson is still under construction */
    underConstruction: Scalars['Boolean']['output'];
    /** Content's updated at date */
    updatedAt: Scalars['DateTime']['output'];
    /** User content for lesson */
    userContents: Maybe<UserContentConnection>;
    /** User quizzes for lesson */
    userQuizzes: Maybe<UserQuizConnection>;
    /** User surveys for lesson */
    userSurveys: Maybe<UserSurveyConnection>;
    /** Webinar item id */
    webinarItemId: Maybe<Scalars['Int']['output']>;
    /** webinar item type */
    webinarItemType: Maybe<Scalars['String']['output']>;
    /** Zoom Item */
    zoomItem: Maybe<ZoomItem>;
  };

/** Live lesson information */
export type LiveLessonUserContentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserContentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserContentOrderByFilter>;
};

/** Live lesson information */
export type LiveLessonUserQuizzesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserQuizFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserQuizOrderBy>;
};

/** Live lesson information */
export type LiveLessonUserSurveysArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserSurveyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserSurveyOrderBy>;
};

/** Logs in a user for a given tenant */
export type LogInUser = ResponseInterface & {
  __typename?: 'LogInUser';
  /**
   * Response Extra Data
   * @deprecated response fields should be well defined following a object structure
   */
  data: Maybe<Scalars['JSON']['output']>;
  errors: Maybe<Array<Maybe<Error>>>;
  /** Response Message */
  message: Maybe<Scalars['String']['output']>;
  /** Return status: SUCCESS, PARTIAL_SUCCESS or FAILED */
  status: Maybe<ResponseStatus>;
};

/** Input field for LogInUser mutation */
export type LogInUserInput = {
  /** The email of the user */
  email: Scalars['String']['input'];
  /** The password of the user */
  password: Scalars['String']['input'];
};

/** Possible sizes to retrieve a logo */
export enum Logo {
  /** Large (500x500 px) */
  Large = 'large',
  /** Medium (370x370 px) */
  Medium = 'medium',
  /** Small (325x125 px) */
  Small = 'small',
}

/** A tenant's looker dashboard */
export type LookerDashboard = {
  __typename?: 'LookerDashboard';
  /** The dashboard's embed url */
  embedUrl: Maybe<Scalars['String']['output']>;
};

/** Possible dashboards for Looker */
export enum LookerDashboardNameEnum {
  /** 'Communities' dashboard */
  Communities = 'COMMUNITIES',
  /** 'Course engagement' dashboard */
  CourseEngagement = 'COURSE_ENGAGEMENT',
  /** 'Enrollments' dashboard */
  Enrollments = 'ENROLLMENTS',
  /** 'Orders' dashboard */
  Orders = 'ORDERS',
  /** 'Quiz' dashboard */
  Quiz = 'QUIZ',
  /** 'Revenue' dashboard */
  Revenue = 'REVENUE',
  /** 'Student engagement' dashboard */
  StudentEngagement = 'STUDENT_ENGAGEMENT',
  /** 'Student engagement overview' dashboard */
  StudentEngagementOverview = 'STUDENT_ENGAGEMENT_OVERVIEW',
  /** 'Student performance' dashboard */
  StudentPerformance = 'STUDENT_PERFORMANCE',
  /** 'Subscriptions' dashboard */
  Subscriptions = 'SUBSCRIPTIONS',
}

export type ManifestInterface = {
  /** Manifest's content */
  content: Maybe<Scalars['JSON']['output']>;
  /** Manifest's created at */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** Manifest's edited at */
  editedAt: Maybe<Scalars['DateTime']['output']>;
  /** Manifest's updated at */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** Funnel metadata */
export type Metadata = {
  __typename?: 'Metadata';
  /** Funnel flow metadata */
  flow: FlowMetadata;
  /** Show offer outside funnel */
  showOfferOutsideFunnel: Maybe<Scalars['Boolean']['output']>;
};

/** MFA settings for a user */
export type MfaSettings = {
  __typename?: 'MfaSettings';
  /** Returns true if email has been successfully verified as part of MFA set up */
  isEmailVerified: Maybe<Scalars['Boolean']['output']>;
  /** Second factor is required for login */
  otpRequiredForLogin: Maybe<Scalars['Boolean']['output']>;
  /** OTP secret for authenticator app */
  otpSecret: Maybe<Scalars['String']['output']>;
  /** URI for generating QR code for authenticator app */
  qrCodeUri: Maybe<Scalars['String']['output']>;
};

/** Tenant mobile information */
export type Mobile = {
  __typename?: 'Mobile';
  /** Tenant branded app configuration */
  appConfiguration: Maybe<AppConfiguration>;
  /** Tenant branded App customization */
  appCustomization: Maybe<AppCustomization>;
  /** Tenant mobile features */
  features: Maybe<MobileFeatures>;
  /** Tenant mobile setting */
  setting: Maybe<MobileSetting>;
};

/** Mobile app features */
export type MobileFeatures = {
  __typename?: 'MobileFeatures';
  /** In-app purchase enabled flag */
  inAppPurchaseEnabled: Maybe<Scalars['Boolean']['output']>;
};

/** Mobile app settings data */
export type MobileSetting = {
  __typename?: 'MobileSetting';
  /** Branded mobile enabled flag */
  brandedMobileEnabled: Maybe<Scalars['Boolean']['output']>;
  /** Free courses visible flag */
  canDiscoverFreeCourses: Maybe<Scalars['Boolean']['output']>;
  /** Requested in-app purchase setup flag */
  inAppPurchaseSetupRequested: Maybe<Scalars['Boolean']['output']>;
  /** Joined mobile waitlist flag */
  joinedBrandedMobileWaitlist: Maybe<Scalars['Boolean']['output']>;
  /** Timestamp for the first time mobile was activated */
  mobileFirstActivatedAt: Maybe<Scalars['String']['output']>;
  /** Thinkific mobile enabled flag */
  thinkificMobileEnabled: Maybe<Scalars['Boolean']['output']>;
};

/** Mobile app settings data */
export type MobileSettings = {
  __typename?: 'MobileSettings';
  /** Firebase link for opening tenant school in mobile app */
  firebaseShortLink: Scalars['String']['output'];
};

/** Shows an amount and a currency */
export type Money = {
  __typename?: 'Money';
  amount: Maybe<Scalars['Float']['output']>;
  currency: Maybe<Scalars['String']['output']>;
};

/** Money comparison options to filter search */
export type MoneyComparison = {
  /** Value is equal to */
  equals?: InputMaybe<Scalars['Float']['input']>;
  /** Value is greater than or equals to */
  greaterThanEquals?: InputMaybe<Scalars['Float']['input']>;
  /** Value is less than or equals to */
  lessThanEquals?: InputMaybe<Scalars['Float']['input']>;
};

/** Day of the week for the monthly recurring meeting */
export enum MonthlyWeekDayEnum {
  Friday = 'Friday',
  Monday = 'Monday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
  Thursday = 'Thursday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
}

/** Week of the month for a recurring meeting */
export enum MonthlyWeekEnum {
  /** First week of the month */
  First = 'First',
  /** Fourth week of the month */
  Fourth = 'Fourth',
  /** Last week of the month */
  Last = 'Last',
  /** Second week of the month */
  Second = 'Second',
  /** Third week of the month */
  Third = 'Third',
}

/** multimedia lesson information */
export type MultimediaLesson = LessonInterface &
  Node & {
    __typename?: 'MultimediaLesson';
    /** Content's chapter */
    chapter: Chapter;
    /** contentable id */
    contentableId: Scalars['ID']['output'];
    /** Content's course */
    course: Course;
    /** Content's created at date */
    createdAt: Scalars['DateTime']['output'];
    /** Lesson default icon */
    defaultLessonTypeIcon: Scalars['String']['output'];
    /** Is discussion enabled */
    discussionsEnabled: Maybe<Scalars['Boolean']['output']>;
    /** Lesson draft status */
    draft: Scalars['Boolean']['output'];
    /** Lesson's associated Drip Schedule Item */
    dripScheduleItem: Maybe<DripScheduleItem>;
    /** lesson drip status */
    dripStatus: Maybe<Scalars['JSON']['output']>;
    /** Is lesson free */
    free: Maybe<Scalars['Boolean']['output']>;
    /** Multimedia's html description */
    htmlDescription: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    /** ID of the job processing this lesson files */
    jobId: Maybe<Scalars['String']['output']>;
    /** Current user lesson status */
    lessonCompleted: Maybe<Scalars['Boolean']['output']>;
    /** lesson drip schedule */
    lessonHasDripSchedule: Maybe<Scalars['String']['output']>;
    /** Lesson integer ID */
    lessonId: Scalars['ID']['output'];
    /** Lesson icon */
    lessonTypeIcon: Maybe<LessonIconEnum>;
    /** Lesson label */
    lessonTypeLabel: Maybe<Scalars['String']['output']>;
    /** Lesson url */
    lessonUrl: Maybe<Scalars['String']['output']>;
    /** Lesson name */
    name: Scalars['String']['output'];
    /** Multimedia's output url */
    outputUrl: Maybe<Scalars['String']['output']>;
    /** Lesson position */
    position: Scalars['Int']['output'];
    /** Is lesson prerequisite */
    prerequisite: Scalars['Boolean']['output'];
    /** Multimedia's source url with raw dynamic variables */
    rawSourceUrl: Maybe<Scalars['String']['output']>;
    /** Lesson's slug */
    slug: Maybe<Scalars['String']['output']>;
    /** Multimedia's source type */
    sourceType: Maybe<MultimediaSourceTypeEnum>;
    /** Multimedia's source url */
    sourceUrl: Maybe<Scalars['String']['output']>;
    /** Lesson Type */
    type: Scalars['String']['output'];
    /** Whether the lesson is still under construction */
    underConstruction: Scalars['Boolean']['output'];
    /** Content's updated at date */
    updatedAt: Scalars['DateTime']['output'];
    /** Multimedia uploaded files information */
    uploadedFiles: Maybe<MultimediaUploadedFiles>;
    /** User content for lesson */
    userContents: Maybe<UserContentConnection>;
    /** User quizzes for lesson */
    userQuizzes: Maybe<UserQuizConnection>;
    /** User surveys for lesson */
    userSurveys: Maybe<UserSurveyConnection>;
  };

/** multimedia lesson information */
export type MultimediaLessonUserContentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserContentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserContentOrderByFilter>;
};

/** multimedia lesson information */
export type MultimediaLessonUserQuizzesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserQuizFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserQuizOrderBy>;
};

/** multimedia lesson information */
export type MultimediaLessonUserSurveysArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserSurveyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserSurveyOrderBy>;
};

/** The source type */
export enum MultimediaSourceTypeEnum {
  /** External source type */
  External = 'external',
  /** Uploaded source type */
  Uploaded = 'uploaded',
}

/** Multimedia uploaded files information */
export type MultimediaUploadedFiles = Node & {
  __typename?: 'MultimediaUploadedFiles';
  /** Array of available files */
  files: Maybe<Array<Scalars['String']['output']>>;
  /** ID of the object. */
  id: Scalars['ID']['output'];
  /** Location of uploaded file */
  location: Maybe<Scalars['String']['output']>;
  /** Name of uploaded file */
  name: Maybe<Scalars['String']['output']>;
  /** The outputted file */
  output: Maybe<Scalars['String']['output']>;
};

/** Mutation root of Thinkific's schema */
export type Mutation = ChangelogMutationInterface &
  CommunitiesMutationInterface &
  EventsMutationInterface &
  FeaturesMutationInterface &
  IhubMutationInterface &
  StudentCertificatesMutationInterface &
  ThinkificPaymentsTenantMutationInterface & {
    __typename?: 'Mutation';
    /** Validates one time password from authenticator app to activate MFA. Returns one-time generated backup codes if successful. */
    activateMfa: Maybe<ActivateMfaPayload>;
    /** Activates product licenses for a target user */
    activateProductLicenses: Maybe<ActivateProductLicensesPayload>;
    /** Activate a theme installation */
    activateThemeInstallation: Maybe<ActivateThemeInstallationPayload>;
    /** safelist all site's domains in active campaign */
    activeCampaignSafelistDomains: Maybe<SafelistDomains>;
    /** Add a course admin for a course */
    addCourseAdmin: Maybe<AddCourseAdminPayload>;
    /** Create an item within a bundle */
    addItemToBundle: Maybe<AddItemToBundlePayload>;
    /** Add a line item to a cart */
    addLineItem: Maybe<AddLineItemResult>;
    /** Add multi instance user */
    addMultiInstance: Maybe<AddMultiInstancePayload>;
    /** Add affiliate(s) to a product */
    addProductAffiliates: Maybe<AddProductAffiliatesPayload>;
    /** Add users to groups */
    addUsersToGroups: Maybe<AddUsersToGroupsPayload>;
    /** Apply a coupon to a cart */
    applyCoupon: Maybe<ApplyCouponResult>;
    /** Apply settings to all course lessons */
    applySettingsToAll: Maybe<ApplySettingsToAllPayload>;
    /** Apply a theme preset to be active */
    applyThemePreset: Maybe<ApplyThemePresetPayload>;
    /** Approve Multi Instance Request */
    approveMultiInstanceRequest: Maybe<ApproveMultiInstanceRequestPayload>;
    /** Archive a Video */
    archiveVideo: Maybe<ArchiveVideo>;
    /** Build tenant's branded app */
    buildBrandedApp: Maybe<BuildTenantBrandedAppPayload>;
    /** Update positions for multiple models within courses */
    bulkUpdatePositions: Maybe<BulkUpdatePositionsPayload>;
    /** Updates tax codes for given product */
    bulkUpdateProducts: Maybe<BulkUpdateProductsPayload>;
    /** calculate tax on cart */
    calculateTax: Maybe<CalculateTaxResult>;
    /** Cancel an existing site subscription */
    cancelSiteSubscription: Maybe<CancelSiteSubscriptionPayload>;
    /** Cancels ongoing subscription by given id */
    cancelSubscription: Maybe<CancelSubscriptionPayload>;
    /**
     * Check All Users In Groups
     * @deprecated deprecated mutation: do not use it
     */
    checkAllUsersInGroups: Maybe<CheckAllUsersInGroupsPayload>;
    /** Create tenant account if necessary, and apply promotion */
    claimPromotionalDeal: Maybe<ClaimPromotionalDealPayload>;
    /** Complete a paid checkout */
    completeCheckout: Maybe<CompleteCheckoutResult>;
    /** Complete a free checkout */
    completeCheckoutWithoutPayment: Maybe<CompleteCheckoutWithoutPaymentResult>;
    /** Mark a dashboard prompt as completed */
    completeDashboardPrompt: Maybe<CompleteDashboardPromptPayload>;
    /** Mark a dashboard prompt based on input prompt type as completed */
    completeDashboardPromptByPromptType: Maybe<
      CompleteDashboardPromptByPromptPayload
    >;
    /** Update a user content as complete */
    completeLesson: Maybe<CompleteLessonPayload>;
    /** Complete a Tenant Checklist Item for a tenant */
    completeTenantChecklistItem: Maybe<CompleteTenantChecklistItemPayload>;
    /** Triggered after 3D Secure payment is confirmed (Strong Customer Authentication is successful) */
    confirmPurchase: Maybe<ConfirmPurchasePayload>;
    /** Save Convertkit tags */
    convertkitSaveTags: Maybe<ConvertkitSaveTagsPayload>;
    /** Update Convertkit configuration */
    convertkitUpdateConfiguration: Maybe<ConvertkitUpdateConfigurationPayload>;
    /** Duplicate a Course */
    copyCourse: Maybe<CopyCoursePayload>;
    /** Copy a course lesson */
    copyLesson: Maybe<CopyLessonPayload>;
    /** Create an Active Campaign */
    createActiveCampaign: Maybe<CreateActiveCampaign>;
    /** Create an event in Active Campaign */
    createActiveCampaignEvent: Maybe<CreateActiveCampaignEvent>;
    /** Create a new additional price */
    createAdditionalProductPrice: Maybe<CreateAdditionalProductPricePayload>;
    /** Create a new after purchase flow */
    createAfterPurchaseFlow: Maybe<CreateAfterPurchaseFlowPayload>;
    /** Create a new after purchase flow product */
    createAfterPurchaseFlowProduct: Maybe<
      CreateAfterPurchaseFlowProductPayload
    >;
    /** Create a Prompt to query the AI service for an AI powered Sales Funnel */
    createAiFunnelPrompt: Maybe<CreateAiFunnelPromptPayload>;
    /** Create an assignment lesson */
    createAssignmentLesson: Maybe<CreateAssignmentLessonPayload>;
    /** Create Attendance for a Live Event for the current User */
    createAttendance: Maybe<CreateAttendancePayload>;
    /** Create a audio lesson */
    createAudioLesson: Maybe<CreateAudioLessonPayload>;
    /** Create a Bulk Enrollment */
    createBulkEnrollment: Maybe<CreateBulkEnrollmentPayload>;
    /** Create a bundle */
    createBundle: Maybe<CreateBundlePayload>;
    /** Create a chapter within a course */
    createChapter: Maybe<CreateChapterPayload>;
    /** Create a Community */
    createCommunity: Maybe<CreateCommunityPayload>;
    /** Create a Community Space */
    createCommunitySpace: Maybe<CreateCommunitySpacePayload>;
    /** Create a Course */
    createCourse: Maybe<CreateCoursePayload>;
    /** Create a new custom page */
    createCustomPage: Maybe<CreateCustomPagePayload>;
    /** Create a download lesson */
    createDownloadLesson: Maybe<CreateDownloadLessonPayload>;
    /** Create a new drip schedule */
    createDripSchedule: Maybe<CreateDripSchedulePayload>;
    /** Create drip schedule items */
    createDripScheduleItems: Maybe<CreateDripScheduleItemsPayload>;
    /** Create an Exam lesson */
    createExamLesson: Maybe<CreateExamLessonPayload>;
    /** Enrolls signed-in user into Free Trial enrollment */
    createFreeTrialEnrollment: Maybe<CreateFreeTrialEnrollment>;
    /** Create a Sales Funnel */
    createFunnel: Maybe<CreateFunnelPayload>;
    /** Create a new group */
    createGroup: Maybe<CreateGroupPayload>;
    /** Creates an in-app purchase product */
    createInAppPurchaseProduct: Maybe<CreateInAppPurchaseProductPayload>;
    /** Create a lesson given an uploaded file */
    createLessonFromUpload: Maybe<CreateLessonFromUploadPayload>;
    /** Creates a theme installation */
    createLibraryThemeInstallation: Maybe<
      CreateLibraryThemeInstallationPayload
    >;
    /** Create a Live Event */
    createLiveEvent: Maybe<CreateLiveEventPayload>;
    /** Create a live lesson */
    createLiveLesson: Maybe<CreateLiveLessonPayload>;
    /** Create a multimedia lesson */
    createMultimediaLesson: Maybe<CreateMultimediaLessonPayload>;
    /** Create a new tenant from the Plus Dashboard */
    createNewPlusTenant: Maybe<CreateNewPlusTenantPayload>;
    /** Create or update tenant lab's attributes */
    createOrUpdateTenantLab: Maybe<CreateOrUpdateTenantLabPayload>;
    /** Creates an order bump */
    createOrderBump: Maybe<CreateOrderBumpPayload>;
    /** Create a pdf lesson */
    createPdfLesson: Maybe<CreatePdfLessonPayload>;
    /** Create a post */
    createPost: Maybe<CreatePostPayload>;
    /** Create an presentation lesson */
    createPresentationLesson: Maybe<CreatePresentationLessonPayload>;
    /** create Product Payee for a course */
    createProductPayee: Maybe<CreateProductPayeePayload>;
    /** Create a new promotion */
    createPromotion: Maybe<CreatePromotionPayload>;
    /** Create a quiz lesson */
    createQuizLesson: Maybe<CreateQuizLessonPayload>;
    /** Create a reply */
    createReply: Maybe<CreateReplyPayload>;
    /** Create a new search bookmark */
    createSearchBookmark: Maybe<CreateSearchBookmarkPayload>;
    /** Create a site page */
    createSitePage: Maybe<CreateSitePagePayload>;
    /** Create a Blueprint for a tenant */
    createStudentCertificateBlueprint: Maybe<CreateBlueprintPayload>;
    /** Create a certificate mapping for a product */
    createStudentCertificatesCertificateMapping: Maybe<
      CreateCertificateMappingPayload
    >;
    /** Create Certificate Mappings in bulk for a tenant */
    createStudentCertificatesCertificateMappings: Maybe<
      CreateCertificateMappingsPayload
    >;
    /** Create a survey lesson */
    createSurveyLesson: Maybe<CreateSurveyLessonPayload>;
    /** Create a Tenant Checklist for a tenant */
    createTenantChecklist: Maybe<CreateTenantChecklistPayload>;
    /** Create a text lesson */
    createTextLesson: Maybe<CreateTextLessonPayload>;
    /** Create a Student Import */
    createUserImport: Maybe<CreateUserImportPayload>;
    /** Create a Video */
    createVideo: Maybe<CreateVideo>;
    /** Create a video lesson */
    createVideoLesson: Maybe<CreateVideoLessonPayload>;
    /** Creates a webhook */
    createWebhook: Maybe<CreateWebhook>;
    /** Deactivates MFA if the user has MFA enabled */
    deactivateMfa: Maybe<DeactivateMfaPayload>;
    /** Deactivates MFA for a given user */
    deactivateMfaForUser: Maybe<DeactivateMfaForUserPayload>;
    /** Delete an additional price */
    deleteAdditionalProductPrice: Maybe<DeleteAdditionalProductPricePayload>;
    /** Delete an after purchase flow */
    deleteAfterPurchaseFlow: Maybe<DeleteAfterPurchaseFlowPayload>;
    /** Delete an after purchase flow product */
    deleteAfterPurchaseFlowProduct: Maybe<
      DeleteAfterPurchaseFlowProductPayload
    >;
    /** Delete Attendance for a Live Event for the current User */
    deleteAttendance: Maybe<DeleteAttendancePayload>;
    /** Delete bulk live lesson */
    deleteBulkLiveLesson: Maybe<DeleteBulkLiveLessonPayload>;
    /** Deleting an existing changelog entry */
    deleteChangelogEntry: Maybe<DeleteEntryResult>;
    /** Delete a Space */
    deleteCommunitySpace: Maybe<DeleteCommunitySpacePayload>;
    /** Delete a course */
    deleteCourse: Maybe<DeleteCoursePayload>;
    /** Delete a custom page */
    deleteCustomPage: Maybe<DeleteCustomPage>;
    /** Delete a drip schedule */
    deleteDripSchedule: Maybe<DeleteDripSchedulePayload>;
    /** Delete a Funnel */
    deleteFunnel: Maybe<DeleteFunnelPayload>;
    /** Delete a Group */
    deleteGroup: Maybe<DeleteGroupPayload>;
    /** delete a user's lesson */
    deleteLesson: Maybe<DeleteLessonPayload>;
    /** Delete a Live Event */
    deleteLiveEvent: Maybe<DeleteLiveEventPayload>;
    /** Deletes an order bump */
    deleteOrderBump: Maybe<DeleteOrderBumpPayload>;
    /** Delete a Post */
    deletePost: Maybe<DeletePostPayload>;
    /** delete Product Payee for a course */
    deleteProductPayee: Maybe<DeleteProductPayeePayload>;
    /** Delete a reply */
    deleteReply: Maybe<DeleteReplyPayload>;
    /** Delete a search bookmark */
    deleteSearchBookmark: Maybe<DeleteSearchBookmarkPayload>;
    /** Delete a Blueprint for a tenant */
    deleteStudentCertificateBlueprint: Maybe<DeleteBlueprintPayload>;
    /** Delete a certificate mapping for a product */
    deleteStudentCertificatesCertificateMapping: Maybe<
      DeleteCertificateMappingPayload
    >;
    /** Delete a theme installation */
    deleteThemeInstallation: Maybe<DeleteThemeInstallationPayload>;
    /** Delete users */
    deleteUsers: Maybe<DeleteUsers>;
    /** Delete a Video */
    deleteVideo: Maybe<DeleteVideo>;
    /** DeleteWebhook mutation's input */
    deleteWebhook: Maybe<DeleteWebhook>;
    /** Deny multi instance request */
    denyMultiInstanceRequest: Maybe<DenyMultiInstanceRequestPayload>;
    /** Disable bundle welcome email for all bundles */
    disableAllBundleWelcomeEmail: Maybe<DisableAllBundleWelcomeEmailPayload>;
    /** Disable course completion email for all courses */
    disableAllCourseCompletionEmail: Maybe<
      DisableAllCourseCompletionEmailPayload
    >;
    /** Disable course enrollment email for all courses */
    disableAllCourseEnrollmentEmail: Maybe<
      DisableAllCourseEnrollmentEmailPayload
    >;
    /** Disable all site scripts for an app */
    disableSiteScriptsByAppId: Maybe<DisableSiteScriptsByAppIdPayload>;
    /** Download a Video */
    downloadVideo: Maybe<DownloadVideo>;
    /** Duplicate a Live Event */
    duplicateLiveEvent: Maybe<DuplicateLiveEventPayload>;
    /** Duplicate a Blueprint for a tenant */
    duplicateStudentCertificateBlueprint: Maybe<DuplicateBlueprintPayload>;
    duplicateThemeInstallation: Maybe<DuplicateThemeInstallationPayload>;
    /** Edit a post */
    editPost: Maybe<EditPostPayload>;
    /** Edit a promotion */
    editPromotion: Maybe<EditPromotionPayload>;
    /** Edit a reply */
    editReply: Maybe<EditReplyPayload>;
    /** Enable bundle welcome email for all bundles */
    enableAllBundleWelcomeEmail: Maybe<EnableAllBundleWelcomeEmailPayload>;
    /** Enable course completion email for all courses */
    enableAllCourseCompletionEmail: Maybe<
      EnableAllCourseCompletionEmailPayload
    >;
    /** Enable course enrollment email for all courses */
    enableAllCourseEnrollmentEmail: Maybe<
      EnableAllCourseEnrollmentEmailPayload
    >;
    /** Enable all site scripts for an app */
    enableSiteScriptsByAppId: Maybe<EnableSiteScriptsByAppIdPayload>;
    /** Creates a Thinkific Payments account for tenant */
    enableThinkificPayments: Maybe<EnableThinkificPaymentsPayload>;
    /** Enroll tenant into the accelerator program */
    enrollIntoAccelerator: Maybe<EnrollIntoAcceleratorPayload>;
    /** Export contents (such as quizzes and surveys) */
    exportContents: Maybe<ExportContentsPayload>;
    /** Export Orders */
    exportOrders: Maybe<ExportOrdersPayload>;
    /** Export Payout Csv */
    exportPayoutCsv: Maybe<ExportPayoutCsvPayload>;
    /** Export Order Transaction Details */
    exportTransactions: Maybe<ExportTransactionsPayload>;
    /** Export user's quiz and survey response data. */
    exportUserQuizSurveyResponses: Maybe<ExportUserQuizSurveyResponses>;
    /** Export Users */
    exportUsers: Maybe<ExportUsers>;
    /** Export engagement data for a tenant's videos */
    exportVideoEngagements: Maybe<SidekiqJobId>;
    /** Follows a post */
    followPost: Maybe<FollowPostPayload>;
    /** Follows a reply */
    followReply: Maybe<FollowReplyPayload>;
    /** Fork a theme installation */
    forkThemeInstallation: Maybe<ForkThemeInstallationPayload>;
    /** Generate a Course Outline using AI */
    generateCourseOutline: Maybe<GenerateCourseOutlinePayload>;
    /** Generates OTP backup codes and returns them to the user */
    generateOtpBackupCodes: Maybe<GenerateOtpBackupCodesPayload>;
    /** Hide a preset for a user */
    hideBookmarkPreset: Maybe<HideBookmarkPresetPayload>;
    /** Import quiz questions through XLSX */
    importQuiz: Maybe<ImportQuizPayload>;
    /** Update a user content as incomplete */
    incompleteLesson: Maybe<IncompleteLessonPayload>;
    /** Save Infusionsoft tags */
    infusionsoftSaveTags: Maybe<InfusionsoftSaveTagsPayload>;
    /** Update Infusionsoft configuration */
    infusionsoftUpdateConfiguration: Maybe<
      InfusionsoftUpdateConfigurationPayload
    >;
    /** Initialize a payment method */
    initializePaymentMethod: Maybe<InitializePaymentMethod>;
    /** Refund a transaction */
    issueRefund: Maybe<IssueRefundPayload>;
    /** Logs in a user for a given tenant */
    logInUser: Maybe<LogInUser>;
    /** Send password recovery information to the email */
    passwordRecovery: Maybe<PasswordRecoveryPayload>;
    /** Pauses payment collection on a subscription or payment plan by given Id */
    pauseRecurringPayment: Maybe<PauseRecurringPaymentPayload>;
    /** Pin a post */
    pinPost: Maybe<PinPostPayload>;
    /** Preauthorize the payment method for the user purchase attempt */
    preauthorizePaymentMethod: Maybe<PreauthorizePaymentMethodType>;
    /** Process a Student Import */
    processUserImport: Maybe<ProcessUserImportPayload>;
    /** Publish a Sales Funnel */
    publishFunnel: Maybe<PublishFunnelPayload>;
    /** Request approval to publish product */
    publishProductRequest: Maybe<PublishProductRequestPayload>;
    /** Create a new tenant account and purchase a paid plan */
    purchasePaidPlan: Maybe<PurchasePaidPlanPayload>;
    /** Remove bulk sale for a cart */
    removeBulkSale: Maybe<RemoveBulkSaleResult>;
    /** Remove a chapter within a course */
    removeChapter: Maybe<RemoveChapterPayload>;
    /** Remove a course admin from a course */
    removeCourseAdmin: Maybe<RemoveCourseAdminPayload>;
    /** Remove an item from a bundle */
    removeItemFromBundle: Maybe<RemoveItemFromBundlePayload>;
    /** Remove a line item from a cart */
    removeLineItem: Maybe<RemoveLineItemResult>;
    /** Remove a multi-instance connection */
    removeMultiInstanceConn: Maybe<RemoveMultiInstanceConnPayload>;
    /** Remove affiliate(s) for a product */
    removeProductAffiliates: Maybe<RemoveProductAffiliatesPayload>;
    /** Remove an existing promotion */
    removePromotion: Maybe<RemovePromotionPayload>;
    /** Remove users from groups */
    removeUsersFromGroups: Maybe<RemoveUsersFromGroupsPayload>;
    /** Rename an existing Group */
    renameGroup: Maybe<RenameGroupPayload>;
    /** Rename a theme installation */
    renameThemeInstallation: Maybe<RenameThemeInstallationPayload>;
    /** Reorder the position of a chapter within a course */
    reorderChapterInCourse: Maybe<ReorderChapterInCoursePayload>;
    /** Reorder a community_space */
    reorderCommunitySpace: Maybe<ReorderCommunitySpacePayload>;
    /** Reorder the position of an item within a bundle */
    reorderItemInBundle: Maybe<ReorderItemInBundlePayload>;
    /** Reorder the position of a lesson within a chapter */
    reorderLessonInChapter: Maybe<ReorderLessonInChapterPayload>;
    /** Update product's position */
    reorderProduct: Maybe<ReorderProductPayload>;
    /** Re-send multi-instance email */
    resendMultiInstanceEmail: Maybe<ResendMultiInstanceEmailPayload>;
    /** Resends verification email to the site owner. */
    resendVerificationEmail: Maybe<ResendVerificationEmailPayload>;
    restoreThemeInstallation: Maybe<RestoreThemeInstallationPayload>;
    /** Resumes payment collection on a subscription or payment plan by given Id */
    resumeRecurringPayment: Maybe<ResumeRecurringPaymentPayload>;
    /** Retries subscription or payment plan by given Id */
    retryRecurringPayment: Maybe<RetryRecurringPaymentPayload>;
    /** Save a new/existing changelog entry */
    saveChangelogEntry: Maybe<SaveEntryResult>;
    /** Save draft and publish a site page */
    saveSitePage: Maybe<SaveSitePagePayload>;
    /** Send email to one or more users */
    sendEmail: Maybe<SendEmailResult>;
    /** Send Email All Users */
    sendEmailToAllUsers: Maybe<SendEmailResult>;
    /** Sends an email for verifying user's identify when setting up MFA */
    sendMfaVerificationEmail: Maybe<SendMfaVerificationEmailPayload>;
    /** Set a Bank account for payouts */
    setBankAccount: Maybe<SetBankAccountPayload>;
    /** Set bulk sale for a cart */
    setBulkSale: Maybe<SetBulkSaleResult>;
    /** Sets tenant's course pricing currency */
    setCurrency: Maybe<SetCurrencyPayload>;
    /** Set a page to redirect to when visting the home page */
    setHomePageRedirect: Maybe<SetHomePageRedirectPayload>;
    /** Sign in an existing tenant */
    signInExistingTenant: Maybe<SignInExistingTenantPayload>;
    /** Creates and signs in a new user for a given tenant */
    signUpUser: Maybe<SignUpUser>;
    /** Create an assignment submission */
    submitAssignment: Maybe<SubmitAssignmentPayload>;
    /** Calls worker to create, update, and delete local Zoom users to match data found on Zoom */
    syncZoomUsers: Maybe<SyncZoomUsersPayload>;
    /** Toggle Reaction on a Post */
    toggleReactionOnPost: Maybe<ToggleReactionOnPostPayload>;
    /** Reacts on a Reply */
    toggleReactionOnReply: Maybe<ToggleReactionOnReplyPayload>;
    /** Trigger a Channel Course validation */
    triggerChannelCourseValidation: Maybe<
      TriggerChannelCourseValidationPayload
    >;
    /** Unarchive a Video */
    unarchiveVideo: Maybe<UnarchiveVideo>;
    /** Undo a "hide a preset" action for a user */
    undoHideBookmarkPreset: Maybe<UndoHideBookmarkPresetPayload>;
    /** Unfollows a post */
    unfollowPost: Maybe<UnfollowPostPayload>;
    /** Unfollows a reply */
    unfollowReply: Maybe<UnfollowReplyPayload>;
    /** Uninstall an oauth app */
    uninstallApp: Maybe<UninstallAppResult>;
    /** Unpin a post */
    unpinPost: Maybe<UnpinPostPayload>;
    /** Unpublish a Sales Funnel */
    unpublishFunnel: Maybe<UnpublishFunnelPayload>;
    /** Unpublishes a site page */
    unpublishSitePage: Maybe<UnpublishSitePagePayload>;
    /** Update an AccountNotification state */
    updateAccountNotification: Maybe<UpdateAccountNotificationPayload>;
    /** Update an Active Campaign */
    updateActiveCampaign: Maybe<UpdateActiveCampaign>;
    /** Update an AdminNotification state */
    updateAdminNotification: Maybe<UpdateAdminNotificationPayload>;
    /** Update an existing after purchase flow */
    updateAfterPurchaseFlow: Maybe<UpdateAfterPurchaseFlowPayload>;
    /** Update an after purchase flow product */
    updateAfterPurchaseFlowProduct: Maybe<
      UpdateAfterPurchaseFlowProductPayload
    >;
    /** Update settings for OAuthAppInstall */
    updateAppSettings: Maybe<UpdateAppSettingsPayload>;
    /** Update an assignment lesson */
    updateAssignmentLesson: Maybe<UpdateAssignmentLessonPayload>;
    /** Update a audio lesson */
    updateAudioLesson: Maybe<UpdateAudioLessonPayload>;
    /** Update a Bundle */
    updateBundle: Maybe<UpdateBundlePayload>;
    /** Update bundle image */
    updateBundleImage: Maybe<UpdateBundleImagePayload>;
    /** Update a Bundle welcome email state and layout */
    updateBundleWelcomeEmail: Maybe<UpdateBundleWelcomeEmailPayload>;
    /** Update a chapter within a course */
    updateChapter: Maybe<UpdateChapterPayload>;
    /** Update a Community */
    updateCommunity: Maybe<UpdateCommunityPayload>;
    /** Update a community_space */
    updateCommunitySpace: Maybe<UpdateCommunitySpacePayload>;
    /** Update a Course */
    updateCourse: Maybe<UpdateCoursePayload>;
    /** Update a Course completion email state and layout */
    updateCourseCompletionEmail: Maybe<UpdateCourseCompletionEmailPayload>;
    /** Update a Course enrollment email state and layout */
    updateCourseEnrollmentEmail: Maybe<UpdateCourseEnrollmentEmailPayload>;
    /** Update course player image */
    updateCourseImage: Maybe<UpdateCourseImagePayload>;
    /** update course player logo image */
    updateCoursePlayerLogo: Maybe<UpdateCoursePlayerLogoPayload>;
    /** Update a custom page */
    updateCustomPage: Maybe<UpdateCustomPage>;
    /** Update a theme's manifest */
    updateCustomSiteThemeManifest: Maybe<UpdateCustomSiteThemeManifest>;
    /** Update a custom site theme view from an object */
    updateCustomSiteThemeView: Maybe<UpdateCustomSiteThemeViewPayload>;
    /** Update a theme's manifest */
    updateCustomSiteThemeViewManifest: Maybe<UpdateCustomSiteThemeViewManifest>;
    /** Update dashboard prompt experience */
    updateDashboardPromptExperience: Maybe<
      UpdateDashboardPromptExperiencePayload
    >;
    /** Update a Disqus lesson */
    updateDisqusLesson: Maybe<UpdateDisqusLessonPayload>;
    /** Update a download lesson */
    updateDownloadLesson: Maybe<UpdateDownloadLessonPayload>;
    /** Update a drip schedule */
    updateDripSchedule: Maybe<UpdateDripSchedulePayload>;
    /** Update enrollment start date */
    updateEnrollmentStartDate: Maybe<UpdateEnrollmentStartDatePayload>;
    /** Update an exam lesson */
    updateExamLesson: Maybe<UpdateExamLessonPayload>;
    /** Update a Sales Funnel */
    updateFunnel: Maybe<UpdateFunnelPayload>;
    /** Update a Funnel's metadata */
    updateFunnelMetadata: Maybe<UpdateFunnelMetadataPayload>;
    /** Update a course lesson */
    updateLesson: Maybe<UpdateLessonPayload>;
    /** Update a Live Event */
    updateLiveEvent: Maybe<UpdateLiveEventPayload>;
    /** Update a live lesson */
    updateLiveLesson: Maybe<UpdateLiveLessonPayload>;
    /** Update a multimedia lesson */
    updateMultimediaLesson: Maybe<UpdateMultimediaLessonPayload>;
    /** Update onboarding flow info for a new tenant account */
    updateOnboardingFlow: Maybe<UpdateOnboardingFlowPayload>;
    /** Update onboarding info for tenant account */
    updateOnboardingTenantInfo: Maybe<UpdateOnboardingTenantInfoPayload>;
    /** Create tenant account if necessary, and apply promotion */
    updateOnboardingVisitorData: Maybe<OnboardingVisitorData>;
    /** Update a One Time Offer */
    updateOneTimeOffer: Maybe<UpdateOneTimeOfferPayload>;
    /** Update settings for the OpenID Connect Client App */
    updateOpenIdConnectClientSettings: Maybe<
      UpdateOpenIdConnectClientSettingsPayload
    >;
    /** Updates an order bump */
    updateOrderBump: Maybe<UpdateOrderBumpPayload>;
    /** Update a pdf lesson */
    updatePdfLesson: Maybe<UpdatePdfLessonPayload>;
    /** Update a presentation lesson */
    updatePresentationLesson: Maybe<UpdatePresentationLessonPayload>;
    /** Update product's attributes */
    updateProduct: Maybe<UpdateProductPayload>;
    /** Updates the users product license expiry date */
    updateProductLicenseExpiryDate: Maybe<
      UpdateProductLicenseExpiryDatePayload
    >;
    /** update Product Payee for a course */
    updateProductPayee: Maybe<UpdateProductPayeePayload>;
    /** Update an existing price */
    updateProductPrice: Maybe<UpdateProductPricePayload>;
    /** Update a quiz lesson */
    updateQuizLesson: Maybe<UpdateQuizLessonPayload>;
    /** Update a search bookmark */
    updateSearchBookmark: Maybe<UpdateSearchBookmarkPayload>;
    /** Update site sign up settings */
    updateSignUpSettings: Maybe<UpdateSignUpSettingsPayload>;
    /** Update a Blueprint for a tenant */
    updateStudentCertificateBlueprint: Maybe<UpdateBlueprintPayload>;
    /** Update a certificate mapping for a product */
    updateStudentCertificatesCertificateMapping: Maybe<
      UpdateCertificateMappingPayload
    >;
    /** Update a StudentRecord for a tenant */
    updateStudentCertificatesRecord: Maybe<UpdateStudentRecordPayload>;
    /** Update a StudentNotification state */
    updateStudentNotification: Maybe<UpdateStudentNotificationPayload>;
    /** Update a submission status */
    updateSubmissionStatus: Maybe<UpdateSubmissionStatusPayload>;
    /** Update a survey lesson */
    updateSurveyLesson: Maybe<UpdateSurveyLessonPayload>;
    /** Updates tenant's tax registration status and id */
    updateTaxRegistration: Maybe<UpdateTaxRegistrationPayload>;
    /** Update a Tenant */
    updateTenant: Maybe<UpdateTenant>;
    /** Update a Tenant's billing information */
    updateTenantBillingInfo: Maybe<UpdateTenantBillingInfoPayload>;
    /** Update tenant's branded app configuration */
    updateTenantBrandedAppConfiguration: Maybe<
      UpdateTenantBrandedAppConfigurationPayload
    >;
    /** Update tenant's branded app customization */
    updateTenantBrandedAppCustomization: Maybe<
      UpdateTenantBrandedAppCustomizationPayload
    >;
    /** Update a Tenant's billing information */
    updateTenantCheckoutBillingInfo: Maybe<UpdateTenantBillingInfo>;
    /** Update a Tenant's country code */
    updateTenantCheckoutCountryCode: Maybe<UpdateTenantBillingInfo>;
    /** Updates a Tenant's mobile settings */
    updateTenantMobileSetting: Maybe<UpdateTenantMobileSettingPayload>;
    /** Update a text lesson */
    updateTextLesson: Maybe<UpdateTextLessonPayload>;
    /** Update a Student Import */
    updateUserImport: Maybe<UpdateUserImportPayload>;
    /** Update a video lesson */
    updateVideoLesson: Maybe<UpdateVideoLessonPayload>;
    /** Update a webhook */
    updateWebhook: Maybe<UpdateWebhook>;
    /** Updates a theme installation */
    upgradeThemeInstallation: Maybe<UpgradeThemeInstallationPayload>;
    /** Track user events */
    userEvent: Maybe<EventPayload>;
    /** Verifies that user password is correct */
    verifyPassword: Maybe<VerifyPasswordPayload>;
    /** Verifies OTP attempt when user has MFA activated */
    verifySecondFactor: Maybe<VerifySecondFactorPayload>;
    /** Find or create a user content */
    viewLesson: Maybe<ViewLessonPayload>;
    /** Send a Webhook Test Event */
    webhookTestEvent: Maybe<WebhookTestEvent>;
  };

/** Mutation root of Thinkific's schema */
export type MutationActivateMfaArgs = {
  input: ActivateMfaInput;
};

/** Mutation root of Thinkific's schema */
export type MutationActivateProductLicensesArgs = {
  input: ActivateProductLicensesInput;
};

/** Mutation root of Thinkific's schema */
export type MutationActivateThemeInstallationArgs = {
  input: ActivateThemeInstallationInput;
};

/** Mutation root of Thinkific's schema */
export type MutationAddCourseAdminArgs = {
  input: CourseAdminInput;
};

/** Mutation root of Thinkific's schema */
export type MutationAddItemToBundleArgs = {
  input: AddItemToBundleInput;
};

/** Mutation root of Thinkific's schema */
export type MutationAddLineItemArgs = {
  input: AddLineItemInput;
};

/** Mutation root of Thinkific's schema */
export type MutationAddMultiInstanceArgs = {
  input: AddMultiInstanceInput;
};

/** Mutation root of Thinkific's schema */
export type MutationAddProductAffiliatesArgs = {
  input: AddProductAffiliatesInput;
};

/** Mutation root of Thinkific's schema */
export type MutationAddUsersToGroupsArgs = {
  input: AddUsersToGroupsInput;
};

/** Mutation root of Thinkific's schema */
export type MutationApplyCouponArgs = {
  input: ApplyCouponInput;
};

/** Mutation root of Thinkific's schema */
export type MutationApplySettingsToAllArgs = {
  input: ApplySettingsToAllInput;
};

/** Mutation root of Thinkific's schema */
export type MutationApplyThemePresetArgs = {
  input: ApplyThemePresetInput;
};

/** Mutation root of Thinkific's schema */
export type MutationApproveMultiInstanceRequestArgs = {
  input: ApproveMultiInstanceRequestInput;
};

/** Mutation root of Thinkific's schema */
export type MutationArchiveVideoArgs = {
  input: ArchiveVideoInput;
};

/** Mutation root of Thinkific's schema */
export type MutationBuildBrandedAppArgs = {
  input: BuildTenantBrandedAppInput;
};

/** Mutation root of Thinkific's schema */
export type MutationBulkUpdatePositionsArgs = {
  input: BulkUpdatePositionsInput;
};

/** Mutation root of Thinkific's schema */
export type MutationBulkUpdateProductsArgs = {
  input: BulkUpdateProductsInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCalculateTaxArgs = {
  input: CalculateTaxInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCancelSiteSubscriptionArgs = {
  input: CancelSiteSubscriptionInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCancelSubscriptionArgs = {
  input: CancelSubscriptionInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCheckAllUsersInGroupsArgs = {
  input: CheckAllUsersInGroupsInput;
};

/** Mutation root of Thinkific's schema */
export type MutationClaimPromotionalDealArgs = {
  input: ClaimPromotionalDealInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCompleteCheckoutArgs = {
  input: CompleteCheckoutInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCompleteCheckoutWithoutPaymentArgs = {
  input: CompleteCheckoutWithoutPaymentInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCompleteDashboardPromptArgs = {
  id: Scalars['String']['input'];
};

/** Mutation root of Thinkific's schema */
export type MutationCompleteDashboardPromptByPromptTypeArgs = {
  input: CompleteDashboardPromptByPromptInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCompleteLessonArgs = {
  input: CompleteLessonInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCompleteTenantChecklistItemArgs = {
  input: CompleteTenantChecklistItemInput;
};

/** Mutation root of Thinkific's schema */
export type MutationConfirmPurchaseArgs = {
  input: ConfirmPurchaseInput;
};

/** Mutation root of Thinkific's schema */
export type MutationConvertkitSaveTagsArgs = {
  input: ConvertkitSaveTagsInput;
};

/** Mutation root of Thinkific's schema */
export type MutationConvertkitUpdateConfigurationArgs = {
  input: ConvertkitUpdateConfigurationInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCopyCourseArgs = {
  input: CopyCourseInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCopyLessonArgs = {
  input: CopyLessonInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateActiveCampaignArgs = {
  input: CreateActiveCampaignInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateActiveCampaignEventArgs = {
  input: CreateEventInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateAdditionalProductPriceArgs = {
  input: CreateAdditionalProductPriceInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateAfterPurchaseFlowArgs = {
  input: CreateAfterPurchaseFlowInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateAfterPurchaseFlowProductArgs = {
  input: CreateAfterPurchaseFlowProductInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateAiFunnelPromptArgs = {
  input: CreateAiFunnelPromptInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateAssignmentLessonArgs = {
  input: CreateAssignmentLessonInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateAttendanceArgs = {
  input: CreateAttendanceInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateAudioLessonArgs = {
  input: CreateAudioLessonInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateBulkEnrollmentArgs = {
  input: CreateBulkEnrollmentInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateBundleArgs = {
  input: CreateBundleInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateChapterArgs = {
  input: CreateChapterInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateCommunityArgs = {
  input: CreateCommunityInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateCommunitySpaceArgs = {
  input: CreateCommunitySpaceInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateCourseArgs = {
  input: CreateCourseInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateCustomPageArgs = {
  input: CreateCustomPageInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateDownloadLessonArgs = {
  input: CreateDownloadLessonInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateDripScheduleArgs = {
  input: CreateDripScheduleInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateDripScheduleItemsArgs = {
  input: CreateDripScheduleItemsInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateExamLessonArgs = {
  input: CreateExamLessonInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateFreeTrialEnrollmentArgs = {
  input: CreateFreeTrialEnrollmentInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateFunnelArgs = {
  input: CreateFunnelInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateGroupArgs = {
  input: CreateGroupInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateInAppPurchaseProductArgs = {
  input: CreateInAppPurchaseProductInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateLessonFromUploadArgs = {
  input: CreateLessonFromUploadInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateLibraryThemeInstallationArgs = {
  input: CreateLibraryThemeInstallationInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateLiveEventArgs = {
  input: CreateLiveEventInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateLiveLessonArgs = {
  input: CreateLiveLessonInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateMultimediaLessonArgs = {
  input: CreateMultimediaLessonInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateNewPlusTenantArgs = {
  input: CreateNewPlusTenantInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateOrUpdateTenantLabArgs = {
  input: CreateOrUpdateTenantLabInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateOrderBumpArgs = {
  input: CreateOrderBumpInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreatePdfLessonArgs = {
  input: CreatePdfLessonInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreatePostArgs = {
  input: CreatePostInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreatePresentationLessonArgs = {
  input: CreatePresentationLessonInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateProductPayeeArgs = {
  input: CreateProductPayeeInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreatePromotionArgs = {
  input: CreatePromotionInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateQuizLessonArgs = {
  input: CreateQuizLessonInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateReplyArgs = {
  input: CreateReplyInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateSearchBookmarkArgs = {
  input: CreateSearchBookmarkInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateSitePageArgs = {
  input: CreateSitePageInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateStudentCertificateBlueprintArgs = {
  input: StudentCertificatesBlueprintInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateStudentCertificatesCertificateMappingArgs = {
  input: StudentCertificatesCreateCertificateMappingInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateStudentCertificatesCertificateMappingsArgs = {
  input: StudentCertificatesCertificateMappingsInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateSurveyLessonArgs = {
  input: CreateSurveyLessonInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateTenantChecklistArgs = {
  input: CreateTenantChecklistInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateTextLessonArgs = {
  input: CreateTextLessonInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateUserImportArgs = {
  input: CreateUserImportInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateVideoArgs = {
  input: CreateVideoInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateVideoLessonArgs = {
  input: CreateVideoLessonInput;
};

/** Mutation root of Thinkific's schema */
export type MutationCreateWebhookArgs = {
  input: CreateWebhookInput;
};

/** Mutation root of Thinkific's schema */
export type MutationDeactivateMfaArgs = {
  input: DeactivateMfaInput;
};

/** Mutation root of Thinkific's schema */
export type MutationDeactivateMfaForUserArgs = {
  input: DeactivateMfaForUserInput;
};

/** Mutation root of Thinkific's schema */
export type MutationDeleteAdditionalProductPriceArgs = {
  input: DeleteAdditionalProductPriceInput;
};

/** Mutation root of Thinkific's schema */
export type MutationDeleteAfterPurchaseFlowArgs = {
  input: DeleteAfterPurchaseFlowInput;
};

/** Mutation root of Thinkific's schema */
export type MutationDeleteAfterPurchaseFlowProductArgs = {
  input: DeleteAfterPurchaseFlowProductInput;
};

/** Mutation root of Thinkific's schema */
export type MutationDeleteAttendanceArgs = {
  input: DeleteAttendanceInput;
};

/** Mutation root of Thinkific's schema */
export type MutationDeleteBulkLiveLessonArgs = {
  input: DeleteBulkLiveLessonInput;
};

/** Mutation root of Thinkific's schema */
export type MutationDeleteChangelogEntryArgs = {
  input: DeleteEntryInput;
};

/** Mutation root of Thinkific's schema */
export type MutationDeleteCommunitySpaceArgs = {
  input: DeleteCommunitySpaceInput;
};

/** Mutation root of Thinkific's schema */
export type MutationDeleteCourseArgs = {
  input: DeleteCourseInput;
};

/** Mutation root of Thinkific's schema */
export type MutationDeleteCustomPageArgs = {
  input: DeleteCustomPageInput;
};

/** Mutation root of Thinkific's schema */
export type MutationDeleteDripScheduleArgs = {
  input: DeleteDripScheduleInput;
};

/** Mutation root of Thinkific's schema */
export type MutationDeleteFunnelArgs = {
  input: DeleteFunnelInput;
};

/** Mutation root of Thinkific's schema */
export type MutationDeleteGroupArgs = {
  input: DeleteGroupInput;
};

/** Mutation root of Thinkific's schema */
export type MutationDeleteLessonArgs = {
  input: DeleteLessonInput;
};

/** Mutation root of Thinkific's schema */
export type MutationDeleteLiveEventArgs = {
  input: DeleteLiveEventInput;
};

/** Mutation root of Thinkific's schema */
export type MutationDeleteOrderBumpArgs = {
  input: DeleteOrderBumpInput;
};

/** Mutation root of Thinkific's schema */
export type MutationDeletePostArgs = {
  input: DeletePostInput;
};

/** Mutation root of Thinkific's schema */
export type MutationDeleteProductPayeeArgs = {
  input: DeleteProductPayeeInput;
};

/** Mutation root of Thinkific's schema */
export type MutationDeleteReplyArgs = {
  input: DeleteReplyInput;
};

/** Mutation root of Thinkific's schema */
export type MutationDeleteSearchBookmarkArgs = {
  input: DeleteSearchBookmarkInput;
};

/** Mutation root of Thinkific's schema */
export type MutationDeleteStudentCertificateBlueprintArgs = {
  input: StudentCertificatesDeleteBlueprintInput;
};

/** Mutation root of Thinkific's schema */
export type MutationDeleteStudentCertificatesCertificateMappingArgs = {
  input: StudentCertificatesDeleteCertificateMappingInput;
};

/** Mutation root of Thinkific's schema */
export type MutationDeleteThemeInstallationArgs = {
  input: DeleteThemeInstallationInput;
};

/** Mutation root of Thinkific's schema */
export type MutationDeleteUsersArgs = {
  userIds: Array<Scalars['ID']['input']>;
};

/** Mutation root of Thinkific's schema */
export type MutationDeleteVideoArgs = {
  input: DeleteVideoInput;
};

/** Mutation root of Thinkific's schema */
export type MutationDeleteWebhookArgs = {
  input: DeleteWebhookInput;
};

/** Mutation root of Thinkific's schema */
export type MutationDenyMultiInstanceRequestArgs = {
  input: DenyMultiInstanceRequestInput;
};

/** Mutation root of Thinkific's schema */
export type MutationDisableAllBundleWelcomeEmailArgs = {
  input: DisableAllBundleWelcomeEmailInput;
};

/** Mutation root of Thinkific's schema */
export type MutationDisableAllCourseCompletionEmailArgs = {
  input: DisableAllCourseCompletionEmailInput;
};

/** Mutation root of Thinkific's schema */
export type MutationDisableAllCourseEnrollmentEmailArgs = {
  input: DisableAllCourseEnrollmentEmailInput;
};

/** Mutation root of Thinkific's schema */
export type MutationDisableSiteScriptsByAppIdArgs = {
  input: DisableSiteScriptsByAppIdInput;
};

/** Mutation root of Thinkific's schema */
export type MutationDownloadVideoArgs = {
  input: DownloadVideoInput;
};

/** Mutation root of Thinkific's schema */
export type MutationDuplicateLiveEventArgs = {
  input: DuplicateLiveEventInput;
};

/** Mutation root of Thinkific's schema */
export type MutationDuplicateStudentCertificateBlueprintArgs = {
  input: StudentCertificatesDuplicateBlueprintInput;
};

/** Mutation root of Thinkific's schema */
export type MutationDuplicateThemeInstallationArgs = {
  input: DuplicateThemeInstallationInput;
};

/** Mutation root of Thinkific's schema */
export type MutationEditPostArgs = {
  input: EditPostInput;
};

/** Mutation root of Thinkific's schema */
export type MutationEditPromotionArgs = {
  input: EditPromotionInput;
};

/** Mutation root of Thinkific's schema */
export type MutationEditReplyArgs = {
  input: EditReplyInput;
};

/** Mutation root of Thinkific's schema */
export type MutationEnableAllBundleWelcomeEmailArgs = {
  input: EnableAllBundleWelcomeEmailInput;
};

/** Mutation root of Thinkific's schema */
export type MutationEnableAllCourseCompletionEmailArgs = {
  input: EnableAllCourseCompletionEmailInput;
};

/** Mutation root of Thinkific's schema */
export type MutationEnableAllCourseEnrollmentEmailArgs = {
  input: EnableAllCourseEnrollmentEmailInput;
};

/** Mutation root of Thinkific's schema */
export type MutationEnableSiteScriptsByAppIdArgs = {
  input: EnableSiteScriptsByAppIdInput;
};

/** Mutation root of Thinkific's schema */
export type MutationEnableThinkificPaymentsArgs = {
  input: EnableThinkificPaymentsInput;
};

/** Mutation root of Thinkific's schema */
export type MutationEnrollIntoAcceleratorArgs = {
  input: EnrollIntoAcceleratorInput;
};

/** Mutation root of Thinkific's schema */
export type MutationExportContentsArgs = {
  input: ExportContentsInput;
};

/** Mutation root of Thinkific's schema */
export type MutationExportOrdersArgs = {
  input: ExportOrdersInput;
};

/** Mutation root of Thinkific's schema */
export type MutationExportPayoutCsvArgs = {
  input: ExportPayoutCsvInput;
};

/** Mutation root of Thinkific's schema */
export type MutationExportTransactionsArgs = {
  input: ExportTransactionsInput;
};

/** Mutation root of Thinkific's schema */
export type MutationExportUserQuizSurveyResponsesArgs = {
  filter: ExportFilter;
};

/** Mutation root of Thinkific's schema */
export type MutationExportUsersArgs = {
  input: ExportUsersInput;
};

/** Mutation root of Thinkific's schema */
export type MutationExportVideoEngagementsArgs = {
  filter?: InputMaybe<VideoFilter>;
  sampleData?: InputMaybe<SampleDataOverride>;
};

/** Mutation root of Thinkific's schema */
export type MutationFollowPostArgs = {
  input: FollowPostInput;
};

/** Mutation root of Thinkific's schema */
export type MutationFollowReplyArgs = {
  input: FollowReplyInput;
};

/** Mutation root of Thinkific's schema */
export type MutationForkThemeInstallationArgs = {
  input: ForkThemeInstallationInput;
};

/** Mutation root of Thinkific's schema */
export type MutationGenerateCourseOutlineArgs = {
  input: GenerateCourseOutlineInput;
};

/** Mutation root of Thinkific's schema */
export type MutationGenerateOtpBackupCodesArgs = {
  input: GenerateOtpBackupCodesInput;
};

/** Mutation root of Thinkific's schema */
export type MutationHideBookmarkPresetArgs = {
  presetId: Scalars['String']['input'];
};

/** Mutation root of Thinkific's schema */
export type MutationImportQuizArgs = {
  input: ImportQuizInput;
};

/** Mutation root of Thinkific's schema */
export type MutationIncompleteLessonArgs = {
  input: IncompleteLessonInput;
};

/** Mutation root of Thinkific's schema */
export type MutationInfusionsoftSaveTagsArgs = {
  input: InfusionsoftSaveTagsInput;
};

/** Mutation root of Thinkific's schema */
export type MutationInfusionsoftUpdateConfigurationArgs = {
  input: InfusionsoftUpdateConfigurationInput;
};

/** Mutation root of Thinkific's schema */
export type MutationInitializePaymentMethodArgs = {
  input: InitializePaymentMethodInput;
};

/** Mutation root of Thinkific's schema */
export type MutationIssueRefundArgs = {
  input: IssueRefundInput;
};

/** Mutation root of Thinkific's schema */
export type MutationLogInUserArgs = {
  input: LogInUserInput;
};

/** Mutation root of Thinkific's schema */
export type MutationPasswordRecoveryArgs = {
  input: PasswordRecoveryInput;
};

/** Mutation root of Thinkific's schema */
export type MutationPauseRecurringPaymentArgs = {
  input: PauseRecurringPaymentInput;
};

/** Mutation root of Thinkific's schema */
export type MutationPinPostArgs = {
  input: PinPostInput;
};

/** Mutation root of Thinkific's schema */
export type MutationPreauthorizePaymentMethodArgs = {
  input: PreauthorizePaymentMethodInput;
};

/** Mutation root of Thinkific's schema */
export type MutationProcessUserImportArgs = {
  input: ProcessUserImportInput;
};

/** Mutation root of Thinkific's schema */
export type MutationPublishFunnelArgs = {
  input: PublishFunnelInput;
};

/** Mutation root of Thinkific's schema */
export type MutationPublishProductRequestArgs = {
  input: PublishProductRequestInput;
};

/** Mutation root of Thinkific's schema */
export type MutationPurchasePaidPlanArgs = {
  input: PurchasePaidPlanInput;
};

/** Mutation root of Thinkific's schema */
export type MutationRemoveBulkSaleArgs = {
  input: RemoveBulkSaleInput;
};

/** Mutation root of Thinkific's schema */
export type MutationRemoveChapterArgs = {
  input: RemoveChapterInput;
};

/** Mutation root of Thinkific's schema */
export type MutationRemoveCourseAdminArgs = {
  input: CourseAdminInput;
};

/** Mutation root of Thinkific's schema */
export type MutationRemoveItemFromBundleArgs = {
  input: RemoveItemFromBundleInput;
};

/** Mutation root of Thinkific's schema */
export type MutationRemoveLineItemArgs = {
  input: RemoveLineItemInput;
};

/** Mutation root of Thinkific's schema */
export type MutationRemoveMultiInstanceConnArgs = {
  input: RemoveMultiInstanceConnInput;
};

/** Mutation root of Thinkific's schema */
export type MutationRemoveProductAffiliatesArgs = {
  input: RemoveProductAffiliatesInput;
};

/** Mutation root of Thinkific's schema */
export type MutationRemovePromotionArgs = {
  input: RemovePromotionInput;
};

/** Mutation root of Thinkific's schema */
export type MutationRemoveUsersFromGroupsArgs = {
  input: RemoveUsersFromGroupsInput;
};

/** Mutation root of Thinkific's schema */
export type MutationRenameGroupArgs = {
  input: RenameGroupInput;
};

/** Mutation root of Thinkific's schema */
export type MutationRenameThemeInstallationArgs = {
  input: RenameThemeInstallationInput;
};

/** Mutation root of Thinkific's schema */
export type MutationReorderChapterInCourseArgs = {
  input: ReorderChapterInCourseInput;
};

/** Mutation root of Thinkific's schema */
export type MutationReorderCommunitySpaceArgs = {
  input: ReorderCommunitySpaceInput;
};

/** Mutation root of Thinkific's schema */
export type MutationReorderItemInBundleArgs = {
  input: ReorderItemInBundleInput;
};

/** Mutation root of Thinkific's schema */
export type MutationReorderLessonInChapterArgs = {
  input: ReorderLessonInChapterInput;
};

/** Mutation root of Thinkific's schema */
export type MutationReorderProductArgs = {
  input: ReorderProductInput;
};

/** Mutation root of Thinkific's schema */
export type MutationResendMultiInstanceEmailArgs = {
  input: ResendMultiInstanceEmailInput;
};

/** Mutation root of Thinkific's schema */
export type MutationResendVerificationEmailArgs = {
  input: ResendVerificationEmailInput;
};

/** Mutation root of Thinkific's schema */
export type MutationRestoreThemeInstallationArgs = {
  input: RestoreThemeInstallationInput;
};

/** Mutation root of Thinkific's schema */
export type MutationResumeRecurringPaymentArgs = {
  input: ResumeRecurringPaymentInput;
};

/** Mutation root of Thinkific's schema */
export type MutationRetryRecurringPaymentArgs = {
  input: RetryRecurringPaymentInput;
};

/** Mutation root of Thinkific's schema */
export type MutationSaveChangelogEntryArgs = {
  input: SaveEntryInput;
};

/** Mutation root of Thinkific's schema */
export type MutationSaveSitePageArgs = {
  input: SaveSitePageInput;
};

/** Mutation root of Thinkific's schema */
export type MutationSendEmailArgs = {
  email: SendEmailInput;
};

/** Mutation root of Thinkific's schema */
export type MutationSendEmailToAllUsersArgs = {
  input: SendEmailAllUsersInput;
};

/** Mutation root of Thinkific's schema */
export type MutationSetBankAccountArgs = {
  input: SetBankAccountInput;
};

/** Mutation root of Thinkific's schema */
export type MutationSetBulkSaleArgs = {
  input: SetBulkSaleInput;
};

/** Mutation root of Thinkific's schema */
export type MutationSetCurrencyArgs = {
  input: SetCurrencyInput;
};

/** Mutation root of Thinkific's schema */
export type MutationSetHomePageRedirectArgs = {
  input: SetHomePageRedirectInput;
};

/** Mutation root of Thinkific's schema */
export type MutationSignInExistingTenantArgs = {
  input: SignInExistingTenantInput;
};

/** Mutation root of Thinkific's schema */
export type MutationSignUpUserArgs = {
  input: SignUpUserInput;
};

/** Mutation root of Thinkific's schema */
export type MutationSubmitAssignmentArgs = {
  input: SubmitAssignmentInput;
};

/** Mutation root of Thinkific's schema */
export type MutationSyncZoomUsersArgs = {
  input: SyncZoomUsersInput;
};

/** Mutation root of Thinkific's schema */
export type MutationToggleReactionOnPostArgs = {
  input: ToggleReactionOnPostInput;
};

/** Mutation root of Thinkific's schema */
export type MutationToggleReactionOnReplyArgs = {
  input: ToggleReactionOnReplyInput;
};

/** Mutation root of Thinkific's schema */
export type MutationTriggerChannelCourseValidationArgs = {
  input: TriggerChannelCourseValidationInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUnarchiveVideoArgs = {
  input: UnarchiveVideoInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUndoHideBookmarkPresetArgs = {
  presetId: Scalars['String']['input'];
};

/** Mutation root of Thinkific's schema */
export type MutationUnfollowPostArgs = {
  input: UnfollowPostInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUnfollowReplyArgs = {
  input: UnfollowReplyInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUninstallAppArgs = {
  input: UninstallAppInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUnpinPostArgs = {
  input: UnpinPostInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUnpublishFunnelArgs = {
  input: UnpublishFunnelInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUnpublishSitePageArgs = {
  input: UnpublishSitePageInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateAccountNotificationArgs = {
  input: UpdateAccountNotificationInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateActiveCampaignArgs = {
  input: UpdateActiveCampaignInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateAdminNotificationArgs = {
  input: UpdateAdminNotificationInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateAfterPurchaseFlowArgs = {
  input: UpdateAfterPurchaseFlowInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateAfterPurchaseFlowProductArgs = {
  input: UpdateAfterPurchaseFlowProductInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateAppSettingsArgs = {
  input: UpdateAppSettingsInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateAssignmentLessonArgs = {
  input: UpdateAssignmentLessonInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateAudioLessonArgs = {
  input: UpdateAudioLessonInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateBundleArgs = {
  input: UpdateBundleInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateBundleImageArgs = {
  input: UpdateBundleImageInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateBundleWelcomeEmailArgs = {
  input: UpdateBundleWelcomeEmailInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateChapterArgs = {
  input: UpdateChapterInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateCommunityArgs = {
  input: UpdateCommunityInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateCommunitySpaceArgs = {
  input: UpdateCommunitySpaceInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateCourseArgs = {
  input: UpdateCourseInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateCourseCompletionEmailArgs = {
  input: UpdateCourseCompletionEmailInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateCourseEnrollmentEmailArgs = {
  input: UpdateCourseEnrollmentEmailInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateCourseImageArgs = {
  input: UpdateCourseImageInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateCoursePlayerLogoArgs = {
  input: UpdateCoursePlayerLogoInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateCustomPageArgs = {
  input: UpdateCustomPageInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateCustomSiteThemeManifestArgs = {
  input: UpdateCustomSiteThemeManifestInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateCustomSiteThemeViewArgs = {
  input: UpdateCustomSiteThemeViewInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateCustomSiteThemeViewManifestArgs = {
  input: UpdateCustomSiteThemeViewManifestInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateDashboardPromptExperienceArgs = {
  type: Scalars['String']['input'];
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateDisqusLessonArgs = {
  input: UpdateDisqusLessonInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateDownloadLessonArgs = {
  input: UpdateDownloadLessonInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateDripScheduleArgs = {
  input: UpdateDripScheduleInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateEnrollmentStartDateArgs = {
  input: UpdateEnrollmentStartDateInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateExamLessonArgs = {
  input: UpdateExamLessonInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateFunnelArgs = {
  input: UpdateFunnelInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateFunnelMetadataArgs = {
  input: UpdateFunnelMetadataInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateLessonArgs = {
  input: UpdateLessonInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateLiveEventArgs = {
  input: UpdateLiveEventInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateLiveLessonArgs = {
  input: UpdateLiveLessonInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateMultimediaLessonArgs = {
  input: UpdateMultimediaLessonInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateOnboardingFlowArgs = {
  input: UpdateOnboardingFlowInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateOnboardingTenantInfoArgs = {
  input: UpdateOnboardingTenantInfoInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateOnboardingVisitorDataArgs = {
  input: UpdateOnboardingVisitorDataInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateOneTimeOfferArgs = {
  input: UpdateOneTimeOfferInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateOpenIdConnectClientSettingsArgs = {
  input: UpdateOpenIdConnectClientSettingsInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateOrderBumpArgs = {
  input: UpdateOrderBumpInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdatePdfLessonArgs = {
  input: UpdatePdfLessonInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdatePresentationLessonArgs = {
  input: UpdatePresentationLessonInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateProductArgs = {
  input: UpdateProductInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateProductLicenseExpiryDateArgs = {
  input: UpdateProductLicenseExpiryDateInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateProductPayeeArgs = {
  input: UpdateProductPayeeInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateProductPriceArgs = {
  input: UpdateProductPriceInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateQuizLessonArgs = {
  input: UpdateQuizLessonInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateSearchBookmarkArgs = {
  input: UpdateSearchBookmarkInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateSignUpSettingsArgs = {
  input: UpdateSignUpSettingsInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateStudentCertificateBlueprintArgs = {
  input: StudentCertificatesUpdateBlueprintInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateStudentCertificatesCertificateMappingArgs = {
  input: StudentCertificatesUpdateCertificateMappingInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateStudentCertificatesRecordArgs = {
  input: StudentCertificatesUpdateStudentRecordInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateStudentNotificationArgs = {
  input: UpdateStudentNotificationInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateSubmissionStatusArgs = {
  submissionId: Scalars['ID']['input'];
  submissionStatus: Scalars['String']['input'];
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateSurveyLessonArgs = {
  input: UpdateSurveyLessonInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateTaxRegistrationArgs = {
  input: UpdateTaxRegistrationInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateTenantArgs = {
  input: UpdateTenantInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateTenantBillingInfoArgs = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  confirmedByCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  taxExempt?: InputMaybe<Scalars['Boolean']['input']>;
  taxExemptReason?: InputMaybe<Scalars['String']['input']>;
  vatNumber?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateTenantBrandedAppConfigurationArgs = {
  input: UpdateTenantBrandedAppConfigurationInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateTenantBrandedAppCustomizationArgs = {
  input: UpdateTenantBrandedAppCustomizationInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateTenantCheckoutBillingInfoArgs = {
  input: UpdateTenantCheckoutBillingInfoInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateTenantCheckoutCountryCodeArgs = {
  input: UpdateTenantCheckoutCountryCodeInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateTenantMobileSettingArgs = {
  input: UpdateTenantMobileSettingInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateTextLessonArgs = {
  input: UpdateTextLessonInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateUserImportArgs = {
  input: UpdateUserImportInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateVideoLessonArgs = {
  input: UpdateVideoLessonInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpdateWebhookArgs = {
  input: UpdateWebhookInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUpgradeThemeInstallationArgs = {
  input: UpgradeThemeInstallationInput;
};

/** Mutation root of Thinkific's schema */
export type MutationUserEventArgs = {
  cartToken: Scalars['String']['input'];
  name: Scalars['String']['input'];
  properties?: InputMaybe<Scalars['JSON']['input']>;
};

/** Mutation root of Thinkific's schema */
export type MutationVerifyPasswordArgs = {
  input: VerifyPasswordInput;
};

/** Mutation root of Thinkific's schema */
export type MutationVerifySecondFactorArgs = {
  input: VerifySecondFactorInput;
};

/** Mutation root of Thinkific's schema */
export type MutationViewLessonArgs = {
  input: ViewLessonInput;
};

/** Mutation root of Thinkific's schema */
export type MutationWebhookTestEventArgs = {
  input: WebhookTestEventInput;
};

/** An object with an ID. */
export type Node = {
  /** ID of the object. */
  id: Scalars['ID']['output'];
};

/** Number comparison options to filter search */
export type NumberComparison = {
  /** Value is equal to */
  equals?: InputMaybe<Scalars['Int']['input']>;
  /** Value is greater than or equals to */
  greaterThanEquals?: InputMaybe<Scalars['Int']['input']>;
  /** Value is less than or equals to */
  lessThanEquals?: InputMaybe<Scalars['Int']['input']>;
};

/** Content used for a tenant's onboarding modal */
export type OnboardingContent = {
  __typename?: 'OnboardingContent';
  /** Onboarding Industry's video URL */
  bannerImageUrls: Maybe<Array<BannerImage>>;
  /** Possible filters for listing course templates */
  courseTemplates: Maybe<CourseTemplateConnection>;
  /** Onboarding Industry's video URL */
  videoUrl: Maybe<Scalars['String']['output']>;
};

/** Content used for a tenant's onboarding modal */
export type OnboardingContentCourseTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** OnboardingFlow info for current visitor */
export type OnboardingFlow = Node & {
  __typename?: 'OnboardingFlow';
  /** Localized currency for user */
  currency: Scalars['String']['output'];
  /** Default promotional deal LP to link to */
  defaultPromoLp: Maybe<Scalars['String']['output']>;
  /** Visitor's email */
  email: Maybe<Scalars['String']['output']>;
  /** Visitor's first name */
  firstName: Maybe<Scalars['String']['output']>;
  /** Type of Onboarding Flow */
  flow: OnboardingFlowType;
  id: Scalars['ID']['output'];
  /** Interval chosen for given plan */
  initialPlanInterval: Maybe<Scalars['String']['output']>;
  /** Visitor's last name */
  lastName: Maybe<Scalars['String']['output']>;
  /** Wether to link to a promotional deal landing page or the signup flow when currency validation fails */
  linkToPromoLp: Maybe<Scalars['Boolean']['output']>;
  /** Plan associated with the onboarding flow */
  plan: Maybe<Plan>;
  /** Settings for plan associated with the onboarding flow */
  planSettings: Maybe<Array<PlanSettings>>;
  /** The previous promo deal slug for the onboarding flow */
  previousPromoDealSlug: Maybe<Scalars['String']['output']>;
  /** Promotional deal associated with onboarding flow */
  promotionalDeal: Maybe<PromotionalDeal>;
  /** Promotional Deal Purchase ID */
  promotionalDealPurchaseId: Maybe<Scalars['Int']['output']>;
  /** The WWW landing page the customer came from */
  referringWwwLandingPage: Maybe<Scalars['String']['output']>;
  /** Signup recaptcha key */
  signupRecaptchaKey: Maybe<Scalars['String']['output']>;
  /** Stripe plan id */
  stripePlanId: Maybe<Scalars['String']['output']>;
  /** Thinkific account's publishable key */
  stripePublishableKeys: Array<Scalars['String']['output']>;
  /** Tenant subdomain */
  subdomain: Maybe<Scalars['String']['output']>;
  /** If the email belongs to an existing tenant */
  tenancyVerified: Maybe<Scalars['Boolean']['output']>;
  /** Tenant */
  tenant: Maybe<Tenant>;
  /** Visitor geo data */
  visitorGeoData: VisitorGeoData;
  /** Visitor ID */
  visitorId: Maybe<Scalars['Int']['output']>;
  /** The country code that WWW identified for this customer */
  wwwCountryCode: Maybe<Scalars['String']['output']>;
};

/** Fields to filter OnboardingFlows */
export type OnboardingFlowFilter = {
  /** Visitor ID */
  visitorId: Scalars['Int']['input'];
};

/** The type of Onboarding flow */
export enum OnboardingFlowType {
  /** api_flow Onboarding flow */
  ApiFlow = 'api_flow',
  /** paid_flow Onboarding flow */
  PaidFlow = 'paid_flow',
  /** promo_flow_v2 Onboarding flow */
  PromoFlowV2 = 'promo_flow_v2',
  /** signup_flow Onboarding flow */
  SignupFlow = 'signup_flow',
}

/** Thinkific Payments onboarding link */
export type OnboardingLink = {
  __typename?: 'OnboardingLink';
  created: Maybe<Scalars['Int']['output']>;
  expiresAt: Maybe<Scalars['Int']['output']>;
  url: Maybe<Scalars['String']['output']>;
};

/** OnboardingVisitorData for current visitor */
export type OnboardingVisitorData = Node & {
  __typename?: 'OnboardingVisitorData';
  /** Visitor brand colour */
  brandcolour: Maybe<Scalars['String']['output']>;
  /** Visitor course name */
  courseName: Maybe<Scalars['String']['output']>;
  /** Visitor email */
  email: Maybe<Scalars['String']['output']>;
  /** Visitor first name */
  firstName: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Instructor bio */
  instructorBio: Maybe<Scalars['String']['output']>;
  /** Instructor first name */
  instructorFirstName: Maybe<Scalars['String']['output']>;
  /** Instructor image content type */
  instructorImageContentType: Maybe<Scalars['String']['output']>;
  /** Instructor image file name */
  instructorImageFileName: Maybe<Scalars['String']['output']>;
  /** Instructor image file size */
  instructorImageFileSize: Maybe<Scalars['Int']['output']>;
  /** Instructor last name */
  instructorLastName: Maybe<Scalars['String']['output']>;
  /** Visitor last name */
  lastName: Maybe<Scalars['String']['output']>;
  /** Visitor school logo */
  logoFileName: Maybe<Scalars['String']['output']>;
  /** Visitor starting productable name */
  productableName: Maybe<Scalars['String']['output']>;
  /** Visitor starting productable type */
  productableType: Maybe<Scalars['String']['output']>;
  /** Visitor school name */
  schoolName: Maybe<Scalars['String']['output']>;
  /** Visitor signup teaching business experience */
  signupTeachingBusinessExperience: Maybe<Scalars['String']['output']>;
  /** Visitor signup teaching business experience other */
  signupTeachingBusinessExperienceOther: Maybe<Scalars['String']['output']>;
  /** Visitor signup teaching content type */
  signupTeachingContentType: Maybe<Scalars['String']['output']>;
  /** Visitor signup teaching content type other */
  signupTeachingContentTypeOther: Maybe<Scalars['String']['output']>;
  /** Visitor signup teaching following */
  signupTeachingFollowing: Maybe<Scalars['String']['output']>;
  /** Visitor signup teaching headcount */
  signupTeachingHeadcount: Maybe<Scalars['String']['output']>;
  /** Visitor signup teaching industry */
  signupTeachingIndustry: Maybe<Scalars['String']['output']>;
  /** Visitor signup teaching industry other */
  signupTeachingIndustryOther: Maybe<Scalars['String']['output']>;
  /** Visitor signup teaching material */
  signupTeachingMaterial: Maybe<Scalars['String']['output']>;
  /** Visitor signup teaching phone number */
  signupTeachingPhoneNumber: Maybe<Scalars['String']['output']>;
  /** Visitor signup teaching stage */
  signupTeachingStage: Maybe<Scalars['String']['output']>;
  /** Visitor ID */
  visitorId: Maybe<Scalars['Int']['output']>;
};

/** Fields to filter Onboarding Visitor Data */
export type OnboardingVisitorDataFilter = {
  /** Visitor ID */
  visitorId: Scalars['Int']['input'];
};

/** Information about a One Time Offer Promotional Tool */
export type OneTimeOffer = {
  __typename?: 'OneTimeOffer';
  /** The Sales Copy used in the Product */
  defaultSalesCopy: Scalars['JSON']['output'];
  /** Default Thank You Page */
  defaultThankYouPage: CustomSiteThemeView;
  /** The total discount percentage of the offer */
  discount: Scalars['Int']['output'];
  /** The display price of the offer */
  displayPrice: Scalars['String']['output'];
  /** The funnel belonging to the offer */
  funnel: Funnel;
  id: Scalars['ID']['output'];
  /** The original display price of the Product linked to the offer */
  originalDisplayPrice: Scalars['String']['output'];
  /** The position of the offer in its associated Funnel */
  position: Scalars['Int']['output'];
  /** The Product associated with the offer */
  product: Product;
  /** The ProductPrice associated with the offer */
  productPrice: ProductPrice;
  /** The Sales Copy used in the Product */
  salesCopy: Scalars['JSON']['output'];
  /** Thank You Page */
  sitePage: Maybe<TemplatePages>;
};

/** The connection type for OneTimeOffer. */
export type OneTimeOfferConnection = {
  __typename?: 'OneTimeOfferConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<OneTimeOfferEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<OneTimeOffer>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type OneTimeOfferEdge = {
  __typename?: 'OneTimeOfferEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<OneTimeOffer>;
};

/** Possible operations */
export enum Operator {
  /** Equal to */
  Eq = 'EQ',
  /** Greater than */
  Gt = 'GT',
  /** Greater than or equal to */
  Gte = 'GTE',
  /** Lesser than */
  Lt = 'LT',
  /** Lesser than or equal to */
  Lte = 'LTE',
}

/** Option input arguments */
export type OptionsInput = {
  /** discussion enable option */
  discussionsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** expand enabled option */
  expandEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Order information */
export type Order = Node & {
  __typename?: 'Order';
  id: Scalars['ID']['output'];
  /** After purchase flow id */
  status: Maybe<StatusEnum>;
};

/** Order bump for a product */
export type OrderBump = Node & {
  __typename?: 'OrderBump';
  /** Number of products that have the option to sell this order bump */
  activeOn: Maybe<Scalars['Int']['output']>;
  /** Price in cents */
  compareAtPriceInCents: Maybe<Scalars['Int']['output']>;
  /** Order bump created at */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** Selected currency */
  currency: Maybe<Scalars['String']['output']>;
  /** Order bump description */
  description: Maybe<Scalars['String']['output']>;
  /** Display price */
  displayCompareAtPrice: Maybe<Scalars['Float']['output']>;
  /** Order bump display price */
  displayPrice: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  /** Order bump name */
  name: Maybe<Scalars['String']['output']>;
  /** Order bump Id */
  orderBumpId: Maybe<Scalars['Int']['output']>;
  /** Order bump product */
  product: Maybe<Product>;
  /** Order bump product price */
  productPrice: Maybe<ProductPrice>;
  /** Products where this order bump is available */
  soldByProducts: Maybe<Array<Maybe<Product>>>;
  /** Order bump updated at */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** The connection type for OrderBump. */
export type OrderBumpConnection = {
  __typename?: 'OrderBumpConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<OrderBumpEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<OrderBump>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type OrderBumpEdge = {
  __typename?: 'OrderBumpEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<OrderBump>;
};

/** Possible fields to filter order bumps */
export type OrderBumpFilter = {
  /** Search by id */
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Possible fields to sort order bumps */
export type OrderBumpOrderBy = {
  direction: OrderDirection;
  field: OrderBumpSortField;
};

/** Sortable fields for order bumps */
export enum OrderBumpSortField {
  /** Sort by created at */
  CreatedAt = 'CREATED_AT',
  /** Sort by name */
  Name = 'NAME',
  /** Sort by price */
  PriceInCents = 'PRICE_IN_CENTS',
}

/** Order for users */
export type OrderBy = {
  direction: OrderDirection;
  field: UserOrderField;
};

/** Possible directions in which to order a list of items when provided an orderBy argument */
export enum OrderDirection {
  /** Ascending order for a given orderBy argument */
  Asc = 'ASC',
  /** Descending order for a given orderBy argument */
  Desc = 'DESC',
}

/** Possible fields to order user bundles */
export enum OrderField {
  /** Order user bundles by bundle_name */
  BundleName = 'BUNDLE_NAME',
  /** Order user bundles by created_at */
  CreatedAt = 'CREATED_AT',
  /** Order user bundles by expiry_date */
  ExpiryDate = 'EXPIRY_DATE',
  /** Order user bundles by updated_at */
  UpdatedAt = 'UPDATED_AT',
}

/** Possible fields to order lessons */
export enum OrderFieldFilter {
  /** order lessons by the number of completed user lessons it has */
  CompletedUserContentsCount = 'completedUserContentsCount',
  /** order lessons by name of course it belongs to */
  CourseName = 'courseName',
  /** order lessons by the date of the last completed user content */
  LastCompletedUserContentDate = 'lastCompletedUserContentDate',
  /** order lessons by name */
  Name = 'name',
  /** order lessons by type */
  Type = 'type',
}

/** OrderTransaction information */
export type OrderTransaction = Node & {
  __typename?: 'OrderTransaction';
  action: Maybe<Scalars['String']['output']>;
  amount: Maybe<Scalars['Float']['output']>;
  amountInDollars: Maybe<Scalars['Float']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  cumulativeOrderValue: Maybe<Scalars['Float']['output']>;
  currencySymbol: Maybe<Scalars['String']['output']>;
  fee: Maybe<Scalars['Float']['output']>;
  formatCurrencyAmount: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  nonRefundableReason: Maybe<Scalars['String']['output']>;
  orderBumpProductName: Maybe<Scalars['String']['output']>;
  orderCurrency: Maybe<Scalars['String']['output']>;
  orderEmail: Maybe<Scalars['EmailAddress']['output']>;
  orderFirstName: Maybe<Scalars['String']['output']>;
  orderId: Maybe<Scalars['ID']['output']>;
  orderLastName: Maybe<Scalars['String']['output']>;
  orderNumber: Maybe<Scalars['String']['output']>;
  orderPaymentMethod: Maybe<Scalars['String']['output']>;
  orderProductName: Maybe<Scalars['String']['output']>;
  orderReferralName: Maybe<Scalars['String']['output']>;
  orderStatus: Maybe<Scalars['String']['output']>;
  orderTransactionId: Maybe<Scalars['ID']['output']>;
  paymentMethodDetails: Maybe<Scalars['String']['output']>;
  paymentProvider: Maybe<Scalars['String']['output']>;
  paymentType: Maybe<Scalars['String']['output']>;
  refundable: Maybe<Scalars['Boolean']['output']>;
  refundedAmount: Maybe<Scalars['Int']['output']>;
  success: Maybe<Scalars['String']['output']>;
};

/** The connection type for OrderTransaction. */
export type OrderTransactionConnection = {
  __typename?: 'OrderTransactionConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<OrderTransactionEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<OrderTransaction>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** Represents an Order Transaction Detail (a thinkific payments transaction with all details) */
export type OrderTransactionDetail = Node & {
  __typename?: 'OrderTransactionDetail';
  /** Referral user (affiliate) */
  affiliate: Maybe<Scalars['String']['output']>;
  /** Amount paid through this transaction */
  amount: Maybe<Scalars['Int']['output']>;
  /** User's card info */
  card: Maybe<Card>;
  /** User's country info */
  country: Maybe<Country>;
  /** Coupon info */
  coupon: Maybe<TransactionCoupon>;
  /** Currency used in this transaction */
  currency: Maybe<Currency>;
  /** Failure description */
  failureReason: Maybe<Scalars['String']['output']>;
  /** Fee amount charged in this transaction */
  fee: Maybe<Scalars['Float']['output']>;
  /** Currency amount already formatted */
  formatCurrencyAmount: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Invoice ID from provider */
  invoiceId: Maybe<Scalars['ID']['output']>;
  /** Net amount (Amount - Discount - Fee) */
  netAmount: Maybe<Scalars['Float']['output']>;
  /** Explains why a refund cannot be issued */
  nonRefundableReason: Maybe<Scalars['String']['output']>;
  /** Array containing all product names used as order bumps in the original order */
  orderBumps: Maybe<Array<Scalars['String']['output']>>;
  /** Original order number (will be repeated for recurring payments) */
  orderNumber: Maybe<Scalars['Int']['output']>;
  /** Original order transaction id */
  orderTransactionId: Scalars['ID']['output'];
  /** Payment method used in this transaction */
  paymentMethod: Maybe<Scalars['String']['output']>;
  /** Transaction pricing types (subset of standard price types) */
  priceType: Maybe<TransactionPricing>;
  /** Price label */
  pricingLabel: Maybe<Scalars['String']['output']>;
  /** Main product description */
  productName: Maybe<Scalars['String']['output']>;
  /** Charge ID from provider */
  providerChargeId: Maybe<Scalars['ID']['output']>;
  /** Reason for a refund, if this transaction is a refund (partial or full) */
  refundReason: Maybe<RefundReason>;
  /** If the transaction is refundable */
  refundable: Scalars['Boolean']['output'];
  /** Refunded amount */
  refundedAmount: Maybe<Scalars['Int']['output']>;
  /** Tax amount collected by Thinkific */
  salesTax: Maybe<Scalars['Float']['output']>;
  /** Subtotal amount (Amount - Sales Tax) */
  subtotal: Maybe<Scalars['Float']['output']>;
  /** Date/Time when this transaction was created by current provider */
  transactionDate: Maybe<Scalars['DateTime']['output']>;
  /** Transaction final status */
  transactionStatus: TransactionStatus;
  /** Transaction type */
  transactionType: Maybe<Transaction>;
  /** User info */
  user: Maybe<TransactionUser>;
};

/** The connection type for OrderTransactionDetail. */
export type OrderTransactionDetailConnection = {
  __typename?: 'OrderTransactionDetailConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<OrderTransactionDetailEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<OrderTransactionDetail>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type OrderTransactionDetailEdge = {
  __typename?: 'OrderTransactionDetailEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<OrderTransactionDetail>;
};

/** OrderTransactionDetail searchable fields */
export type OrderTransactionDetailFilter = {
  /** Search by string query */
  query?: InputMaybe<Scalars['String']['input']>;
  /** Search by created at */
  transactionDate?: InputMaybe<DateFilter>;
};

/** Represents an Order Transaction Detail Item (a thinkific payments transaction with all details) */
export type OrderTransactionDetailItem = {
  __typename?: 'OrderTransactionDetailItem';
  /** Total amount */
  amount: Maybe<Scalars['Float']['output']>;
  /** User's card info */
  card: Maybe<Card>;
  /** User's country info */
  country: Maybe<Country>;
  /** Currency used in this transaction */
  currency: Maybe<Currency>;
  /** Fee amount charged in this transaction */
  fee: Maybe<Scalars['Float']['output']>;
  /** Transaction fee breakdown */
  feeBreakdown: TransactionFeeBreakdownConnection;
  id: Scalars['ID']['output'];
  /** Net amount (Amount - Discount - Fee) */
  netAmount: Maybe<Scalars['Float']['output']>;
  /** Original order id */
  orderId: Maybe<Scalars['ID']['output']>;
  /** Original order number (will be repeated for recurring payments) */
  orderNumber: Maybe<Scalars['Int']['output']>;
  /** Payment method used in this transaction */
  paymentMethod: Maybe<Scalars['String']['output']>;
  /** Transaction pricing types (subset of standard price types) */
  priceType: Maybe<TransactionPricing>;
  /** Tax amount collected by Thinkific */
  salesTax: Maybe<Scalars['Float']['output']>;
  /** Subtotal amount (Amount - Sales Tax) */
  subtotal: Maybe<Scalars['Float']['output']>;
  /** List of tax breakdowns related to the transaction */
  taxBreakdown: Maybe<Array<TaxBreakdownItem>>;
  /** Date/Time when this transaction was created by current provider */
  transactionDate: Maybe<Scalars['DateTime']['output']>;
  /** Transaction final status */
  transactionStatus: TransactionStatus;
  /** Transaction type */
  transactionType: Maybe<Transaction>;
  /** User info */
  user: Maybe<TransactionUser>;
};

/** Represents an Order Transaction Detail Item (a thinkific payments transaction with all details) */
export type OrderTransactionDetailItemFeeBreakdownArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** OrderTransactionDetailItem fields */
export type OrderTransactionDetailItemFilter = {
  /** Get by ID */
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** OrderTransactionDetail sort input */
export type OrderTransactionDetailOrderBy = {
  direction?: InputMaybe<OrderDirection>;
  field?: InputMaybe<OrderTransactionDetailOrderField>;
};

/** Possible fields to order transaction details */
export enum OrderTransactionDetailOrderField {
  /** Order transaction detail by affiliate */
  Affiliate = 'AFFILIATE',
  /** Order transaction detail by amount */
  Amount = 'AMOUNT',
  /** Order transaction detail by card_expiry_date */
  CardExpiryDate = 'CARD_EXPIRY_DATE',
  /** Order transaction detail by card_last4_digits */
  CardLast4Digits = 'CARD_LAST4_DIGITS',
  /** Order transaction detail by card_type */
  CardType = 'CARD_TYPE',
  /** Order transaction detail by country_code */
  CountryCode = 'COUNTRY_CODE',
  /** Order transaction detail by coupon_code */
  CouponCode = 'COUPON_CODE',
  /** Order transaction detail by coupon_discount */
  CouponDiscount = 'COUPON_DISCOUNT',
  /** Order transaction detail by coupon_name */
  CouponName = 'COUPON_NAME',
  /** Order transaction detail by currency */
  Currency = 'CURRENCY',
  /** Order transaction detail by email */
  Email = 'EMAIL',
  /** Order transaction detail by failure_reason */
  FailureReason = 'FAILURE_REASON',
  /** Order transaction detail by fee */
  Fee = 'FEE',
  /** Order transaction detail by format_currency_amount */
  FormatCurrencyAmount = 'FORMAT_CURRENCY_AMOUNT',
  /** Order transaction detail by full_name */
  FullName = 'FULL_NAME',
  /** Order transaction detail by id */
  Id = 'ID',
  /** Order transaction detail by invoice_id */
  InvoiceId = 'INVOICE_ID',
  /** Order transaction detail by net_amount */
  NetAmount = 'NET_AMOUNT',
  /** Order transaction detail by order_bumps */
  OrderBumps = 'ORDER_BUMPS',
  /** Order transaction detail by order_id */
  OrderId = 'ORDER_ID',
  /** Order transaction detail by order_number */
  OrderNumber = 'ORDER_NUMBER',
  /** Order transaction detail by order_transaction_id */
  OrderTransactionId = 'ORDER_TRANSACTION_ID',
  /** Order transaction detail by payment_method */
  PaymentMethod = 'PAYMENT_METHOD',
  /** Order transaction detail by payment_provider */
  PaymentProvider = 'PAYMENT_PROVIDER',
  /** Order transaction detail by price_label */
  PriceLabel = 'PRICE_LABEL',
  /** Order transaction detail by price_type */
  PriceType = 'PRICE_TYPE',
  /** Order transaction detail by product_name */
  ProductName = 'PRODUCT_NAME',
  /** Order transaction detail by provider_charge_id */
  ProviderChargeId = 'PROVIDER_CHARGE_ID',
  /** Order transaction detail by refunded_amount */
  RefundedAmount = 'REFUNDED_AMOUNT',
  /** Order transaction detail by refund_reason */
  RefundReason = 'REFUND_REASON',
  /** Order transaction detail by sales_tax */
  SalesTax = 'SALES_TAX',
  /** Order transaction detail by subtotal */
  Subtotal = 'SUBTOTAL',
  /** Order transaction detail by tenant_id */
  TenantId = 'TENANT_ID',
  /** Order transaction detail by transaction_date */
  TransactionDate = 'TRANSACTION_DATE',
  /** Order transaction detail by transaction_status */
  TransactionStatus = 'TRANSACTION_STATUS',
  /** Order transaction detail by transaction_type */
  TransactionType = 'TRANSACTION_TYPE',
  /** Order transaction detail by user_id */
  UserId = 'USER_ID',
  /** Order transaction detail by user_region */
  UserRegion = 'USER_REGION',
  /** Order transaction detail by zip_code */
  ZipCode = 'ZIP_CODE',
}

/** An edge in a connection. */
export type OrderTransactionEdge = {
  __typename?: 'OrderTransactionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<OrderTransaction>;
};

/** Possible fields to filter order transactions */
export type OrderTransactionFilter = {
  /** Search by created at */
  createdAt?: InputMaybe<DateFilter>;
  /** Search by email */
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  /** Search by name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Search by graphql_name or email */
  nameOrEmail?: InputMaybe<Scalars['String']['input']>;
  /** Search by payment provider */
  paymentProvider?: InputMaybe<PaymentProvider>;
  /** Search by order transaction successful status */
  successful?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Possible fields to order transactions */
export type OrderTransactionOrderBy = {
  direction?: InputMaybe<OrderDirection>;
  field?: InputMaybe<OrderTransactionOrderField>;
};

/** Possible fields to order transactions */
export enum OrderTransactionOrderField {
  /** Order transactions by amount_in_dollars */
  AmountInDollars = 'AMOUNT_IN_DOLLARS',
  /** Order transactions by application_fee */
  ApplicationFee = 'APPLICATION_FEE',
  /** Order transactions by created_at */
  CreatedAt = 'CREATED_AT',
  /** Order transactions by cumulative_order_value */
  CumulativeOrderValue = 'CUMULATIVE_ORDER_VALUE',
  /** Order transactions by email */
  Email = 'EMAIL',
  /** Order transactions by first_name */
  FirstName = 'FIRST_NAME',
  /** Order transactions by last_name */
  LastName = 'LAST_NAME',
  /** Order transactions by order_number */
  OrderNumber = 'ORDER_NUMBER',
  /** Order transactions by order_referrer */
  OrderReferrer = 'ORDER_REFERRER',
  /** Order transactions by payment_method */
  PaymentMethod = 'PAYMENT_METHOD',
  /** Order transactions by payment_provider */
  PaymentProvider = 'PAYMENT_PROVIDER',
  /** Order transactions by payment_type */
  PaymentType = 'PAYMENT_TYPE',
  /** Order transactions by product_name */
  ProductName = 'PRODUCT_NAME',
  /** Order transactions by status */
  Status = 'STATUS',
  /** Order transactions by updated_at */
  UpdatedAt = 'UPDATED_AT',
}

/** Possible fields to filter OrganizationUserUser */
export type OrganizationUserUserFilter = {
  /** Search by connection token */
  connectionToken?: InputMaybe<Scalars['String']['input']>;
  /** Search by item ID */
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** Information about the Owner of a Site */
export type Owner = UserInterface & {
  __typename?: 'Owner';
  /** User's amount spent */
  amountSpent: Maybe<Scalars['Float']['output']>;
  /**
   * User's avatar url
   * @deprecated Use profile field instead
   */
  avatarUrl: Maybe<Scalars['String']['output']>;
  /** User's billing country */
  billingCountry: Maybe<Scalars['String']['output']>;
  /** User's billing postal code */
  billingPostalCode: Maybe<Scalars['String']['output']>;
  /** User's billing region */
  billingRegion: Maybe<Scalars['String']['output']>;
  /** User's ability to access sidekiq */
  canAccessSidekiq: Maybe<Scalars['Boolean']['output']>;
  /**
   * User's company
   * @deprecated Use profile field instead
   */
  company: Maybe<Scalars['String']['output']>;
  /** User's created date */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** User's custom profile field */
  customProfileFields: Maybe<Array<Maybe<CustomField>>>;
  /** User's default payment card */
  defaultPaymentCard: Maybe<DefaultPaymentCard>;
  /** User's email */
  email: Maybe<Scalars['String']['output']>;
  /** A list of user's enrollments */
  enrollments: Maybe<EnrollmentConnection>;
  /** User's external source */
  externalSource: Maybe<Scalars['String']['output']>;
  /** User's first name */
  firstName: Maybe<Scalars['String']['output']>;
  /** User's full name */
  fullName: Maybe<Scalars['String']['output']>;
  /** A list of groups that user belongs */
  groups: Maybe<GroupConnection>;
  /** Whether this user has Payments tab enabled on User profile page */
  hasPaymentsTab: Scalars['Boolean']['output'];
  /** Whether user has set a thinkific password */
  hasThinkificPassword: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  /** Most recent identity provider user authenticated with */
  identityProvider: Maybe<IdentityProvider>;
  /** Users's IP address */
  ipAddress: Maybe<Scalars['String']['output']>;
  /** Whether the user is an admin */
  isAdmin: Maybe<Scalars['Boolean']['output']>;
  /** Whether the user is an unlimited admin ie. owner, site admin or partner */
  isUnlimitedAdmin: Maybe<Scalars['Boolean']['output']>;
  /** User's landing page */
  landingPage: Maybe<Scalars['String']['output']>;
  /** User's last name */
  lastName: Maybe<Scalars['String']['output']>;
  /** User's last sign in */
  lastSignIn: Maybe<Scalars['DateTime']['output']>;
  /** User's specified locale setting */
  locale: Maybe<Scalars['String']['output']>;
  /** MFA settings for user */
  mfaSettings: Maybe<MfaSettings>;
  /** User's total number of enrollments */
  numberOfEnrollments: Maybe<Scalars['Int']['output']>;
  /** User's sign in date for previous session */
  previousSignIn: Maybe<Scalars['DateTime']['output']>;
  /** User's profile */
  profile: Maybe<Profile>;
  /** A list of the user's quiz and survey responses */
  quizSurveyResponses: Maybe<UserQuizSurveyResponsesConnection>;
  /** User's subscriptions and payment plans */
  recurringPayments: Maybe<Array<RecurringPayment>>;
  /** Affiliate who has referred user */
  referredBy: Maybe<Affiliate>;
  /** User's referred from url */
  referredFrom: Maybe<Scalars['String']['output']>;
  /** User's roles */
  roles: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** User's sign in count */
  signInCount: Maybe<Scalars['Int']['output']>;
  /** User's updated date */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  /** A list of user's bundles */
  userBundles: Maybe<Bundle_EnrollmentConnection>;
};

/** Information about the Owner of a Site */
export type OwnerDefaultPaymentCardArgs = {
  cartToken?: InputMaybe<Scalars['String']['input']>;
};

/** Information about the Owner of a Site */
export type OwnerEnrollmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserEnrollmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<EnrollmentOrderBy>;
};

/** Information about the Owner of a Site */
export type OwnerGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Information about the Owner of a Site */
export type OwnerQuizSurveyResponsesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ReportingOrderBy>;
  pagination: Pagination;
};

/** Information about the Owner of a Site */
export type OwnerUserBundlesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserBundlesOrderBy>;
};

/** Possible fields to filter pages and products */
export type PageAndProductFilter = {
  /** Search by page and product name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve installed theme by the theme id */
  themeId?: InputMaybe<Scalars['String']['input']>;
};

/** Possible page scope grouping keys */
export enum PageGroupKeyEnum {
  /** all pages that allow site scripts */
  All = 'all',
  /** checkout-related pages that allow site scripts */
  Checkout = 'checkout',
  /** individual pages specified to allow site scripts */
  IndividualPages = 'individual_pages',
  /** landing pages that allow site scripts */
  LandingPages = 'landing_pages',
  /** pages that drive student learning experiences that allow site scripts */
  LearningExperience = 'learning_experience',
}

/** Grouping of pages that a site script can be injected onto */
export type PageGrouping = {
  __typename?: 'PageGrouping';
  /** Identifier for grouping */
  key: Maybe<PageGroupKeyEnum>;
  /** User-friendly name for grouping */
  title: Maybe<Scalars['String']['output']>;
};

/** Pages that a site script can be injected onto */
export type PageGroupingScope = {
  __typename?: 'PageGroupingScope';
  /** Identifier for page */
  key: Maybe<PageScopeKeyEnum>;
  /** User-friendly name for page */
  title: Maybe<Scalars['String']['output']>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** Page scope information for a site script */
export type PageScope = {
  __typename?: 'PageScope';
  /** Domains that the site script can be injected onto */
  grouping: Maybe<PageGrouping>;
  /** Pages that the site script can be injected onto */
  scopes: Maybe<Array<Maybe<PageGroupingScope>>>;
};

/** Possible page scope grouping keys */
export enum PageScopeKeyEnum {
  BundleLandingPage = 'bundle_landing_page',
  CheckoutThankyouPage = 'checkout_thankyou_page',
  CollectionsLandingPage = 'collections_landing_page',
  ComingSoonPage = 'coming_soon_page',
  CourseLandingPage = 'course_landing_page',
  CoursePlayer = 'course_player',
  HomeLandingPage = 'home_landing_page',
  PageTemplate = 'page_template',
  StudentDashboard = 'student_dashboard',
}

/** Possible page statuses */
export enum PageStatusEnum {
  /** Page is draft */
  Draft = 'DRAFT',
  /** Page is published */
  Published = 'PUBLISHED',
}

/** Possible page types */
export enum PageTypeEnum {
  /** Bundle page */
  Bundle = 'bundle',
  /** Course page */
  Course = 'course',
  /** Custom page */
  Custom = 'custom',
  /** Default page */
  Default = 'default',
  /** Thank you page */
  ThankYou = 'thank_you',
}

/** Pagination option for Elasticsearch */
export type Pagination = {
  /** page number */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** page limit */
  per?: InputMaybe<Scalars['Int']['input']>;
};

/** Partners feature */
export type PartnersFeature = FeatureInterface & {
  __typename?: 'PartnersFeature';
  /** Feature checkout path */
  checkoutPath: Maybe<Scalars['String']['output']>;
  /** Flag to indicate feature is enabled */
  isEnabled: Maybe<Scalars['Boolean']['output']>;
  /** Maximum number of partners */
  maxPartners: Scalars['Int']['output'];
  /** The name of the minimum plan required to access this feature */
  minimumPlanName: Maybe<Scalars['String']['output']>;
};

/** Tenant password complexity settings */
export type PasswordComplexitySettings = {
  __typename?: 'PasswordComplexitySettings';
  /** Alphanumeric password required */
  alphanumericPasswordRequired: Maybe<Scalars['Boolean']['output']>;
  /** Minimum password length setting */
  minPasswordLength: Maybe<Scalars['Int']['output']>;
  /** Mixed case password required */
  mixedCasePasswordRequired: Maybe<Scalars['Boolean']['output']>;
  /** Non alphanumeric password required */
  nonAlphanumericPasswordRequired: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of PasswordRecovery */
export type PasswordRecoveryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The email address of the user */
  email: Scalars['String']['input'];
  /** Google recaptcha value */
  recaptchaValue?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of PasswordRecovery */
export type PasswordRecoveryPayload = {
  __typename?: 'PasswordRecoveryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The email where the link is sent */
  email: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of PauseRecurringPayment */
export type PauseRecurringPaymentInput = {
  /** Paused behavior value */
  behavior: PausedBehavior;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Subscription or Payment plan Id */
  id: Scalars['ID']['input'];
  /** Paused until value */
  pausedUntil?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated return type of PauseRecurringPayment */
export type PauseRecurringPaymentPayload = {
  __typename?: 'PauseRecurringPaymentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Response data */
  pausedPaymentCollection: Maybe<PausedPaymentCollection>;
  userErrors: Maybe<Array<UserError>>;
};

/** Possible paused behavior fields */
export enum PausedBehavior {
  /** allow access */
  AllowAccess = 'allow_access',
  /** block access */
  BlockAccess = 'block_access',
}

/** Represents a subscription paused status */
export type PausedPaymentCollection = {
  __typename?: 'PausedPaymentCollection';
  /** Paused at date */
  createdAt: Scalars['DateTime']['output'];
  /** Paused behavior value */
  pausedBehavior: PausedBehavior;
  /** Payments (subscription or payment plan) will be paused until this date */
  pausedUntil: Maybe<Scalars['DateTime']['output']>;
};

/** Revenue Partners fields */
export type Payee = Node &
  UserInterface & {
    __typename?: 'Payee';
    /** User's amount spent */
    amountSpent: Maybe<Scalars['Float']['output']>;
    /**
     * User's avatar url
     * @deprecated Use profile field instead
     */
    avatarUrl: Maybe<Scalars['String']['output']>;
    /** User's billing country */
    billingCountry: Maybe<Scalars['String']['output']>;
    /** User's billing postal code */
    billingPostalCode: Maybe<Scalars['String']['output']>;
    /** User's billing region */
    billingRegion: Maybe<Scalars['String']['output']>;
    /** User's ability to access sidekiq */
    canAccessSidekiq: Maybe<Scalars['Boolean']['output']>;
    /**
     * User's company
     * @deprecated Use profile field instead
     */
    company: Maybe<Scalars['String']['output']>;
    /** User's created date */
    createdAt: Maybe<Scalars['DateTime']['output']>;
    /** User's custom profile field */
    customProfileFields: Maybe<Array<Maybe<CustomField>>>;
    /** User's default payment card */
    defaultPaymentCard: Maybe<DefaultPaymentCard>;
    /** User's email */
    email: Maybe<Scalars['String']['output']>;
    /** A list of user's enrollments */
    enrollments: Maybe<EnrollmentConnection>;
    /** User's external source */
    externalSource: Maybe<Scalars['String']['output']>;
    /** User's first name */
    firstName: Maybe<Scalars['String']['output']>;
    /** User's full name */
    fullName: Maybe<Scalars['String']['output']>;
    /** A list of groups that user belongs */
    groups: Maybe<GroupConnection>;
    /** Whether this user has Payments tab enabled on User profile page */
    hasPaymentsTab: Scalars['Boolean']['output'];
    /** Whether user has set a thinkific password */
    hasThinkificPassword: Maybe<Scalars['Boolean']['output']>;
    id: Scalars['ID']['output'];
    /** Most recent identity provider user authenticated with */
    identityProvider: Maybe<IdentityProvider>;
    /** Users's IP address */
    ipAddress: Maybe<Scalars['String']['output']>;
    /** Whether the user is an admin */
    isAdmin: Maybe<Scalars['Boolean']['output']>;
    /** Whether the user is an unlimited admin ie. owner, site admin or partner */
    isUnlimitedAdmin: Maybe<Scalars['Boolean']['output']>;
    /** User's landing page */
    landingPage: Maybe<Scalars['String']['output']>;
    /** User's last name */
    lastName: Maybe<Scalars['String']['output']>;
    /** User's last sign in */
    lastSignIn: Maybe<Scalars['DateTime']['output']>;
    /** User's specified locale setting */
    locale: Maybe<Scalars['String']['output']>;
    /** MFA settings for user */
    mfaSettings: Maybe<MfaSettings>;
    /** User's total number of enrollments */
    numberOfEnrollments: Maybe<Scalars['Int']['output']>;
    /** User's sign in date for previous session */
    previousSignIn: Maybe<Scalars['DateTime']['output']>;
    /** User's profile */
    profile: Maybe<Profile>;
    /** A list of the user's quiz and survey responses */
    quizSurveyResponses: Maybe<UserQuizSurveyResponsesConnection>;
    /** User's subscriptions and payment plans */
    recurringPayments: Maybe<Array<RecurringPayment>>;
    /** Affiliate who has referred user */
    referredBy: Maybe<Affiliate>;
    /** User's referred from url */
    referredFrom: Maybe<Scalars['String']['output']>;
    /** User's roles */
    roles: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** User's sign in count */
    signInCount: Maybe<Scalars['Int']['output']>;
    /** User's updated date */
    updatedAt: Maybe<Scalars['DateTime']['output']>;
    /** A list of user's bundles */
    userBundles: Maybe<Bundle_EnrollmentConnection>;
  };

/** Revenue Partners fields */
export type PayeeDefaultPaymentCardArgs = {
  cartToken?: InputMaybe<Scalars['String']['input']>;
};

/** Revenue Partners fields */
export type PayeeEnrollmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserEnrollmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<EnrollmentOrderBy>;
};

/** Revenue Partners fields */
export type PayeeGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Revenue Partners fields */
export type PayeeQuizSurveyResponsesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ReportingOrderBy>;
  pagination: Pagination;
};

/** Revenue Partners fields */
export type PayeeUserBundlesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserBundlesOrderBy>;
};

/** The connection type for Payee. */
export type PayeeConnection = {
  __typename?: 'PayeeConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PayeeEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Payee>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type PayeeEdge = {
  __typename?: 'PayeeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Payee>;
};

/** Payment Intent object returned from Stripe once a customer initiates the checkout process */
export type PaymentIntent = Node & {
  __typename?: 'PaymentIntent';
  /** Client secret of a payment intent */
  clientSecret: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Payment intents status code */
  status: Maybe<Scalars['String']['output']>;
};

/** Supported payment providers */
export enum PaymentProvider {
  /** PayPal */
  Paypal = 'PAYPAL',
  /** PayPal Smart */
  PaypalSmart = 'PAYPAL_SMART',
  /** Stripe standard integration */
  Stripe = 'STRIPE',
  /** Thinkific Payments */
  ThinkificPayments = 'THINKIFIC_PAYMENTS',
}

/** Possible payment types for products */
export enum PaymentType {
  /** Free */
  Free = 'free',
  /** One-time payment */
  OneTime = 'one_time',
  /** Monthly payment plan */
  PaymentPlan = 'payment_plan',
  /** Subscription / Membership */
  Subscription = 'subscription',
}

/** Payout object */
export type Payout = {
  __typename?: 'Payout';
  account: Maybe<Account>;
  date: Maybe<Scalars['Int']['output']>;
  destination: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['String']['output']>;
  money: Maybe<Money>;
  status: Maybe<Scalars['String']['output']>;
};

/** Details on when funds from payments are available, and when they are paid out to an external account. */
export type PayoutSchedule = {
  __typename?: 'PayoutSchedule';
  delayDays: Maybe<Scalars['Int']['output']>;
  interval: Maybe<Scalars['String']['output']>;
  monthlyAnchor: Maybe<Scalars['Int']['output']>;
  weeklyAnchor: Maybe<Scalars['String']['output']>;
};

/** List of payouts */
export type Payouts = {
  __typename?: 'Payouts';
  data: Maybe<Array<Maybe<Payout>>>;
  hasMore: Maybe<Scalars['Boolean']['output']>;
};

/** Count of payouts based on date range */
export type PayoutsCount = {
  __typename?: 'PayoutsCount';
  /** Returns count of payouts */
  totalCount: Maybe<Scalars['Int']['output']>;
};

/** Fields to filter Payouts */
export type PayoutsCountFilter = {
  /** Date used as the end date */
  endDate?: InputMaybe<Scalars['String']['input']>;
  /** Date used as the start date */
  startDate?: InputMaybe<Scalars['String']['input']>;
};

/** Possible fields to filter Payouts */
export type PayoutsFilter = {
  /** Number of payouts to display */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** A cursor for use in pagination */
  startingAfter?: InputMaybe<Scalars['String']['input']>;
};

/** Tenant Paypal feature */
export type PaypalFeature = {
  __typename?: 'PaypalFeature';
  /** Paypal client id */
  clientId: Maybe<Scalars['String']['output']>;
  /** Flag to indicate feature is configured */
  isConfigured: Maybe<Scalars['Boolean']['output']>;
  /** Flag to indicate feature is enabled */
  isEnabled: Maybe<Scalars['Boolean']['output']>;
  /** Tenant's merchant id */
  merchantId: Maybe<Scalars['String']['output']>;
};

/** pdf lesson information */
export type PdfLesson = LessonInterface &
  Node & {
    __typename?: 'PdfLesson';
    /** Content's chapter */
    chapter: Chapter;
    /** contentable id */
    contentableId: Scalars['ID']['output'];
    /** Content's course */
    course: Course;
    /** Content's created at date */
    createdAt: Scalars['DateTime']['output'];
    /** Lesson default icon */
    defaultLessonTypeIcon: Scalars['String']['output'];
    /** Is discussion enabled */
    discussionsEnabled: Maybe<Scalars['Boolean']['output']>;
    /** Pdf downloadable */
    downloadable: Maybe<Scalars['Boolean']['output']>;
    /** Lesson draft status */
    draft: Scalars['Boolean']['output'];
    /** Lesson's associated Drip Schedule Item */
    dripScheduleItem: Maybe<DripScheduleItem>;
    /** lesson drip status */
    dripStatus: Maybe<Scalars['JSON']['output']>;
    /** Is lesson free */
    free: Maybe<Scalars['Boolean']['output']>;
    /** Lesson html text */
    htmlDescription: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    /** Current user lesson status */
    lessonCompleted: Maybe<Scalars['Boolean']['output']>;
    /** lesson drip schedule */
    lessonHasDripSchedule: Maybe<Scalars['String']['output']>;
    /** Lesson integer ID */
    lessonId: Scalars['ID']['output'];
    /** Lesson icon */
    lessonTypeIcon: Maybe<LessonIconEnum>;
    /** Lesson label */
    lessonTypeLabel: Maybe<Scalars['String']['output']>;
    /** Lesson url */
    lessonUrl: Maybe<Scalars['String']['output']>;
    /** Lesson name */
    name: Scalars['String']['output'];
    /** Lesson position */
    position: Scalars['Int']['output'];
    /** Is lesson prerequisite */
    prerequisite: Scalars['Boolean']['output'];
    /** Lesson's slug */
    slug: Maybe<Scalars['String']['output']>;
    /** Lesson Type */
    type: Scalars['String']['output'];
    /** Whether the lesson is still under construction */
    underConstruction: Scalars['Boolean']['output'];
    /** Content's updated at date */
    updatedAt: Scalars['DateTime']['output'];
    /** Pdf's url */
    url: Maybe<Scalars['String']['output']>;
    /** User content for lesson */
    userContents: Maybe<UserContentConnection>;
    /** User quizzes for lesson */
    userQuizzes: Maybe<UserQuizConnection>;
    /** User surveys for lesson */
    userSurveys: Maybe<UserSurveyConnection>;
  };

/** pdf lesson information */
export type PdfLessonUserContentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserContentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserContentOrderByFilter>;
};

/** pdf lesson information */
export type PdfLessonUserQuizzesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserQuizFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserQuizOrderBy>;
};

/** pdf lesson information */
export type PdfLessonUserSurveysArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserSurveyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserSurveyOrderBy>;
};

/** Attributes for permitted sso domain settings */
export type PermittedSsoDomainsInput = {
  /** Permitted sso domains */
  permittedSsoDomains?: InputMaybe<Scalars['String']['input']>;
};

/** Personal contact information */
export type PersonalContactInformation = {
  __typename?: 'PersonalContactInformation';
  /** Email address for App Store Support */
  emailAddress: Maybe<Scalars['String']['output']>;
  /** First name for App Store Support */
  firstName: Maybe<Scalars['String']['output']>;
  /** Last name for App Store Support */
  lastName: Maybe<Scalars['String']['output']>;
  /** Contact phone number for App Store Support */
  phoneNumber: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of PinPost */
export type PinPostInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the post */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of PinPost */
export type PinPostPayload = {
  __typename?: 'PinPostPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The post */
  post: Maybe<Post>;
  userErrors: Maybe<Array<UserError>>;
};

/** Thinkific Plan data */
export type Plan = {
  __typename?: 'Plan';
  /** Feature set marketable for this plan */
  marketableFeatures: Maybe<Array<Scalars['String']['output']>>;
  /** Name of plan */
  name: Scalars['String']['output'];
  /** Name of plan below current plan */
  onePlanLowerName: Scalars['String']['output'];
  /** Pricing options for the plan */
  prices: Maybe<Array<PlanPricing>>;
  /** Slug of plan */
  slug: Scalars['String']['output'];
};

/** Pricing information for a given Thinkific Plan */
export type PlanPricing = {
  __typename?: 'PlanPricing';
  /** Formatted price for plan at interval (ie. $49.00) */
  formattedPrice: Scalars['String']['output'];
  /** Interval the price gets charged on */
  interval: Scalars['String']['output'];
  /** Name of plan pricing */
  name: Scalars['String']['output'];
  /** Price for plan at interval */
  price: Scalars['Int']['output'];
};

/** Thinkific settings for a plan */
export type PlanSettings = {
  __typename?: 'PlanSettings';
  /** Info for settings of plan */
  info: Maybe<Scalars['String']['output']>;
  /** Text for settings of plan */
  text: Scalars['String']['output'];
};

/** Possible site plan types */
export enum PlanTypeEnum {
  /** Site plan represents one of the addon plans offered */
  Addon = 'addon',
  /** Site plan represents one of the main plans offered */
  Main = 'main',
}

/** Batches position updates for various course models */
export type PositionsInput = {
  /** The id of the model to update */
  id: Scalars['ID']['input'];
  /** The position */
  position?: InputMaybe<Scalars['Int']['input']>;
};

/** Post information */
export type Post = Node & {
  __typename?: 'Post';
  /** Whether the post is approved */
  approved: Maybe<Scalars['Boolean']['output']>;
  /** The community_space the post is attached to */
  communitySpace: Maybe<CommunitySpace>;
  /** The post content */
  content: Maybe<Scalars['String']['output']>;
  /** The content name */
  contentName: Maybe<Scalars['String']['output']>;
  /** The course name if discussionable is content */
  courseName: Maybe<Scalars['String']['output']>;
  /** When the post was created */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The discussionable id */
  discussionableId: Maybe<Scalars['Int']['output']>;
  /** The discussionable type */
  discussionableType: Maybe<Scalars['String']['output']>;
  /** Timestamp of the last time this post was edited */
  editedAt: Maybe<Scalars['DateTime']['output']>;
  /** Whether the user has reacted to the post */
  hasReacted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** Whether the user is following the post */
  isFollowing: Scalars['Boolean']['output'];
  /** Timestamp of the last time this post was interacted with */
  lastInteractionAt: Maybe<Scalars['DateTime']['output']>;
  /** Users mentioned in the post */
  mentionedUsers: Maybe<UserConnection>;
  /** When the post was pinned */
  pinnedAt: Maybe<Scalars['DateTime']['output']>;
  /** The post attachments */
  postAttachments: Maybe<Array<PostAttachment>>;
  /** The post id */
  postId: Scalars['ID']['output'];
  /** The reactions on the post */
  postReactions: Maybe<PostReactionConnection>;
  /** The replies on the post */
  replies: ReplyConnection;
  /** How many replies the post has */
  replyCount: Maybe<Scalars['Int']['output']>;
  /** The post title */
  title: Maybe<Scalars['String']['output']>;
  /** When the post was updated */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  /** The user that created the post */
  user: Maybe<User>;
};

/** Post information */
export type PostMentionedUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Post information */
export type PostPostReactionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Post information */
export type PostRepliesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ReplyOrderBy>;
};

/** An attachment on a post */
export type PostAttachment = Node & {
  __typename?: 'PostAttachment';
  /** The alt text */
  altText: Maybe<Scalars['String']['output']>;
  /** The type of attachment */
  attachmentType: Maybe<PostAttachmentType>;
  /** The file name */
  fileName: Maybe<Scalars['String']['output']>;
  /** The file size */
  fileSizeInBytes: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  /** The attachment url */
  url: Maybe<Scalars['String']['output']>;
};

/** Input fields for a post attachment */
export type PostAttachmentInput = {
  /** The optional alt text for a post attachment image */
  altText?: InputMaybe<Scalars['String']['input']>;
  attachmentType: PostAttachmentType;
  /** The url for the attachment (required for videos) */
  attachmentUrl?: InputMaybe<Scalars['String']['input']>;
  /** The file name of the uploaded file (required for images & files) */
  fileName?: InputMaybe<Scalars['String']['input']>;
  /** The file size of the uploaded file (in bytes) */
  fileSizeInBytes?: InputMaybe<Scalars['Int']['input']>;
  /** The Filestack ID for the uploaded file (required for images & files) */
  filestackId?: InputMaybe<Scalars['String']['input']>;
  /** The S3 bucket for the uploaded file (required for images & files) */
  s3Bucket?: InputMaybe<Scalars['String']['input']>;
  /** The S3 key for the uploaded file (required for images & files) */
  s3Key?: InputMaybe<Scalars['String']['input']>;
};

/** The type of the post attachment */
export enum PostAttachmentType {
  /** File attachment type */
  File = 'file',
  /** Image attachment type */
  Image = 'image',
  /** Video attachment type */
  Video = 'video',
}

/** The connection type for Post. */
export type PostConnection = {
  __typename?: 'PostConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PostEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Post>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of posts, comments, or replies */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type PostEdge = {
  __typename?: 'PostEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Post>;
};

/** Possible fields to filter posts */
export type PostFilter = {
  /** Filter by space */
  communitySpaceId?: InputMaybe<Scalars['ID']['input']>;
  /** Filter by posts the current user is following */
  following?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by trending posts */
  trending?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by user */
  userId?: InputMaybe<Scalars['ID']['input']>;
};

/** Possible fields to order posts */
export type PostOrderBy = {
  direction?: InputMaybe<OrderDirection>;
  field?: InputMaybe<PostOrderField>;
};

/** Possible fields to order posts */
export enum PostOrderField {
  /** Order posts by content_name */
  ContentName = 'CONTENT_NAME',
  /** Order posts by course_name */
  CourseName = 'COURSE_NAME',
  /** Order posts by created_at */
  CreatedAt = 'CREATED_AT',
  /** Order posts by last_interaction_at */
  LastInteractionAt = 'LAST_INTERACTION_AT',
  /** Order posts by pinned_at */
  PinnedAt = 'PINNED_AT',
  /** Order posts by reply_count */
  ReplyCount = 'REPLY_COUNT',
  /** Order posts by title */
  Title = 'TITLE',
  /** Order posts by trending_score */
  TrendingScore = 'TRENDING_SCORE',
  /** Order posts by updated_at */
  UpdatedAt = 'UPDATED_AT',
}

/** PostReaction information */
export type PostReaction = Node & {
  __typename?: 'PostReaction';
  id: Scalars['ID']['output'];
  /** The type of reaction */
  reactionType: Maybe<PostReactionType>;
  /** User's detail */
  user: Maybe<User>;
};

/** The connection type for PostReaction. */
export type PostReactionConnection = {
  __typename?: 'PostReactionConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PostReactionEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<PostReaction>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of post reactions */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type PostReactionEdge = {
  __typename?: 'PostReactionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<PostReaction>;
};

/** The type of the post reaction */
export enum PostReactionType {
  /** Like reaction type */
  Like = 'like',
}

/** Inputs for preauthorizing the user payment method */
export type PreauthorizePaymentMethod = {
  /** Tenant country code */
  countryCode: Scalars['String']['input'];
  /** Tenant mixpanel ID */
  mixpanelId?: InputMaybe<Scalars['String']['input']>;
  /** Onboarding Flow ID */
  onboardingFlowId: Scalars['ID']['input'];
  /** Google recaptcha value */
  recaptchaValue?: InputMaybe<Scalars['String']['input']>;
  /** Payment stripe token */
  stripeToken: Scalars['String']['input'];
  /** Tenant subdomain */
  subdomain?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of PreauthorizePaymentMethod */
export type PreauthorizePaymentMethodInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  input: PreauthorizePaymentMethod;
};

/** Payload returned after PreauthorizePaymentMethod mutation */
export type PreauthorizePaymentMethodType = Node & {
  __typename?: 'PreauthorizePaymentMethodType';
  id: Scalars['ID']['output'];
  /** Reason behind failure if payment method is not valid */
  reason: Maybe<Scalars['String']['output']>;
  /** Flag to determine if payment method is valid */
  valid: Scalars['Boolean']['output'];
};

/** presentation lesson information */
export type PresentationLesson = LessonInterface &
  Node & {
    __typename?: 'PresentationLesson';
    /** Content's chapter */
    chapter: Chapter;
    /** Presentation completion required */
    completionRequired: Maybe<Scalars['Boolean']['output']>;
    /** contentable id */
    contentableId: Scalars['ID']['output'];
    /** Content's course */
    course: Course;
    /** Content's created at date */
    createdAt: Scalars['DateTime']['output'];
    /** Lesson default icon */
    defaultLessonTypeIcon: Scalars['String']['output'];
    /** Is discussion enabled */
    discussionsEnabled: Maybe<Scalars['Boolean']['output']>;
    /** Presentation downloadable */
    downloadable: Maybe<Scalars['Boolean']['output']>;
    /** Lesson draft status */
    draft: Scalars['Boolean']['output'];
    /** Lesson's associated Drip Schedule Item */
    dripScheduleItem: Maybe<DripScheduleItem>;
    /** lesson drip status */
    dripStatus: Maybe<Scalars['JSON']['output']>;
    /** Is lesson free */
    free: Maybe<Scalars['Boolean']['output']>;
    /** Lesson html text */
    htmlDescription: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    /** Current user lesson status */
    lessonCompleted: Maybe<Scalars['Boolean']['output']>;
    /** lesson drip schedule */
    lessonHasDripSchedule: Maybe<Scalars['String']['output']>;
    /** Lesson integer ID */
    lessonId: Scalars['ID']['output'];
    /** Lesson icon */
    lessonTypeIcon: Maybe<LessonIconEnum>;
    /** Lesson label */
    lessonTypeLabel: Maybe<Scalars['String']['output']>;
    /** Lesson url */
    lessonUrl: Maybe<Scalars['String']['output']>;
    /** Lesson name */
    name: Scalars['String']['output'];
    /** Lesson position */
    position: Scalars['Int']['output'];
    /** Is lesson prerequisite */
    prerequisite: Scalars['Boolean']['output'];
    /** Presentation processing status (polling endpoint) */
    processingStatus: Maybe<PresentationStatusEnum>;
    /** Presentation size */
    size: Maybe<Scalars['String']['output']>;
    /** A list of the presentation lesson's slides */
    slides: Maybe<SlideConnection>;
    /** Lesson's slug */
    slug: Maybe<Scalars['String']['output']>;
    /** Lesson Type */
    type: Scalars['String']['output'];
    /** Whether the lesson is still under construction */
    underConstruction: Scalars['Boolean']['output'];
    /** Content's updated at date */
    updatedAt: Scalars['DateTime']['output'];
    /** User content for lesson */
    userContents: Maybe<UserContentConnection>;
    /** User quizzes for lesson */
    userQuizzes: Maybe<UserQuizConnection>;
    /** User surveys for lesson */
    userSurveys: Maybe<UserSurveyConnection>;
  };

/** presentation lesson information */
export type PresentationLessonSlidesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** presentation lesson information */
export type PresentationLessonUserContentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserContentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserContentOrderByFilter>;
};

/** presentation lesson information */
export type PresentationLessonUserQuizzesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserQuizFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserQuizOrderBy>;
};

/** presentation lesson information */
export type PresentationLessonUserSurveysArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserSurveyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserSurveyOrderBy>;
};

/** Input for presentation slides attached to a Lesson */
export type PresentationSlideInput = {
  /** Name of audio file */
  audioFileName?: InputMaybe<Scalars['String']['input']>;
  /** The length of the audio file */
  audioFileSeconds?: InputMaybe<Scalars['Int']['input']>;
  /** Url of audio file */
  audioFileUrl?: InputMaybe<Scalars['String']['input']>;
  /** Destroy the slide */
  destroy?: InputMaybe<Scalars['Boolean']['input']>;
  /** The presentation slide id */
  id: Scalars['ID']['input'];
  /** Name of image file */
  imageFileName?: InputMaybe<Scalars['String']['input']>;
  /** Url of image file */
  imageFileUrl?: InputMaybe<Scalars['String']['input']>;
};

/** The presentation processing status type */
export enum PresentationStatusEnum {
  /** Complete status type */
  Complete = 'complete',
  /** Error status type */
  Error = 'error',
  /** Processing status type */
  Processing = 'processing',
}

/** The pricing options for all purchasable currencies */
export type PriceOption = {
  __typename?: 'PriceOption';
  /** The amount, including the currency symbol */
  amountWithSymbol: Scalars['String']['output'];
};

/** The pricing options for all purchasable currencies */
export type PriceOptions = {
  __typename?: 'PriceOptions';
  /** The pricing option for EUR */
  eur: PriceOption;
  /** The pricing option for GBP */
  gbp: PriceOption;
  /** The pricing option for USD */
  usd: PriceOption;
};

/** PriceProduct information */
export type PriceProduct = Node &
  ProductInterface & {
    __typename?: 'PriceProduct';
    /** The name of the Accredible certificate template */
    accredibleCertificateTemplateName: Maybe<Scalars['String']['output']>;
    /** Welcome email sent when enrolling in a bundle */
    bundleWelcomeEmail: Maybe<BundleWelcomeEmail>;
    /** Indicates if the product can issue Accredible certificates */
    canIssueAccredibleCertificate: Maybe<Scalars['Boolean']['output']>;
    /** Indicates if the product can issue Thinkific Student Certificates */
    canIssueThinkificStudentCertificate: Maybe<Scalars['Boolean']['output']>;
    /** The community product relation */
    communityProductRelation: Maybe<CommunityProductRelation>;
    /** Email sent when completing a course */
    courseCompletionEmail: Maybe<CourseCompletionEmail>;
    /** Welcome email sent when enrolling in a course */
    courseEnrollmentEmail: Maybe<CourseEnrollmentEmail>;
    /** When product was created */
    createdAt: Maybe<Scalars['DateTime']['output']>;
    /** Product's description */
    description: Maybe<Scalars['String']['output']>;
    /** When product was published first time */
    firstPublishedAt: Maybe<Scalars['DateTime']['output']>;
    /** Whether the productable has a free preview */
    hasFreePreview: Maybe<Scalars['Boolean']['output']>;
    /** Whether the product has an image uploaded */
    hasUploadedImage: Maybe<Scalars['Boolean']['output']>;
    /** The Product's ID */
    id: Scalars['ID']['output'];
    /** Product's image */
    image: Maybe<Scalars['String']['output']>;
    /** Whether or not the product is hidden */
    isHidden: Maybe<Scalars['Boolean']['output']>;
    /** Whether or not the product is private */
    isPrivate: Maybe<Scalars['Boolean']['output']>;
    /** Product's Page footer code */
    landingPageLiquidMarkup: Maybe<Scalars['String']['output']>;
    /**
     * Product's name
     * @deprecated use productableName instead
     */
    name: Maybe<Scalars['String']['output']>;
    /** Number of lessons in the course */
    numberOfCourseLessons: Maybe<Scalars['Int']['output']>;
    /** Product's ordering position */
    position: Maybe<Scalars['Int']['output']>;
    /** Whether or not the product is private */
    private: Maybe<Scalars['Boolean']['output']>;
    /** Product's ID */
    productId: Maybe<Scalars['Int']['output']>;
    /** The associated productable */
    productable: Maybe<ProductableUnion>;
    /** Productable ID */
    productableId: Maybe<Scalars['String']['output']>;
    /** Product's name */
    productableName: Maybe<Scalars['String']['output']>;
    /** The product's published status */
    publishedStatus: Maybe<Scalars['String']['output']>;
    /** Product's slug */
    slug: Maybe<Scalars['String']['output']>;
    /** The product's status */
    status: Maybe<Scalars['String']['output']>;
    /** The product's type */
    type: Maybe<Scalars['String']['output']>;
  };

/** Possible product price types */
export enum PriceTypes {
  /** Free course */
  Free = 'Free',
  /** One time payment */
  OneTime = 'OneTime',
  /** Payment plan */
  PaymentPlan = 'PaymentPlan',
  /** Subscription plan */
  Subscription = 'Subscription',
}

/** Autogenerated input type of ProcessUserImport */
export type ProcessUserImportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the user import */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of ProcessUserImport */
export type ProcessUserImportPayload = {
  __typename?: 'ProcessUserImportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /**
   * Response data
   * @deprecated Backwards compatibility for old response data
   */
  data: Maybe<Scalars['JSON']['output']>;
  /**
   * Response error
   * @deprecated Backwards compatibility for old response error
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
};

/** Product information */
export type Product = Node &
  ProductInterface &
  StudentCertificatesProductQueryInterface & {
    __typename?: 'Product';
    /** The name of the Accredible certificate template */
    accredibleCertificateTemplateName: Maybe<Scalars['String']['output']>;
    /** Product's after purchase flows */
    afterPurchaseFlows: Maybe<AfterPurchaseFlowConnection>;
    /** Welcome email sent when enrolling in a bundle */
    bundleWelcomeEmail: Maybe<BundleWelcomeEmail>;
    /** Indicates if the product can issue Accredible certificates */
    canIssueAccredibleCertificate: Maybe<Scalars['Boolean']['output']>;
    /** Indicates if the product can issue Thinkific Student Certificates */
    canIssueThinkificStudentCertificate: Maybe<Scalars['Boolean']['output']>;
    /** The community product relation */
    communityProductRelation: Maybe<CommunityProductRelation>;
    /** Email sent when completing a course */
    courseCompletionEmail: Maybe<CourseCompletionEmail>;
    /** Welcome email sent when enrolling in a course */
    courseEnrollmentEmail: Maybe<CourseEnrollmentEmail>;
    /** When product was created */
    createdAt: Maybe<Scalars['DateTime']['output']>;
    /** Product's template (View) */
    customThemeTemplate: Maybe<CustomSiteThemeView>;
    /** Product's description */
    description: Maybe<Scalars['String']['output']>;
    /** Whether new affiliates is excluded for a product */
    excludeNewAffiliates: Maybe<Scalars['Boolean']['output']>;
    /** A list of excluded affiliates for this product */
    excludedAffiliates: AffiliateConnection;
    /** When product was published first time */
    firstPublishedAt: Maybe<Scalars['DateTime']['output']>;
    /** Whether the productable has a free preview */
    hasFreePreview: Maybe<Scalars['Boolean']['output']>;
    /** Product has a recurring price */
    hasRecurringPrice: Maybe<Scalars['Boolean']['output']>;
    /** Whether the product has an image uploaded */
    hasUploadedImage: Maybe<Scalars['Boolean']['output']>;
    id: Scalars['ID']['output'];
    /** Product's image */
    image: Maybe<Scalars['String']['output']>;
    /** The in-app purchase information of the product */
    inAppPurchaseProduct: Maybe<InAppPurchaseProduct>;
    /** Whether or not the product is hidden */
    isHidden: Maybe<Scalars['Boolean']['output']>;
    /** Whether or not the product is private */
    isPrivate: Maybe<Scalars['Boolean']['output']>;
    /** Product's Page footer code */
    landingPageLiquidMarkup: Maybe<Scalars['String']['output']>;
    /**
     * Product's name
     * @deprecated use productableName instead
     */
    name: Maybe<Scalars['String']['output']>;
    /** Number of lessons in the course */
    numberOfCourseLessons: Maybe<Scalars['Int']['output']>;
    /** Product's order bumps */
    orderBumps: Maybe<OrderBumpConnection>;
    /** Product's ordering position */
    position: Maybe<Scalars['Int']['output']>;
    /** Product's prices */
    prices: Maybe<ProductPriceConnection>;
    /** Product's primary price */
    primaryPrice: Maybe<ProductPrice>;
    /** Whether or not the product is private */
    private: Maybe<Scalars['Boolean']['output']>;
    /** Product's ID */
    productId: Maybe<Scalars['Int']['output']>;
    /** Product payees */
    productPayees: Maybe<ProductPayeeConnection>;
    /** The associated productable */
    productable: Maybe<ProductableUnion>;
    /** Productable ID */
    productableId: Maybe<Scalars['String']['output']>;
    /** Product's name */
    productableName: Maybe<Scalars['String']['output']>;
    /** The product's published status */
    publishedStatus: Maybe<Scalars['String']['output']>;
    /** Product's Site Builder Url */
    siteBuilderUrl: Maybe<Scalars['String']['output']>;
    /** Product's slug */
    slug: Maybe<Scalars['String']['output']>;
    /** The product's status */
    status: Maybe<Scalars['String']['output']>;
    studentCertificatesMapping: Maybe<StudentCertificatesCertificateMapping>;
    /** Product's tax code for checkout purposes */
    taxCode: Maybe<Scalars['String']['output']>;
    /** Product's theme data */
    themeData: Maybe<Scalars['JSON']['output']>;
    /** The product's type */
    type: Maybe<Scalars['String']['output']>;
  };

/** Product information */
export type ProductAfterPurchaseFlowsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Product information */
export type ProductCustomThemeTemplateArgs = {
  themeId?: InputMaybe<Scalars['String']['input']>;
};

/** Product information */
export type ProductExcludedAffiliatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Product information */
export type ProductOrderBumpsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<OrderBumpFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderBumpOrderBy>;
};

/** Product information */
export type ProductPricesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProductPriceFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Product information */
export type ProductProductPayeesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Input fields for a Product */
export type ProductBulkPatchInput = {
  /** Product tax code for checkout purposes */
  taxCode: Scalars['String']['input'];
};

/** Input fields for a product bulk update */
export type ProductBulkUpdateInput = {
  /** Product's ID */
  id: Scalars['ID']['input'];
  /** Product properties to bulk update */
  patch: ProductBulkPatchInput;
};

/** The connection type for Product. */
export type ProductConnection = {
  __typename?: 'ProductConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ProductEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Product>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ProductEdge = {
  __typename?: 'ProductEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Product>;
};

/** Possible fields to filter products */
export type ProductFilter = {
  /** Search by id */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Search by products that support in-app purchases */
  inAppPurchaseSupported?: InputMaybe<Scalars['Boolean']['input']>;
  /** Search by name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Search by price Type */
  price?: InputMaybe<Array<InputMaybe<PriceTypes>>>;
  /** Search by primary price Type */
  primaryPriceType?: InputMaybe<Array<InputMaybe<PriceTypes>>>;
  /** Search by privacy setting */
  privacySetting?: InputMaybe<Scalars['String']['input']>;
  /** Search by productable's integer ID */
  productableId?: InputMaybe<Scalars['Int']['input']>;
  /** Search by publication status */
  publicationStatus?: InputMaybe<Array<InputMaybe<PublicationStatusTypes>>>;
  /** Search by type */
  type?: InputMaybe<Array<InputMaybe<ProductTypes>>>;
};

/** Product interface */
export type ProductInterface = {
  /** The name of the Accredible certificate template */
  accredibleCertificateTemplateName: Maybe<Scalars['String']['output']>;
  /** Welcome email sent when enrolling in a bundle */
  bundleWelcomeEmail: Maybe<BundleWelcomeEmail>;
  /** Indicates if the product can issue Accredible certificates */
  canIssueAccredibleCertificate: Maybe<Scalars['Boolean']['output']>;
  /** Indicates if the product can issue Thinkific Student Certificates */
  canIssueThinkificStudentCertificate: Maybe<Scalars['Boolean']['output']>;
  /** The community product relation */
  communityProductRelation: Maybe<CommunityProductRelation>;
  /** Email sent when completing a course */
  courseCompletionEmail: Maybe<CourseCompletionEmail>;
  /** Welcome email sent when enrolling in a course */
  courseEnrollmentEmail: Maybe<CourseEnrollmentEmail>;
  /** When product was created */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** Product's description */
  description: Maybe<Scalars['String']['output']>;
  /** When product was published first time */
  firstPublishedAt: Maybe<Scalars['DateTime']['output']>;
  /** Whether the productable has a free preview */
  hasFreePreview: Maybe<Scalars['Boolean']['output']>;
  /** Whether the product has an image uploaded */
  hasUploadedImage: Maybe<Scalars['Boolean']['output']>;
  /** Product's image */
  image: Maybe<Scalars['String']['output']>;
  /** Whether or not the product is hidden */
  isHidden: Maybe<Scalars['Boolean']['output']>;
  /** Whether or not the product is private */
  isPrivate: Maybe<Scalars['Boolean']['output']>;
  /** Product's Page footer code */
  landingPageLiquidMarkup: Maybe<Scalars['String']['output']>;
  /**
   * Product's name
   * @deprecated use productableName instead
   */
  name: Maybe<Scalars['String']['output']>;
  /** Number of lessons in the course */
  numberOfCourseLessons: Maybe<Scalars['Int']['output']>;
  /** Product's ordering position */
  position: Maybe<Scalars['Int']['output']>;
  /** Whether or not the product is private */
  private: Maybe<Scalars['Boolean']['output']>;
  /** Product's ID */
  productId: Maybe<Scalars['Int']['output']>;
  /** The associated productable */
  productable: Maybe<ProductableUnion>;
  /** Productable ID */
  productableId: Maybe<Scalars['String']['output']>;
  /** Product's name */
  productableName: Maybe<Scalars['String']['output']>;
  /** The product's published status */
  publishedStatus: Maybe<Scalars['String']['output']>;
  /** Product's slug */
  slug: Maybe<Scalars['String']['output']>;
  /** The product's status */
  status: Maybe<Scalars['String']['output']>;
  /** The product's type */
  type: Maybe<Scalars['String']['output']>;
};

/** Sources though which a product license create/update event can occur */
export enum ProductLicenseEventSourceEnum {
  /** product license create/update bulk_enrollment event */
  BulkEnrollment = 'BULK_ENROLLMENT',
  /** product license create/update tenant_admin event */
  TenantAdmin = 'TENANT_ADMIN',
}

/** Possible fields to sort order products */
export type ProductOrderBy = {
  direction: OrderDirection;
  field: ProductSortField;
};

/** Input fields for a Product */
export type ProductPatch = {
  /** add / remove all affiliates */
  bulkAffiliatesAction?: InputMaybe<BulkAffiliatesActionEnum>;
  /** Exclude New Affiliates */
  excludeNewAffiliates?: InputMaybe<Scalars['Boolean']['input']>;
  /** The position of the product */
  position?: InputMaybe<Scalars['Int']['input']>;
  /** Product Payees input */
  productPayees?: InputMaybe<Array<ProductPayeesInput>>;
  /** The status of the product */
  status?: InputMaybe<ProductStatus>;
  /** Product tax code for checkout purposes */
  taxCode?: InputMaybe<Scalars['String']['input']>;
  /** Product's theme data */
  themeData?: InputMaybe<Scalars['JSON']['input']>;
};

/** ProductPayee can refer users to sign up with the tenant */
export type ProductPayee = Node & {
  __typename?: 'ProductPayee';
  id: Scalars['ID']['output'];
  /** Email for payout */
  payoutEmail: Maybe<Scalars['String']['output']>;
  /** Commission */
  payoutPercentage: Scalars['Float']['output'];
  /** revenue partner's detail */
  revenuePartner: Payee;
};

/** The connection type for ProductPayee. */
export type ProductPayeeConnection = {
  __typename?: 'ProductPayeeConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ProductPayeeEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<ProductPayee>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ProductPayeeEdge = {
  __typename?: 'ProductPayeeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<ProductPayee>;
};

/** Product payees feature */
export type ProductPayeesFeature = FeatureInterface & {
  __typename?: 'ProductPayeesFeature';
  /** Feature checkout path */
  checkoutPath: Maybe<Scalars['String']['output']>;
  /** Flag to indicate feature is enabled */
  isEnabled: Maybe<Scalars['Boolean']['output']>;
  /** Maximum number of product payees */
  maxProductPayees: Scalars['Int']['output'];
  /** The name of the minimum plan required to access this feature */
  minimumPlanName: Maybe<Scalars['String']['output']>;
};

/** Product payees input */
export type ProductPayeesInput = {
  /** Product payee id */
  id: Scalars['ID']['input'];
  /** Product payee payout percentage */
  payoutPercentage?: InputMaybe<Scalars['Float']['input']>;
};

/** Product price information */
export type ProductPrice = Node & {
  __typename?: 'ProductPrice';
  /** After purchase flow id */
  afterPurchaseFlowId: Maybe<Scalars['ID']['output']>;
  /** The checkout page URL */
  checkoutUrl: Maybe<Scalars['String']['output']>;
  /** Product price created at */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** Used for subscription price, custom first payment period of subscription */
  customFirstPrice: Maybe<Scalars['Float']['output']>;
  /** Days until price expires */
  daysUntilExpiry: Maybe<Scalars['Int']['output']>;
  /** Product price deleted at */
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  /** Marketing name of price */
  displayName: Maybe<Scalars['String']['output']>;
  /** Used to explain the price */
  displayPrice: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Used for subscription and monthly price, values are month and year */
  interval: Maybe<Scalars['String']['output']>;
  /** Number of months/years in subscription/monthly price */
  intervalCount: Maybe<Scalars['Int']['output']>;
  /** If the product price is the primary price for the product */
  isPrimary: Maybe<Scalars['Boolean']['output']>;
  /** Name for additional prices */
  label: Maybe<Scalars['String']['output']>;
  /** Number of payments (only applicable to payment plans) */
  numberOfPayments: Maybe<Scalars['Int']['output']>;
  /** Student facing pay text */
  payButtonText: Maybe<Scalars['String']['output']>;
  /** Amount */
  price: Maybe<Scalars['Float']['output']>;
  /** Unencoded ID */
  priceId: Scalars['Int']['output'];
  /** Label of product price */
  priceName: Maybe<Scalars['String']['output']>;
  /** Product Price Product */
  product: Maybe<PriceProduct>;
  /** Used for subscription price, values are day and month */
  trialInterval: Maybe<Scalars['String']['output']>;
  /** Number of days/months of the trial */
  trialIntervalCount: Maybe<Scalars['Int']['output']>;
  /** The price payment type; values are free, one-time, subscription, and payment-plan */
  type: Maybe<Scalars['String']['output']>;
  /** Product price updated at */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** The connection type for ProductPrice. */
export type ProductPriceConnection = {
  __typename?: 'ProductPriceConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ProductPriceEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<ProductPrice>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ProductPriceEdge = {
  __typename?: 'ProductPriceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<ProductPrice>;
};

/** Possible fields to filter product prices */
export type ProductPriceFilter = {
  /** Scope to only fetch prices that have not been deleted */
  active?: InputMaybe<Scalars['Boolean']['input']>;
  /** Search by id */
  id?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Search by priceId (Site Builder - Manifest) */
  priceId?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

/** Input fields for a product price */
export type ProductPriceInput = {
  /** Used for subscription price, custom first payment period of subscription */
  customFirstPrice?: InputMaybe<Scalars['Float']['input']>;
  /** The number of days until the enrollment expires */
  daysUntilExpiry?: InputMaybe<Scalars['Int']['input']>;
  /** Used for subscription and monthly (payment-plan) price. Values are day, week, month, and year */
  interval?: InputMaybe<Scalars['String']['input']>;
  /** Number for the interval */
  intervalCount?: InputMaybe<Scalars['Int']['input']>;
  /** Truthy value determining if product price is a primary or additional price */
  isPrimary?: InputMaybe<Scalars['Boolean']['input']>;
  /** The name for additional prices */
  label?: InputMaybe<Scalars['String']['input']>;
  /** Number of months for the payment-plan payment type */
  numberOfPayments?: InputMaybe<Scalars['Int']['input']>;
  /** The text displayed on the Buy Button on the course card and the course landing page */
  payButtonText?: InputMaybe<Scalars['String']['input']>;
  /** The price value for the price */
  price?: InputMaybe<Scalars['Float']['input']>;
  /** Used for subscription price, values are day and month */
  trialInterval?: InputMaybe<Scalars['String']['input']>;
  /** Number for the trialInterval */
  trialIntervalCount?: InputMaybe<Scalars['Int']['input']>;
  /** The price payment type values are: free, one-time, subscription, and payment-plan */
  type?: InputMaybe<Scalars['String']['input']>;
};

/** Sortable fields for products */
export enum ProductSortField {
  /** Sort by the associated product page's edited_at date from within the active theme */
  EditedAt = 'EDITED_AT',
  /** Sort by the associate product's type value */
  Type = 'TYPE',
  /** Sort by the associate product's updated_at value */
  UpdatedAt = 'UPDATED_AT',
}

/** Possible statuses for a product */
export enum ProductStatus {
  /** Draft */
  Draft = 'draft',
  /** Pending approval */
  PendingApproval = 'pending_approval',
  /** Pre-order */
  Presell = 'presell',
  /** Published */
  Published = 'published',
}

/** Tax details related to a product in a transaction */
export type ProductTaxLineItem = {
  __typename?: 'ProductTaxLineItem';
  /** Country */
  country: Scalars['String']['output'];
  /** Jurisdiction display name */
  jurisdictionDisplayName: Scalars['String']['output'];
  /** Tax amount */
  taxAmount: Scalars['Float']['output'];
  /** Tax percentage */
  taxPercentage: Scalars['Float']['output'];
  /** Tax type */
  taxType: Scalars['String']['output'];
  /** Tax type display name */
  taxTypeDisplayName: Scalars['String']['output'];
  /** Taxable status */
  taxable: Scalars['Boolean']['output'];
};

/** Possible product types */
export enum ProductTypes {
  /** A bundle */
  Bundle = 'Bundle',
  /** A community */
  Community = 'Community',
  /** A course */
  Course = 'Course',
}

/** Image properties for a productable */
export type ProductableImagePropertiesInput = {
  /** Image file name */
  name: Scalars['String']['input'];
  /** Image size */
  size: Scalars['Int']['input'];
  /** Image content type */
  type: Scalars['String']['input'];
};

/** Productable interface */
export type ProductableInterface = {
  /**
   * A list of after purchase flows that belong to the product
   * @deprecated Access via the product field instead
   */
  afterPurchaseFlows: Maybe<AfterPurchaseFlowConnection>;
  /** Product's card image url */
  cardImageUrl: Maybe<Scalars['String']['output']>;
  /** Product's created date */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** Product's description */
  description: Maybe<Scalars['String']['output']>;
  /** Whether new affiliates is excluded for a product */
  excludeNewAffiliates: Maybe<Scalars['Boolean']['output']>;
  /** Whether the product is hidden */
  isHidden: Maybe<Scalars['Boolean']['output']>;
  /** Whether the product is presell */
  isPresell: Maybe<Scalars['Boolean']['output']>;
  /** Whether the product is private */
  isPrivate: Maybe<Scalars['Boolean']['output']>;
  /** Whether the product is published */
  isPublished: Maybe<Scalars['Boolean']['output']>;
  /** Product's keywords */
  keywords: Maybe<Scalars['String']['output']>;
  /** Product's Page footer code */
  landingPageLiquidMarkup: Maybe<Scalars['String']['output']>;
  /** Product's name */
  name: Maybe<Scalars['String']['output']>;
  /** The product position for ordering */
  position: Maybe<Scalars['Int']['output']>;
  /** Product associated with productable */
  product: Maybe<Product>;
  /**
   * Product's id
   * @deprecated Access product id via the product field instead
   */
  productId: Maybe<Scalars['String']['output']>;
  /**
   * A list of product prices that belongs to the product
   * @deprecated Access via the product field instead
   */
  productPrices: Maybe<ProductPriceConnection>;
  /** Product's SEO description */
  seoDescription: Maybe<Scalars['String']['output']>;
  /** Product's SEO title */
  seoTitle: Maybe<Scalars['String']['output']>;
  /** Product's Site Builder Link */
  siteBuilderLink: Maybe<Scalars['String']['output']>;
  /** Product's slug */
  slug: Maybe<Scalars['String']['output']>;
  /** Product's status */
  status: Maybe<Scalars['String']['output']>;
  /** Product's title */
  title: Maybe<Scalars['String']['output']>;
};

/** Productable interface */
export type ProductableInterfaceAfterPurchaseFlowsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Productable interface */
export type ProductableInterfaceProductPricesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The productable */
export type ProductableUnion = Bundle | Community | Course;

/** A user profile */
export type Profile = {
  __typename?: 'Profile';
  /** A User's avatar url */
  avatarUrl: Maybe<Scalars['String']['output']>;
  /** A User's company */
  company: Maybe<Scalars['String']['output']>;
  /** A User's headline */
  headline: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** A User's timezone */
  timezone: Maybe<Scalars['String']['output']>;
};

/** Filter for progress based on a course */
export type ProgressFilter = {
  /** Course id */
  course: Scalars['String']['input'];
  /** Progress filter */
  progress: NumberComparison;
};

/** Promotion information */
export type Promotion = Node & {
  __typename?: 'Promotion';
  /** Discount amount */
  amount: Scalars['Float']['output'];
  /** Coupons that belongs to this promotion */
  coupons: Array<Coupon>;
  /** Created date */
  createdAt: Scalars['DateTime']['output'];
  /** Discount type: Fixed or Percentage */
  discountType: DiscountEnum;
  /** How many months should this discount apply to membership/subscription prices */
  duration: Maybe<Scalars['Int']['output']>;
  /** Expiration date */
  expiresAt: Maybe<Scalars['DateTime']['output']>;
  /** Indicates whether the promotion is associated with a bulk order */
  hasBulkSale: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** Promotion name */
  name: Scalars['String']['output'];
  /** Products the promotion applies to */
  productPrices: Maybe<Array<ProductPrice>>;
  /** Total coupons quantity */
  totalCouponsQuantity: Maybe<Scalars['String']['output']>;
  /** Total coupons quantity used */
  totalCouponsQuantityUsed: Maybe<Scalars['Int']['output']>;
};

/** Promotion information */
export type PromotionCouponsArgs = {
  filter?: InputMaybe<CouponFilter>;
};

/** The connection type for Promotion. */
export type PromotionConnection = {
  __typename?: 'PromotionConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PromotionEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Promotion>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type PromotionEdge = {
  __typename?: 'PromotionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Promotion>;
};

/** Possible fields to filter groups */
export type PromotionFilter = {
  /** Search by id */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Search by name */
  name?: InputMaybe<Scalars['String']['input']>;
};

/** PromotionalDeal info for OnboardingFlow */
export type PromotionalDeal = Node & {
  __typename?: 'PromotionalDeal';
  /** Promotional deal start date */
  activationDate: Scalars['DateTime']['output'];
  /** Flag to check whether downgrades are blocked */
  blockDowngrades: Scalars['Boolean']['output'];
  /** Promotional deal bonuses */
  bonuses: Maybe<Array<Scalars['String']['output']>>;
  /** Checkout Page Bottom HTML */
  checkoutPageBottomCustomHtml: Maybe<Scalars['String']['output']>;
  /** Checkout Page Footer Code */
  checkoutPageFooterCode: Maybe<Scalars['String']['output']>;
  /** Checkout Page Top HTML */
  checkoutPageTopCustomHtml: Maybe<Scalars['String']['output']>;
  /** Promotional deal currency */
  currency: Scalars['String']['output'];
  /** Flag to check whether Promotional deal is inactive */
  deactivated: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** Promotional deal image */
  image: Maybe<Scalars['String']['output']>;
  /** Name of Promotional deal */
  name: Scalars['String']['output'];
  /** Promotional deal payment summary */
  paymentSummary: Maybe<Scalars['String']['output']>;
  /** Promotional deal landing page title */
  promoLandingPageTitle: Maybe<Scalars['String']['output']>;
  /** Promotional deal payment page title */
  promoPaymentPageTitle: Maybe<Scalars['String']['output']>;
  /** Sign in/Sign up Page HTML */
  signinSignupCustomHtml: Maybe<Scalars['String']['output']>;
  /** Sign in/Sign up Footer Code */
  signinSignupFooterCode: Maybe<Scalars['String']['output']>;
  /** Slug of Promotional deal */
  slug: Scalars['String']['output'];
  /** Promotional deal testinomial image */
  testimonialImage: Maybe<Scalars['String']['output']>;
  /** Promotional deal testimonial name */
  testimonialName: Maybe<Scalars['String']['output']>;
  /** Promotional deal testimonial quote */
  testimonialQuote: Maybe<Scalars['String']['output']>;
  /** Thankyou Page HTML */
  thankyouPageCustomHtml: Maybe<Scalars['String']['output']>;
  /** Thankyou Page Footer Code */
  thankyouPageFooterCode: Maybe<Scalars['String']['output']>;
  /** Title of Promotional deal */
  title: Maybe<Scalars['String']['output']>;
};

/** Tenant promotions feature */
export type PromotionsFeature = {
  __typename?: 'PromotionsFeature';
  /** Flag to indicate feature is enabled */
  isEnabled: Maybe<Scalars['Boolean']['output']>;
};

/** An AI Prompt */
export type Prompt = Node & {
  __typename?: 'Prompt';
  id: Scalars['ID']['output'];
  /** The prompt message sent to AI */
  promptMessage: Maybe<Scalars['String']['output']>;
  /** The type of the prompt */
  promptType: Maybe<PromptTypeEnum>;
  /** The response sent back from AI */
  response: Maybe<Scalars['String']['output']>;
  /** The status of the prompt */
  status: Maybe<PromptStatusEnum>;
};

/** Shows whether the prompt is in progress, completed or failed. */
export enum PromptStatusEnum {
  /** Completed */
  Completed = 'Completed',
  /** Failed */
  Failed = 'Failed',
  /** In Progress */
  InProgress = 'InProgress',
}

/** Describe the type of prompt */
export enum PromptTypeEnum {
  /** Generate Course Outline */
  GenerateCourseOutline = 'generate_course_outline',
  /** Generate Lead Capture */
  GenerateFunnelPageContent = 'generate_funnel_page_content',
  /** Generate Quiz */
  GenerateQuiz = 'generate_quiz',
}

/** Possible publication types */
export enum PublicationStatusTypes {
  /** A draft product */
  Draft = 'draft',
  /** A pre order product */
  Presell = 'presell',
  /** A published product */
  Published = 'published',
}

/** Autogenerated input type of PublishFunnel */
export type PublishFunnelInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The Funnel ID */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of PublishFunnel */
export type PublishFunnelPayload = {
  __typename?: 'PublishFunnelPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The updated Funnel */
  funnel: Maybe<Funnel>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of PublishProductRequest */
export type PublishProductRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Product's ID */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of PublishProductRequest */
export type PublishProductRequestPayload = {
  __typename?: 'PublishProductRequestPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The updated product */
  product: Maybe<Product>;
  userErrors: Maybe<Array<UserError>>;
};

/** Inputs for purchasing paid plan */
export type PurchasePaidPlanInfo = {
  /** Onboarding Flow ID */
  onboardingFlowId: Scalars['ID']['input'];
  /** Plan Interval */
  planInterval: Scalars['String']['input'];
  /** Tenant share a sale SSCID */
  shareASaleSscid?: InputMaybe<Scalars['String']['input']>;
  /** Payment stripe token */
  stripeToken: Scalars['String']['input'];
  /** Tenant billing details */
  tenantBillingInfo: TenantBillingInfoInput;
};

/** Autogenerated input type of PurchasePaidPlan */
export type PurchasePaidPlanInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  input: PurchasePaidPlanInfo;
};

/** Autogenerated return type of PurchasePaidPlan */
export type PurchasePaidPlanPayload = {
  __typename?: 'PurchasePaidPlanPayload';
  /** Validation errors from saving the billing info */
  billingInfoValidationErrors: Maybe<Array<BillingInfoValidationError>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Error messages when failed to purchase plan */
  errorMessage: Maybe<Scalars['String']['output']>;
  /** OnboardingFlow info for current visitor */
  onboardingFlow: Maybe<OnboardingFlow>;
  /** Object to determine the payment's status */
  paymentIntent: Maybe<PaymentIntent>;
  /** Flag to determine if the plan was purchased successfully */
  planPurchaseSuccessful: Maybe<Scalars['Boolean']['output']>;
  /** Redirect URL address */
  redirectUrl: Maybe<Scalars['String']['output']>;
  /** Tenant associated with onboarding flow */
  tenant: Maybe<VerifiedTenants>;
  userErrors: Maybe<Array<UserError>>;
};

/** The query root of Thinkific's schema */
export type Query = AppStoreQueryInterface &
  ChangelogQueryInterface &
  CommunitiesQueryInterface &
  EventsQueryInterface &
  IhubQueryInterface &
  StudentCertificatesQueryInterface & {
    __typename?: 'Query';
    /** Determine if the accelerator components should be visible to a user */
    acceleratorVisibility: AcceleratorVisibility;
    /** A list of the current user's AccountNotification states */
    accountNotifications: Maybe<Array<AccountNotification>>;
    addonSitePlans: Array<SitePlan>;
    /** A list of the current user's AdminNotification states */
    adminNotifications: Maybe<Array<AdminNotification>>;
    /** App changelog announcements */
    announcements: Maybe<AnnouncementsConnection>;
    /** App group information */
    appGroup: Maybe<Array<AppGroup>>;
    /** OAuth app information */
    appInformation: Maybe<AppInformation>;
    appStore: Maybe<AppStore>;
    /** OAuth app installs */
    apps: Maybe<AppConnection>;
    /** Determine if the branded mobile components should be visible to a user */
    brandedMobileVisibility: BrandedMobileVisibility;
    bundle: Maybe<Bundle>;
    /** A list of bundles that belongs to the tenant */
    bundles: Maybe<BundleConnection>;
    /** A list of certificate design presets */
    certificateDesignPresets: Maybe<StudentCertificatesDesignPresetConnection>;
    /** App Changelog entries */
    changelogEntry: Maybe<ChangelogEntryConnection>;
    chapter: Maybe<Chapter>;
    /** Tab title and progress for each checklist type */
    checklists: Array<Checklist>;
    /** A list of the collections that belong to the tenant */
    collections: Maybe<CollectionConnection>;
    communities: Maybe<CommunityConnection>;
    community: Maybe<Community>;
    /** Convertkit queries */
    convertkit: Maybe<ConvertkitQueryInterface>;
    /** List of countries from IsoCountryCodes */
    countries: Maybe<Array<Scalars['JSON']['output']>>;
    course: Maybe<Course>;
    /** Course templates for course creation */
    courseTemplates: Maybe<CourseTemplateConnection>;
    /** A list of courses that belongs to the tenant */
    courses: Maybe<CourseConnection>;
    /** Currency for given country code */
    currency: Maybe<Scalars['String']['output']>;
    /** List of custom domain suggestions from CustomDomainReseller */
    customDomainSuggestions: Maybe<Array<DomainListing>>;
    enrollment: Maybe<Enrollment>;
    /** Google Places API key used for address autocompletion in the tenant's billing details UI */
    googlePlacesApiKey: Maybe<Scalars['String']['output']>;
    /** Infusionsoft queries */
    infusionsoft: Maybe<InfusionsoftQueryInterface>;
    /** A list of the instructors that belong to the tenant */
    instructors: Maybe<InstructorConnection>;
    lesson: Maybe<LessonUnion>;
    liveEvent: Maybe<LiveEvent>;
    /** A list of events */
    liveEvents: Maybe<LiveEventConnection>;
    me: Maybe<User>;
    /** Mobile app settings data */
    mobileSettings: MobileSettings;
    /** Fetches an object given its ID. */
    node: Maybe<Node>;
    /** Fetches a list of objects given a list of IDs. */
    nodes: Array<Maybe<Node>>;
    /** @deprecated nobody is using */
    onboardingContent: Maybe<OnboardingContent>;
    /** Onboarding Flow for the visitor */
    onboardingFlow: Maybe<OnboardingFlow>;
    /** Onboarding Data for the visitor */
    onboardingVisitorData: Maybe<OnboardingVisitorData>;
    /** The generated HTML */
    previewStudentCertificateBlueprint: Maybe<
      StudentCertificatesPreviewBlueprint
    >;
    product: Maybe<Product>;
    /** A list of products that belongs to the tenant */
    products: Maybe<ProductConnection>;
    prompt: Maybe<Prompt>;
    /** A list of ThemeLibraryItems */
    sidekiqJobs: Maybe<SidekiqJobStatus>;
    /** The current logged in tenant information */
    site: Maybe<Tenant>;
    sitePage: Maybe<SitePage>;
    sitePages: SitePageConnection;
    siteSection: Maybe<SiteSection>;
    siteTemplates: SiteTemplateConnection;
    /** Stripe API key used in the tenant's billing details UI */
    stripePublishableKey: Maybe<Scalars['String']['output']>;
    /** A list of the current user's StudentNotification states */
    studentNotifications: Maybe<Array<StudentNotification>>;
    /** A list of tax codes applicable to products */
    taxCodes: Maybe<Array<TaxCode>>;
    /** Tax rate for given country code + zip code */
    taxRate: Maybe<Scalars['Float']['output']>;
    /** Countries where Thinkific charges tax */
    taxableCountryCodes: Maybe<Array<Scalars['String']['output']>>;
    /** Tenant information requested by Thinkific Admin */
    tenant: Maybe<Tenant>;
    /** Billing info associated with a tenant */
    tenantAdminCheckoutBillingInfo: Maybe<CheckoutBillingInfo>;
    /** Tenant subdomain with mobile support verification */
    tenantMobileSubdomainValidation: Subdomain;
    /** A list of ThemeLibraryItems */
    themeLibraryItem: Maybe<ThemeLibraryItemConnection>;
    /** Users multi-factor authentication events */
    usersMfaEvents: Maybe<UserMfaEventConnection>;
    /** Countries where Thinkific collects VAT numbers */
    vatCountryCodes: Maybe<Array<Scalars['String']['output']>>;
    /** Verifies if email is attached to an existing account */
    verifyEmail: VerifyEmail;
    /** Verifies tenant credentials */
    verifyTenantCredentials: Array<VerifiedTenants>;
  };

/** The query root of Thinkific's schema */
export type QueryAnnouncementsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  pagination: Pagination;
};

/** The query root of Thinkific's schema */
export type QueryAppGroupArgs = {
  groups: Array<Scalars['String']['input']>;
};

/** The query root of Thinkific's schema */
export type QueryAppInformationArgs = {
  slug: Scalars['String']['input'];
};

/** The query root of Thinkific's schema */
export type QueryAppsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AppFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The query root of Thinkific's schema */
export type QueryBundleArgs = {
  id: Scalars['ID']['input'];
};

/** The query root of Thinkific's schema */
export type QueryBundlesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  usedForMemberships?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The query root of Thinkific's schema */
export type QueryCertificateDesignPresetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<StudentCertificatesDesignPresetFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The query root of Thinkific's schema */
export type QueryChangelogEntryArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ChangelogEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The query root of Thinkific's schema */
export type QueryChapterArgs = {
  id: Scalars['ID']['input'];
};

/** The query root of Thinkific's schema */
export type QueryChecklistsArgs = {
  checklistTypes: Array<ChecklistTypesEnum>;
};

/** The query root of Thinkific's schema */
export type QueryCollectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CollectionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The query root of Thinkific's schema */
export type QueryCommunitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommunityFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CommunityOrderBy>;
};

/** The query root of Thinkific's schema */
export type QueryCommunityArgs = {
  id: Scalars['ID']['input'];
};

/** The query root of Thinkific's schema */
export type QueryCourseArgs = {
  id: Scalars['ID']['input'];
};

/** The query root of Thinkific's schema */
export type QueryCourseTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CourseTemplateFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The query root of Thinkific's schema */
export type QueryCoursesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CourseFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CourseOrderBy>;
};

/** The query root of Thinkific's schema */
export type QueryCurrencyArgs = {
  countryCode?: InputMaybe<Scalars['String']['input']>;
};

/** The query root of Thinkific's schema */
export type QueryCustomDomainSuggestionsArgs = {
  query: Scalars['String']['input'];
};

/** The query root of Thinkific's schema */
export type QueryEnrollmentArgs = {
  id: Scalars['ID']['input'];
};

/** The query root of Thinkific's schema */
export type QueryInstructorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<InstructorFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The query root of Thinkific's schema */
export type QueryLessonArgs = {
  id: Scalars['ID']['input'];
};

/** The query root of Thinkific's schema */
export type QueryLiveEventArgs = {
  id: Scalars['ID']['input'];
};

/** The query root of Thinkific's schema */
export type QueryLiveEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  eventSourceId: Scalars['ID']['input'];
  eventSourceType: EventSourceTypes;
  filter?: InputMaybe<EventsFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<EventsOrderBy>;
};

/** The query root of Thinkific's schema */
export type QueryNodeArgs = {
  id: Scalars['ID']['input'];
};

/** The query root of Thinkific's schema */
export type QueryNodesArgs = {
  ids: Array<Scalars['ID']['input']>;
};

/** The query root of Thinkific's schema */
export type QueryOnboardingFlowArgs = {
  filter?: InputMaybe<OnboardingFlowFilter>;
};

/** The query root of Thinkific's schema */
export type QueryOnboardingVisitorDataArgs = {
  filter?: InputMaybe<OnboardingVisitorDataFilter>;
};

/** The query root of Thinkific's schema */
export type QueryPreviewStudentCertificateBlueprintArgs = {
  input: StudentCertificatesPreviewBlueprintInput;
};

/** The query root of Thinkific's schema */
export type QueryProductArgs = {
  id: Scalars['ID']['input'];
};

/** The query root of Thinkific's schema */
export type QueryProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProductFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ProductOrderBy>;
};

/** The query root of Thinkific's schema */
export type QueryPromptArgs = {
  id: Scalars['ID']['input'];
};

/** The query root of Thinkific's schema */
export type QuerySidekiqJobsArgs = {
  id: Scalars['String']['input'];
};

/** The query root of Thinkific's schema */
export type QuerySitePageArgs = {
  id: Scalars['ID']['input'];
};

/** The query root of Thinkific's schema */
export type QuerySitePagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The query root of Thinkific's schema */
export type QuerySiteSectionArgs = {
  id: Scalars['ID']['input'];
};

/** The query root of Thinkific's schema */
export type QuerySiteTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The query root of Thinkific's schema */
export type QueryTaxRateArgs = {
  countryCode: Scalars['String']['input'];
  region?: InputMaybe<Scalars['String']['input']>;
  zipCode: Scalars['String']['input'];
};

/** The query root of Thinkific's schema */
export type QueryTenantArgs = {
  tenantId: Scalars['ID']['input'];
};

/** The query root of Thinkific's schema */
export type QueryTenantAdminCheckoutBillingInfoArgs = {
  filter: TenantAdminCheckoutBillingInfoFilter;
};

/** The query root of Thinkific's schema */
export type QueryTenantMobileSubdomainValidationArgs = {
  subdomain: Scalars['String']['input'];
};

/** The query root of Thinkific's schema */
export type QueryThemeLibraryItemArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ThemeLibraryItemFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The query root of Thinkific's schema */
export type QueryUsersMfaEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  tenantId?: InputMaybe<Scalars['ID']['input']>;
};

/** The query root of Thinkific's schema */
export type QueryVerifyEmailArgs = {
  filter: VerifyEmailFilter;
};

/** The query root of Thinkific's schema */
export type QueryVerifyTenantCredentialsArgs = {
  input: VerifyTenantCredentialsInput;
};

/** Quiz question information */
export type Question = Node & {
  __typename?: 'Question';
  /** The question choices */
  choices: Maybe<ChoiceConnection>;
  /** The question type */
  displayType: Maybe<QuestionTypeEnum>;
  id: Scalars['ID']['output'];
  /** The title of the question */
  name: Maybe<Scalars['String']['output']>;
  /** The position of the question */
  position: Maybe<Scalars['Int']['output']>;
  /** The question prompt */
  prompt: Maybe<Scalars['String']['output']>;
  /** The explanation of the question */
  textExplanation: Maybe<Scalars['String']['output']>;
};

/** Quiz question information */
export type QuestionChoicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Input for quiz questions */
export type QuestionChoiceInput = {
  /** Whether this question counts as an answer */
  credited?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to destroy the question */
  destroy?: InputMaybe<Scalars['Boolean']['input']>;
  /** The choice id */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Whether the choice was AI generated */
  isAiGenerated?: InputMaybe<Scalars['Boolean']['input']>;
  /** The choice position */
  position?: InputMaybe<Scalars['Int']['input']>;
  /** The choice text */
  text?: InputMaybe<Scalars['String']['input']>;
};

/** The connection type for Question. */
export type QuestionConnection = {
  __typename?: 'QuestionConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<QuestionEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Question>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type QuestionEdge = {
  __typename?: 'QuestionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Question>;
};

/** The question type */
export enum QuestionTypeEnum {
  /** Checkbox question type */
  Checkbox = 'checkbox',
  /** Radio question type */
  Radio = 'radio',
}

/** Quiz lesson information */
export type QuizLesson = LessonInterface &
  Node & {
    __typename?: 'QuizLesson';
    /** Content's chapter */
    chapter: Chapter;
    /** contentable id */
    contentableId: Scalars['ID']['output'];
    /** Content's course */
    course: Course;
    /** Content's created at date */
    createdAt: Scalars['DateTime']['output'];
    /** Lesson default icon */
    defaultLessonTypeIcon: Scalars['String']['output'];
    /** Is discussion enabled */
    discussionsEnabled: Maybe<Scalars['Boolean']['output']>;
    /** Lesson draft status */
    draft: Scalars['Boolean']['output'];
    /** Lesson's associated Drip Schedule Item */
    dripScheduleItem: Maybe<DripScheduleItem>;
    /** lesson drip status */
    dripStatus: Maybe<Scalars['JSON']['output']>;
    /** Is lesson free */
    free: Maybe<Scalars['Boolean']['output']>;
    /** Lesson html text */
    htmlDescription: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    /** Current user lesson status */
    lessonCompleted: Maybe<Scalars['Boolean']['output']>;
    /** lesson drip schedule */
    lessonHasDripSchedule: Maybe<Scalars['String']['output']>;
    /** Lesson integer ID */
    lessonId: Scalars['ID']['output'];
    /** Lesson icon */
    lessonTypeIcon: Maybe<LessonIconEnum>;
    /** Lesson label */
    lessonTypeLabel: Maybe<Scalars['String']['output']>;
    /** Lesson url */
    lessonUrl: Maybe<Scalars['String']['output']>;
    /** Lesson name */
    name: Scalars['String']['output'];
    /** The numer of question bank questions selected */
    numberOfSelectedQuizQuestions: Maybe<Scalars['Int']['output']>;
    /** Score required to pass the quiz */
    passingScore: Maybe<Scalars['Int']['output']>;
    /** Lesson position */
    position: Scalars['Int']['output'];
    /** Is lesson prerequisite */
    prerequisite: Scalars['Boolean']['output'];
    /** A single quiz question by ID */
    question: Maybe<Question>;
    /** The quiz questions */
    questions: Maybe<QuestionConnection>;
    /** Whether the quiz is using the question bank or not */
    quizQuestionBank: Maybe<Scalars['Boolean']['output']>;
    /** The quiz type */
    quizType: Maybe<QuizTypeEnum>;
    /** Whether to show the explanation to students or not */
    showExplanation: Maybe<Scalars['Boolean']['output']>;
    /** Lesson's slug */
    slug: Maybe<Scalars['String']['output']>;
    /** Lesson Type */
    type: Scalars['String']['output'];
    /** Whether the lesson is still under construction */
    underConstruction: Scalars['Boolean']['output'];
    /** Content's updated at date */
    updatedAt: Scalars['DateTime']['output'];
    /** User content for lesson */
    userContents: Maybe<UserContentConnection>;
    /** User quizzes for lesson */
    userQuizzes: Maybe<UserQuizConnection>;
    /** User surveys for lesson */
    userSurveys: Maybe<UserSurveyConnection>;
  };

/** Quiz lesson information */
export type QuizLessonQuestionArgs = {
  id: Scalars['ID']['input'];
};

/** Quiz lesson information */
export type QuizLessonQuestionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Quiz lesson information */
export type QuizLessonUserContentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserContentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserContentOrderByFilter>;
};

/** Quiz lesson information */
export type QuizLessonUserQuizzesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserQuizFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserQuizOrderBy>;
};

/** Quiz lesson information */
export type QuizLessonUserSurveysArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserSurveyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserSurveyOrderBy>;
};

/** Input for quiz questions */
export type QuizQuestionInput = {
  /** The quiz choices */
  choices?: InputMaybe<Array<QuestionChoiceInput>>;
  /** Whether to destroy the question */
  destroy?: InputMaybe<Scalars['Boolean']['input']>;
  /** The type of question */
  displayType?: InputMaybe<QuestionTypeEnum>;
  /** The question id */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Whether the question was generated by AI */
  isAiGenerated?: InputMaybe<Scalars['Boolean']['input']>;
  /** The question name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The question position */
  position?: InputMaybe<Scalars['Int']['input']>;
  /** The quiz question prompt */
  prompt?: InputMaybe<Scalars['String']['input']>;
  /** The explanation of the answer */
  textExplanation?: InputMaybe<Scalars['String']['input']>;
};

/** The quiz type */
export enum QuizTypeEnum {
  /** Pass Fail quiz type */
  PassFail = 'pass_fail',
  /** Simple quiz type */
  Simple = 'simple',
}

/** Possible reasons for uninstalling third-party oauth app */
export enum ReasonType {
  /** Filter by reason */
  Functionality = 'FUNCTIONALITY',
  /** Filter by reason */
  Other = 'OTHER',
  /** Filter by reason */
  Price = 'PRICE',
  /** Filter by reason */
  Security = 'SECURITY',
  /** Filter by reason */
  Usability = 'USABILITY',
}

/** Live Lessons recurrence type */
export enum RecurrenceTypeEnum {
  Day = 'Day',
  Month = 'Month',
  Week = 'Week',
}

/** User's subscriptions and payment plans */
export type RecurringPayment = {
  __typename?: 'RecurringPayment';
  /** Cancelled at date */
  cancelledAt: Maybe<Scalars['DateTime']['output']>;
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Payment interval */
  interval: Scalars['String']['output'];
  /** Last payment date */
  lastPaymentAt: Maybe<Scalars['DateTime']['output']>;
  /** Subscription paused status */
  pausedPaymentCollection: Maybe<PausedPaymentCollection>;
  /** Payment type */
  paymentType: Scalars['String']['output'];
  /** Product name */
  productName: Scalars['String']['output'];
  /** Purchase price */
  purchasePrice: Scalars['String']['output'];
  /** Renewal date for active and paused subscriptions */
  renewsOn: Maybe<Scalars['DateTime']['output']>;
  /** Payment status */
  status: Scalars['String']['output'];
  /** Updated at date */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** Redirect to a bundle page */
export type RedirectBundlePageInput = {
  /** Redirect Bundle ID */
  bundleId: Scalars['ID']['input'];
};

/** Redirect to a community page */
export type RedirectCommunityPageInput = {
  /** Redirect Community ID */
  communityId: Scalars['ID']['input'];
};

/** Redirect to a course page */
export type RedirectCoursePageInput = {
  /** Redirect Course ID */
  courseId: Scalars['ID']['input'];
};

/** Redirect to a custom page */
export type RedirectCustomPageInput = {
  /** Redirect Custom Page ID */
  customPageId: Scalars['ID']['input'];
};

/** Redirect to an external page */
export type RedirectExternalPageInput = {
  /** Redirect URL */
  url: Scalars['URL']['input'];
};

/** Redirect to a product price page */
export type RedirectProductPricePageInput = {
  /** Redirect Product Price ID */
  productPriceId: Scalars['ID']['input'];
};

/** Redirect to a site page */
export type RedirectSitePageInput = {
  /** Redirect default page type */
  viewType: RedirectSitePageView;
};

/** Default pages that can be redirected to */
export enum RedirectSitePageView {
  CollectionsLandingPage = 'collections_landing_page',
  ComingSoonPage = 'coming_soon_page',
  HomeLandingPage = 'home_landing_page',
  SignInPage = 'sign_in_page',
  SignUpPage = 'sign_up_page',
  StudentDashboard = 'student_dashboard',
}

/** Reason for the refund */
export enum RefundReason {
  /** duplicate refund */
  Duplicate = 'duplicate',
  /** fraudulent refund */
  Fraudulent = 'fraudulent',
  /** refund requested by customer */
  RequestedByCustomer = 'requested_by_customer',
}

/** Input field for removing bulk sale for a cart */
export type RemoveBulkSaleInput = {
  /** Token identifier of the cart */
  cartToken: Scalars['String']['input'];
};

/** Result of removing bulk sale for a cart */
export type RemoveBulkSaleResult = ResponseInterface & {
  __typename?: 'RemoveBulkSaleResult';
  /**
   * Response Extra Data
   * @deprecated response fields should be well defined following a object structure
   */
  data: Maybe<Scalars['JSON']['output']>;
  errors: Maybe<Array<Maybe<Error>>>;
  /** Response Message */
  message: Maybe<Scalars['String']['output']>;
  /** Return status: SUCCESS, PARTIAL_SUCCESS or FAILED */
  status: Maybe<ResponseStatus>;
};

/** Autogenerated input type of RemoveChapter */
export type RemoveChapterInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The chapter ID */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of RemoveChapter */
export type RemoveChapterPayload = {
  __typename?: 'RemoveChapterPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated return type of RemoveCourseAdmin */
export type RemoveCourseAdminPayload = {
  __typename?: 'RemoveCourseAdminPayload';
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of RemoveItemFromBundle */
export type RemoveItemFromBundleInput = {
  /** The bundle's ID */
  bundleId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The product's ID */
  productId: Scalars['ID']['input'];
};

/** Autogenerated return type of RemoveItemFromBundle */
export type RemoveItemFromBundlePayload = {
  __typename?: 'RemoveItemFromBundlePayload';
  /** The bundle */
  bundle: Maybe<Bundle>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The product */
  product: Maybe<Product>;
  userErrors: Maybe<Array<UserError>>;
};

/** Input field for removing an additional line item from a cart */
export type RemoveLineItemInput = {
  /** Token identifier of the cart to remove the item from */
  cartToken: Scalars['String']['input'];
  /** Id of the line item to remove from the cart */
  itemId: Scalars['ID']['input'];
  /** Type of line item product to remove from the cart (additional product or order bump, e.g.) */
  itemType: Scalars['String']['input'];
  /** Student location */
  location?: InputMaybe<BillingAddressInput>;
};

/** Result of removing an additional line item from a cart */
export type RemoveLineItemResult = ResponseInterface & {
  __typename?: 'RemoveLineItemResult';
  /**
   * Response Extra Data
   * @deprecated response fields should be well defined following a object structure
   */
  data: Maybe<Scalars['JSON']['output']>;
  errors: Maybe<Array<Maybe<Error>>>;
  /** Response Message */
  message: Maybe<Scalars['String']['output']>;
  /** Return status: SUCCESS, PARTIAL_SUCCESS or FAILED */
  status: Maybe<ResponseStatus>;
};

/** Autogenerated input type of RemoveMultiInstanceConn */
export type RemoveMultiInstanceConnInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID */
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated return type of RemoveMultiInstanceConn */
export type RemoveMultiInstanceConnPayload = {
  __typename?: 'RemoveMultiInstanceConnPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /**
   * Response data
   * @deprecated Backwards compatibility for old response
   */
  data: Maybe<Scalars['String']['output']>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of RemoveProductAffiliates */
export type RemoveProductAffiliatesInput = {
  /** Affiliate Id(s), do not pass this argument if you need to remove all the affiliates */
  affiliateIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Product ID */
  productId: Scalars['ID']['input'];
};

/** Autogenerated return type of RemoveProductAffiliates */
export type RemoveProductAffiliatesPayload = {
  __typename?: 'RemoveProductAffiliatesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of RemovePromotion */
export type RemovePromotionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the promotion */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of RemovePromotion */
export type RemovePromotionPayload = {
  __typename?: 'RemovePromotionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of RemoveUsersFromGroups */
export type RemoveUsersFromGroupsInput = {
  /** Boolean for all users selected */
  allSelected?: InputMaybe<Scalars['Boolean']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Filter for users to be removed to Groups */
  filter?: InputMaybe<UserFilter>;
  /** Group IDs */
  groupIds: Array<InputMaybe<Scalars['String']['input']>>;
  /** User IDs */
  userIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Autogenerated return type of RemoveUsersFromGroups */
export type RemoveUsersFromGroupsPayload = {
  __typename?: 'RemoveUsersFromGroupsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /**
   * Response data
   * @deprecated Backwards compatibility for old response data
   */
  data: Maybe<Scalars['JSON']['output']>;
  /**
   * Response error
   * @deprecated Backwards compatibility for old response error
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of RenameGroup */
export type RenameGroupInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The existing Group ID */
  id: Scalars['ID']['input'];
  /** New Group name */
  name: Scalars['String']['input'];
};

/** Autogenerated return type of RenameGroup */
export type RenameGroupPayload = {
  __typename?: 'RenameGroupPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /**
   * Response Extra Data
   * @deprecated Backwards compatibility for old data response
   */
  data: Maybe<Scalars['JSON']['output']>;
  /** @deprecated Backwards compatibility for old errors response */
  errors: Maybe<Array<Error>>;
  /**
   * Response Message
   * @deprecated Backwards compatibility for old message response
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Return status: SUCCESS, PARTIAL_SUCCESS or FAILED
   * @deprecated Backwards compatibility for old status response
   */
  status: Maybe<ResponseStatus>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of RenameThemeInstallation */
export type RenameThemeInstallationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The theme installation id to be renamed */
  id: Scalars['ID']['input'];
  /** The graphql_name for the theme installation */
  name: Scalars['String']['input'];
};

/** Autogenerated return type of RenameThemeInstallation */
export type RenameThemeInstallationPayload = {
  __typename?: 'RenameThemeInstallationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /**
   * Response data
   * @deprecated Backwards compatibility for old response data
   */
  data: Maybe<Scalars['String']['output']>;
  /**
   * Response errors
   * @deprecated Backwards compatibility for old response errors
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of ReorderChapterInCourse */
export type ReorderChapterInCourseInput = {
  /** The chapter ID */
  chapterId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The new position */
  newPosition: Scalars['Int']['input'];
};

/** Autogenerated return type of ReorderChapterInCourse */
export type ReorderChapterInCoursePayload = {
  __typename?: 'ReorderChapterInCoursePayload';
  /** The chapter */
  chapter: Maybe<Chapter>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of ReorderCommunitySpace */
export type ReorderCommunitySpaceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the community_space */
  id: Scalars['ID']['input'];
  /** The community_space position (starts at 0) */
  position: Scalars['NonNegativeInt']['input'];
};

/** Autogenerated return type of ReorderCommunitySpace */
export type ReorderCommunitySpacePayload = {
  __typename?: 'ReorderCommunitySpacePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The community space */
  communitySpace: Maybe<CommunitySpace>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of ReorderItemInBundle */
export type ReorderItemInBundleInput = {
  /** The bundle's ID */
  bundleId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The new position of the item */
  newPosition: Scalars['Int']['input'];
  /** The product's ID */
  productId: Scalars['ID']['input'];
};

/** Autogenerated return type of ReorderItemInBundle */
export type ReorderItemInBundlePayload = {
  __typename?: 'ReorderItemInBundlePayload';
  /** The bundle */
  bundle: Maybe<Bundle>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of ReorderLessonInChapter */
export type ReorderLessonInChapterInput = {
  /** The chapter ID */
  chapterId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the lesson */
  lessonId: Scalars['ID']['input'];
  /** The new position */
  newPosition: Scalars['Int']['input'];
};

/** Autogenerated return type of ReorderLessonInChapter */
export type ReorderLessonInChapterPayload = {
  __typename?: 'ReorderLessonInChapterPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The lesson */
  lesson: Maybe<LessonUnion>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of ReorderProduct */
export type ReorderProductInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Product's ID */
  id: Scalars['ID']['input'];
  /** Product's position */
  newPosition: Scalars['Int']['input'];
};

/** Autogenerated return type of ReorderProduct */
export type ReorderProductPayload = {
  __typename?: 'ReorderProductPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /**
   * Response errors
   * @deprecated Backwards compatibility for old errors response
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /** The product that was reordered */
  product: Maybe<Product>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Post reply information */
export type Reply = Node & {
  __typename?: 'Reply';
  /** The content of the reply */
  content: Maybe<Scalars['String']['output']>;
  /** When the reply was created */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** Reply depth */
  depth: Maybe<Scalars['Int']['output']>;
  /** When the reply was edited */
  editedAt: Maybe<Scalars['DateTime']['output']>;
  /** Whether the user has reacted to the reply */
  hasReacted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** Whether the user is following the reply */
  isFollowing: Scalars['Boolean']['output'];
  /** Users mentioned in the reply */
  mentionedUsers: Maybe<UserConnection>;
  /** This reply's parent post */
  parentPost: Maybe<Post>;
  /** The reply attachments */
  postAttachments: Maybe<Array<PostAttachment>>;
  /** Reactions to this reply */
  postReactions: Maybe<PostReactionConnection>;
  /** Replies to this reply */
  replies: ReplyConnection;
  /** The user that replied */
  user: Maybe<User>;
};

/** Post reply information */
export type ReplyMentionedUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Post reply information */
export type ReplyPostReactionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Post reply information */
export type ReplyRepliesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ReplyOrderBy>;
};

/** The connection type for Reply. */
export type ReplyConnection = {
  __typename?: 'ReplyConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ReplyEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Reply>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of posts, comments, or replies */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ReplyEdge = {
  __typename?: 'ReplyEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Reply>;
};

/** Possible fields to order posts */
export type ReplyOrderBy = {
  direction?: InputMaybe<OrderDirection>;
  field?: InputMaybe<ReplyOrderField>;
};

/** Possible fields to order post replies */
export enum ReplyOrderField {
  /** Order posts by content */
  Content = 'CONTENT',
  /** Order posts by created_at */
  CreatedAt = 'CREATED_AT',
  /** Order posts by updated_at */
  UpdatedAt = 'UPDATED_AT',
}

/** Interface for Looker Dashboards */
export type ReportingDashboardsTenantQueryInterface = {
  /** Looker dashboard */
  lookerDashboard: Maybe<LookerDashboard>;
  /** List of looker tabs the user has access to */
  reportAllowlist: Maybe<Array<Scalars['String']['output']>>;
};

/** Interface for Looker Dashboards */
export type ReportingDashboardsTenantQueryInterfaceLookerDashboardArgs = {
  dashboardName: LookerDashboardNameEnum;
};

/** Order for reporting */
export type ReportingOrderBy = {
  direction: OrderDirection;
  field: ReportingOrderField;
};

/** Possible fields to order reporting */
export enum ReportingOrderField {
  /** order by completedAt date */
  CompletionDate = 'completionDate',
  /** order by content name date */
  ContentName = 'contentName',
  /** order by content type */
  ContentType = 'contentType',
  /** order by completedAt date */
  CourseName = 'courseName',
  /** order by passing grade (for quiz/surveys) */
  PassingGrade = 'passingGrade',
  /** order by student grade (for quiz/surveys) */
  StudentGrade = 'studentGrade',
}

/** Autogenerated input type of ResendMultiInstanceEmail */
export type ResendMultiInstanceEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Search by connection token */
  connection_token?: InputMaybe<Scalars['String']['input']>;
  /** Search by item ID */
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated return type of ResendMultiInstanceEmail */
export type ResendMultiInstanceEmailPayload = {
  __typename?: 'ResendMultiInstanceEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /**
   * Response data
   * @deprecated Backwards compatibility for old response
   */
  data: Maybe<Scalars['String']['output']>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of ResendVerificationEmail */
export type ResendVerificationEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ResendVerificationEmail */
export type ResendVerificationEmailPayload = {
  __typename?: 'ResendVerificationEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

export type ResponseInterface = {
  /**
   * Response Extra Data
   * @deprecated response fields should be well defined following a object structure
   */
  data: Maybe<Scalars['JSON']['output']>;
  errors: Maybe<Array<Maybe<Error>>>;
  /** Response Message */
  message: Maybe<Scalars['String']['output']>;
  /** Return status: SUCCESS, PARTIAL_SUCCESS or FAILED */
  status: Maybe<ResponseStatus>;
};

/** Response status for mutations */
export enum ResponseStatus {
  /** Process failed */
  Failed = 'FAILED',
  /** Process partially successful due to some ids returned with errors */
  PartialSuccess = 'PARTIAL_SUCCESS',
  /** Process successfully executed */
  Success = 'SUCCESS',
}

/** Autogenerated input type of RestoreThemeInstallation */
export type RestoreThemeInstallationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The theme installation to be restored */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of RestoreThemeInstallation */
export type RestoreThemeInstallationPayload = {
  __typename?: 'RestoreThemeInstallationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /**
   * Response data
   * @deprecated Backwards compatibility for old response data
   */
  data: Maybe<Scalars['String']['output']>;
  /**
   * Response errors
   * @deprecated Backwards compatibility for old response errors
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of ResumeRecurringPayment */
export type ResumeRecurringPaymentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Subscription or Payment plan Id */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of ResumeRecurringPayment */
export type ResumeRecurringPaymentPayload = {
  __typename?: 'ResumeRecurringPaymentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Response data */
  resumedSubscription: Maybe<ResumedSubscriptionResponse>;
  userErrors: Maybe<Array<UserError>>;
};

/** Response data for Pause or Resume Recurring Payment */
export type ResumedSubscriptionResponse = {
  __typename?: 'ResumedSubscriptionResponse';
  /** Renews on date */
  renewsOn: Maybe<Scalars['DateTime']['output']>;
};

/** Autogenerated input type of RetryRecurringPayment */
export type RetryRecurringPaymentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Subscription or Payment plan ID */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of RetryRecurringPayment */
export type RetryRecurringPaymentPayload = {
  __typename?: 'RetryRecurringPaymentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Course information */
export type ReviewCourse = CourseInterface &
  Node &
  ProductableInterface & {
    __typename?: 'ReviewCourse';
    /** Accredible certificate expiry date */
    accredibleCertificateExpiryDate: Maybe<Scalars['Date']['output']>;
    /** Accredible certificate template name */
    accredibleCertificateTemplateName: Maybe<Scalars['String']['output']>;
    /**
     * A list of after purchase flows that belong to the product
     * @deprecated Access via the product field instead
     */
    afterPurchaseFlows: Maybe<AfterPurchaseFlowConnection>;
    /** Video lesson autoplay */
    autoplayAllVideos: Maybe<Scalars['Boolean']['output']>;
    /** Course has no enrollments and can be destroyed */
    canBeDestroyed: Maybe<Scalars['Boolean']['output']>;
    /** Product's card image url */
    cardImageUrl: Maybe<Scalars['String']['output']>;
    /** Completion Page html */
    completionPageHtml: Maybe<Scalars['String']['output']>;
    /**
     * Course id
     * @deprecated Avoid using the decoded ID, use the encoded id instead
     */
    courseId: Maybe<Scalars['Int']['output']>;
    /** Course player style font */
    coursePlayerFont: Maybe<Scalars['String']['output']>;
    /** Course player style primary color */
    coursePlayerPrimaryColor: Maybe<Scalars['String']['output']>;
    /** Course player theme */
    coursePlayerTheme: Maybe<Scalars['String']['output']>;
    /** Course Settings Social Share Message */
    courseSettingsSocialShareText: Maybe<Scalars['String']['output']>;
    /** Product's created date */
    createdAt: Maybe<Scalars['DateTime']['output']>;
    /** Days until Accredible Certificate Expiry */
    daysUntilAccredibleCertificateExpiry: Maybe<Scalars['Int']['output']>;
    /** Product's description */
    description: Maybe<Scalars['String']['output']>;
    /** Disable chapter completed modal */
    disableChapterCompletedModal: Maybe<Scalars['Boolean']['output']>;
    /** disable text selection */
    disableTextSelection: Maybe<Scalars['Boolean']['output']>;
    /** The number of enrollments for this course */
    enrollmentsCount: Maybe<Scalars['Int']['output']>;
    /** Whether new affiliates is excluded for a product */
    excludeNewAffiliates: Maybe<Scalars['Boolean']['output']>;
    /** Use Custom Completion page */
    hasCustomCompletionPage: Maybe<Scalars['Boolean']['output']>;
    /** Hide Lesson Type Icon */
    hideLessonTypeIcon: Maybe<Scalars['Boolean']['output']>;
    /** Hide Lesson Type Icon and label for a course */
    hideLessonTypeLabelAndIcon: Maybe<Scalars['Boolean']['output']>;
    /** Course ID */
    id: Scalars['ID']['output'];
    /** The instructor for this course */
    instructor: Maybe<Instructor>;
    /** Whether the course is used for channels */
    isChannel: Maybe<Scalars['Boolean']['output']>;
    /** Whether the course is editable */
    isEditable: Maybe<Scalars['Boolean']['output']>;
    /** Whether the product is hidden */
    isHidden: Maybe<Scalars['Boolean']['output']>;
    /** Whether the product is presell */
    isPresell: Maybe<Scalars['Boolean']['output']>;
    /** Whether the product is private */
    isPrivate: Maybe<Scalars['Boolean']['output']>;
    /** Whether the product is published */
    isPublished: Maybe<Scalars['Boolean']['output']>;
    /** Video completion required */
    isVideoCompletionRequired: Maybe<Scalars['Boolean']['output']>;
    /** Product's keywords */
    keywords: Maybe<Scalars['String']['output']>;
    /** Product's Page footer code */
    landingPageLiquidMarkup: Maybe<Scalars['String']['output']>;
    /** The landing page URL */
    landingPageUrl: Maybe<Scalars['String']['output']>;
    /** Course logo */
    logo: Maybe<Scalars['String']['output']>;
    /** Course name */
    name: Maybe<Scalars['String']['output']>;
    /** The percentage completed for the current user */
    percentageCompleted: Maybe<Scalars['Float']['output']>;
    /** The product position for ordering */
    position: Maybe<Scalars['Int']['output']>;
    /**
     * Is product private
     * @deprecated Use isPrivate instead
     */
    private: Maybe<Scalars['Boolean']['output']>;
    /** Product associated with productable */
    product: Maybe<Product>;
    /**
     * Product's id
     * @deprecated Access product id via the product field instead
     */
    productId: Maybe<Scalars['String']['output']>;
    /**
     * A list of product prices that belongs to the product
     * @deprecated Access via the product field instead
     */
    productPrices: Maybe<ProductPriceConnection>;
    /** The url by which one can be directed to continue the course */
    resumeCourseUrl: Maybe<Scalars['String']['output']>;
    /** Product's SEO description */
    seoDescription: Maybe<Scalars['String']['output']>;
    /** Product's SEO title */
    seoTitle: Maybe<Scalars['String']['output']>;
    /** Show course logo */
    showLogo: Maybe<Scalars['Boolean']['output']>;
    /** Product's Site Builder Link */
    siteBuilderLink: Maybe<Scalars['String']['output']>;
    /** Product's slug */
    slug: Maybe<Scalars['String']['output']>;
    /** Course settings social share at chapter completion is overridden */
    socialShareChapterCompletionOverridden: Maybe<Scalars['Boolean']['output']>;
    /** Course settings social share at course completion is overridden */
    socialShareCourseCompletionOverridden: Maybe<Scalars['Boolean']['output']>;
    /** Product's status */
    status: Maybe<Scalars['String']['output']>;
    /** Product's title */
    title: Maybe<Scalars['String']['output']>;
    /** Vieo completion percentage */
    videoCompletionPercentage: Maybe<Scalars['Int']['output']>;
  };

/** Course information */
export type ReviewCourseAfterPurchaseFlowsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Course information */
export type ReviewCourseProductPricesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Filter options for roles */
export type RoleFilter = {
  /** users who have specified roles */
  is?: InputMaybe<Array<InputMaybe<UserRole>>>;
  /** users who does not have specified roles */
  isNot?: InputMaybe<Array<InputMaybe<UserRole>>>;
};

/** safelist all site's domains in active campaign */
export type SafelistDomains = ResponseInterface & {
  __typename?: 'SafelistDomains';
  /**
   * Response Extra Data
   * @deprecated response fields should be well defined following a object structure
   */
  data: Maybe<Scalars['JSON']['output']>;
  errors: Maybe<Array<Maybe<Error>>>;
  /** Response Message */
  message: Maybe<Scalars['String']['output']>;
  /** Return status: SUCCESS, PARTIAL_SUCCESS or FAILED */
  status: Maybe<ResponseStatus>;
};

/** Accepts a data structure to override the queried export values with sample data */
export type SampleDataOverride = {
  /** Sample data */
  data?: InputMaybe<Scalars['String']['input']>;
};

/** Input field to save a new/existing changelog entry */
export type SaveEntryInput = {
  /** Author of entry */
  author: Scalars['String']['input'];
  /** Category in which entry belongs */
  category: Scalars['String']['input'];
  /** Description of entry */
  description: Scalars['String']['input'];
  /** ID of entry */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Date published */
  publishDate: Scalars['String']['input'];
  /** Time published */
  publishTime: Scalars['String']['input'];
  /** Title of entry */
  title: Scalars['String']['input'];
  /** Visibility of entry */
  visibility: ChangelogEntryVisibilities;
};

/** Result of saving a new/existing changelog entry */
export type SaveEntryResult = ResponseInterface & {
  __typename?: 'SaveEntryResult';
  /**
   * Response Extra Data
   * @deprecated response fields should be well defined following a object structure
   */
  data: Maybe<Scalars['JSON']['output']>;
  errors: Maybe<Array<Maybe<Error>>>;
  /** Response Message */
  message: Maybe<Scalars['String']['output']>;
  /** Return status: SUCCESS, PARTIAL_SUCCESS or FAILED */
  status: Maybe<ResponseStatus>;
};

/** Autogenerated input type of SaveSitePage */
export type SaveSitePageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Whether to publish the page or not? */
  publish?: InputMaybe<Scalars['Boolean']['input']>;
  /** The attributes of the sections to be saved */
  sectionAttributes: Array<SectionAttributesInput>;
  /** The ID of the site page to be saved */
  sitePageId: Scalars['ID']['input'];
};

/** Autogenerated return type of SaveSitePage */
export type SaveSitePagePayload = {
  __typename?: 'SaveSitePagePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The site page */
  sitePage: Maybe<TemplatePages>;
  userErrors: Maybe<Array<UserError>>;
};

/** An object that contains additional details for a scope */
export type Scope = {
  __typename?: 'Scope';
  /** Description of scope */
  description: Scalars['String']['output'];
  /** Slug of scope */
  needsConsent: Scalars['Boolean']['output'];
  /** Slug of scope */
  slug: Scalars['String']['output'];
  /** User-friendly title for scope */
  title: Scalars['String']['output'];
  /** Value of scope */
  value: Scalars['String']['output'];
};

/** Search Bookmark information */
export type SearchBookmark = Node & {
  __typename?: 'SearchBookmark';
  id: Scalars['ID']['output'];
  /** Search Bookmark name */
  name: Maybe<Scalars['String']['output']>;
  /** Search Bookmark search */
  search: Maybe<Scalars['JSON']['output']>;
  /** Tenant id */
  tenantId: Maybe<Scalars['Int']['output']>;
  /** User id */
  userId: Maybe<Scalars['Int']['output']>;
};

/** The connection type for SearchBookmark. */
export type SearchBookmarkConnection = {
  __typename?: 'SearchBookmarkConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<SearchBookmarkEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<SearchBookmark>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SearchBookmarkEdge = {
  __typename?: 'SearchBookmarkEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<SearchBookmark>;
};

/** Possible fields to filter groups */
export type SearchBookmarkFilter = {
  /** Search by type */
  bookmarkType?: InputMaybe<Scalars['String']['input']>;
  /** Search by id */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Search by name */
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Input fields for a SearchBookmark */
export type SearchBookmarkInput = {
  /** SearchBookmark type */
  bookmark_type?: InputMaybe<Scalars['String']['input']>;
  /** SearchBookmark's name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** SearchBookmark's search */
  search?: InputMaybe<Scalars['JSON']['input']>;
};

/** Searchable Post information */
export type SearchablePost = Node & {
  __typename?: 'SearchablePost';
  /** The community_space the post is attached to */
  communitySpace: Maybe<CommunitySpace>;
  /** The post content */
  content: Maybe<Scalars['String']['output']>;
  /** When the post was created */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** Reply depth */
  depth: Maybe<Scalars['Int']['output']>;
  /** Timestamp of the last time this post was edited */
  editedAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** Users mentioned in the post */
  mentionedUsers: Maybe<UserConnection>;
  /** The post attachments */
  postAttachments: Maybe<Array<PostAttachment>>;
  /** The post id */
  postId: Scalars['ID']['output'];
  /** The post type */
  postType: Maybe<Scalars['String']['output']>;
  /** The root post for the comment or reply */
  rootPost: Maybe<Post>;
  /** The post title */
  title: Maybe<Scalars['String']['output']>;
  /** When the post was updated */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  /** The user that created the post */
  user: Maybe<User>;
};

/** Searchable Post information */
export type SearchablePostMentionedUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for SearchablePost. */
export type SearchablePostConnection = {
  __typename?: 'SearchablePostConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<SearchablePostEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<SearchablePost>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SearchablePostEdge = {
  __typename?: 'SearchablePostEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<SearchablePost>;
};

/** Input fields for saving draft section attributes */
export type SectionAttributesInput = {
  /** The ID of the section */
  id: Scalars['ID']['input'];
  /** The new position for the section */
  newPosition: Scalars['Int']['input'];
  /** The new settings for the section */
  newSettings: Scalars['JSON']['input'];
};

/** Send Email All Users' input fields */
export type SendEmailAllUsersInput = {
  /** Email's body */
  body: Scalars['String']['input'];
  /** Filter for users to be emailed */
  filter?: InputMaybe<UserFilter>;
  /** Include self */
  includeSelf?: InputMaybe<Scalars['Boolean']['input']>;
  /** Reply to email */
  replyToEmail: Scalars['String']['input'];
  /** Email's subject */
  subject: Scalars['String']['input'];
  /** Send test email */
  testEmail?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Attributes to send email */
export type SendEmailInput = {
  /** Email's body */
  body: Scalars['String']['input'];
  /** Include self */
  includeSelf?: InputMaybe<Scalars['Boolean']['input']>;
  /** Reply to email */
  replyToEmail: Scalars['String']['input'];
  /** Email's subject */
  subject: Scalars['String']['input'];
  /** Send test email */
  testEmail?: InputMaybe<Scalars['Boolean']['input']>;
  /** List of user ids to receive the email */
  userIds: Array<Scalars['ID']['input']>;
};

/** Send Email Result */
export type SendEmailResult = ResponseInterface & {
  __typename?: 'SendEmailResult';
  /**
   * Response Extra Data
   * @deprecated response fields should be well defined following a object structure
   */
  data: Maybe<Scalars['JSON']['output']>;
  errors: Maybe<Array<Maybe<Error>>>;
  /** Response Message */
  message: Maybe<Scalars['String']['output']>;
  /** Return status: SUCCESS, PARTIAL_SUCCESS or FAILED */
  status: Maybe<ResponseStatus>;
};

/** Autogenerated return type of SendMfaVerificationEmail */
export type SendMfaVerificationEmailPayload = {
  __typename?: 'SendMfaVerificationEmailPayload';
  /** Date email verification sent at */
  emailSentDate: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of SetBankAccount */
export type SetBankAccountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Bank Account token */
  token: Scalars['String']['input'];
};

/** Autogenerated return type of SetBankAccount */
export type SetBankAccountPayload = {
  __typename?: 'SetBankAccountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Input field for setting bulk sale for a cart */
export type SetBulkSaleInput = {
  /** Token identifier of the cart */
  cartToken: Scalars['String']['input'];
  /** Line item quantity */
  quantity: Scalars['Int']['input'];
};

/** Result of setting bulk sale for a cart */
export type SetBulkSaleResult = ResponseInterface & {
  __typename?: 'SetBulkSaleResult';
  /**
   * Response Extra Data
   * @deprecated response fields should be well defined following a object structure
   */
  data: Maybe<Scalars['JSON']['output']>;
  errors: Maybe<Array<Maybe<Error>>>;
  /** Response Message */
  message: Maybe<Scalars['String']['output']>;
  /** Return status: SUCCESS, PARTIAL_SUCCESS or FAILED */
  status: Maybe<ResponseStatus>;
};

/** Autogenerated input type of SetCurrency */
export type SetCurrencyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Three-letter ISO currency code */
  code: Scalars['String']['input'];
};

/** Autogenerated return type of SetCurrency */
export type SetCurrencyPayload = {
  __typename?: 'SetCurrencyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of SetHomePageRedirect */
export type SetHomePageRedirectInput = {
  /** Redirect to a bundle page */
  bundlePage?: InputMaybe<RedirectBundlePageInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Redirect to a community page */
  communityPage?: InputMaybe<RedirectCommunityPageInput>;
  /** Redirect to a course page */
  coursePage?: InputMaybe<RedirectCoursePageInput>;
  /** Redirect to a custom page */
  customPage?: InputMaybe<RedirectCustomPageInput>;
  /** Redirect to a external page */
  externalPage?: InputMaybe<RedirectExternalPageInput>;
  /** Redirect to a product price page */
  productPricePage?: InputMaybe<RedirectProductPricePageInput>;
  /** Redirect to a site page */
  sitePage?: InputMaybe<RedirectSitePageInput>;
};

/** Autogenerated return type of SetHomePageRedirect */
export type SetHomePageRedirectPayload = {
  __typename?: 'SetHomePageRedirectPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** View manifest of the page being redirect to */
  redirectedToViewManifest: Maybe<CustomSiteThemeViewManifest>;
  userErrors: Maybe<Array<UserError>>;
  /** Home page view */
  view: Maybe<CustomSiteThemeView>;
};

/** Includes all the shared inputs for "Apply to all" lesson settings. */
export type SettingsInput = {
  /** Student required to complete lessons (Presentation only) */
  completionRequired?: InputMaybe<Scalars['Boolean']['input']>;
  /** Enable discussions */
  discussionsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Make lessons downloadable (PDF only) */
  downloadable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Remove margins in course player */
  expandEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Metadata about an export job for advanced reporting data. */
export type SidekiqJobId = ResponseInterface & {
  __typename?: 'SidekiqJobId';
  /**
   * Response Extra Data
   * @deprecated response fields should be well defined following a object structure
   */
  data: Maybe<Scalars['JSON']['output']>;
  errors: Maybe<Array<Maybe<Error>>>;
  /** ID of the export job */
  jobId: Maybe<Scalars['String']['output']>;
  /** Response Message */
  message: Maybe<Scalars['String']['output']>;
  /** Return status: SUCCESS, PARTIAL_SUCCESS or FAILED */
  status: Maybe<ResponseStatus>;
};

/** Check sidekiq job status */
export type SidekiqJobStatus = {
  __typename?: 'SidekiqJobStatus';
  /** SidekiqJob's reason for erroring */
  errorReasons: Maybe<Array<Scalars['String']['output']>>;
  /** SidekiqJob's metadata */
  metadata: Maybe<Scalars['JSON']['output']>;
  /** SidekiqJob's status */
  status: Maybe<Scalars['String']['output']>;
};

/** Inputs for signing in an existing tenant */
export type SignInExistingTenantInfo = {
  /** Onboarding Flow ID */
  onboardingFlowId: Scalars['ID']['input'];
  /** Google recaptcha value */
  recaptchaValue?: InputMaybe<Scalars['String']['input']>;
  /** Tenant subdomain */
  subdomain: Scalars['String']['input'];
};

/** Autogenerated input type of SignInExistingTenant */
export type SignInExistingTenantInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  input: SignInExistingTenantInfo;
};

/** Autogenerated return type of SignInExistingTenant */
export type SignInExistingTenantPayload = {
  __typename?: 'SignInExistingTenantPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Redirect URL address */
  redirectUrl: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Tenant settings relating to sign up */
export type SignUpSettings = {
  __typename?: 'SignUpSettings';
  /** Password Complexity Settings */
  passwordComplexity: Maybe<PasswordComplexitySettings>;
  /** Path to the privacy agreement */
  privacyAgreementPath: Maybe<Scalars['String']['output']>;
  /** Path to the terms and services agreement */
  termsAgreementPath: Maybe<Scalars['String']['output']>;
};

/** Creates and signs in a new user for a given tenant */
export type SignUpUser = ResponseInterface & {
  __typename?: 'SignUpUser';
  /**
   * Response Extra Data
   * @deprecated response fields should be well defined following a object structure
   */
  data: Maybe<Scalars['JSON']['output']>;
  errors: Maybe<Array<Maybe<Error>>>;
  /** Response Message */
  message: Maybe<Scalars['String']['output']>;
  /** Return status: SUCCESS, PARTIAL_SUCCESS or FAILED */
  status: Maybe<ResponseStatus>;
};

/** Input field for SignUpUser mutation */
export type SignUpUserInput = {
  /** Any custom profile fields for user registration */
  customProfileFields?: InputMaybe<Array<CustomProfileFieldInput>>;
  /** The email of the user */
  email: Scalars['String']['input'];
  /** The first name of the user */
  firstName: Scalars['String']['input'];
  /** The last name of the user */
  lastName: Scalars['String']['input'];
  /** Student location */
  location?: InputMaybe<BillingAddressInput>;
  /** The password of the user */
  password?: InputMaybe<Scalars['String']['input']>;
  /** The token returned from checkout's invisible Recaptcha widget */
  recaptchaToken?: InputMaybe<Scalars['String']['input']>;
  /** Has the user accepted the terms and privacy */
  terms?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Site admins feature */
export type SiteAdminsFeature = FeatureInterface & {
  __typename?: 'SiteAdminsFeature';
  /** Feature checkout path */
  checkoutPath: Maybe<Scalars['String']['output']>;
  /** Flag to indicate feature is enabled */
  isEnabled: Maybe<Scalars['Boolean']['output']>;
  /** Maximum number of site admins */
  maxSiteAdmins: Scalars['Int']['output'];
  /** The name of the minimum plan required to access this feature */
  minimumPlanName: Maybe<Scalars['String']['output']>;
};

/** A site layout */
export type SiteLayout = Node & {
  __typename?: 'SiteLayout';
  /** Whether this is the defaultlayout */
  default: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** The name of the layout */
  name: Scalars['String']['output'];
  /** The dynamic site sections for this layout */
  siteSections: Array<SiteSection>;
  /** The site template ID */
  siteTemplateId: Scalars['ID']['output'];
  /** The identifier for the layout template */
  templateIdentifier: Scalars['String']['output'];
};

/** A site page */
export type SitePage = Node & {
  __typename?: 'SitePage';
  /** Whether this page is homepage */
  homepage: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** The name of the page */
  name: Scalars['String']['output'];
  /** The page type */
  pageType: Scalars['String']['output'];
  /** The associated product */
  product: Maybe<Product>;
  /** The layout for this page */
  siteLayout: Maybe<SiteLayout>;
  /** The dynamic site sections for this page */
  siteSections: Array<SiteSection>;
  /** The site template ID */
  siteTemplateId: Scalars['ID']['output'];
  /** The page slug */
  slug: Scalars['String']['output'];
  /** The identifier for the page template */
  templateIdentifier: Scalars['String']['output'];
};

/** The connection type for SitePage. */
export type SitePageConnection = {
  __typename?: 'SitePageConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<SitePageEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<SitePage>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SitePageEdge = {
  __typename?: 'SitePageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<SitePage>;
};

/** Active addon site subscription associated with tenant */
export type SitePlan = {
  __typename?: 'SitePlan';
  /** Timestamp of creation */
  createdAt: Scalars['String']['output'];
  /** Fee percentage owed on plan */
  feePercentage: Scalars['Int']['output'];
  /** Site plan human readable name */
  name: Scalars['String']['output'];
  /** Type of plan, either main or addon */
  planType: PlanTypeEnum;
  /**
   * Price associated with plan
   * @deprecated Use prices instead
   */
  price: SiteSubscriptionPlan;
  /** Prices associated with plan */
  prices: Array<SiteSubscriptionPlan>;
  /** Site plan slug */
  slug: Scalars['String']['output'];
  /** Timestamp of last update */
  updatedAt: Scalars['String']['output'];
  /** Site plan hierarchy weight */
  weight: Scalars['Int']['output'];
};

/** Site script information */
export type SiteScript = {
  __typename?: 'SiteScript';
  /** Description of what the site script does */
  description: Maybe<Scalars['String']['output']>;
  /** Is the site script enabled? */
  enabled: Scalars['Boolean']['output'];
  /** Name for the site script */
  name: Maybe<Scalars['String']['output']>;
  /** Pages that the site script has access to */
  pageScopes: Maybe<Array<Maybe<PageScope>>>;
};

/** A site section */
export type SiteSection = Node & {
  __typename?: 'SiteSection';
  id: Scalars['ID']['output'];
  /** The position of the section */
  position: Scalars['Int']['output'];
  /** The position draft of the section */
  positionDraft: Maybe<Scalars['Int']['output']>;
  /** The settings for this section */
  settings: Array<SiteSectionSettingUnion>;
  /** The settings draft for this section */
  settingsDraft: Maybe<Array<SiteSectionSettingUnion>>;
  /** The site template ID */
  siteTemplateId: Scalars['ID']['output'];
  /** The section target key */
  targetKey: Scalars['String']['output'];
  /** The identifier for the section template */
  templateIdentifier: Scalars['String']['output'];
};

/** A block within a site section setting for a list of blocks */
export type SiteSectionSettingBlock = {
  __typename?: 'SiteSectionSettingBlock';
  /** The block type */
  blockType: Scalars['String']['output'];
  /** The settings for this block */
  settings: Array<SiteSectionSettingUnion>;
};

/** A site section setting for a list of blocks */
export type SiteSectionSettingBlockList = SiteSectionSettingInterface & {
  __typename?: 'SiteSectionSettingBlockList';
  /** The blocks in this block list */
  blocks: Array<SiteSectionSettingBlock>;
  /** The name of this setting */
  name: Scalars['String']['output'];
  /** The type of this setting */
  type: Scalars['String']['output'];
  /** The value_type of this setting */
  valueType: Scalars['String']['output'];
};

/** A site section setting for a boolean */
export type SiteSectionSettingBoolean = SiteSectionSettingInterface & {
  __typename?: 'SiteSectionSettingBoolean';
  /** The boolean value */
  boolean: Maybe<Scalars['Boolean']['output']>;
  /** The name of this setting */
  name: Scalars['String']['output'];
  /** The type of this setting */
  type: Scalars['String']['output'];
  /** The value_type of this setting */
  valueType: Scalars['String']['output'];
};

/** A site section setting for a Collection */
export type SiteSectionSettingCollection = SiteSectionSettingInterface & {
  __typename?: 'SiteSectionSettingCollection';
  /** The collection */
  collection: Maybe<Collection>;
  /** The name of this setting */
  name: Scalars['String']['output'];
  /** The type of this setting */
  type: Scalars['String']['output'];
  /** The value_type of this setting */
  valueType: Scalars['String']['output'];
};

/** A site section setting for a Course */
export type SiteSectionSettingCourse = SiteSectionSettingInterface & {
  __typename?: 'SiteSectionSettingCourse';
  /** The course */
  course: Maybe<Course>;
  /** The name of this setting */
  name: Scalars['String']['output'];
  /** The type of this setting */
  type: Scalars['String']['output'];
  /** The value_type of this setting */
  valueType: Scalars['String']['output'];
};

/** A site section setting for a list of Courses */
export type SiteSectionSettingCourseList = SiteSectionSettingInterface & {
  __typename?: 'SiteSectionSettingCourseList';
  /** The courses */
  courseList: Maybe<Array<Course>>;
  /** The name of this setting */
  name: Scalars['String']['output'];
  /** The type of this setting */
  type: Scalars['String']['output'];
  /** The value_type of this setting */
  valueType: Scalars['String']['output'];
};

/** A site section setting for a Course Review */
export type SiteSectionSettingCourseReview = SiteSectionSettingInterface & {
  __typename?: 'SiteSectionSettingCourseReview';
  /** The course review */
  courseReview: Maybe<CourseReview>;
  /** The name of this setting */
  name: Scalars['String']['output'];
  /** The type of this setting */
  type: Scalars['String']['output'];
  /** The value_type of this setting */
  valueType: Scalars['String']['output'];
};

/** A site section setting for a list of Course Reviews */
export type SiteSectionSettingCourseReviewList = SiteSectionSettingInterface & {
  __typename?: 'SiteSectionSettingCourseReviewList';
  /** The course reviews */
  courseReviewList: Array<CourseReview>;
  /** The name of this setting */
  name: Scalars['String']['output'];
  /** The type of this setting */
  type: Scalars['String']['output'];
  /** The value_type of this setting */
  valueType: Scalars['String']['output'];
};

/** A site section setting for a date */
export type SiteSectionSettingDate = SiteSectionSettingInterface & {
  __typename?: 'SiteSectionSettingDate';
  /** The date value */
  date: Maybe<Scalars['Date']['output']>;
  /** The name of this setting */
  name: Scalars['String']['output'];
  /** The type of this setting */
  type: Scalars['String']['output'];
  /** The value_type of this setting */
  valueType: Scalars['String']['output'];
};

/** A site section setting for a datetime */
export type SiteSectionSettingDateTime = SiteSectionSettingInterface & {
  __typename?: 'SiteSectionSettingDateTime';
  /** The date value */
  datetime: Maybe<Scalars['DateTime']['output']>;
  /** The name of this setting */
  name: Scalars['String']['output'];
  /** The type of this setting */
  type: Scalars['String']['output'];
  /** The value_type of this setting */
  valueType: Scalars['String']['output'];
};

/** A site section setting for a float value */
export type SiteSectionSettingFloat = SiteSectionSettingInterface & {
  __typename?: 'SiteSectionSettingFloat';
  /** The float value */
  float: Maybe<Scalars['Float']['output']>;
  /** The name of this setting */
  name: Scalars['String']['output'];
  /** The type of this setting */
  type: Scalars['String']['output'];
  /** The value_type of this setting */
  valueType: Scalars['String']['output'];
};

/** A site section setting for an instructor */
export type SiteSectionSettingInstructor = SiteSectionSettingInterface & {
  __typename?: 'SiteSectionSettingInstructor';
  /** The instructor */
  instructor: Maybe<Instructor>;
  /** The name of this setting */
  name: Scalars['String']['output'];
  /** The type of this setting */
  type: Scalars['String']['output'];
  /** The value_type of this setting */
  valueType: Scalars['String']['output'];
};

/** A site section setting */
export type SiteSectionSettingInterface = {
  /** The name of this setting */
  name: Scalars['String']['output'];
  /** The type of this setting */
  type: Scalars['String']['output'];
  /** The value_type of this setting */
  valueType: Scalars['String']['output'];
};

/** A site section setting for a Product */
export type SiteSectionSettingProduct = SiteSectionSettingInterface & {
  __typename?: 'SiteSectionSettingProduct';
  /** The name of this setting */
  name: Scalars['String']['output'];
  /** The product */
  product: Maybe<Product>;
  /** The type of this setting */
  type: Scalars['String']['output'];
  /** The value_type of this setting */
  valueType: Scalars['String']['output'];
};

/** A site section setting for a list of Products */
export type SiteSectionSettingProductList = SiteSectionSettingInterface & {
  __typename?: 'SiteSectionSettingProductList';
  /** The name of this setting */
  name: Scalars['String']['output'];
  /** The products */
  productList: Array<Product>;
  /** The type of this setting */
  type: Scalars['String']['output'];
  /** The value_type of this setting */
  valueType: Scalars['String']['output'];
};

/** A site section setting for a Product Price */
export type SiteSectionSettingProductPrice = SiteSectionSettingInterface & {
  __typename?: 'SiteSectionSettingProductPrice';
  /** The name of this setting */
  name: Scalars['String']['output'];
  /** The product price */
  productPrice: Maybe<ProductPrice>;
  /** The type of this setting */
  type: Scalars['String']['output'];
  /** The value_type of this setting */
  valueType: Scalars['String']['output'];
};

/** A site section setting for a list of Product Prices */
export type SiteSectionSettingProductPriceList = SiteSectionSettingInterface & {
  __typename?: 'SiteSectionSettingProductPriceList';
  /** The name of this setting */
  name: Scalars['String']['output'];
  /** The product prices */
  productPriceList: Maybe<Array<ProductPrice>>;
  /** The type of this setting */
  type: Scalars['String']['output'];
  /** The value_type of this setting */
  valueType: Scalars['String']['output'];
};

/** A site section setting for a string */
export type SiteSectionSettingString = SiteSectionSettingInterface & {
  __typename?: 'SiteSectionSettingString';
  /** The name of this setting */
  name: Scalars['String']['output'];
  /** The string value */
  string: Maybe<Scalars['String']['output']>;
  /** The type of this setting */
  type: Scalars['String']['output'];
  /** The value_type of this setting */
  valueType: Scalars['String']['output'];
};

/** A site section setting */
export type SiteSectionSettingUnion =
  | SiteSectionSettingBlockList
  | SiteSectionSettingBoolean
  | SiteSectionSettingCollection
  | SiteSectionSettingCourse
  | SiteSectionSettingCourseList
  | SiteSectionSettingCourseReview
  | SiteSectionSettingCourseReviewList
  | SiteSectionSettingDate
  | SiteSectionSettingDateTime
  | SiteSectionSettingFloat
  | SiteSectionSettingInstructor
  | SiteSectionSettingProduct
  | SiteSectionSettingProductList
  | SiteSectionSettingProductPrice
  | SiteSectionSettingProductPriceList
  | SiteSectionSettingString
  | SiteSectionSettingVideo;

/** A site section setting for a video */
export type SiteSectionSettingVideo = SiteSectionSettingInterface & {
  __typename?: 'SiteSectionSettingVideo';
  /** The name of this setting */
  name: Scalars['String']['output'];
  /** The type of this setting */
  type: Scalars['String']['output'];
  /** The value_type of this setting */
  valueType: Scalars['String']['output'];
  /** The video */
  video: Maybe<SiteSectionVideo>;
};

/** A video value for a site section */
export type SiteSectionVideo = {
  __typename?: 'SiteSectionVideo';
  /** The frame */
  frame: Maybe<Scalars['String']['output']>;
  /** The video identifier */
  identifier: Maybe<Scalars['String']['output']>;
  /** The oembed */
  oembed: Maybe<Scalars['String']['output']>;
  /** The popover */
  popover: Maybe<Scalars['String']['output']>;
  /** The URL */
  url: Maybe<Scalars['String']['output']>;
  /** The video record */
  video: Maybe<Video>;
};

/** Active site subscription associated with tenant */
export type SiteSubscription = {
  __typename?: 'SiteSubscription';
  /** Billing price slug */
  billingPriceSlug: Maybe<Scalars['String']['output']>;
  /** Timestamp for cancellation */
  cancelledAt: Maybe<Scalars['String']['output']>;
  /** Timestamp of creation */
  createdAt: Scalars['String']['output'];
  /** Currency of subscription */
  currency: Maybe<Scalars['String']['output']>;
  /** Timestamp for expiry */
  expiryDate: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Interval of subscription */
  interval: Scalars['String']['output'];
  /** Type of subscription, either main or addon */
  planType: PlanTypeEnum;
  /** The slug of the site plan the subscription is for */
  sitePlanSlug: Scalars['String']['output'];
  /** Stripe price ID for subscription */
  stripePlanId: Scalars['String']['output'];
  /** Stripe subscription ID for subscription */
  stripeSubscriptionId: Scalars['String']['output'];
  /** Stripe subscription item ID for subscription */
  stripeSubscriptionItemId: Scalars['String']['output'];
  /** Quantity of subscription items */
  stripeSubscriptionItemQuantity: Scalars['Int']['output'];
  /** Whether the subscription can be cancelled by the tenant */
  tenantCancellationSupported: Scalars['Boolean']['output'];
  /** Timestamp of last update */
  updatedAt: Scalars['String']['output'];
};

/** Possible input fields for site subscription */
export type SiteSubscriptionInput = {
  /** Stripe subscription id for site subscription */
  stripeSubscriptionId: Scalars['String']['input'];
};

/** Prices associated with Thinkific site plans */
export type SiteSubscriptionPlan = {
  __typename?: 'SiteSubscriptionPlan';
  /** Billing plan slug associated with price */
  billingPlanSlug: Scalars['String']['output'];
  /** Timestamp of creation */
  createdAt: Scalars['String']['output'];
  /** ID of price */
  id: Scalars['Int']['output'];
  /** Interval of price */
  interval: Scalars['String']['output'];
  /** Legacy status of price */
  legacy: Scalars['Boolean']['output'];
  /** Price */
  price: Scalars['Int']['output'];
  /** Short blurb for price */
  priceDescription: Scalars['String']['output'];
  /** The pricing options for all purchasable currencies */
  priceOptions: PriceOptions;
  /** Site plan hierarchy weight */
  purchasable: Maybe<Scalars['Boolean']['output']>;
  /** Stripe price ID associated with price */
  stripePlanId: Scalars['String']['output'];
  /** Human readable subscription name */
  subscriptionName: Maybe<Scalars['String']['output']>;
  /** Timestamp of last update */
  updatedAt: Scalars['String']['output'];
};

/** A site template */
export type SiteTemplate = Node & {
  __typename?: 'SiteTemplate';
  /** The current version this tenant has installed */
  currentVersion: SiteTemplateVersion;
  id: Scalars['ID']['output'];
  /** The unique identifier */
  identifier: Scalars['String']['output'];
  /** The name */
  name: Scalars['String']['output'];
};

/** A site template asset */
export type SiteTemplateAsset = Node & {
  __typename?: 'SiteTemplateAsset';
  /** The file name */
  fileName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** The URL */
  url: Scalars['String']['output'];
};

/** The connection type for SiteTemplateAsset. */
export type SiteTemplateAssetConnection = {
  __typename?: 'SiteTemplateAssetConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<SiteTemplateAssetEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<SiteTemplateAsset>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SiteTemplateAssetEdge = {
  __typename?: 'SiteTemplateAssetEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<SiteTemplateAsset>;
};

/** The connection type for SiteTemplate. */
export type SiteTemplateConnection = {
  __typename?: 'SiteTemplateConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<SiteTemplateEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<SiteTemplate>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SiteTemplateEdge = {
  __typename?: 'SiteTemplateEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<SiteTemplate>;
};

/** A site template layout */
export type SiteTemplateLayout = Node & {
  __typename?: 'SiteTemplateLayout';
  id: Scalars['ID']['output'];
  /** The unique identifier */
  identifier: Scalars['String']['output'];
  /** The liquid markup */
  liquidMarkup: Scalars['Liquid']['output'];
  /** The liquid markup for the bottom of the <body> */
  liquidMarkupForFooter: Maybe<Scalars['Liquid']['output']>;
  /** The liquid markup for the <head> */
  liquidMarkupForHead: Maybe<Scalars['Liquid']['output']>;
  /** The name */
  name: Scalars['String']['output'];
  /** The layout schema */
  schema: Scalars['JSON']['output'];
  /** The site template version ID */
  siteTemplateVersionId: Scalars['ID']['output'];
};

/** The connection type for SiteTemplateLayout. */
export type SiteTemplateLayoutConnection = {
  __typename?: 'SiteTemplateLayoutConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<SiteTemplateLayoutEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<SiteTemplateLayout>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SiteTemplateLayoutEdge = {
  __typename?: 'SiteTemplateLayoutEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<SiteTemplateLayout>;
};

/** A site template page */
export type SiteTemplatePage = Node & {
  __typename?: 'SiteTemplatePage';
  id: Scalars['ID']['output'];
  /** The unique identifier */
  identifier: Scalars['String']['output'];
  /** The liquid markup */
  liquidMarkup: Scalars['Liquid']['output'];
  /** The liquid markup for the bottom of the <body> */
  liquidMarkupForFooter: Maybe<Scalars['Liquid']['output']>;
  /** The liquid markup for the <head> */
  liquidMarkupForHead: Maybe<Scalars['Liquid']['output']>;
  /** The name */
  name: Scalars['String']['output'];
  /** The page type */
  pageType: Scalars['String']['output'];
  /** The page schema */
  schema: Scalars['JSON']['output'];
  /** The site template version ID */
  siteTemplateVersionId: Scalars['ID']['output'];
};

/** The connection type for SiteTemplatePage. */
export type SiteTemplatePageConnection = {
  __typename?: 'SiteTemplatePageConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<SiteTemplatePageEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<SiteTemplatePage>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SiteTemplatePageEdge = {
  __typename?: 'SiteTemplatePageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<SiteTemplatePage>;
};

/** A site template section */
export type SiteTemplateSection = Node & {
  __typename?: 'SiteTemplateSection';
  /** The custom GraphQL for this section */
  graphqlQuery: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** The unique identifier */
  identifier: Scalars['String']['output'];
  /** The liquid markup */
  liquidMarkup: Scalars['Liquid']['output'];
  /** The liquid markup for the bottom of the <body> */
  liquidMarkupForFooter: Maybe<Scalars['Liquid']['output']>;
  /** The liquid markup for the <head> */
  liquidMarkupForHead: Maybe<Scalars['Liquid']['output']>;
  /** The name */
  name: Scalars['String']['output'];
  /** The section schema */
  schema: Scalars['JSON']['output'];
  /** The site template version ID */
  siteTemplateVersionId: Scalars['ID']['output'];
};

/** The connection type for SiteTemplateSection. */
export type SiteTemplateSectionConnection = {
  __typename?: 'SiteTemplateSectionConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<SiteTemplateSectionEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<SiteTemplateSection>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SiteTemplateSectionEdge = {
  __typename?: 'SiteTemplateSectionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<SiteTemplateSection>;
};

/** A site template snippet */
export type SiteTemplateSnippet = Node & {
  __typename?: 'SiteTemplateSnippet';
  id: Scalars['ID']['output'];
  /** The unique identifier */
  identifier: Scalars['String']['output'];
  /** The liquid markup */
  liquidMarkup: Scalars['Liquid']['output'];
};

/** The connection type for SiteTemplateSnippet. */
export type SiteTemplateSnippetConnection = {
  __typename?: 'SiteTemplateSnippetConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<SiteTemplateSnippetEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<SiteTemplateSnippet>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SiteTemplateSnippetEdge = {
  __typename?: 'SiteTemplateSnippetEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<SiteTemplateSnippet>;
};

/** A site template version */
export type SiteTemplateVersion = Node & {
  __typename?: 'SiteTemplateVersion';
  id: Scalars['ID']['output'];
  /** The liquid markup for the bottom of the <body> */
  liquidMarkupForFooter: Maybe<Scalars['Liquid']['output']>;
  /** The liquid markup for the <head> */
  liquidMarkupForHead: Maybe<Scalars['Liquid']['output']>;
  /** The assets */
  siteTemplateAssets: SiteTemplateAssetConnection;
  /** The layout templates */
  siteTemplateLayouts: SiteTemplateLayoutConnection;
  /** The page templates */
  siteTemplatePages: SiteTemplatePageConnection;
  /** The section templates */
  siteTemplateSections: SiteTemplateSectionConnection;
  /** The snippet templates */
  siteTemplateSnippets: SiteTemplateSnippetConnection;
  /** The semantic version */
  version: Scalars['String']['output'];
};

/** A site template version */
export type SiteTemplateVersionSiteTemplateAssetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A site template version */
export type SiteTemplateVersionSiteTemplateLayoutsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A site template version */
export type SiteTemplateVersionSiteTemplatePagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A site template version */
export type SiteTemplateVersionSiteTemplateSectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A site template version */
export type SiteTemplateVersionSiteTemplateSnippetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** slide information */
export type Slide = Node & {
  __typename?: 'Slide';
  /** Slide's audio file name */
  audioFileName: Maybe<Scalars['String']['output']>;
  /** Slide's audio file url */
  audioFileUrl: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Slide's image file name */
  imageFileName: Maybe<Scalars['String']['output']>;
  /** Slide's image file url */
  imageFileUrl: Maybe<Scalars['String']['output']>;
};

/** The connection type for Slide. */
export type SlideConnection = {
  __typename?: 'SlideConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<SlideEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Slide>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SlideEdge = {
  __typename?: 'SlideEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Slide>;
};

/** Attributes for Social Login sign up settings */
export type SocialLoginInput = {
  /** Apple Sign up / Sign in enabled */
  appleEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Facebook sign up / Sign in enabled */
  facebookEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Google Sign up / Sign in enabled */
  googleEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Linkedin Sign up / Sign in enabled */
  linkedinEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The source for payment activation */
export enum SourceEnum {
  /** Cp Payouts source */
  CpPayouts = 'cp_payouts',
}

/** SSO Url */
export type SsoUrl = {
  __typename?: 'SsoUrl';
  id: Scalars['ID']['output'];
  /** The URL to use for SSO login to another tenant */
  url: Maybe<Scalars['String']['output']>;
};

/** From/to users for multi-instance SSO login */
export type SsoUsers = {
  /** The ID of the user account that the user wishes to switch to? */
  toUser?: InputMaybe<Scalars['Int']['input']>;
};

/** Possible types for OAuth App state */
export enum StateType {
  /** Filter apps by approved */
  Approved = 'APPROVED',
  /** Filter apps by draft */
  Draft = 'DRAFT',
  /** Filter apps by suspended */
  Suspended = 'SUSPENDED',
}

/** Shows whether the order was fulfilled. */
export enum StatusEnum {
  /** Complete, fulfilled order */
  Complete = 'Complete',
  /** Incomplete, unfulfilled order */
  Incomplete = 'Incomplete',
  /** Processing, processing order */
  Processing = 'Processing',
}

/** Possible statuses for submissions */
export type StatusFilter = {
  /** submissions who have the specified statuses */
  is?: InputMaybe<Array<SubmissionStatus>>;
  /** submissions who do not have the specified statuses */
  isNot?: InputMaybe<Array<SubmissionStatus>>;
};

/** A product associated to a certificate blueprint */
export type StudentCertificateProduct = Node & {
  __typename?: 'StudentCertificateProduct';
  /** Date the Product was created at */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** Product's ID */
  id: Scalars['ID']['output'];
  /** The graphql_name of the product */
  name: Maybe<Scalars['String']['output']>;
  /** The type of the product (Course or Bundle) */
  type: Maybe<Scalars['String']['output']>;
  /** Date the Product was updated at */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/**
 * An object that belongs to a tenant, implements a DesignPreset, and has a
 * manifest JSON. The DesignPreset and manifest are used to generate a
 * StudentCertificateRecord
 */
export type StudentCertificatesBlueprint = Node & {
  __typename?: 'StudentCertificatesBlueprint';
  /** A list of objects that contain the products using this certificate blueprint */
  certificateMappings: Maybe<StudentCertificatesCertificateMappingConnection>;
  /** Date the blueprint was created at */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The design preset associated to the blueprint */
  designPreset: Maybe<StudentCertificatesDesignPreset>;
  id: Scalars['ID']['output'];
  /** Contains the values entered by the tenant to be combined with the manifest schema in order to generate a certificate */
  manifest: Maybe<Scalars['JSON']['output']>;
  /** A unique graphql_name choosen by the site admin for the blueprint */
  name: Maybe<Scalars['String']['output']>;
  /** Date of last update to the blueprint */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/**
 * An object that belongs to a tenant, implements a DesignPreset, and has a
 * manifest JSON. The DesignPreset and manifest are used to generate a
 * StudentCertificateRecord
 */
export type StudentCertificatesBlueprintCertificateMappingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<StudentCertificatesCertificateMappingFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for StudentCertificatesBlueprint. */
export type StudentCertificatesBlueprintConnection = {
  __typename?: 'StudentCertificatesBlueprintConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<StudentCertificatesBlueprintEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<StudentCertificatesBlueprint>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type StudentCertificatesBlueprintEdge = {
  __typename?: 'StudentCertificatesBlueprintEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<StudentCertificatesBlueprint>;
};

/** Possible fields to filter blueprints by */
export type StudentCertificatesBlueprintFilter = {
  /** Search by id */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Search by name */
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Input fields for a blueprint */
export type StudentCertificatesBlueprintInput = {
  /** The certificate mappings associated to the blueprint */
  certificateMappingAttributes?: InputMaybe<
    Array<InputMaybe<StudentCertificatesCertificateMappingInput>>
  >;
  /** The design preset associated to the blueprint */
  designPresetId: Scalars['ID']['input'];
  /** Contains the values entered by the tenant to be combined with the manifest schema in order to generate a certificate */
  manifest: Scalars['JSON']['input'];
  /** A unique graphql_name choosen by the site admin for the blueprint */
  name: Scalars['String']['input'];
};

/** An object that belongs to a Product and a Blueprint. It maps a certificate blueprint to the product */
export type StudentCertificatesCertificateMapping = Node & {
  __typename?: 'StudentCertificatesCertificateMapping';
  /** The blueprint CertificateMapping is accociated with */
  blueprint: Maybe<StudentCertificatesBlueprint>;
  /** Date the CertificateMapping was created at */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** Days until the instance of a CertificateMapping expires */
  daysUntilExpiry: Maybe<Scalars['Int']['output']>;
  /** Date the instance of a CertificateMapping expires on */
  expiryDate: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  /** The product CertificateMapping is accociated with */
  product: Maybe<StudentCertificateProduct>;
  /** Date the CertificateMapping was updated at */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** The connection type for StudentCertificatesCertificateMapping. */
export type StudentCertificatesCertificateMappingConnection = {
  __typename?: 'StudentCertificatesCertificateMappingConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<StudentCertificatesCertificateMappingEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<StudentCertificatesCertificateMapping>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type StudentCertificatesCertificateMappingEdge = {
  __typename?: 'StudentCertificatesCertificateMappingEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<StudentCertificatesCertificateMapping>;
};

/** Possible fields to filter certificate mapping by */
export type StudentCertificatesCertificateMappingFilter = {
  /** Search by id */
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Input fields for a certificate mapping */
export type StudentCertificatesCertificateMappingInput = {
  /** Days until the certificate mapping expires, starting from when the enrollment was completed */
  daysUntilExpiry?: InputMaybe<Scalars['Int']['input']>;
  /** Indicates whether to delete this certificate mapping record */
  destroy?: InputMaybe<Scalars['Boolean']['input']>;
  /** Date the certificate mapping expires on */
  expiryDate?: InputMaybe<Scalars['Date']['input']>;
  /** The certificate mapping's ID */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** The product associated to the certificate mapping */
  productId: Scalars['ID']['input'];
};

/** Certificate mapping patch */
export type StudentCertificatesCertificateMappingPatch = {
  /** The blueprint for the certificate mapping */
  blueprintId?: InputMaybe<Scalars['ID']['input']>;
  /** Days until the certificate mapping expires, starting from when the enrollment was completed */
  daysUntilExpiry?: InputMaybe<Scalars['Int']['input']>;
  /** Date the certificate mapping expires on */
  expiryDate?: InputMaybe<Scalars['Date']['input']>;
};

/** Input fields for CertificateMappings */
export type StudentCertificatesCertificateMappingsInput = {
  /** The blueprint for the certificate mapping */
  blueprintId: Scalars['ID']['input'];
  /** List of products to be mapped to the blueprint */
  productIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

/** Input fields for creating a certificate mapping */
export type StudentCertificatesCreateCertificateMappingInput = {
  /** The blueprint for the certificate mapping */
  blueprintId: Scalars['ID']['input'];
  /** Days until the certificate mapping expires, starting from when the enrollment was completed */
  daysUntilExpiry?: InputMaybe<Scalars['Int']['input']>;
  /** Date the certificate mapping expires on */
  expiryDate?: InputMaybe<Scalars['Date']['input']>;
  /** Product to be mapped to the blueprint */
  productId: Scalars['ID']['input'];
};

/** Input fields for deleting a blueprint */
export type StudentCertificatesDeleteBlueprintInput = {
  /** The Blueprint's ID */
  id: Scalars['ID']['input'];
};

/** Input fields for deleting a certificate mapping */
export type StudentCertificatesDeleteCertificateMappingInput = {
  /** The certificate mapping ID */
  id: Scalars['ID']['input'];
};

/**
 * An object that contains a template and a manifestSchema. A design preset is
 * meant to be used by a Design Blueprint to generate a student certificate record.
 */
export type StudentCertificatesDesignPreset = Node & {
  __typename?: 'StudentCertificatesDesignPreset';
  /** Date the design preset was created at */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** Contains the form_settings and design_settings of the design preset */
  manifestSchema: Maybe<Scalars['JSON']['output']>;
  /** A unique graphql_name for the design preset */
  name: Maybe<Scalars['String']['output']>;
  /** Design preset preview image */
  previewImageUrl: Maybe<Scalars['String']['output']>;
  /** The Liquid template code used for rendering a certificate record's HTML and PDF */
  template: Maybe<Scalars['String']['output']>;
  /** Date of last update to the design preset */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** The connection type for StudentCertificatesDesignPreset. */
export type StudentCertificatesDesignPresetConnection = {
  __typename?: 'StudentCertificatesDesignPresetConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<StudentCertificatesDesignPresetEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<StudentCertificatesDesignPreset>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type StudentCertificatesDesignPresetEdge = {
  __typename?: 'StudentCertificatesDesignPresetEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<StudentCertificatesDesignPreset>;
};

/** Possible fields to filter design presets by */
export type StudentCertificatesDesignPresetFilter = {
  /** Search by id */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Search by name */
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Input fields for duplicating a blueprint */
export type StudentCertificatesDuplicateBlueprintInput = {
  /** The Blueprint's ID */
  id: Scalars['ID']['input'];
};

/** Mutation interface for student certificates */
export type StudentCertificatesMutationInterface = {
  /** Create a Blueprint for a tenant */
  createStudentCertificateBlueprint: Maybe<CreateBlueprintPayload>;
  /** Create a certificate mapping for a product */
  createStudentCertificatesCertificateMapping: Maybe<
    CreateCertificateMappingPayload
  >;
  /** Create Certificate Mappings in bulk for a tenant */
  createStudentCertificatesCertificateMappings: Maybe<
    CreateCertificateMappingsPayload
  >;
  /** Delete a Blueprint for a tenant */
  deleteStudentCertificateBlueprint: Maybe<DeleteBlueprintPayload>;
  /** Delete a certificate mapping for a product */
  deleteStudentCertificatesCertificateMapping: Maybe<
    DeleteCertificateMappingPayload
  >;
  /** Duplicate a Blueprint for a tenant */
  duplicateStudentCertificateBlueprint: Maybe<DuplicateBlueprintPayload>;
  /** Update a Blueprint for a tenant */
  updateStudentCertificateBlueprint: Maybe<UpdateBlueprintPayload>;
  /** Update a certificate mapping for a product */
  updateStudentCertificatesCertificateMapping: Maybe<
    UpdateCertificateMappingPayload
  >;
  /** Update a StudentRecord for a tenant */
  updateStudentCertificatesRecord: Maybe<UpdateStudentRecordPayload>;
};

/** Mutation interface for student certificates */
export type StudentCertificatesMutationInterfaceCreateStudentCertificateBlueprintArgs = {
  input: StudentCertificatesBlueprintInput;
};

/** Mutation interface for student certificates */
export type StudentCertificatesMutationInterfaceCreateStudentCertificatesCertificateMappingArgs = {
  input: StudentCertificatesCreateCertificateMappingInput;
};

/** Mutation interface for student certificates */
export type StudentCertificatesMutationInterfaceCreateStudentCertificatesCertificateMappingsArgs = {
  input: StudentCertificatesCertificateMappingsInput;
};

/** Mutation interface for student certificates */
export type StudentCertificatesMutationInterfaceDeleteStudentCertificateBlueprintArgs = {
  input: StudentCertificatesDeleteBlueprintInput;
};

/** Mutation interface for student certificates */
export type StudentCertificatesMutationInterfaceDeleteStudentCertificatesCertificateMappingArgs = {
  input: StudentCertificatesDeleteCertificateMappingInput;
};

/** Mutation interface for student certificates */
export type StudentCertificatesMutationInterfaceDuplicateStudentCertificateBlueprintArgs = {
  input: StudentCertificatesDuplicateBlueprintInput;
};

/** Mutation interface for student certificates */
export type StudentCertificatesMutationInterfaceUpdateStudentCertificateBlueprintArgs = {
  input: StudentCertificatesUpdateBlueprintInput;
};

/** Mutation interface for student certificates */
export type StudentCertificatesMutationInterfaceUpdateStudentCertificatesCertificateMappingArgs = {
  input: StudentCertificatesUpdateCertificateMappingInput;
};

/** Mutation interface for student certificates */
export type StudentCertificatesMutationInterfaceUpdateStudentCertificatesRecordArgs = {
  input: StudentCertificatesUpdateStudentRecordInput;
};

/** A preview blueprint */
export type StudentCertificatesPreviewBlueprint = {
  __typename?: 'StudentCertificatesPreviewBlueprint';
  /** The generated HTML */
  html: Maybe<Scalars['String']['output']>;
};

/** Input fields for blueprint preview query */
export type StudentCertificatesPreviewBlueprintInput = {
  /** The manifest to use for generating the html preview */
  manifest: Scalars['JSON']['input'];
  /** The liquid template to use for generating the html preview */
  template: Scalars['String']['input'];
};

/** A Product interface for Student Certificates */
export type StudentCertificatesProductQueryInterface = {
  studentCertificatesMapping: Maybe<StudentCertificatesCertificateMapping>;
};

/** A query interface for Student Certificates */
export type StudentCertificatesQueryInterface = {
  /** A list of certificate design presets */
  certificateDesignPresets: Maybe<StudentCertificatesDesignPresetConnection>;
  /** The generated HTML */
  previewStudentCertificateBlueprint: Maybe<
    StudentCertificatesPreviewBlueprint
  >;
};

/** A query interface for Student Certificates */
export type StudentCertificatesQueryInterfaceCertificateDesignPresetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<StudentCertificatesDesignPresetFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A query interface for Student Certificates */
export type StudentCertificatesQueryInterfacePreviewStudentCertificateBlueprintArgs = {
  input: StudentCertificatesPreviewBlueprintInput;
};

/** A snapshot of user data at the moment of completing an enrollment to be used to generate a certificate PDF */
export type StudentCertificatesRecord = Node & {
  __typename?: 'StudentCertificatesRecord';
  /** Date the student record was created at */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The credential ID for this student record */
  credentialId: Maybe<Scalars['String']['output']>;
  /** The design preset associated to the student record */
  designPreset: Maybe<StudentCertificatesDesignPreset>;
  /** The id of the enrollment this student record belongs to */
  enrollmentId: Maybe<Scalars['ID']['output']>;
  /** Date the student record expires on */
  expiryDate: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  /** Contains the values entered by the tenant to be combined with the manifest schema in order to generate a certificate */
  manifest: Maybe<Scalars['JSON']['output']>;
  /** The URL path used to download the certificate PDF */
  pdfDownloadPath: Scalars['String']['output'];
  /** Date of last update to the student record */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** The connection type for StudentCertificatesRecord. */
export type StudentCertificatesRecordConnection = {
  __typename?: 'StudentCertificatesRecordConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<StudentCertificatesRecordEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<StudentCertificatesRecord>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type StudentCertificatesRecordEdge = {
  __typename?: 'StudentCertificatesRecordEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<StudentCertificatesRecord>;
};

/** Possible fields to filter student records by */
export type StudentCertificatesRecordFilter = {
  /** Search by credential id */
  credentialId?: InputMaybe<Scalars['String']['input']>;
  /** Search by enrollment id */
  enrollmentId?: InputMaybe<Scalars['ID']['input']>;
  /** Search by id */
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Input fields for a student record */
export type StudentCertificatesRecordInput = {
  /** The date the certificate expires on */
  expiryDate?: InputMaybe<Scalars['Date']['input']>;
  /** Contains the values used to generate the certificate PDF */
  manifest?: InputMaybe<Scalars['JSON']['input']>;
};

/** A Tenant interface for Student Certificates */
export type StudentCertificatesTenantQueryInterface = {
  /** A list of certificate blueprints */
  certificateBlueprints: Maybe<StudentCertificatesBlueprintConnection>;
  /** Generated student record html */
  studentCertificatesRecordHtml: Maybe<Scalars['String']['output']>;
  /** A list of student records */
  studentCertificatesRecords: Maybe<StudentCertificatesRecordConnection>;
};

/** A Tenant interface for Student Certificates */
export type StudentCertificatesTenantQueryInterfaceCertificateBlueprintsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<StudentCertificatesBlueprintFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A Tenant interface for Student Certificates */
export type StudentCertificatesTenantQueryInterfaceStudentCertificatesRecordHtmlArgs = {
  id: Scalars['ID']['input'];
  manifest?: InputMaybe<Scalars['JSON']['input']>;
};

/** A Tenant interface for Student Certificates */
export type StudentCertificatesTenantQueryInterfaceStudentCertificatesRecordsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<StudentCertificatesRecordFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Input fields for updating a blueprint */
export type StudentCertificatesUpdateBlueprintInput = {
  /** The Blueprint's ID */
  id: Scalars['ID']['input'];
  /** Blueprint's attributes to be updated */
  patch: StudentCertificatesBlueprintInput;
};

/** Input fields for updating a certificate mapping */
export type StudentCertificatesUpdateCertificateMappingInput = {
  /** The certificate mapping ID */
  id: Scalars['ID']['input'];
  /** Attributes of the certificate mapping to be updated */
  patch: StudentCertificatesCertificateMappingPatch;
};

/** Input fields for updating a student record */
export type StudentCertificatesUpdateStudentRecordInput = {
  /** The StudentRecord's ID */
  id: Scalars['ID']['input'];
  /** StudentRecord's attributes to be updated */
  patch: StudentCertificatesRecordInput;
};

/** Notifications for students */
export type StudentNotification = {
  __typename?: 'StudentNotification';
  /** The description for the notification */
  description: Maybe<Scalars['String']['output']>;
  /** Whether the notification is configurable */
  isConfigurable: Maybe<Scalars['Boolean']['output']>;
  /** Whether the notification is editable */
  isEditable: Maybe<Scalars['Boolean']['output']>;
  /** Whether the notification is enabled */
  isEnabled: Maybe<Scalars['Boolean']['output']>;
  /** The name of the notification */
  name: Maybe<Scalars['String']['output']>;
  /** The slug for the notification */
  slug: Maybe<StudentNotificationSlugType>;
};

/** The type of the slug student notification */
export enum StudentNotificationSlugType {
  /** Community Welcome Email */
  CommunityWelcomeEmail = 'community_welcome_email',
  /** Discussion replies */
  DiscussionReply = 'discussion_reply',
  /** Incomplete purchase reminder */
  IncompletePurchaseReminder = 'incomplete_purchase_reminder',
  /** Receipts */
  PurchaseReceipt = 'purchase_receipt',
  /** Weekly reminder */
  WeeklyEmailReminders = 'weekly_email_reminders',
  /** Site welcome */
  WelcomeEmail = 'welcome_email',
  /** Site welcome (SSO) */
  WelcomeEmailFromSso = 'welcome_email_from_sso',
}

/** Subdomain */
export type Subdomain = {
  __typename?: 'Subdomain';
  /** If the subdomain is valid and has mobile access */
  available: Maybe<Scalars['Boolean']['output']>;
};

/** Assignment submissions */
export type Submission = Node & {
  __typename?: 'Submission';
  /** Submission's assignment */
  assignment: Assignment;
  /** Submission's created at date */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** Submission's file name */
  fileName: Maybe<Scalars['String']['output']>;
  /** Submission's file size */
  fileSize: Maybe<Scalars['Int']['output']>;
  /** Submission's file type */
  fileType: Maybe<Scalars['String']['output']>;
  /** Submission's human readable file size */
  humanSize: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Submission's reviewed at time */
  reviewedAt: Maybe<Scalars['DateTime']['output']>;
  /** Submission's status */
  status: Maybe<SubmissionStatus>;
  /** Submission's updated at date */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  /** Submission's url */
  url: Maybe<Scalars['String']['output']>;
  /** Submission's user */
  user: Maybe<User>;
};

/** The connection type for Submission. */
export type SubmissionConnection = {
  __typename?: 'SubmissionConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<SubmissionEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Submission>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SubmissionEdge = {
  __typename?: 'SubmissionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Submission>;
};

/** Possible fields to filter submissions */
export type SubmissionFilter = {
  /** Search by id */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Search by submission name, student name or email */
  search?: InputMaybe<Scalars['String']['input']>;
};

/** Order for submissions */
export type SubmissionOrderBy = {
  direction: OrderDirection;
  field: SubmissionOrderField;
};

/** Possible fields to order submissions */
export enum SubmissionOrderField {
  /** order submissions by submitter's full name */
  ChapterName = 'chapterName',
  /** order submissions by submitter's full name */
  CourseName = 'courseName',
  /** order submissions by created at */
  CreatedAt = 'createdAt',
  /** order submissions by submitter's email */
  Email = 'email',
  /** order submissions by file name */
  FileName = 'fileName',
  /** order submissions by file size */
  FileSize = 'fileSize',
  /** order submissions by submitter's full name */
  LessonName = 'lessonName',
  /** order submissions by reviewed at */
  ReviewedAt = 'reviewedAt',
  /** order submissions by status */
  Status = 'status',
  /** order submissions by updated at */
  UpdatedAt = 'updatedAt',
  /** order submissions by submitter's full name */
  UserFullName = 'userFullName',
}

/** Available types for Assignment submission status */
export enum SubmissionStatus {
  /** Submission status approved */
  Approved = 'approved',
  /** Submission status pending */
  Pending = 'pending',
  /** Submission status rejected */
  Rejected = 'rejected',
}

/** Autogenerated input type of SubmitAssignment */
export type SubmitAssignmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** topic image */
  file: FileInput;
  /** The ID of the lesson assignment the submission is associated with */
  lessonId: Scalars['ID']['input'];
};

/** Autogenerated return type of SubmitAssignment */
export type SubmitAssignmentPayload = {
  __typename?: 'SubmitAssignmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The submission */
  submission: Maybe<Submission>;
  userErrors: Maybe<Array<UserError>>;
};

/** Survey question choice information */
export type SurveyChoice = Node & {
  __typename?: 'SurveyChoice';
  id: Scalars['ID']['output'];
  /** The position of the choice */
  position: Maybe<Scalars['Int']['output']>;
  /** The choice text */
  text: Maybe<Scalars['String']['output']>;
};

/** The connection type for SurveyChoice. */
export type SurveyChoiceConnection = {
  __typename?: 'SurveyChoiceConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<SurveyChoiceEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<SurveyChoice>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SurveyChoiceEdge = {
  __typename?: 'SurveyChoiceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<SurveyChoice>;
};

/** Input for survey choices */
export type SurveyChoiceInput = {
  /** Whether the choice should be destroyed */
  destroy?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the choice */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** The position of the choice */
  position?: InputMaybe<Scalars['Int']['input']>;
  /** The choice text */
  text?: InputMaybe<Scalars['String']['input']>;
};

/** Survey lesson information */
export type SurveyLesson = LessonInterface &
  Node & {
    __typename?: 'SurveyLesson';
    /** Content's chapter */
    chapter: Chapter;
    /** contentable id */
    contentableId: Scalars['ID']['output'];
    /** Content's course */
    course: Course;
    /** Content's created at date */
    createdAt: Scalars['DateTime']['output'];
    /** Lesson default icon */
    defaultLessonTypeIcon: Scalars['String']['output'];
    /** Is discussion enabled */
    discussionsEnabled: Maybe<Scalars['Boolean']['output']>;
    /** Lesson draft status */
    draft: Scalars['Boolean']['output'];
    /** Lesson's associated Drip Schedule Item */
    dripScheduleItem: Maybe<DripScheduleItem>;
    /** lesson drip status */
    dripStatus: Maybe<Scalars['JSON']['output']>;
    /** Is lesson free */
    free: Maybe<Scalars['Boolean']['output']>;
    /** Lesson html text */
    htmlDescription: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    /** Current user lesson status */
    lessonCompleted: Maybe<Scalars['Boolean']['output']>;
    /** lesson drip schedule */
    lessonHasDripSchedule: Maybe<Scalars['String']['output']>;
    /** Lesson integer ID */
    lessonId: Scalars['ID']['output'];
    /** Lesson icon */
    lessonTypeIcon: Maybe<LessonIconEnum>;
    /** Lesson label */
    lessonTypeLabel: Maybe<Scalars['String']['output']>;
    /** Lesson url */
    lessonUrl: Maybe<Scalars['String']['output']>;
    /** Lesson name */
    name: Scalars['String']['output'];
    /** Lesson position */
    position: Scalars['Int']['output'];
    /** Is lesson prerequisite */
    prerequisite: Scalars['Boolean']['output'];
    /** A single survey question by ID */
    question: Maybe<SurveyQuestion>;
    /** The survey questions */
    questions: Maybe<SurveyQuestionConnection>;
    /** Lesson's slug */
    slug: Maybe<Scalars['String']['output']>;
    /** Lesson Type */
    type: Scalars['String']['output'];
    /** Whether the lesson is still under construction */
    underConstruction: Scalars['Boolean']['output'];
    /** Content's updated at date */
    updatedAt: Scalars['DateTime']['output'];
    /** User content for lesson */
    userContents: Maybe<UserContentConnection>;
    /** User quizzes for lesson */
    userQuizzes: Maybe<UserQuizConnection>;
    /** User surveys for lesson */
    userSurveys: Maybe<UserSurveyConnection>;
  };

/** Survey lesson information */
export type SurveyLessonQuestionArgs = {
  id: Scalars['ID']['input'];
};

/** Survey lesson information */
export type SurveyLessonQuestionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Survey lesson information */
export type SurveyLessonUserContentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserContentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserContentOrderByFilter>;
};

/** Survey lesson information */
export type SurveyLessonUserQuizzesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserQuizFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserQuizOrderBy>;
};

/** Survey lesson information */
export type SurveyLessonUserSurveysArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserSurveyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserSurveyOrderBy>;
};

/** Survey question information */
export type SurveyQuestion = Node & {
  __typename?: 'SurveyQuestion';
  /** The question choices */
  choices: Maybe<SurveyChoiceConnection>;
  /** The question type */
  displayType: Maybe<SurveyQuestionTypeEnum>;
  id: Scalars['ID']['output'];
  /** Whether the question is optional */
  isOptional: Maybe<Scalars['Boolean']['output']>;
  /** The left label of the question */
  leftLabel: Maybe<Scalars['String']['output']>;
  /** The middle label of the question */
  middleLabel: Maybe<Scalars['String']['output']>;
  /** The name of the question */
  name: Maybe<Scalars['String']['output']>;
  /** The position of the question */
  position: Maybe<Scalars['Int']['output']>;
  /** The question prompt */
  prompt: Maybe<Scalars['String']['output']>;
  /** The right label of the question */
  rightLabel: Maybe<Scalars['String']['output']>;
};

/** Survey question information */
export type SurveyQuestionChoicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for SurveyQuestion. */
export type SurveyQuestionConnection = {
  __typename?: 'SurveyQuestionConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<SurveyQuestionEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<SurveyQuestion>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SurveyQuestionEdge = {
  __typename?: 'SurveyQuestionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<SurveyQuestion>;
};

/** Input for survey questions */
export type SurveyQuestionInput = {
  /** The question choices */
  choices?: InputMaybe<Array<SurveyChoiceInput>>;
  /** Whether the question should be destroyed */
  destroy?: InputMaybe<Scalars['Boolean']['input']>;
  /** The question type */
  displayType?: InputMaybe<SurveyQuestionTypeEnum>;
  /** The ID of the question */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Whether the question is optional */
  isOptional?: InputMaybe<Scalars['Boolean']['input']>;
  /** The left label of the question */
  leftLabel?: InputMaybe<Scalars['String']['input']>;
  /** The middle label of the question */
  middleLabel?: InputMaybe<Scalars['String']['input']>;
  /** The name of the question */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The position of the question */
  position?: InputMaybe<Scalars['Int']['input']>;
  /** The question prompt */
  prompt?: InputMaybe<Scalars['String']['input']>;
  /** The right label of the question */
  rightLabel?: InputMaybe<Scalars['String']['input']>;
};

/** The question type */
export enum SurveyQuestionTypeEnum {
  /** Checkbox question type */
  Checkbox = 'checkbox',
  /** Freetext question type */
  Freetext = 'freetext',
  /** Radio question type */
  Radio = 'radio',
  /** Rating question type */
  Rating = 'rating',
  /** Scale question type */
  Scale = 'scale',
}

/** Fields relating to Onboarding Survey Responses */
export type SurveyResponses = {
  __typename?: 'SurveyResponses';
  /** Tenant business experience */
  businessExperience: Maybe<Scalars['String']['output']>;
  /** Tenant starting content type */
  contentType: Maybe<Scalars['String']['output']>;
  /** Tenant headcount for business */
  headcount: Maybe<Scalars['String']['output']>;
  /** Tenant industry */
  industry: Maybe<Scalars['String']['output']>;
  /** Productable type Tenant chose to begin with */
  startingProductableType: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of SyncZoomUsers */
export type SyncZoomUsersInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of SyncZoomUsers */
export type SyncZoomUsersPayload = {
  __typename?: 'SyncZoomUsersPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Response message */
  message: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Filter options for system version */
export type SystemVersionFilter = {
  /** Major system version */
  major?: InputMaybe<Scalars['Int']['input']>;
  /** Minor system version */
  minor?: InputMaybe<Scalars['Int']['input']>;
  /** Operator */
  operator?: InputMaybe<Operator>;
  /** Patch system version */
  patch?: InputMaybe<Scalars['Int']['input']>;
};

/** Transaction tax breakdown item */
export type TaxBreakdownItem = {
  __typename?: 'TaxBreakdownItem';
  /** Product name */
  productName: Scalars['String']['output'];
  /** Label for product tax category */
  productTaxCategory: Scalars['String']['output'];
  /** Product tax detail item */
  taxDetails: Array<ProductTaxLineItem>;
};

/** Product tax code */
export type TaxCode = {
  __typename?: 'TaxCode';
  /** Tax code */
  code: Scalars['String']['output'];
  /** Tax code description */
  description: Scalars['String']['output'];
  /** Tax code label */
  label: Scalars['String']['output'];
};

/** Tax item of a cart */
export type TaxItem = Node & {
  __typename?: 'TaxItem';
  /** Total amount for the tax code */
  amount: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  /** Tax name */
  name: Maybe<Scalars['String']['output']>;
};

/** The connection type for TaxItem. */
export type TaxItemConnection = {
  __typename?: 'TaxItemConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<TaxItemEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<TaxItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type TaxItemEdge = {
  __typename?: 'TaxItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<TaxItem>;
};

/** Status of tax registration */
export enum TaxRegistrationStatus {
  /** tax not registered */
  NotRegistered = 'not_registered',
  /** tax registered */
  Registered = 'registered',
}

/** Template pages */
export type TemplatePages = Node & {
  __typename?: 'TemplatePages';
  /** Page description for SEO purposes */
  description: Maybe<Scalars['String']['output']>;
  /** Whether the Site page is the homepage? */
  homepage: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  /** Page keywords for SEO purposes */
  keywords: Maybe<Scalars['String']['output']>;
  /** Page url */
  landingPageUrl: Maybe<Scalars['String']['output']>;
  /** Page name */
  name: Maybe<Scalars['String']['output']>;
  /** Page type */
  pageType: Maybe<PageTypeEnum>;
  /** Product id of page */
  productId: Maybe<Scalars['Int']['output']>;
  /** Template page's builder URL */
  siteBuilderUrl: Scalars['String']['output'];
  /** Page slug */
  slug: Maybe<Scalars['String']['output']>;
  /** Page status */
  status: Maybe<PageStatusEnum>;
};

/** The connection type for TemplatePages. */
export type TemplatePagesConnection = {
  __typename?: 'TemplatePagesConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<TemplatePagesEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<TemplatePages>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type TemplatePagesEdge = {
  __typename?: 'TemplatePagesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<TemplatePages>;
};

/** Possible fields to filter Site template pages */
export type TemplatePagesFilter = {
  /** Filter by page id */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Search by name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Filter by page type */
  pageType?: InputMaybe<PageTypeEnum>;
};

/** Tenant information */
export type Tenant = CommunitiesTenantQueryInterface &
  EventsTenantQueryInterface &
  FeaturesTenantQueryInterface &
  IhubTenantQueryInterface &
  Node &
  ReportingDashboardsTenantQueryInterface &
  StudentCertificatesTenantQueryInterface &
  ThinkificPaymentsTenantQueryInterface & {
    __typename?: 'Tenant';
    /** List of Accredible certificate templates */
    accredibleCertificateTemplates: Maybe<Array<Scalars['JSON']['output']>>;
    /** OAuth App Install's active campaign */
    activeCampaign: Maybe<ActiveCampaign>;
    activeCampaignCode: Maybe<Scalars['String']['output']>;
    /** Active addon site subscriptions associated with tenant */
    addonSubscriptions: Array<SiteSubscription>;
    affiliates: Maybe<AffiliateConnection>;
    /** List of OAuth Apps installed for the tenant */
    appInstallations: Maybe<Array<AppInstallation>>;
    /** Whether Appcues is disabled for the tenant */
    appcuesDisabled: Maybe<Scalars['Boolean']['output']>;
    /** Apple Enabled as Sign in option */
    appleEnabled: Maybe<Scalars['Boolean']['output']>;
    /** S3 Import CDN URL */
    assetsImportUrl: Maybe<Scalars['String']['output']>;
    /** A list of submissions that belong to the tenant */
    assignmentSubmissions: Maybe<SubmissionConnection>;
    /** A list of submissions that belong to the tenant */
    assignments: Maybe<AssignmentConnection>;
    /** Aweber List */
    aweberLists: Maybe<Array<Scalars['JSON']['output']>>;
    /** Current tenant balances */
    balance: Maybe<AccountBalance>;
    billingInfo: Maybe<BillingInfo>;
    /** AWS bucket region */
    bucketRegion: Maybe<Scalars['String']['output']>;
    /** A list of the bulk enrollments that belong to the tenant */
    bulkEnrollments: Maybe<BulkEnrollmentConnection>;
    /** A list of bundles that belongs to the tenant */
    bundles: Maybe<BundleConnection>;
    /** Whether tenant can see acredible certificates */
    canSeeAccredible: Scalars['Boolean']['output'];
    /** A specific cart on the tenant */
    cart: Maybe<CartConnection>;
    /** A list of the collections that belong to the tenant */
    categories: Maybe<CollectionConnection>;
    /** A list of certificate blueprints */
    certificateBlueprints: Maybe<StudentCertificatesBlueprintConnection>;
    communities: Maybe<CommunityConnection>;
    community: Community;
    /** Whether the APF feature has been enabled for Communities */
    communityApfEnabled: Maybe<Scalars['Boolean']['output']>;
    /** Whether the tenant has completed onboarding modal */
    completedOnboardingModal: Maybe<Scalars['Boolean']['output']>;
    /** Constant Contact List */
    constantContactLists: Maybe<Array<Scalars['JSON']['output']>>;
    /** ISO Alpha 2 country code based on available information such as stripe_country, IP address country, etc. */
    country: Maybe<Scalars['String']['output']>;
    /** A list of coupons that belongs to the tenant */
    coupons: Maybe<CouponConnection>;
    courseAdmins: Maybe<CourseAdminConnection>;
    /** @deprecated Student engagement feature is deprecated */
    courseEnrollmentTotals: Maybe<CourseEnrollmentTotals>;
    /** Course Player color */
    coursePlayerColor: Maybe<Scalars['String']['output']>;
    /** Course Player font */
    coursePlayerFont: Maybe<Scalars['String']['output']>;
    /** Course Player logo URL */
    coursePlayerLogo: Maybe<Scalars['String']['output']>;
    /** Course Player theme */
    coursePlayerTheme: Maybe<Scalars['String']['output']>;
    /** A list of courses that belongs to the tenant */
    courses: Maybe<CourseConnection>;
    createdAt: Scalars['String']['output'];
    /** Tenant currency */
    currency: Scalars['String']['output'];
    /** Tenant currency symbol */
    currencySymbol: Scalars['String']['output'];
    /** A list of the tenant's custom profile field definitions */
    customProfileFieldDefinitions: Maybe<
      CustomProfileFieldDefinitionConnection
    >;
    /** A list of the dashboard prompts that belong to the tenant */
    dashboardPrompts: Maybe<DashboardPromptConnection>;
    /** Site's default student locale setting */
    defaultStudentLocale: Maybe<Scalars['String']['output']>;
    /** Whether tenant has Disqus connected */
    disqusConnected: Scalars['Boolean']['output'];
    /** Site's safelisted html attributes for rich-text editor */
    editorAllowedAttrs: Maybe<Scalars['String']['output']>;
    /** Site's safelisted html tags for rich-text editor */
    editorAllowedTags: Maybe<Scalars['String']['output']>;
    /** A list of submissions that belong to the tenant */
    elasticAssignmentSubmissions: Maybe<ElasticSubmissionConnection>;
    /** Facebook Enabled as Sign in option */
    facebookEnabled: Maybe<Scalars['Boolean']['output']>;
    /** Site's favicon */
    favicon: Maybe<Scalars['String']['output']>;
    /** Feature flags for a tenant */
    featureFlags: Maybe<Array<Scalars['JSON']['output']>>;
    features: Maybe<Features>;
    /** File upload URL */
    fileUploadUrl: Scalars['String']['output'];
    /** Initialization Credentials for Filestack Client */
    filestackPolicy: Maybe<FilestackSecurity>;
    fullstory: Maybe<Fullstory>;
    /** Whether FullStory will run in this tenant website */
    fullstoryEnabled: Maybe<Scalars['Boolean']['output']>;
    funnel: Maybe<Funnel>;
    funnels: FunnelConnection;
    /** Global styles for templates */
    globalStyles: Maybe<GlobalStyles>;
    googleAnalyticsKey: Maybe<Scalars['String']['output']>;
    /** Google Enabled as Sign in option */
    googleEnabled: Maybe<Scalars['Boolean']['output']>;
    /** A list of the groups that belong to the tenant */
    groups: Maybe<GroupConnection>;
    /** Whether tenant has any active stripe subscriptions */
    hasActiveSubscriptions: Scalars['Boolean']['output'];
    /** Whether tenant has dismissed the AI course outline notification card */
    hasDismissedAiCourseOutlineNotification: Maybe<
      Scalars['Boolean']['output']
    >;
    /** Whether tenant has dismissed the community notification card */
    hasDismissedCommunityNotification: Maybe<Scalars['Boolean']['output']>;
    /** Whether tenant has dismissed the mobile app notification card */
    hasDismissedMobileAppNotification: Maybe<Scalars['Boolean']['output']>;
    /** Whether tenant has published courses */
    hasPublishedCourses: Maybe<Scalars['Boolean']['output']>;
    /** Whether the tenant has seen the new leads notification or not */
    hasUnseenLeadsNotification: Maybe<Scalars['Boolean']['output']>;
    /** Hide publish upgrade prompt */
    hidePublishUpgradePrompt: Maybe<Scalars['Boolean']['output']>;
    id: Scalars['ID']['output'];
    /** Image upload URL */
    imageUploadUrl: Scalars['String']['output'];
    /** AWS bucket name */
    importBucketName: Maybe<Scalars['String']['output']>;
    instanceConnectionData: Maybe<InstanceConnectionData>;
    /** Instances for multi-instance login */
    instances: Maybe<InstanceConnection>;
    /** A list of the instructors that belong to the tenant */
    instructors: Maybe<InstructorConnection>;
    integrations: Maybe<Integrations>;
    /** Flag determining if communities as a product banner is hidden */
    isCaapAwarenessBannerHidden: Maybe<Scalars['Boolean']['output']>;
    /** Whether the site owner needs to validate their email */
    isEmailVerified: Maybe<Scalars['Boolean']['output']>;
    /** Flag determining if branded mobile banner is hidden */
    isMobileAwarenessBannerHidden: Maybe<Scalars['Boolean']['output']>;
    /** Whether Kobayashi is enabled */
    kobayashiEnabled: Maybe<Scalars['Boolean']['output']>;
    /** A list of tenant TenantLabs */
    labs: Maybe<FeaturesTenantLabConnection>;
    /** A list of lessons that belong to the tenant */
    lessons: LessonInterfaceConnection;
    /** Linkedin Enabled as Sign in option */
    linkedinEnabled: Maybe<Scalars['Boolean']['output']>;
    liveEvent: LiveEvent;
    /** A list of events */
    liveEvents: Maybe<LiveEventConnection>;
    /**
     * Site's logo
     * @deprecated Logo is deprecated
     */
    logo: Maybe<Scalars['String']['output']>;
    /** Looker dashboard */
    lookerDashboard: Maybe<LookerDashboard>;
    /** Mailchimp List */
    mailchimpLists: Maybe<Array<Scalars['JSON']['output']>>;
    /** Tenant mobile information */
    mobile: Maybe<Mobile>;
    /**
     * Site's mobile icon
     * @deprecated Mobile icon is deprecated
     */
    mobileIcon: Maybe<Scalars['String']['output']>;
    /** Site's name */
    name: Maybe<Scalars['String']['output']>;
    onboardingLink: Maybe<OnboardingLink>;
    onboardingSurveyResponses: Maybe<SurveyResponses>;
    orderBumps: Maybe<OrderBumpConnection>;
    /** Get a single order transaction detail by id */
    orderTransactionDetailItem: Maybe<OrderTransactionDetailItem>;
    /** A list of the order transactions detail that belongs to the tenant */
    orderTransactionDetails: OrderTransactionDetailConnection;
    /** A list of the order transactions that belong to the tenant */
    orderTransactions: OrderTransactionConnection;
    owner: Owner;
    /** A list of the custom site themes that belong to the tenant */
    pages: Maybe<CustomPageConnection>;
    /** Returns pages and products from site */
    pagesAndProducts: Maybe<Array<SearchPagesAndProducts>>;
    /** Site has paid custom pages enabled? */
    paidCustomPagesEnabled: Maybe<Scalars['Boolean']['output']>;
    /** Whether tenant has any payment provider connected */
    paymentProviderConnected: Scalars['Boolean']['output'];
    /** List of tenant payouts */
    payouts: Maybe<Payouts>;
    /** Count of payouts in ElasticSearch by date range */
    payoutsCount: Maybe<PayoutsCount>;
    /** Permitted SSO Domains */
    permittedSsoDomains: Maybe<Scalars['String']['output']>;
    /** A list of the posts that belong to the tenant */
    posts: Maybe<ElasticPostConnection>;
    /** A list of product prices that belongs to the tenant */
    prices: Maybe<ProductPriceConnection>;
    /** A list of products that belongs to the tenant */
    products: Maybe<ProductConnection>;
    /** A list of promotions that belongs to the tenant */
    promotions: Maybe<PromotionConnection>;
    /** Tenant Public URL */
    publicUrl: Maybe<Scalars['String']['output']>;
    rawId: Scalars['Int']['output'];
    /** List of looker tabs the user has access to */
    reportAllowlist: Maybe<Array<Scalars['String']['output']>>;
    revenuePartners: Maybe<PayeeConnection>;
    /** A list of course reviews that belongs to the tenant */
    reviews: Maybe<CourseReviewConnection>;
    /** A list of search bookmarks that belongs to the tenant */
    searchBookmarks: Maybe<SearchBookmarkConnection>;
    /** Whether students get a Site Welcome Email or not */
    sendLearnerWelcomeEmail: Maybe<Scalars['Boolean']['output']>;
    /** Site connected to shopify */
    shopifyConnected: Maybe<Scalars['Boolean']['output']>;
    /** Shopify Settings */
    shopifyShopUrl: Maybe<Scalars['String']['output']>;
    /** Whether the tenant should see any admin home banners */
    shouldShowAdminHomeBanner: Maybe<Scalars['Boolean']['output']>;
    /** Whether the tenant should show the funnel banner */
    shouldShowFunnelBanner: Maybe<Scalars['Boolean']['output']>;
    signUpSettings: Maybe<SignUpSettings>;
    /** URL for site builder thank you page */
    siteBuilderThankYouPageUrl: Maybe<Scalars['String']['output']>;
    /** The name of the current plan that the tenant is on */
    sitePlanNameWithPackages: Maybe<Scalars['String']['output']>;
    /** Whether tenants theme allows for multiple learning products */
    siteThemeSupportsMultiProducts: Maybe<Scalars['Boolean']['output']>;
    /** Enable tenant settings social share at chapter completion */
    socialShareChapterCompletionEnabled: Maybe<Scalars['Boolean']['output']>;
    /** Enable tenant settings social share at course completion */
    socialShareCourseCompletionEnabled: Maybe<Scalars['Boolean']['output']>;
    /** Social share enabled */
    socialShareEnabled: Maybe<Scalars['Boolean']['output']>;
    /** Tenant Settings Social Share Message */
    socialShareMessage: Maybe<Scalars['String']['output']>;
    /** List of social sign on options available */
    socialSignOnLinks: Maybe<Array<Scalars['JSON']['output']>>;
    /** A user's SSO login URL for multi-instance management */
    ssoUrl: Maybe<SsoUrl>;
    /** Stripe account session secret */
    stripeAccountSecret: Maybe<Scalars['String']['output']>;
    /** Whether tenant has Stripe standard account connected */
    stripeConnected: Scalars['Boolean']['output'];
    stripePublishableKey: Maybe<Scalars['String']['output']>;
    /** Generated student record html */
    studentCertificatesRecordHtml: Maybe<Scalars['String']['output']>;
    /** A list of student records */
    studentCertificatesRecords: Maybe<StudentCertificatesRecordConnection>;
    subdomain: Scalars['String']['output'];
    /** Tenant's support email */
    supportEmail: Maybe<Scalars['String']['output']>;
    /** A list of the courses, bundles, default and custom pages that belong to the tenant */
    templatePages: Maybe<TemplatePagesConnection>;
    /** Tenant currency decimals */
    tenantCurrencyDecimals: Scalars['Int']['output'];
    /** Site's theme data */
    themeData: Maybe<Scalars['JSON']['output']>;
    /** A list of the theme installations that belong to the tenant */
    themeInstallations: Maybe<ThemeInstallationConnection>;
    themeSupportsAfterPurchaseFlows: Maybe<Scalars['Boolean']['output']>;
    /** A list of the custom site themes that belong to the tenant */
    themes: Maybe<CustomSiteThemeConnection>;
    /** Current tenant */
    thinkificPaymentsAccount: Maybe<Account>;
    thinkificPaymentsAccountId: Maybe<Scalars['String']['output']>;
    /** Thinkific payment connected */
    thinkificPaymentsConnected: Scalars['Boolean']['output'];
    /** Platform publishable key from Stripe */
    thinkificPaymentsStripePublishableKey: Maybe<Scalars['String']['output']>;
    /** Site Owner's Distinct Mixpanel Tracking ID */
    trackingKey: Maybe<Scalars['String']['output']>;
    /** A list of the user imports that belong to the tenant */
    userImports: Maybe<UserImportConnection>;
    /** A list of users that belongs to the tenant */
    users: Maybe<UserConnection>;
    /** Whether tenant has performance checkout enabled */
    usesPerformanceCheckout: Maybe<Scalars['Boolean']['output']>;
    videoEngagementTotals: Maybe<VideoEngagementTotals>;
    /** A list of videos and their stats */
    videoStats: Maybe<VideoStatisticConnection>;
    videoStatsTotals: Maybe<VideoStatsTotals>;
    /** A list of the videos that belong to the tenant */
    videos: Maybe<VideoConnection>;
    /** A list of the webhooks that belong to the tenant */
    webhooks: Maybe<WebhookConnection>;
    /** Whether the site will show Thinkific brand references */
    whiteLabellingActivated: Maybe<Scalars['Boolean']['output']>;
    /** Whether tenant is using the new Zoom Admin app */
    zoomAdminApp: Scalars['Boolean']['output'];
    /** Whether tenant has Zoom account connected */
    zoomConnected: Scalars['Boolean']['output'];
    /** A list of zoom users that are associated with the tenant */
    zoomUsers: Maybe<ZoomUserConnection>;
  };

/** Tenant information */
export type TenantAffiliatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Tenant information */
export type TenantAppInstallationsArgs = {
  filter?: InputMaybe<AppInstallationFilter>;
};

/** Tenant information */
export type TenantAssignmentSubmissionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<SubmissionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SubmissionOrderBy>>;
};

/** Tenant information */
export type TenantAssignmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AssignmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AssignmentOrderBy>;
};

/** Tenant information */
export type TenantBulkEnrollmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<BulkEnrollmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Tenant information */
export type TenantBundlesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  usedForMemberships?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Tenant information */
export type TenantCartArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  calculateTax?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<CartFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<BillingAddressInput>;
};

/** Tenant information */
export type TenantCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CollectionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Tenant information */
export type TenantCertificateBlueprintsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<StudentCertificatesBlueprintFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Tenant information */
export type TenantCommunitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommunityFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CommunityOrderBy>;
};

/** Tenant information */
export type TenantCommunityArgs = {
  id: Scalars['ID']['input'];
};

/** Tenant information */
export type TenantCouponsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Tenant information */
export type TenantCourseAdminsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Tenant information */
export type TenantCourseEnrollmentTotalsArgs = {
  filter?: InputMaybe<CourseEnrollmentTotalsFilter>;
};

/** Tenant information */
export type TenantCoursesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CourseFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CourseOrderBy>;
};

/** Tenant information */
export type TenantCustomProfileFieldDefinitionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Tenant information */
export type TenantDashboardPromptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<DashboardPromptFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Tenant information */
export type TenantElasticAssignmentSubmissionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  cursorAfter?: InputMaybe<Array<Scalars['String']['input']>>;
  filter?: InputMaybe<ElasticSubmissionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SubmissionOrderBy>>;
};

/** Tenant information */
export type TenantFeatureFlagsArgs = {
  token?: InputMaybe<Scalars['String']['input']>;
};

/** Tenant information */
export type TenantFunnelArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Tenant information */
export type TenantFunnelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Tenant information */
export type TenantGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<GroupFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Tenant information */
export type TenantInstanceConnectionDataArgs = {
  filter?: InputMaybe<OrganizationUserUserFilter>;
};

/** Tenant information */
export type TenantInstancesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Tenant information */
export type TenantInstructorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<InstructorFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Tenant information */
export type TenantLabsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<FeaturesLabsFilters>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<FeaturesLabsOrderBy>;
};

/** Tenant information */
export type TenantLessonsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<LessonFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<LessonOrderBy>;
};

/** Tenant information */
export type TenantLiveEventArgs = {
  id: Scalars['ID']['input'];
};

/** Tenant information */
export type TenantLiveEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  eventSourceId: Scalars['ID']['input'];
  eventSourceType: EventSourceTypes;
  filter?: InputMaybe<EventsFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<EventsOrderBy>;
};

/** Tenant information */
export type TenantLogoArgs = {
  size?: InputMaybe<Logo>;
};

/** Tenant information */
export type TenantLookerDashboardArgs = {
  dashboardName: LookerDashboardNameEnum;
};

/** Tenant information */
export type TenantOnboardingLinkArgs = {
  refreshUrl: Scalars['String']['input'];
  returnUrl: Scalars['String']['input'];
};

/** Tenant information */
export type TenantOrderBumpsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<OrderBumpFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderBumpOrderBy>;
};

/** Tenant information */
export type TenantOrderTransactionDetailItemArgs = {
  filter: OrderTransactionDetailItemFilter;
};

/** Tenant information */
export type TenantOrderTransactionDetailsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<OrderTransactionDetailFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderTransactionDetailOrderBy>;
  pagination?: InputMaybe<Pagination>;
};

/** Tenant information */
export type TenantOrderTransactionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<OrderTransactionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderTransactionOrderBy>;
  pagination?: InputMaybe<Pagination>;
};

/** Tenant information */
export type TenantPagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomPageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CustomPageOrderBy>;
};

/** Tenant information */
export type TenantPagesAndProductsArgs = {
  filter?: InputMaybe<PageAndProductFilter>;
};

/** Tenant information */
export type TenantPayoutsArgs = {
  filter?: InputMaybe<PayoutsFilter>;
};

/** Tenant information */
export type TenantPayoutsCountArgs = {
  filter?: InputMaybe<PayoutsCountFilter>;
};

/** Tenant information */
export type TenantPostsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ElasticPostFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ElasticPostOrderBy>;
  pagination?: InputMaybe<Pagination>;
};

/** Tenant information */
export type TenantPricesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProductPriceFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Tenant information */
export type TenantProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProductFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ProductOrderBy>;
};

/** Tenant information */
export type TenantPromotionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PromotionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Tenant information */
export type TenantRevenuePartnersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Tenant information */
export type TenantReviewsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CourseReviewFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Tenant information */
export type TenantSearchBookmarksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<SearchBookmarkFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Tenant information */
export type TenantSsoUrlArgs = {
  users?: InputMaybe<SsoUsers>;
};

/** Tenant information */
export type TenantStudentCertificatesRecordHtmlArgs = {
  id: Scalars['ID']['input'];
  manifest?: InputMaybe<Scalars['JSON']['input']>;
};

/** Tenant information */
export type TenantStudentCertificatesRecordsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<StudentCertificatesRecordFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Tenant information */
export type TenantTemplatePagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TemplatePagesFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Tenant information */
export type TenantThemeInstallationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ThemeInstallationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ThemeInstallationOrderByFilter>;
};

/** Tenant information */
export type TenantThemesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomSiteThemeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CustomSiteThemeOrderByFilter>;
};

/** Tenant information */
export type TenantUserImportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserImportFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Tenant information */
export type TenantUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderBy>;
};

/** Tenant information */
export type TenantVideoEngagementTotalsArgs = {
  filter?: InputMaybe<VideoFilter>;
};

/** Tenant information */
export type TenantVideoStatsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<VideoFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<VideoOrderBy>;
  pagination: Pagination;
};

/** Tenant information */
export type TenantVideoStatsTotalsArgs = {
  filter?: InputMaybe<VideoFilter>;
};

/** Tenant information */
export type TenantVideosArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<VideoFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<VideoOrderBy>;
};

/** Tenant information */
export type TenantWebhooksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<WebhookFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Tenant information */
export type TenantZoomUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ZoomUserFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ZoomUserOrderByFilter>;
};

/** BillingInfo query parameters */
export type TenantAdminCheckoutBillingInfoFilter = {
  /** Site subscription request token */
  token: Scalars['String']['input'];
};

/** Inputs to verify and update tenant basic info */
export type TenantBasicInfoInput = {
  /** Tenants Email Address */
  email: Scalars['String']['input'];
  /** Tenants First Name */
  firstName: Scalars['String']['input'];
  /** Tenants Last Name */
  lastName: Scalars['String']['input'];
  /** Tenants Mixpanel ID */
  mixpanelId?: InputMaybe<Scalars['String']['input']>;
  /** Tenants Account Password */
  password: Scalars['String']['input'];
  /** Tenant affiliate code */
  referringAffiliateCode?: InputMaybe<Scalars['String']['input']>;
  /** Tenant referring PartnerStack key */
  referringPartnerKey?: InputMaybe<Scalars['String']['input']>;
};

/** Inputs to verify and update tenant billing info */
export type TenantBillingInfoInput = {
  /** Tenant's address, line 1 */
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  /** Tenant's address, line 2 */
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  /** Tenant's city */
  city?: InputMaybe<Scalars['String']['input']>;
  /** Tenants Confirmation of Information */
  confirmedByCustomer: Scalars['Boolean']['input'];
  /** Tenant's country code */
  countryCode: Scalars['String']['input'];
  /** Tenant's billing name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Tenant's billing state */
  state?: InputMaybe<Scalars['String']['input']>;
  /** Tenant Tax Exempt Status */
  taxExempt?: InputMaybe<Scalars['Boolean']['input']>;
  /** Tenant's VAT number */
  vatNumber?: InputMaybe<Scalars['String']['input']>;
  /** Tenant's billing zip code */
  zipCode: Scalars['String']['input'];
};

/** Tenant checklist information */
export type TenantChecklist = Node & {
  __typename?: 'TenantChecklist';
  /** Checklist ID */
  checklistId: Scalars['ID']['output'];
  /** Tenant Checklist created at */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Tenant ID */
  tenantId: Scalars['ID']['output'];
  /** Tenant Checklist updated at */
  updatedAt: Scalars['DateTime']['output'];
  /** Visible */
  visible: Maybe<Scalars['Boolean']['output']>;
};

/** Tenant checklist item information */
export type TenantChecklistItem = Node & {
  __typename?: 'TenantChecklistItem';
  /** Checklist Item ID */
  checklistItemId: Scalars['ID']['output'];
  /** Tenant Checklist completed at */
  completedAt: Maybe<Scalars['DateTime']['output']>;
  /** Tenant Checklist created at */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** Tenant Checklist ID */
  tenantChecklistId: Scalars['ID']['output'];
  /** Tenant ID */
  tenantId: Scalars['ID']['output'];
  /** Tenant Checklist updated at */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** Input fields for a TenantLab */
export type TenantLabInput = {
  /** If a tenant opted in to a lab */
  optedIn?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Fields to update Tenant */
export type TenantPatch = {
  /** Whether the tenant has dismissed the onboarding modal */
  completedOnboardingModal?: InputMaybe<Scalars['Boolean']['input']>;
  /** The course player color */
  coursePlayerColor?: InputMaybe<Scalars['String']['input']>;
  /** The course player color */
  coursePlayerLogo?: InputMaybe<Scalars['String']['input']>;
  /** Tenant's favicon */
  favicon?: InputMaybe<Scalars['String']['input']>;
  /** Tenant has dismissed the AI course outline notification card */
  hasDismissedAiCourseOutlineNotification?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  /** Tenant has dismissed the community notification card */
  hasDismissedCommunityNotification?: InputMaybe<Scalars['Boolean']['input']>;
  /** Tenant has dismissed the mobile app notification card */
  hasDismissedMobileAppNotification?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether the tenant has seen the new leads notification or not */
  hasUnseenLeadsNotification?: InputMaybe<Scalars['Boolean']['input']>;
  /** Hide publish upgrade prompt */
  hidePublishUpgradePrompt?: InputMaybe<Scalars['Boolean']['input']>;
  /** Flag determining if communities as a product banner is hidden */
  isCaapAwarenessBannerHidden?: InputMaybe<Scalars['Boolean']['input']>;
  /** Flag determining if funnels as a product banner is hidden */
  isFunnelAwarenessBannerHidden?: InputMaybe<Scalars['Boolean']['input']>;
  /** Flag determining if branded mobile banner is hidden */
  isMobileAwarenessBannerHidden?: InputMaybe<Scalars['Boolean']['input']>;
  /** Tenant's logo */
  logo?: InputMaybe<Scalars['String']['input']>;
  /** Whether the mobile app is enabled for a tenant or not */
  mobileEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Tenant's mobileIcon */
  mobileIcon?: InputMaybe<Scalars['String']['input']>;
  /** Tenant's name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Whether the tenant has skipped the onboarding V3 experience or not */
  onboardingV3Skipped?: InputMaybe<Scalars['Boolean']['input']>;
  /** Tenant's theme data */
  themeData?: InputMaybe<Scalars['JSON']['input']>;
};

/** Attributes for Terms and conditions Sign up settings */
export type TermsAndConditionsInput = {
  /** Privacy Policy agreement settings */
  privacyPolicy?: InputMaybe<AgreementSettingsInput>;
  /** Terms of use agreement settings */
  termsOfUse?: InputMaybe<AgreementSettingsInput>;
};

/** Text Comparison options only accepted one at a time */
export type TextComparison = {
  /** Value contains */
  contains?: InputMaybe<Scalars['String']['input']>;
  /** Value is equal to */
  equals?: InputMaybe<Scalars['String']['input']>;
  /** Value does not contain */
  notContains?: InputMaybe<Scalars['String']['input']>;
  /** Value is not equal to */
  notEquals?: InputMaybe<Scalars['String']['input']>;
};

/** text lesson information */
export type TextLesson = LessonInterface &
  Node & {
    __typename?: 'TextLesson';
    /** Content's chapter */
    chapter: Chapter;
    /** contentable id */
    contentableId: Scalars['ID']['output'];
    /** Content's course */
    course: Course;
    /** Content's created at date */
    createdAt: Scalars['DateTime']['output'];
    /** Lesson default icon */
    defaultLessonTypeIcon: Scalars['String']['output'];
    /** Is discussion enabled */
    discussionsEnabled: Maybe<Scalars['Boolean']['output']>;
    /** Lesson draft status */
    draft: Scalars['Boolean']['output'];
    /** Lesson's associated Drip Schedule Item */
    dripScheduleItem: Maybe<DripScheduleItem>;
    /** lesson drip status */
    dripStatus: Maybe<Scalars['JSON']['output']>;
    /** Is expand enabled */
    expandEnabled: Maybe<Scalars['Boolean']['output']>;
    /** Is lesson free */
    free: Maybe<Scalars['Boolean']['output']>;
    /** Lesson html text */
    htmlDescription: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    /** Current user lesson status */
    lessonCompleted: Maybe<Scalars['Boolean']['output']>;
    /** lesson drip schedule */
    lessonHasDripSchedule: Maybe<Scalars['String']['output']>;
    /** Lesson integer ID */
    lessonId: Scalars['ID']['output'];
    /** Lesson icon */
    lessonTypeIcon: Maybe<LessonIconEnum>;
    /** Lesson label */
    lessonTypeLabel: Maybe<Scalars['String']['output']>;
    /** Lesson url */
    lessonUrl: Maybe<Scalars['String']['output']>;
    /** Lesson name */
    name: Scalars['String']['output'];
    /** Lesson position */
    position: Scalars['Int']['output'];
    /** Is lesson prerequisite */
    prerequisite: Scalars['Boolean']['output'];
    /** Lesson's slug */
    slug: Maybe<Scalars['String']['output']>;
    /** Lesson Type */
    type: Scalars['String']['output'];
    /** Whether the lesson is still under construction */
    underConstruction: Scalars['Boolean']['output'];
    /** Content's updated at date */
    updatedAt: Scalars['DateTime']['output'];
    /** User content for lesson */
    userContents: Maybe<UserContentConnection>;
    /** User quizzes for lesson */
    userQuizzes: Maybe<UserQuizConnection>;
    /** User surveys for lesson */
    userSurveys: Maybe<UserSurveyConnection>;
  };

/** text lesson information */
export type TextLessonUserContentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserContentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserContentOrderByFilter>;
};

/** text lesson information */
export type TextLessonUserQuizzesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserQuizFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserQuizOrderBy>;
};

/** text lesson information */
export type TextLessonUserSurveysArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserSurveyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserSurveyOrderBy>;
};

/** A Theme Installation */
export type ThemeInstallation = Node & {
  __typename?: 'ThemeInstallation';
  /** Is the theme active? */
  active: Maybe<Scalars['Boolean']['output']>;
  /** Can this theme be renamed? */
  canRename: Maybe<Scalars['Boolean']['output']>;
  /** Can this theme be restored? */
  canRestore: Maybe<Scalars['Boolean']['output']>;
  /** Can this theme be upgraded? */
  canUpgrade: Maybe<Scalars['Boolean']['output']>;
  /** Installation version of current theme */
  currentThemeInstallationVersion: Maybe<ThemeInstallationVersion>;
  id: Scalars['ID']['output'];
  /** The name of the theme */
  name: Maybe<Scalars['String']['output']>;
  /** Does this theme support Site Builder */
  supportsSiteBuilder: Maybe<Scalars['Boolean']['output']>;
  /** A list of the versions in this theme installation */
  themeInstallationVersions: Maybe<ThemeInstallationVersionConnection>;
  /** Theme library item assigned to this installation */
  themeLibraryItem: Maybe<ThemeLibraryItem>;
};

/** A Theme Installation */
export type ThemeInstallationThemeInstallationVersionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for ThemeInstallation. */
export type ThemeInstallationConnection = {
  __typename?: 'ThemeInstallationConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ThemeInstallationEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<ThemeInstallation>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ThemeInstallationEdge = {
  __typename?: 'ThemeInstallationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<ThemeInstallation>;
};

/** Possible fields to filter theme installations */
export type ThemeInstallationFilter = {
  /** Search by activation status */
  active?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Field and direction to order theme installations */
export type ThemeInstallationOrderByFilter = {
  direction: OrderDirection;
  field: ThemeInstallationOrderFieldFilter;
};

/** Possible fields to order theme installations */
export enum ThemeInstallationOrderFieldFilter {
  /** Order theme installations by installed_at */
  InstalledAt = 'INSTALLED_AT',
}

/** A Theme Installation Version */
export type ThemeInstallationVersion = Node & {
  __typename?: 'ThemeInstallationVersion';
  /** Is this the current version? */
  current: Maybe<Scalars['Boolean']['output']>;
  /** Custom site theme assigned to this installation */
  customSiteTheme: Maybe<CustomSiteTheme>;
  id: Scalars['ID']['output'];
  /** The installation type. One of: [duplicated, imported, library, library_forked] */
  installationType: Maybe<Scalars['String']['output']>;
  /** Created at date */
  installedAt: Maybe<Scalars['DateTime']['output']>;
  /** Theme library item version assigned to this installation */
  themeLibraryItemVersion: Maybe<ThemeLibraryItemVersion>;
};

/** The connection type for ThemeInstallationVersion. */
export type ThemeInstallationVersionConnection = {
  __typename?: 'ThemeInstallationVersionConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ThemeInstallationVersionEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<ThemeInstallationVersion>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ThemeInstallationVersionEdge = {
  __typename?: 'ThemeInstallationVersionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<ThemeInstallationVersion>;
};

/** The theme library items */
export type ThemeLibraryItem = Node & {
  __typename?: 'ThemeLibraryItem';
  /** ThemeLibraryItem is the site's current active theme? */
  active: Maybe<Scalars['Boolean']['output']>;
  /** ThemeLibraryItem's card image */
  cardImage: Maybe<Scalars['String']['output']>;
  /** ThemeLibraryItem's created at */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** ThemeLibraryItem's description */
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** ThemeLibraryItem's name */
  name: Maybe<Scalars['String']['output']>;
  /** ThemeLibraryItem's URL */
  previewUrl: Maybe<Scalars['String']['output']>;
  /** ThemeLibraryItem's status type */
  status: Maybe<Scalars['String']['output']>;
  /** ThemeLibraryItem's updated at */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  /** All versions of the theme library item */
  versions: Maybe<ThemeLibraryItemVersionConnection>;
};

/** The theme library items */
export type ThemeLibraryItemVersionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ThemeLibraryItemVersionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for ThemeLibraryItem. */
export type ThemeLibraryItemConnection = {
  __typename?: 'ThemeLibraryItemConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ThemeLibraryItemEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<ThemeLibraryItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ThemeLibraryItemEdge = {
  __typename?: 'ThemeLibraryItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<ThemeLibraryItem>;
};

/** Possible fields to filter ThemeLibraryItems */
export type ThemeLibraryItemFilter = {
  /** Search by id */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Search by name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Search by system version */
  systemVersion?: InputMaybe<SystemVersionFilter>;
};

/** The theme library item versions */
export type ThemeLibraryItemVersion = Node & {
  __typename?: 'ThemeLibraryItemVersion';
  /** ThemeLibraryItemVersion's description */
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** ThemeLibraryItemVersion system's major version */
  majorSystemVersion: Maybe<Scalars['Int']['output']>;
  /** ThemeLibraryItemVersion's major version */
  majorVersion: Maybe<Scalars['Int']['output']>;
  /** ThemeLibraryItemVersion system's minor version */
  minorSystemVersion: Maybe<Scalars['Int']['output']>;
  /** ThemeLibraryItemVersion's minor version */
  minorVersion: Maybe<Scalars['Int']['output']>;
  /** ThemeLibraryItemVersion system's patch version */
  patchSystemVersion: Maybe<Scalars['Int']['output']>;
  /** ThemeLibraryItemVersion's patch version */
  patchVersion: Maybe<Scalars['Int']['output']>;
  /** Flag to indicate if ThemeLibraryItemVersion is published */
  published: Maybe<Scalars['Boolean']['output']>;
  /** All styles of this version of theme library item */
  styles: Maybe<ThemeLibraryItemVersionStyleConnection>;
  /** ThemeLibraryItem's version */
  version: Maybe<Scalars['Int']['output']>;
};

/** The theme library item versions */
export type ThemeLibraryItemVersionStylesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ThemeLibraryItemVersionStyleOrderByFilter>;
};

/** The connection type for ThemeLibraryItemVersion. */
export type ThemeLibraryItemVersionConnection = {
  __typename?: 'ThemeLibraryItemVersionConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ThemeLibraryItemVersionEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<ThemeLibraryItemVersion>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ThemeLibraryItemVersionEdge = {
  __typename?: 'ThemeLibraryItemVersionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<ThemeLibraryItemVersion>;
};

/** Possible fields to filter ThemeLibraryItemsVersions */
export type ThemeLibraryItemVersionFilter = {
  /** Search by latest version */
  latest?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The theme library item version styles */
export type ThemeLibraryItemVersionStyle = Node & {
  __typename?: 'ThemeLibraryItemVersionStyle';
  /** ThemeLibraryItemVersionStyle's card image */
  cardImage: Maybe<Scalars['String']['output']>;
  /** ThemeLibraryItemVersionStyle's color */
  color: Maybe<Scalars['String']['output']>;
  /** ThemeLibraryItemVersionStyle's desktop Image */
  desktopImage: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** ThemeLibraryItemVersionStyle's images */
  images: Maybe<Array<ThemeLibraryItemVersionStyleImage>>;
  /** ThemeLibraryItemVersionStyle's main Image */
  mainImage: Maybe<Scalars['String']['output']>;
  /** ThemeLibraryItemVersionStyle's mobile Image */
  mobileImage: Maybe<Scalars['String']['output']>;
  /** ThemeLibraryItemVersionStyle's name */
  name: Maybe<Scalars['String']['output']>;
  /** ThemeLibraryItemVersionStyle's preset slug */
  presetSlug: Maybe<Scalars['String']['output']>;
  /** ThemeLibraryItemVersionStyle's color */
  primaryColor: Maybe<Scalars['String']['output']>;
  /** ThemeLibraryItemVersionStyle's color */
  secondaryColor: Maybe<Scalars['String']['output']>;
  /** ThemeLibraryItemVersionStyle's color */
  tertiaryColor: Maybe<Scalars['String']['output']>;
};

/** The connection type for ThemeLibraryItemVersionStyle. */
export type ThemeLibraryItemVersionStyleConnection = {
  __typename?: 'ThemeLibraryItemVersionStyleConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ThemeLibraryItemVersionStyleEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<ThemeLibraryItemVersionStyle>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ThemeLibraryItemVersionStyleEdge = {
  __typename?: 'ThemeLibraryItemVersionStyleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<ThemeLibraryItemVersionStyle>;
};

/** The theme library item version style images */
export type ThemeLibraryItemVersionStyleImage = {
  __typename?: 'ThemeLibraryItemVersionStyleImage';
  id: Scalars['ID']['output'];
  /** ThemeLibraryItemVersionStyleImage's label */
  label: Maybe<Scalars['String']['output']>;
  /** ThemeLibraryItemVersionStyleImage's image */
  url: Maybe<Scalars['String']['output']>;
};

/** Possible fields to order styles */
export type ThemeLibraryItemVersionStyleOrderByFilter = {
  direction: OrderDirection;
  field: ThemeLibraryItemVersionStyleOrderFieldFilter;
};

/** Possible fields to order styles */
export enum ThemeLibraryItemVersionStyleOrderFieldFilter {
  /** Order styles by id */
  Id = 'ID',
  /** Order styles by name */
  Name = 'NAME',
}

/** A preset for a custom site theme */
export type ThemePreset = Node & {
  __typename?: 'ThemePreset';
  /** ThemePreset's active */
  active: Maybe<Scalars['Boolean']['output']>;
  /** ThemePreset's created at date */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** ThemePreset's description */
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** ThemePreset's manifest schema */
  manifestSchema: Maybe<Scalars['JSON']['output']>;
  /** ThemePreset's slug */
  slug: Maybe<Scalars['String']['output']>;
  /** ThemePreset's title */
  title: Maybe<Scalars['String']['output']>;
  /** ThemePreset's updated at date */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** The connection type for ThemePreset. */
export type ThemePresetConnection = {
  __typename?: 'ThemePresetConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ThemePresetEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<ThemePreset>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ThemePresetEdge = {
  __typename?: 'ThemePresetEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<ThemePreset>;
};

/** Possible fields to filter presets */
export type ThemePresetFilter = {
  /** only active preset */
  active?: InputMaybe<Scalars['Boolean']['input']>;
  /** ThemePreset's id */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** ThemePreset's slug */
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** Possible fields to order presets */
export type ThemePresetOrderByFilter = {
  direction: OrderDirection;
  field: ThemePresetOrderFieldFilter;
};

/** Possible fields to order presets */
export enum ThemePresetOrderFieldFilter {
  /** Order presets by description */
  Description = 'DESCRIPTION',
  /** Order presets by id */
  Id = 'ID',
  /** Order presets by slug */
  Slug = 'SLUG',
  /** Order presets by title */
  Title = 'TITLE',
}

export type ThinkificPaymentsTenantMutationInterface = {
  /** Creates a Thinkific Payments account for tenant */
  enableThinkificPayments: Maybe<EnableThinkificPaymentsPayload>;
  /** Refund a transaction */
  issueRefund: Maybe<IssueRefundPayload>;
  /** Set a Bank account for payouts */
  setBankAccount: Maybe<SetBankAccountPayload>;
  /** Sets tenant's course pricing currency */
  setCurrency: Maybe<SetCurrencyPayload>;
  /** Updates tenant's tax registration status and id */
  updateTaxRegistration: Maybe<UpdateTaxRegistrationPayload>;
};

export type ThinkificPaymentsTenantMutationInterfaceEnableThinkificPaymentsArgs = {
  input: EnableThinkificPaymentsInput;
};

export type ThinkificPaymentsTenantMutationInterfaceIssueRefundArgs = {
  input: IssueRefundInput;
};

export type ThinkificPaymentsTenantMutationInterfaceSetBankAccountArgs = {
  input: SetBankAccountInput;
};

export type ThinkificPaymentsTenantMutationInterfaceSetCurrencyArgs = {
  input: SetCurrencyInput;
};

export type ThinkificPaymentsTenantMutationInterfaceUpdateTaxRegistrationArgs = {
  input: UpdateTaxRegistrationInput;
};

export type ThinkificPaymentsTenantQueryInterface = {
  /** Current tenant balances */
  balance: Maybe<AccountBalance>;
  onboardingLink: Maybe<OnboardingLink>;
  /** List of tenant payouts */
  payouts: Maybe<Payouts>;
  /** Count of payouts in ElasticSearch by date range */
  payoutsCount: Maybe<PayoutsCount>;
  /** Current tenant */
  thinkificPaymentsAccount: Maybe<Account>;
  /** Platform publishable key from Stripe */
  thinkificPaymentsStripePublishableKey: Maybe<Scalars['String']['output']>;
};

export type ThinkificPaymentsTenantQueryInterfaceOnboardingLinkArgs = {
  refreshUrl: Scalars['String']['input'];
  returnUrl: Scalars['String']['input'];
};

export type ThinkificPaymentsTenantQueryInterfacePayoutsArgs = {
  filter?: InputMaybe<PayoutsFilter>;
};

export type ThinkificPaymentsTenantQueryInterfacePayoutsCountArgs = {
  filter?: InputMaybe<PayoutsCountFilter>;
};

/** Autogenerated input type of ToggleReactionOnPost */
export type ToggleReactionOnPostInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the post the user is following */
  id: Scalars['ID']['input'];
  /** The type of reaction */
  reactionType: PostReactionType;
};

/** Autogenerated return type of ToggleReactionOnPost */
export type ToggleReactionOnPostPayload = {
  __typename?: 'ToggleReactionOnPostPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The post */
  post: Maybe<Post>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of ToggleReactionOnReply */
export type ToggleReactionOnReplyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the reply the user is following */
  id: Scalars['ID']['input'];
  /** The type of reaction */
  reactionType: PostReactionType;
};

/** Autogenerated return type of ToggleReactionOnReply */
export type ToggleReactionOnReplyPayload = {
  __typename?: 'ToggleReactionOnReplyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The reply */
  reply: Maybe<Reply>;
  userErrors: Maybe<Array<UserError>>;
};

/** Transaction types */
export enum Transaction {
  /** When a transaction is a single payment */
  Payment = 'PAYMENT',
  /** When a transaction is a full or a partial refund */
  Refund = 'REFUND',
}

/** Transaction coupon info */
export type TransactionCoupon = {
  __typename?: 'TransactionCoupon';
  /** Coupon code */
  code: Maybe<Scalars['String']['output']>;
  /** Coupon discount amount */
  discount: Maybe<Scalars['Float']['output']>;
  /** Coupon description */
  name: Maybe<Scalars['String']['output']>;
};

/** Transaction fee breakdown */
export type TransactionFeeBreakdown = {
  __typename?: 'TransactionFeeBreakdown';
  /** Fee amount */
  amount: Scalars['Int']['output'];
  /** Fee formula */
  formula: Maybe<Scalars['String']['output']>;
  /** Fee name */
  name: Scalars['String']['output'];
};

/** The connection type for TransactionFeeBreakdown. */
export type TransactionFeeBreakdownConnection = {
  __typename?: 'TransactionFeeBreakdownConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<TransactionFeeBreakdownEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<TransactionFeeBreakdown>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type TransactionFeeBreakdownEdge = {
  __typename?: 'TransactionFeeBreakdownEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<TransactionFeeBreakdown>;
};

/** Allowed transaction price types */
export enum TransactionPricing {
  /** One time payment */
  OneTime = 'ONE_TIME',
  /** Payment plan */
  PaymentPlan = 'PAYMENT_PLAN',
  /** Subscription plan */
  Subscription = 'SUBSCRIPTION',
}

/** Represents the final status of a thinkific payments transaction */
export enum TransactionStatus {
  /** There was an issue with this transaction */
  Failed = 'FAILED',
  /** This transaction represents a total refund of the original order */
  FullyRefunded = 'FULLY_REFUNDED',
  /** This transaction represents a partial refund on the original order */
  PartiallyRefunded = 'PARTIALLY_REFUNDED',
  /** This transaction has succeeded */
  Succeeded = 'SUCCEEDED',
  /** This transaction represents a tax-only refund on the original order */
  TaxRefunded = 'TAX_REFUNDED',
}

/** User info from a specific transaction */
export type TransactionUser = {
  __typename?: 'TransactionUser';
  /** User's email */
  email: Maybe<Scalars['EmailAddress']['output']>;
  /** User full name */
  fullName: Maybe<Scalars['String']['output']>;
  /** User's postal code */
  postalCode: Maybe<Scalars['String']['output']>;
  /** User's region/province/state */
  region: Maybe<Scalars['String']['output']>;
  /** User's tax exemption status */
  taxExempt: Maybe<Scalars['Boolean']['output']>;
  /** User's UUID */
  userGid: Maybe<Scalars['String']['output']>;
  /** User's ID */
  userId: Maybe<Scalars['Int']['output']>;
};

/** Autogenerated input type of TriggerChannelCourseValidation */
export type TriggerChannelCourseValidationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The Channel Course's ID */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of TriggerChannelCourseValidation */
export type TriggerChannelCourseValidationPayload = {
  __typename?: 'TriggerChannelCourseValidationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Unarchive Video Result */
export type UnarchiveVideo = ResponseInterface & {
  __typename?: 'UnarchiveVideo';
  /**
   * Response Extra Data
   * @deprecated response fields should be well defined following a object structure
   */
  data: Maybe<Scalars['JSON']['output']>;
  errors: Maybe<Array<Maybe<Error>>>;
  /** Response Message */
  message: Maybe<Scalars['String']['output']>;
  /** Return status: SUCCESS, PARTIAL_SUCCESS or FAILED */
  status: Maybe<ResponseStatus>;
};

/** Input field for unarchiving a video */
export type UnarchiveVideoInput = {
  /** The video ID */
  videoId: Scalars['String']['input'];
};

/** Autogenerated return type of UndoHideBookmarkPreset */
export type UndoHideBookmarkPresetPayload = {
  __typename?: 'UndoHideBookmarkPresetPayload';
  /**
   * Response errors
   * @deprecated Backwards compatibility for old response status
   */
  errors: Maybe<Array<Error>>;
  /** Response message */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of UnfollowPost */
export type UnfollowPostInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the post the user is following */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UnfollowPost */
export type UnfollowPostPayload = {
  __typename?: 'UnfollowPostPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The post */
  post: Maybe<Post>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of UnfollowReply */
export type UnfollowReplyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The post ID */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UnfollowReply */
export type UnfollowReplyPayload = {
  __typename?: 'UnfollowReplyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The reply */
  reply: Maybe<Reply>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of UninstallApp */
export type UninstallAppInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Detail for other reason for uninstalling the third-party oauth app */
  detail?: InputMaybe<Scalars['String']['input']>;
  /** ID of oauth app install */
  id: Scalars['Int']['input'];
  /** Reason for uninstalling the third-party oauth app */
  reason?: InputMaybe<ReasonType>;
};

/** Result of uninstalling an oauth app */
export type UninstallAppResult = ResponseInterface & {
  __typename?: 'UninstallAppResult';
  /**
   * Response Extra Data
   * @deprecated response fields should be well defined following a object structure
   */
  data: Maybe<Scalars['JSON']['output']>;
  errors: Maybe<Array<Maybe<Error>>>;
  /** Response Message */
  message: Maybe<Scalars['String']['output']>;
  /** Return status: SUCCESS, PARTIAL_SUCCESS or FAILED */
  status: Maybe<ResponseStatus>;
};

/** Autogenerated input type of UnpinPost */
export type UnpinPostInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the post the admin has pinned */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UnpinPost */
export type UnpinPostPayload = {
  __typename?: 'UnpinPostPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The post */
  post: Maybe<Post>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of UnpublishFunnel */
export type UnpublishFunnelInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The Funnel ID */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UnpublishFunnel */
export type UnpublishFunnelPayload = {
  __typename?: 'UnpublishFunnelPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The updated Funnel */
  funnel: Maybe<Funnel>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of UnpublishSitePage */
export type UnpublishSitePageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID for the site page to be unpublished */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UnpublishSitePage */
export type UnpublishSitePagePayload = {
  __typename?: 'UnpublishSitePagePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The site page */
  sitePage: Maybe<TemplatePages>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of UpdateAccountNotification */
export type UpdateAccountNotificationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Notification state */
  isEnabled: Scalars['Boolean']['input'];
  slug?: InputMaybe<AccountNotificationSlugType>;
};

/** Autogenerated return type of UpdateAccountNotification */
export type UpdateAccountNotificationPayload = {
  __typename?: 'UpdateAccountNotificationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Updates an active campaign */
export type UpdateActiveCampaign = ResponseInterface & {
  __typename?: 'UpdateActiveCampaign';
  /**
   * Response Extra Data
   * @deprecated response fields should be well defined following a object structure
   */
  data: Maybe<Scalars['JSON']['output']>;
  errors: Maybe<Array<Maybe<Error>>>;
  /** Response Message */
  message: Maybe<Scalars['String']['output']>;
  /** Return status: SUCCESS, PARTIAL_SUCCESS or FAILED */
  status: Maybe<ResponseStatus>;
};

/** Autogenerated input type of UpdateActiveCampaign */
export type UpdateActiveCampaignInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Active Campaign's fields */
  patch: ActiveCampaignInput;
};

/** Autogenerated input type of UpdateAdminNotification */
export type UpdateAdminNotificationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Notification state */
  isEnabled: Scalars['Boolean']['input'];
  slug?: InputMaybe<AdminNotificationSlugType>;
};

/** Autogenerated return type of UpdateAdminNotification */
export type UpdateAdminNotificationPayload = {
  __typename?: 'UpdateAdminNotificationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of UpdateAfterPurchaseFlow */
export type UpdateAfterPurchaseFlowInput = {
  /** Attributes to be bulk updated */
  bulk?: InputMaybe<AfterPurchaseFlowBulkInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The After Purchase Flow's ID */
  id: Scalars['ID']['input'];
  /** Attributes to be updated */
  patch?: InputMaybe<AfterPurchaseFlowInput>;
};

/** Autogenerated return type of UpdateAfterPurchaseFlow */
export type UpdateAfterPurchaseFlowPayload = {
  __typename?: 'UpdateAfterPurchaseFlowPayload';
  afterPurchaseFlow: Maybe<AfterPurchaseFlow>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /**
   * Response error
   * @deprecated Backwards compatibility for old response error
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of UpdateAfterPurchaseFlowProduct */
export type UpdateAfterPurchaseFlowProductInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The After Purchase Flow Product's ID */
  id: Scalars['ID']['input'];
  /** Product position that is associated with after purchase flow product */
  productPosition: Scalars['Int']['input'];
};

/** Autogenerated return type of UpdateAfterPurchaseFlowProduct */
export type UpdateAfterPurchaseFlowProductPayload = {
  __typename?: 'UpdateAfterPurchaseFlowProductPayload';
  /** after purchase flow product */
  afterPurchaseFlowProduct: Maybe<AfterPurchaseFlowProduct>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /**
   * Response error
   * @deprecated Backwards compatibility for old response error
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of UpdateAppSettings */
export type UpdateAppSettingsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of oauth app install */
  id: Scalars['ID']['input'];
  /** OAuthAppInstall settings */
  settings: Scalars['JSON']['input'];
};

/** Autogenerated return type of UpdateAppSettings */
export type UpdateAppSettingsPayload = {
  __typename?: 'UpdateAppSettingsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of UpdateAssignmentLesson */
export type UpdateAssignmentLessonInput = {
  assignmentTemplateInput?: InputMaybe<UpdateAssignmentTemplateInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the lesson */
  lessonId: Scalars['ID']['input'];
  lessonInput: LessonInput;
  lessonWithAssignmentInput: LessonWithAssignmentInput;
};

/** Autogenerated return type of UpdateAssignmentLesson */
export type UpdateAssignmentLessonPayload = {
  __typename?: 'UpdateAssignmentLessonPayload';
  /** Created assignment_template */
  assignmentTemplate: Maybe<AssignmentTemplate>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Created assignment lesson */
  lesson: Maybe<AssignmentLesson>;
  userErrors: Maybe<Array<UserError>>;
};

/** Input for updating assignment template */
export type UpdateAssignmentTemplateInput = {
  /** AWS key of file */
  awsKey?: InputMaybe<Scalars['String']['input']>;
  /** Actual filename of file */
  fileName?: InputMaybe<Scalars['String']['input']>;
  /** File size in bytes */
  fileSize: Scalars['Int']['input'];
  /** File MIME type */
  fileType: Scalars['String']['input'];
  /** Creator inputted filename of file */
  label: Scalars['String']['input'];
  /** Url of assignment template file */
  url: Scalars['String']['input'];
};

/** Autogenerated input type of UpdateAudioLesson */
export type UpdateAudioLessonInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the lesson */
  lessonId: Scalars['ID']['input'];
  lessonInput: LessonInput;
  lessonWithAudioInput: LessonWithAudioInput;
};

/** Autogenerated return type of UpdateAudioLesson */
export type UpdateAudioLessonPayload = {
  __typename?: 'UpdateAudioLessonPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Updated audio lesson */
  lesson: Maybe<AudioLesson>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated return type of UpdateBlueprint */
export type UpdateBlueprintPayload = {
  __typename?: 'UpdateBlueprintPayload';
  /**
   * Response errors
   * @deprecated Backwards compatibility for old response status
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response status
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of UpdateBundleImage */
export type UpdateBundleImageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Bundle Image container */
  container: Scalars['String']['input'];
  /** Bundle Image handle */
  handle: Scalars['String']['input'];
  /** The Bundle's ID */
  id: Scalars['ID']['input'];
  /** Course image properties */
  imageProperties: ProductableImagePropertiesInput;
  /** Bundle Image key */
  key: Scalars['String']['input'];
};

/** Autogenerated return type of UpdateBundleImage */
export type UpdateBundleImagePayload = {
  __typename?: 'UpdateBundleImagePayload';
  /** The bundle */
  bundle: Maybe<Bundle>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of UpdateBundle */
export type UpdateBundleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The bundle ID */
  id: Scalars['ID']['input'];
  /** Attributes to be updated */
  patch: BundlePatch;
  /** Automatically regenerate the bundle slug if the name has changed */
  regenerateSlug?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of UpdateBundle */
export type UpdateBundlePayload = {
  __typename?: 'UpdateBundlePayload';
  /** The bundle being updated */
  bundle: Maybe<Bundle>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /**
   * Response errors
   * @deprecated Backwards compatibility for old errors response
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of UpdateBundleWelcomeEmail */
export type UpdateBundleWelcomeEmailInput = {
  /** Notification body */
  body?: InputMaybe<Scalars['String']['input']>;
  /** Bundle id */
  bundleId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Notification state */
  isEnabled: Scalars['Boolean']['input'];
  /** Notification subject */
  subject?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateBundleWelcomeEmail */
export type UpdateBundleWelcomeEmailPayload = {
  __typename?: 'UpdateBundleWelcomeEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Response message */
  message: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated return type of UpdateCertificateMapping */
export type UpdateCertificateMappingPayload = {
  __typename?: 'UpdateCertificateMappingPayload';
  /** The updated certificate mapping */
  certificateMapping: Maybe<StudentCertificatesCertificateMapping>;
  /**
   * Response errors
   * @deprecated Backwards compatibility for old response status
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response status
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of UpdateChapter */
export type UpdateChapterInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The chapter ID */
  id: Scalars['ID']['input'];
  /** The name of the chapter */
  name: Scalars['String']['input'];
  /** Set the new lessons for the chapter to draft */
  setNewLessonsToDraft?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of UpdateChapter */
export type UpdateChapterPayload = {
  __typename?: 'UpdateChapterPayload';
  /** The chapter */
  chapter: Maybe<Chapter>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of UpdateCommunity */
export type UpdateCommunityInput = {
  /** Group IDs that have access to communities */
  accessGroupIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Product IDs that students must be enrolled in to be able to access the community */
  accessProductIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Community access type */
  accessType?: InputMaybe<Scalars['String']['input']>;
  /** The community card image url */
  cardImageUrl?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The description of the community */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The font to be used on the community */
  font?: InputMaybe<Scalars['String']['input']>;
  /** The Community's ID */
  id: Scalars['ID']['input'];
  /** The identifier for the image in Filestack */
  imageFilestackId?: InputMaybe<Scalars['String']['input']>;
  /** The name of the S3 bucket where the image is stored */
  imageS3Bucket?: InputMaybe<Scalars['String']['input']>;
  /** The S3 key for the image */
  imageS3Key?: InputMaybe<Scalars['String']['input']>;
  /** Community hidden status */
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  /** Community private status */
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  /** Keywords of the product associated with the community */
  keywords?: InputMaybe<Scalars['String']['input']>;
  /** Community landing page footer code */
  landingPageLiquidMarkup?: InputMaybe<Scalars['String']['input']>;
  /** The name of the community */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The primary color of the community */
  primaryColor?: InputMaybe<Scalars['String']['input']>;
  /** SEO description of the product associated with the community */
  seoDescription?: InputMaybe<Scalars['String']['input']>;
  /** SEO title of the product associated with the community */
  seoTitle?: InputMaybe<Scalars['String']['input']>;
  /** Community's slug */
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateCommunity */
export type UpdateCommunityPayload = {
  __typename?: 'UpdateCommunityPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The community */
  community: Maybe<Community>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of UpdateCommunitySpace */
export type UpdateCommunitySpaceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The description of the community space */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The icon image for the community space */
  iconName?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the community space */
  id: Scalars['ID']['input'];
  /** Community space image */
  image?: InputMaybe<ImageInput>;
  /** The name of the community space */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The encrypted product_id that this space is related to */
  relatedProductId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of UpdateCommunitySpace */
export type UpdateCommunitySpacePayload = {
  __typename?: 'UpdateCommunitySpacePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The community space */
  communitySpace: Maybe<CommunitySpace>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of UpdateCourseCompletionEmail */
export type UpdateCourseCompletionEmailInput = {
  /** Notification body */
  body?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Course id */
  courseId: Scalars['ID']['input'];
  /** Notification state */
  isEnabled: Scalars['Boolean']['input'];
  /** Notification subject */
  subject?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateCourseCompletionEmail */
export type UpdateCourseCompletionEmailPayload = {
  __typename?: 'UpdateCourseCompletionEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Response message */
  message: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of UpdateCourseEnrollmentEmail */
export type UpdateCourseEnrollmentEmailInput = {
  /** Notification body */
  body?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Course id */
  courseId: Scalars['ID']['input'];
  /** Notification state */
  isEnabled: Scalars['Boolean']['input'];
  /** Notification subject */
  subject?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateCourseEnrollmentEmail */
export type UpdateCourseEnrollmentEmailPayload = {
  __typename?: 'UpdateCourseEnrollmentEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Response message */
  message: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of UpdateCourseImage */
export type UpdateCourseImageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the Course */
  id: Scalars['ID']['input'];
  /** Course Image Url */
  imageUrl?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateCourseImage */
export type UpdateCourseImagePayload = {
  __typename?: 'UpdateCourseImagePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The course */
  course: Maybe<Course>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of UpdateCourse */
export type UpdateCourseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The course's ID */
  id: Scalars['ID']['input'];
  /** Attributes to be updated */
  patch: CoursePatch;
  /** Automatically regenerate the course slug if the name has changed */
  regenerateSlug?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of UpdateCourse */
export type UpdateCoursePayload = {
  __typename?: 'UpdateCoursePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The course */
  course: Maybe<Course>;
  /**
   * Response error
   * @deprecated Backwards compatibility for old response error
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of UpdateCoursePlayerLogo */
export type UpdateCoursePlayerLogoInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Course Player Logo Image container */
  container: Scalars['String']['input'];
  /** Course Player Logo Image handle */
  handle: Scalars['String']['input'];
  /** The ID of the Course */
  id: Scalars['ID']['input'];
  /** Course Player Logo Image key */
  key: Scalars['String']['input'];
  /** Course Player Logo Image url */
  url: Scalars['String']['input'];
};

/** Autogenerated return type of UpdateCoursePlayerLogo */
export type UpdateCoursePlayerLogoPayload = {
  __typename?: 'UpdateCoursePlayerLogoPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The course */
  course: Maybe<Course>;
  userErrors: Maybe<Array<UserError>>;
};

/** Update a custom page */
export type UpdateCustomPage = ResponseInterface & {
  __typename?: 'UpdateCustomPage';
  /**
   * Response Extra Data
   * @deprecated response fields should be well defined following a object structure
   */
  data: Maybe<Scalars['JSON']['output']>;
  errors: Maybe<Array<Maybe<Error>>>;
  /** Response Message */
  message: Maybe<Scalars['String']['output']>;
  /** Return status: SUCCESS, PARTIAL_SUCCESS or FAILED */
  status: Maybe<ResponseStatus>;
};

/** Autogenerated input type of UpdateCustomPage */
export type UpdateCustomPageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** CustomPage's id */
  id: Scalars['String']['input'];
  /** CustomPage's fields to be updated */
  patch: CustomPageInput;
  /** Product IDs for Product Custom Pages */
  productIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Update a CustomSiteThemeManifest */
export type UpdateCustomSiteThemeManifest = ResponseInterface & {
  __typename?: 'UpdateCustomSiteThemeManifest';
  /**
   * Response Extra Data
   * @deprecated response fields should be well defined following a object structure
   */
  data: Maybe<Scalars['JSON']['output']>;
  errors: Maybe<Array<Maybe<Error>>>;
  /** Response Message */
  message: Maybe<Scalars['String']['output']>;
  /** Return status: SUCCESS, PARTIAL_SUCCESS or FAILED */
  status: Maybe<ResponseStatus>;
};

/** Input field for mutating a theme's CustomSiteThemeViewManifest */
export type UpdateCustomSiteThemeManifestInput = {
  /** The CustomSiteThemeManifest's Id */
  id: Scalars['String']['input'];
  /** Manifest's fields to be updated */
  patch: CustomSiteThemeViewManifestPatch;
};

/** Autogenerated input type of UpdateCustomSiteThemeView */
export type UpdateCustomSiteThemeViewInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Custom Site Theme View's ID */
  id: Scalars['ID']['input'];
  /** CustomSiteThemeView field's to update */
  patch: CustomSiteThemeViewPatchInput;
};

/** Update a CustomSiteThemeViewManifest */
export type UpdateCustomSiteThemeViewManifest = ResponseInterface & {
  __typename?: 'UpdateCustomSiteThemeViewManifest';
  /**
   * Response Extra Data
   * @deprecated response fields should be well defined following a object structure
   */
  data: Maybe<Scalars['JSON']['output']>;
  errors: Maybe<Array<Maybe<Error>>>;
  /** Response Message */
  message: Maybe<Scalars['String']['output']>;
  /** Return status: SUCCESS, PARTIAL_SUCCESS or FAILED */
  status: Maybe<ResponseStatus>;
};

/** Input field for mutating CustomSiteThemeViewManifest */
export type UpdateCustomSiteThemeViewManifestInput = {
  /** The CustomSiteThemeViewManifest's ID */
  id: Scalars['String']['input'];
  /** Manifest's field to be updated */
  patch: CustomSiteThemeViewManifestPatch;
};

/** Autogenerated return type of UpdateCustomSiteThemeView */
export type UpdateCustomSiteThemeViewPayload = {
  __typename?: 'UpdateCustomSiteThemeViewPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /**
   * Response data
   * @deprecated Backwards compatibility for old response data
   */
  data: Maybe<Scalars['String']['output']>;
  /**
   * Response errors
   * @deprecated Backwards compatibility for old response errors
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated return type of UpdateDashboardPromptExperience */
export type UpdateDashboardPromptExperiencePayload = {
  __typename?: 'UpdateDashboardPromptExperiencePayload';
  /**
   * Response error
   * @deprecated Backwards compatibility for old response error
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Scalars['String']['output'];
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Scalars['String']['output'];
};

/** Autogenerated input type of UpdateDisqusLesson */
export type UpdateDisqusLessonInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the lesson */
  lessonId: Scalars['ID']['input'];
  lessonInput: LessonInput;
  lessonWithTextInput?: InputMaybe<LessonWithTextInput>;
};

/** Autogenerated return type of UpdateDisqusLesson */
export type UpdateDisqusLessonPayload = {
  __typename?: 'UpdateDisqusLessonPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Updated Disqus lesson */
  lesson: Maybe<DisqusLesson>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of UpdateDownloadLesson */
export type UpdateDownloadLessonInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the lesson */
  lessonId: Scalars['ID']['input'];
  lessonInput?: InputMaybe<LessonInput>;
  lessonWithDownloadInput?: InputMaybe<Array<LessonWithDownloadInput>>;
  lessonWithTextInput?: InputMaybe<LessonWithTextInput>;
};

/** Autogenerated return type of UpdateDownloadLesson */
export type UpdateDownloadLessonPayload = {
  __typename?: 'UpdateDownloadLessonPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Updated download lesson */
  lesson: Maybe<DownloadLesson>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of UpdateDripSchedule */
export type UpdateDripScheduleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dripScheduleInput: DripScheduleInput;
  /** The ID of the drip schedule */
  id: Scalars['ID']['input'];
  /** @deprecated Handled by CreateDripScheduleItems mutation */
  lessonsWithScheduleInput?: InputMaybe<Array<LessonWithScheduleInput>>;
};

/** Autogenerated return type of UpdateDripSchedule */
export type UpdateDripSchedulePayload = {
  __typename?: 'UpdateDripSchedulePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Created Drip Schedule */
  dripSchedule: DripSchedule;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of UpdateEnrollmentStartDate */
export type UpdateEnrollmentStartDateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the enrollment */
  enrollmentId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateEnrollmentStartDate */
export type UpdateEnrollmentStartDatePayload = {
  __typename?: 'UpdateEnrollmentStartDatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The enrollment */
  enrollment: Maybe<Enrollment>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of UpdateExamLesson */
export type UpdateExamLessonInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the lesson */
  lessonId: Scalars['ID']['input'];
  lessonInput?: InputMaybe<LessonInput>;
  lessonWithExamInput?: InputMaybe<LessonWithExamInput>;
};

/** Autogenerated return type of UpdateExamLesson */
export type UpdateExamLessonPayload = {
  __typename?: 'UpdateExamLessonPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Updated exam lesson */
  lesson: Maybe<ExamLesson>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of UpdateFunnel */
export type UpdateFunnelInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Discount amount for the cross sell product */
  crossSellDiscountAmount?: InputMaybe<Scalars['Float']['input']>;
  /** Discount type for the cross sell product: Fixed or Percentage */
  crossSellDiscountType?: InputMaybe<DiscountEnum>;
  /** The ID of the cross sell product of the funnel */
  crossSellProductId?: InputMaybe<Scalars['ID']['input']>;
  /** Discount amount */
  discountAmount?: InputMaybe<Scalars['Float']['input']>;
  /** Discount type: Fixed or Percentage */
  discountType?: InputMaybe<DiscountEnum>;
  /** The URL of the download file */
  downloadFileUrl?: InputMaybe<Scalars['String']['input']>;
  /** The type of download resource */
  downloadResourceType?: InputMaybe<DownloadResourceTypeEnum>;
  /** The funnel ID */
  id: Scalars['ID']['input'];
  /** The ID of the product the funnel will be for */
  productId?: InputMaybe<Scalars['ID']['input']>;
  /** Show offer outside funnel? */
  showOfferOutsideFunnel?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated input type of UpdateFunnelMetadata */
export type UpdateFunnelMetadataInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Funnel flow metadata */
  flow?: InputMaybe<FlowMetadataInput>;
  /** The funnel ID */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateFunnelMetadata */
export type UpdateFunnelMetadataPayload = {
  __typename?: 'UpdateFunnelMetadataPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The funnel */
  funnel: Maybe<Funnel>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated return type of UpdateFunnel */
export type UpdateFunnelPayload = {
  __typename?: 'UpdateFunnelPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The funnel */
  funnel: Maybe<Funnel>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of UpdateLesson */
export type UpdateLessonInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the lesson (AKA content) */
  lessonId: Scalars['ID']['input'];
  /** The new name of the lesson */
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateLesson */
export type UpdateLessonPayload = {
  __typename?: 'UpdateLessonPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Updated lesson */
  lesson: Maybe<LessonUnion>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of UpdateLiveEvent */
export type UpdateLiveEventInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The description of the event */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The end time of the event (UTC) */
  endTime: Scalars['DateTime']['input'];
  /** The ID of the live event */
  id: Scalars['ID']['input'];
  /** Image input */
  image?: InputMaybe<EventImageInput>;
  /** The location of the event */
  location?: InputMaybe<Scalars['String']['input']>;
  /** The meetingUrl of the event */
  meetingUrl?: InputMaybe<Scalars['URL']['input']>;
  /** Should users be notified of updates? */
  sendEmails?: InputMaybe<Scalars['Boolean']['input']>;
  /** The start time of the event (UTC) */
  startTime: Scalars['DateTime']['input'];
  /** The timezone of the event */
  timezone?: InputMaybe<Scalars['String']['input']>;
  /** The title of the event */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateLiveEvent */
export type UpdateLiveEventPayload = {
  __typename?: 'UpdateLiveEventPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The live event */
  liveEvent: Maybe<LiveEvent>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of UpdateLiveLesson */
export type UpdateLiveLessonInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the lesson */
  lessonId: Scalars['ID']['input'];
  lessonInput: LessonInput;
  lessonWithLiveInput: LessonWithLiveInput;
  webinarItemInput: ZoomItemInput;
};

/** Autogenerated return type of UpdateLiveLesson */
export type UpdateLiveLessonPayload = {
  __typename?: 'UpdateLiveLessonPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** updated Live lesson */
  lesson: Maybe<LiveLesson>;
  userErrors: Maybe<Array<UserError>>;
  /** updated zoom item */
  zoomItem: Maybe<ZoomItem>;
};

/** Autogenerated input type of UpdateMultimediaLesson */
export type UpdateMultimediaLessonInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the lesson */
  lessonId: Scalars['ID']['input'];
  lessonInput: LessonInput;
  lessonWithMultimediaInput: LessonWithMultimediaInput;
};

/** Autogenerated return type of UpdateMultimediaLesson */
export type UpdateMultimediaLessonPayload = {
  __typename?: 'UpdateMultimediaLessonPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** ID for multimedia import Sidekiq job */
  jobId: Maybe<Scalars['String']['output']>;
  /** Updated multimedia lesson */
  lesson: Maybe<MultimediaLesson>;
  userErrors: Maybe<Array<UserError>>;
};

/** Inputs for updating onboarding flow info */
export type UpdateOnboardingFlow = {
  /** Country code for user */
  countryCode?: InputMaybe<Scalars['String']['input']>;
  /** Onboarding Flow ID */
  onboardingFlowId: Scalars['ID']['input'];
  /** Interval for plan subscription */
  planInterval?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UpdateOnboardingFlow */
export type UpdateOnboardingFlowInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  input: UpdateOnboardingFlow;
};

/** Autogenerated return type of UpdateOnboardingFlow */
export type UpdateOnboardingFlowPayload = {
  __typename?: 'UpdateOnboardingFlowPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** OnboardingFlow info for current visitor */
  onboardingFlow: Maybe<OnboardingFlow>;
  userErrors: Maybe<Array<UserError>>;
};

/** Inputs for updating onboarding tenant info */
export type UpdateOnboardingTenantInfo = {
  /** Onboarding Flow ID */
  onboardingFlowId: Scalars['ID']['input'];
  /** Google recaptcha value */
  recaptchaValue?: InputMaybe<Scalars['String']['input']>;
  /** Tenant basic details */
  tenantBasicInfo: TenantBasicInfoInput;
};

/** Autogenerated input type of UpdateOnboardingTenantInfo */
export type UpdateOnboardingTenantInfoInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  input: UpdateOnboardingTenantInfo;
};

/** Autogenerated return type of UpdateOnboardingTenantInfo */
export type UpdateOnboardingTenantInfoPayload = {
  __typename?: 'UpdateOnboardingTenantInfoPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** OnboardingFlow info for current visitor */
  onboardingFlow: Maybe<OnboardingFlow>;
  /** Redirect URL address */
  redirectUrl: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Inputs for Onboarding Visitor Data */
export type UpdateOnboardingVisitorData = {
  /** Visitor brand colour */
  brandcolour?: InputMaybe<Scalars['String']['input']>;
  /** Visitor course name */
  courseName?: InputMaybe<Scalars['String']['input']>;
  /** Onboarding Visitor Data ID */
  id: Scalars['String']['input'];
  /** Instructor bio */
  instructorBio?: InputMaybe<Scalars['String']['input']>;
  /** Instructor first name */
  instructorFirstName?: InputMaybe<Scalars['String']['input']>;
  /** Instructor image content type */
  instructorImageContentType?: InputMaybe<Scalars['String']['input']>;
  /** Instructor image file name */
  instructorImageFileName?: InputMaybe<Scalars['String']['input']>;
  /** Instructor image file size */
  instructorImageFileSize?: InputMaybe<Scalars['Int']['input']>;
  /** Instructor last name */
  instructorLastName?: InputMaybe<Scalars['String']['input']>;
  /** Visitor school logo */
  logoFileName?: InputMaybe<Scalars['String']['input']>;
  /** Visitor starting productable name */
  productableName?: InputMaybe<Scalars['String']['input']>;
  /** Visitor starting productable type */
  productableType?: InputMaybe<Scalars['String']['input']>;
  /** Visitor school name */
  schoolName?: InputMaybe<Scalars['String']['input']>;
  /** Visitor signup teaching business experience */
  signupTeachingBusinessExperience?: InputMaybe<Scalars['String']['input']>;
  /** Visitor signup teaching business experience other */
  signupTeachingBusinessExperienceOther?: InputMaybe<
    Scalars['String']['input']
  >;
  /** Visitor signup teaching content type */
  signupTeachingContentType?: InputMaybe<Scalars['String']['input']>;
  /** Visitor signup teaching content type other */
  signupTeachingContentTypeOther?: InputMaybe<Scalars['String']['input']>;
  /** Visitor signup teaching following */
  signupTeachingFollowing?: InputMaybe<Scalars['String']['input']>;
  /** Visitor signup teaching headcount */
  signupTeachingHeadcount?: InputMaybe<Scalars['String']['input']>;
  /** Visitor signup teaching industry */
  signupTeachingIndustry?: InputMaybe<Scalars['String']['input']>;
  /** Visitor signup teaching industry other */
  signupTeachingIndustryOther?: InputMaybe<Scalars['String']['input']>;
  /** Visitor signup teaching material */
  signupTeachingMaterial?: InputMaybe<Scalars['String']['input']>;
  /** Visitor signup teaching phone number */
  signupTeachingPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** Visitor signup teaching stage */
  signupTeachingStage?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UpdateOnboardingVisitorData */
export type UpdateOnboardingVisitorDataInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  input: UpdateOnboardingVisitorData;
};

/** Autogenerated input type of UpdateOneTimeOffer */
export type UpdateOneTimeOfferInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The one_time_offer ID */
  id: Scalars['ID']['input'];
  /** The Button CTA Text */
  payButtonText?: InputMaybe<Scalars['String']['input']>;
  /** The Modal Heading Text */
  salesCopyHeading?: InputMaybe<Scalars['String']['input']>;
  /** The Modal Body Text */
  salesCopyText?: InputMaybe<Scalars['String']['input']>;
  /** Show offer outside funnel? */
  showOfferOutsideFunnel?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of UpdateOneTimeOffer */
export type UpdateOneTimeOfferPayload = {
  __typename?: 'UpdateOneTimeOfferPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The offer */
  oneTimeOffer: Maybe<OneTimeOffer>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of UpdateOpenIdConnectClientSettings */
export type UpdateOpenIdConnectClientSettingsInput = {
  /** OpenID Connect Authorization Endpoint */
  authorizationEndpoint?: InputMaybe<Scalars['URL']['input']>;
  /** Client Identifier */
  clientId?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Client Secret Key */
  clientSecret?: InputMaybe<Scalars['String']['input']>;
  /** OpenID Connect Issuer */
  issuer?: InputMaybe<Scalars['URL']['input']>;
  /** Location to redirect to after logout */
  postLogoutRedirectUri?: InputMaybe<Scalars['URL']['input']>;
  /** Location to redirect to when signing up */
  signUpUri?: InputMaybe<Scalars['URL']['input']>;
  /** OpenID Connect Token Endpoint */
  tokenEndpoint?: InputMaybe<Scalars['URL']['input']>;
  /** OpenID Connect User Info Endpoint */
  userInfoEndpoint?: InputMaybe<Scalars['URL']['input']>;
};

/** Autogenerated return type of UpdateOpenIdConnectClientSettings */
export type UpdateOpenIdConnectClientSettingsPayload = {
  __typename?: 'UpdateOpenIdConnectClientSettingsPayload';
  /** OpenID Connect App that has been installed */
  app: Maybe<App>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of UpdateOrderBump */
export type UpdateOrderBumpInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Previous price for this product */
  compareAtPriceInCents?: InputMaybe<Scalars['NonNegativeFloat']['input']>;
  /** Order bump description */
  description: Scalars['String']['input'];
  /** Order bump ID to update */
  id: Scalars['ID']['input'];
  /** Order bump name */
  name: Scalars['String']['input'];
  /** List of products where order bump is offered */
  productIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Attributes to be updated */
  productPriceData?: InputMaybe<ProductPriceInput>;
  /** Product ID being sold */
  productSoldId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateOrderBump */
export type UpdateOrderBumpPayload = {
  __typename?: 'UpdateOrderBumpPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The order bump */
  orderBump: Maybe<OrderBump>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of UpdatePdfLesson */
export type UpdatePdfLessonInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the lesson */
  lessonId: Scalars['ID']['input'];
  lessonInput: LessonInput;
  lessonWithPdfInput: LessonWithPdfInput;
};

/** Autogenerated return type of UpdatePdfLesson */
export type UpdatePdfLessonPayload = {
  __typename?: 'UpdatePdfLessonPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Updated pdf lesson */
  lesson: Maybe<PdfLesson>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of UpdatePresentationLesson */
export type UpdatePresentationLessonInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the lesson */
  lessonId: Scalars['ID']['input'];
  lessonInput?: InputMaybe<LessonInput>;
  lessonWithPresentationInput?: InputMaybe<LessonWithPresentationInput>;
  slides?: InputMaybe<Array<PresentationSlideInput>>;
};

/** Autogenerated return type of UpdatePresentationLesson */
export type UpdatePresentationLessonPayload = {
  __typename?: 'UpdatePresentationLessonPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Updated presentation lesson */
  lesson: Maybe<PresentationLesson>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of UpdateProduct */
export type UpdateProductInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Product's ID */
  id: Scalars['ID']['input'];
  /** Product's fields to be updated */
  patch: ProductPatch;
};

/** Autogenerated input type of UpdateProductLicenseExpiryDate */
export type UpdateProductLicenseExpiryDateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** User product expiry date */
  expiresAt: Scalars['DateTime']['input'];
  /** Product ID */
  productId: Scalars['ID']['input'];
  /** User ID */
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateProductLicenseExpiryDate */
export type UpdateProductLicenseExpiryDatePayload = {
  __typename?: 'UpdateProductLicenseExpiryDatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of UpdateProductPayee */
export type UpdateProductPayeeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Product payee ID */
  id: Scalars['ID']['input'];
  /** Product payee payout percentage */
  payoutPercentage: Scalars['Float']['input'];
};

/** Autogenerated return type of UpdateProductPayee */
export type UpdateProductPayeePayload = {
  __typename?: 'UpdateProductPayeePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /**
   * Response errors
   * @deprecated Backwards compatibility for old errors response
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /** The updated product payee */
  productPayee: Maybe<ProductPayee>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
};

/** Autogenerated return type of UpdateProduct */
export type UpdateProductPayload = {
  __typename?: 'UpdateProductPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /**
   * Response errors
   * @deprecated Backwards compatibility for old errors response
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /** The updated product */
  product: Maybe<Product>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of UpdateProductPrice */
export type UpdateProductPriceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The price's ID */
  id: Scalars['ID']['input'];
  /** Attributes to be updated */
  patch: ProductPriceInput;
};

/** Autogenerated return type of UpdateProductPrice */
export type UpdateProductPricePayload = {
  __typename?: 'UpdateProductPricePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /**
   * Response errors
   * @deprecated Backwards compatibility for old errors response
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /** Update primary or additional product price field */
  productPrice: Maybe<ProductPrice>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of UpdateQuizLesson */
export type UpdateQuizLessonInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the lesson */
  lessonId: Scalars['ID']['input'];
  lessonInput?: InputMaybe<LessonInput>;
  lessonWithQuizInput?: InputMaybe<LessonWithQuizInput>;
  /** The ID of the AI prompt used to generate questions */
  promptId?: InputMaybe<Scalars['ID']['input']>;
  questions?: InputMaybe<Array<QuizQuestionInput>>;
};

/** Autogenerated return type of UpdateQuizLesson */
export type UpdateQuizLessonPayload = {
  __typename?: 'UpdateQuizLessonPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Updated quiz lesson */
  lesson: Maybe<QuizLesson>;
  userErrors: Maybe<Array<UserError>>;
};

/** Input field for mutating a SearchBookmark */
export type UpdateSearchBookmarkInput = {
  /** SearchBookmark's id */
  id: Scalars['String']['input'];
  /** SearchBookmark's fields */
  search_bookmark: SearchBookmarkInput;
};

/** Autogenerated return type of UpdateSearchBookmark */
export type UpdateSearchBookmarkPayload = {
  __typename?: 'UpdateSearchBookmarkPayload';
  /**
   * Response errors
   * @deprecated Backwards compatibility for old response status
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response status
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of UpdateSignUpSettings */
export type UpdateSignUpSettingsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Permitted SSO domains settings */
  permittedSsoDomains?: InputMaybe<PermittedSsoDomainsInput>;
  /** Social Login settings */
  socialLogin?: InputMaybe<SocialLoginInput>;
  /** Terms and conditions settings */
  termsAndConditions?: InputMaybe<TermsAndConditionsInput>;
};

/** Autogenerated return type of UpdateSignUpSettings */
export type UpdateSignUpSettingsPayload = {
  __typename?: 'UpdateSignUpSettingsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of UpdateStudentNotification */
export type UpdateStudentNotificationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Notification state */
  isEnabled: Scalars['Boolean']['input'];
  slug?: InputMaybe<StudentNotificationSlugType>;
};

/** Autogenerated return type of UpdateStudentNotification */
export type UpdateStudentNotificationPayload = {
  __typename?: 'UpdateStudentNotificationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated return type of UpdateStudentRecord */
export type UpdateStudentRecordPayload = {
  __typename?: 'UpdateStudentRecordPayload';
  /**
   * Response errors
   * @deprecated Backwards compatibility for old response status
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response status
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
};

/** Autogenerated return type of UpdateSubmissionStatus */
export type UpdateSubmissionStatusPayload = {
  __typename?: 'UpdateSubmissionStatusPayload';
  /**
   * Response error
   * @deprecated Backwards compatibility for old response error
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Scalars['String']['output'];
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Scalars['String']['output'];
  /** The submission */
  submission: Maybe<Submission>;
};

/** Autogenerated input type of UpdateSurveyLesson */
export type UpdateSurveyLessonInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the lesson */
  lessonId: Scalars['ID']['input'];
  lessonInput?: InputMaybe<LessonInput>;
  questions?: InputMaybe<Array<SurveyQuestionInput>>;
};

/** Autogenerated return type of UpdateSurveyLesson */
export type UpdateSurveyLessonPayload = {
  __typename?: 'UpdateSurveyLessonPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Updated survey lesson */
  lesson: Maybe<SurveyLesson>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of UpdateTaxRegistration */
export type UpdateTaxRegistrationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Tax registration id */
  taxRegistrationId?: InputMaybe<Scalars['String']['input']>;
  /** Tax registration status */
  taxRegistrationStatus: TaxRegistrationStatus;
};

/** Autogenerated return type of UpdateTaxRegistration */
export type UpdateTaxRegistrationPayload = {
  __typename?: 'UpdateTaxRegistrationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Update a tenant */
export type UpdateTenant = ResponseInterface & {
  __typename?: 'UpdateTenant';
  /**
   * Response Extra Data
   * @deprecated response fields should be well defined following a object structure
   */
  data: Maybe<Scalars['JSON']['output']>;
  errors: Maybe<Array<Maybe<Error>>>;
  /** Response Message */
  message: Maybe<Scalars['String']['output']>;
  /** Return status: SUCCESS, PARTIAL_SUCCESS or FAILED */
  status: Maybe<ResponseStatus>;
};

/** Response returned for update tenant billing info mutation */
export type UpdateTenantBillingInfo = ResponseInterface & {
  __typename?: 'UpdateTenantBillingInfo';
  /** Tenant billing info */
  billingInfo: Maybe<CheckoutBillingInfo>;
  /**
   * Response Extra Data
   * @deprecated response fields should be well defined following a object structure
   */
  data: Maybe<Scalars['JSON']['output']>;
  errors: Maybe<Array<Maybe<Error>>>;
  /** Response Message */
  message: Maybe<Scalars['String']['output']>;
  /** Return status: SUCCESS, PARTIAL_SUCCESS or FAILED */
  status: Maybe<ResponseStatus>;
};

/** Autogenerated return type of UpdateTenantBillingInfo */
export type UpdateTenantBillingInfoPayload = {
  __typename?: 'UpdateTenantBillingInfoPayload';
  /** Tenant billing info */
  billingInfo: Maybe<BillingInfo>;
  /**
   * Response error
   * @deprecated Backwards compatibility for old response error
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of UpdateTenantBrandedAppConfiguration */
export type UpdateTenantBrandedAppConfigurationInput = {
  /** Apple app store description */
  appleAppDescription?: InputMaybe<Scalars['String']['input']>;
  /** Apple app store keywords */
  appleAppKeywords?: InputMaybe<Scalars['String']['input']>;
  /** Apple app store name */
  appleAppName?: InputMaybe<Scalars['String']['input']>;
  /** Apple app store promotional text */
  appleAppPromotionalText?: InputMaybe<Scalars['String']['input']>;
  /** Apple app store subtitle */
  appleAppSubtitle?: InputMaybe<Scalars['String']['input']>;
  /** Apple app store issuer id */
  appleIssuerId?: InputMaybe<Scalars['String']['input']>;
  /** Apple app store key identifier */
  appleKeyIdentifier?: InputMaybe<Scalars['String']['input']>;
  /** Apple app store primary category */
  applePrimaryAppCategory?: InputMaybe<Scalars['String']['input']>;
  /** Apple app store secondary category */
  appleSecondaryAppCategory?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Email address for App Store Support */
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  /** First name for App Store Support */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Google app store category */
  googleAppCategory?: InputMaybe<Scalars['String']['input']>;
  /** Google app store full description */
  googleAppFullDescription?: InputMaybe<Scalars['String']['input']>;
  /** Google app store name */
  googleAppName?: InputMaybe<Scalars['String']['input']>;
  /** Google app store short description */
  googleAppShortDescription?: InputMaybe<Scalars['String']['input']>;
  /** Google app store tags */
  googleAppTags?: InputMaybe<Scalars['String']['input']>;
  /** Last name for App Store Support */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Contact phone number for App Store Support */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateTenantBrandedAppConfiguration */
export type UpdateTenantBrandedAppConfigurationPayload = {
  __typename?: 'UpdateTenantBrandedAppConfigurationPayload';
  /** Tenant branded app configuration */
  appConfiguration: Maybe<AppConfiguration>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of UpdateTenantBrandedAppCustomization */
export type UpdateTenantBrandedAppCustomizationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** App primary background color */
  primaryBackgroundColor?: InputMaybe<Scalars['String']['input']>;
  /** App primary button background color */
  primaryButtonBackgroundColor?: InputMaybe<Scalars['String']['input']>;
  /** App primary button border color */
  primaryButtonBorderColor?: InputMaybe<Scalars['String']['input']>;
  /** App primary button text color */
  primaryButtonTextColor?: InputMaybe<Scalars['String']['input']>;
  /** App primary text color */
  primaryTextColor?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateTenantBrandedAppCustomization */
export type UpdateTenantBrandedAppCustomizationPayload = {
  __typename?: 'UpdateTenantBrandedAppCustomizationPayload';
  /** Tenant branded app customization */
  appCustomization: Maybe<AppCustomization>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Inputs for updating tenant billing info mutation */
export type UpdateTenantCheckoutBillingInfoInput = {
  /** Tenant's address, line 1 */
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  /** Tenant's address, line 2 */
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  /** Tenant's city */
  city?: InputMaybe<Scalars['String']['input']>;
  /** Weather zipcode confirmed by customer */
  confirmedByCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  /** Tenant's country code */
  countryCode?: InputMaybe<Scalars['String']['input']>;
  /** Tenant's billing name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Tenant's billing state */
  state?: InputMaybe<Scalars['String']['input']>;
  /** Is the tenant tax exempt */
  taxExempt?: InputMaybe<Scalars['Boolean']['input']>;
  /** Reason for tenant being tax exempt */
  taxExemptReason?: InputMaybe<Scalars['String']['input']>;
  /** Site subscription request token */
  token: Scalars['String']['input'];
  /** Tenant's vat number */
  vatNumber?: InputMaybe<Scalars['String']['input']>;
  /** Tenant's billing zip code */
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

/** Inputs for updating tenant billing country code */
export type UpdateTenantCheckoutCountryCodeInput = {
  /** Tenant's country code */
  countryCode?: InputMaybe<Scalars['String']['input']>;
  /** Site subscription request token */
  token: Scalars['String']['input'];
  /** Tenant's billing zip code */
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

/** Input fields for mutating a tenant */
export type UpdateTenantInput = {
  /** Tenant's fields to be updated */
  patch: TenantPatch;
};

/** Autogenerated input type of UpdateTenantMobileSetting */
export type UpdateTenantMobileSettingInput = {
  /** is branded mobile enabled */
  brandedMobileEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** are free courses visible */
  canDiscoverFreeCourses?: InputMaybe<Scalars['Boolean']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** has the tenant requested in-app purchase setup */
  inAppPurchaseSetupRequested?: InputMaybe<Scalars['Boolean']['input']>;
  /** has the tenant joining branded waitlist */
  joinedBrandedMobileWaitlist?: InputMaybe<Scalars['Boolean']['input']>;
  /** is thinkfic mobile enabled */
  thinkificMobileEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of UpdateTenantMobileSetting */
export type UpdateTenantMobileSettingPayload = {
  __typename?: 'UpdateTenantMobileSettingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Tenant mobile settings */
  mobileSetting: Maybe<MobileSetting>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of UpdateTextLesson */
export type UpdateTextLessonInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the lesson */
  lessonId: Scalars['ID']['input'];
  lessonInput: LessonInput;
  lessonWithTextInput?: InputMaybe<LessonWithTextInput>;
};

/** Autogenerated return type of UpdateTextLesson */
export type UpdateTextLessonPayload = {
  __typename?: 'UpdateTextLessonPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Updated text lesson */
  lesson: Maybe<TextLesson>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of UpdateUserImport */
export type UpdateUserImportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the user import */
  id: Scalars['ID']['input'];
  patch: UserImportPatchInput;
};

/** Autogenerated return type of UpdateUserImport */
export type UpdateUserImportPayload = {
  __typename?: 'UpdateUserImportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /**
   * Response data
   * @deprecated Backwards compatibility for old response data
   */
  data: Maybe<Scalars['JSON']['output']>;
  /**
   * Response error
   * @deprecated Backwards compatibility for old response error
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of UpdateVideoLesson */
export type UpdateVideoLessonInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the lesson */
  lessonId: Scalars['ID']['input'];
  lessonInput?: InputMaybe<LessonInput>;
  lessonWithDownloadInput?: InputMaybe<Array<LessonWithDownloadInput>>;
  lessonWithVideoInput?: InputMaybe<LessonWithVideoInput>;
};

/** Autogenerated return type of UpdateVideoLesson */
export type UpdateVideoLessonPayload = {
  __typename?: 'UpdateVideoLessonPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Updated video lesson */
  lesson: Maybe<VideoLesson>;
  userErrors: Maybe<Array<UserError>>;
};

/** Update an existing webhook */
export type UpdateWebhook = ResponseInterface & {
  __typename?: 'UpdateWebhook';
  /**
   * Response Extra Data
   * @deprecated response fields should be well defined following a object structure
   */
  data: Maybe<Scalars['JSON']['output']>;
  errors: Maybe<Array<Maybe<Error>>>;
  /** Response Message */
  message: Maybe<Scalars['String']['output']>;
  /** Return status: SUCCESS, PARTIAL_SUCCESS or FAILED */
  status: Maybe<ResponseStatus>;
};

/** Input fields to update a webhook */
export type UpdateWebhookInput = {
  /** Webhook's id */
  id: Scalars['String']['input'];
  /** Webhook's fields to be updated */
  patch: WebhookInput;
};

/** Autogenerated input type of UpgradeThemeInstallation */
export type UpgradeThemeInstallationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The theme installation id to be upgraded */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpgradeThemeInstallation */
export type UpgradeThemeInstallationPayload = {
  __typename?: 'UpgradeThemeInstallationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /**
   * Response data
   * @deprecated Backwards compatibility for old response data
   */
  data: Maybe<Scalars['String']['output']>;
  /**
   * Response errors
   * @deprecated Backwards compatibility for old response errors
   */
  errors: Maybe<Array<Error>>;
  /**
   * Response message
   * @deprecated Backwards compatibility for old response message
   */
  message: Maybe<Scalars['String']['output']>;
  /**
   * Response status
   * @deprecated Backwards compatibility for old response status
   */
  status: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** A user that has access to the tenant */
export type User = CommunitiesUserQueryInterface &
  Node &
  UserInterface & {
    __typename?: 'User';
    /** User's amount spent */
    amountSpent: Maybe<Scalars['Float']['output']>;
    /**
     * User's avatar url
     * @deprecated Use profile field instead
     */
    avatarUrl: Maybe<Scalars['String']['output']>;
    /** User's billing country */
    billingCountry: Maybe<Scalars['String']['output']>;
    /** User's billing postal code */
    billingPostalCode: Maybe<Scalars['String']['output']>;
    /** User's billing region */
    billingRegion: Maybe<Scalars['String']['output']>;
    /** User's ability to access sidekiq */
    canAccessSidekiq: Maybe<Scalars['Boolean']['output']>;
    /** A list of community users */
    communityUsers: Maybe<CommunityUserConnection>;
    /**
     * User's company
     * @deprecated Use profile field instead
     */
    company: Maybe<Scalars['String']['output']>;
    /** User's created date */
    createdAt: Maybe<Scalars['DateTime']['output']>;
    /** User's custom profile field */
    customProfileFields: Maybe<Array<Maybe<CustomField>>>;
    /** User's default payment card */
    defaultPaymentCard: Maybe<DefaultPaymentCard>;
    /** User's email */
    email: Maybe<Scalars['String']['output']>;
    /** A list of user's enrollments */
    enrollments: Maybe<EnrollmentConnection>;
    /** User's external source */
    externalSource: Maybe<Scalars['String']['output']>;
    /** User's first name */
    firstName: Maybe<Scalars['String']['output']>;
    /** User's full name */
    fullName: Maybe<Scalars['String']['output']>;
    /** A list of groups that user belongs */
    groups: Maybe<GroupConnection>;
    /** Whether this user has Payments tab enabled on User profile page */
    hasPaymentsTab: Scalars['Boolean']['output'];
    /** Whether user has set a thinkific password */
    hasThinkificPassword: Maybe<Scalars['Boolean']['output']>;
    id: Scalars['ID']['output'];
    /** Most recent identity provider user authenticated with */
    identityProvider: Maybe<IdentityProvider>;
    /** Users's IP address */
    ipAddress: Maybe<Scalars['String']['output']>;
    /** Whether the user is an admin */
    isAdmin: Maybe<Scalars['Boolean']['output']>;
    /** Whether the user is an unlimited admin ie. owner, site admin or partner */
    isUnlimitedAdmin: Maybe<Scalars['Boolean']['output']>;
    /** User's landing page */
    landingPage: Maybe<Scalars['String']['output']>;
    /** User's last name */
    lastName: Maybe<Scalars['String']['output']>;
    /** User's last sign in */
    lastSignIn: Maybe<Scalars['DateTime']['output']>;
    /** User's specified locale setting */
    locale: Maybe<Scalars['String']['output']>;
    /** MFA settings for user */
    mfaSettings: Maybe<MfaSettings>;
    /** User's total number of communities */
    numberOfCommunities: Maybe<Scalars['Int']['output']>;
    /** User's total number of enrollments */
    numberOfEnrollments: Maybe<Scalars['Int']['output']>;
    /** User's sign in date for previous session */
    previousSignIn: Maybe<Scalars['DateTime']['output']>;
    /** User's profile */
    profile: Maybe<Profile>;
    /** A list of the user's quiz and survey responses */
    quizSurveyResponses: Maybe<UserQuizSurveyResponsesConnection>;
    /** User's raw id */
    rawId: Maybe<Scalars['Int']['output']>;
    /** User's subscriptions and payment plans */
    recurringPayments: Maybe<Array<RecurringPayment>>;
    /** Affiliate who has referred user */
    referredBy: Maybe<Affiliate>;
    /** User's referred from url */
    referredFrom: Maybe<Scalars['String']['output']>;
    /** User's roles */
    roles: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** User's sign in count */
    signInCount: Maybe<Scalars['Int']['output']>;
    /** User's updated date */
    updatedAt: Maybe<Scalars['DateTime']['output']>;
    /** A list of user's bundles */
    userBundles: Maybe<Bundle_EnrollmentConnection>;
  };

/** A user that has access to the tenant */
export type UserCommunityUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommunityUserFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CommunityUserOrderBy>;
};

/** A user that has access to the tenant */
export type UserDefaultPaymentCardArgs = {
  cartToken?: InputMaybe<Scalars['String']['input']>;
};

/** A user that has access to the tenant */
export type UserEnrollmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserEnrollmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<EnrollmentOrderBy>;
};

/** A user that has access to the tenant */
export type UserGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A user that has access to the tenant */
export type UserQuizSurveyResponsesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ReportingOrderBy>;
  pagination: Pagination;
};

/** A user that has access to the tenant */
export type UserUserBundlesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserBundlesOrderBy>;
};

/** Filter options for affiliates with access to specified products */
export type UserAffiliationFilter = {
  /** Product ids the affiliate does not have access to */
  excludes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Product ids the affiliate has access to */
  includes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** User bulk email feature */
export type UserBulkEmailFeature = FeatureInterface & {
  __typename?: 'UserBulkEmailFeature';
  /** Feature checkout path */
  checkoutPath: Maybe<Scalars['String']['output']>;
  /** Flag to indicate feature is enabled */
  isEnabled: Maybe<Scalars['Boolean']['output']>;
  /** Maximum number of emails per month */
  maxEmailsPerMonth: Scalars['Int']['output'];
  /** The name of the minimum plan required to access this feature */
  minimumPlanName: Maybe<Scalars['String']['output']>;
};

/** Possible fields to order userBundles */
export type UserBundlesOrderBy = {
  direction?: InputMaybe<OrderDirection>;
  field?: InputMaybe<OrderField>;
};

/** The connection type for User. */
export type UserConnection = {
  __typename?: 'UserConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<UserEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<User>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** User content */
export type UserContent = Node & {
  __typename?: 'UserContent';
  /** UserContent's created at date */
  completedAt: Maybe<Scalars['DateTime']['output']>;
  /** UserContent's created at date */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** UserContent's updated at date */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** The connection type for UserContent. */
export type UserContentConnection = {
  __typename?: 'UserContentConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<UserContentEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<UserContent>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type UserContentEdge = {
  __typename?: 'UserContentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<UserContent>;
};

/** Possible fields to filter userContents */
export type UserContentFilter = {
  /** Search user content completed at or after */
  completedAfter?: InputMaybe<Scalars['Date']['input']>;
  /** Search user content completed at or before */
  completedBefore?: InputMaybe<Scalars['Date']['input']>;
};

/** Order for user contents */
export type UserContentOrderByFilter = {
  direction: OrderDirection;
  field: UserContentOrderField;
};

/** Possible fields to order user contents */
export enum UserContentOrderField {
  /** order user contents by completedAt date */
  CompletedAt = 'completedAt',
}

/** An edge in a connection. */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<User>;
};

/** Filter options for enrollment */
export type UserEnrollmentFilter = {
  /** Students active enrollments */
  active?: InputMaybe<Scalars['Boolean']['input']>;
  /** Bundle Ids where users have enrollments */
  bundleIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Course Ids where users have enrollments */
  courseIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** The status of the product */
  productStatus?: InputMaybe<Array<PublicationStatusTypes>>;
  /** The visibilty of the product */
  productVisible?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by enrollment status */
  status?: InputMaybe<UserEnrollmentStatus>;
  /** Filter by enrollment type */
  type?: InputMaybe<EnrollmentType>;
};

/** Enrollment status types */
export enum UserEnrollmentStatus {
  /** User's completed enrollments in course */
  Completed = 'COMPLETED',
  /** User's in progress enrollments in course */
  Inprogress = 'INPROGRESS',
  /** User's not started enrollments in course */
  Notstarted = 'NOTSTARTED',
}

/** Filter options for enrollment status */
export type UserEnrollmentStatusFilter = {
  /** Bundle Ids where users have enrollments */
  bundleIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Course Ids where users have enrollments */
  courseIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filter by enrollment status type */
  status?: InputMaybe<EnrollmentStatus>;
};

/** Common error type */
export type UserError = {
  __typename?: 'UserError';
  /** Code of the error */
  code: Maybe<Scalars['String']['output']>;
  /** Description of the error */
  message: Maybe<Scalars['String']['output']>;
  /** Which input value this error came from */
  path: Maybe<Array<Scalars['String']['output']>>;
};

/** Possible fields to filter users */
export type UserFilter = {
  /** Search users who administers courses */
  administersCourses?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  /** Search users who administers groups */
  administersGroups?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Search users for affiliates with access to specified products */
  affiliation?: InputMaybe<Array<InputMaybe<UserAffiliationFilter>>>;
  /** Search users who spent certain amount of money */
  amountSpent?: InputMaybe<Array<InputMaybe<MoneyComparison>>>;
  /** Search by created date */
  createdAt?: InputMaybe<DateFilter>;
  /** Search by email */
  email?: InputMaybe<Array<InputMaybe<TextComparison>>>;
  /** Search users by enrollment */
  enrollment?: InputMaybe<Array<InputMaybe<UserEnrollmentFilter>>>;
  /** Search users by enrollment status */
  enrollmentStatus?: InputMaybe<Array<InputMaybe<UserEnrollmentStatusFilter>>>;
  /** Search by External Source */
  externalSource?: InputMaybe<Array<InputMaybe<TextComparison>>>;
  /** Search users who are in the specified group */
  group?: InputMaybe<Array<InputMaybe<TextComparison>>>;
  /** Search users that has purchased products */
  hasPurchased?: InputMaybe<Array<InputMaybe<UserHasPurchasedFilter>>>;
  /** Search by id */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Search by ip address */
  ipAddress?: InputMaybe<Array<InputMaybe<TextComparison>>>;
  /** Search by last login date */
  lastLogin?: InputMaybe<DateFilter>;
  /** Search by name */
  name?: InputMaybe<Array<InputMaybe<TextComparison>>>;
  /** Search users by progress percentage in courses */
  progress?: InputMaybe<Array<InputMaybe<ProgressFilter>>>;
  /** Search users with referral */
  referredBy?: InputMaybe<Array<InputMaybe<UserReferredByFilter>>>;
  /** Search users by role */
  roles?: InputMaybe<Array<InputMaybe<RoleFilter>>>;
  /** Search by name or email */
  text?: InputMaybe<Scalars['String']['input']>;
  /** Search users who have used coupon - list of coupon ids */
  usedCoupon?: InputMaybe<Array<InputMaybe<UserUsedCouponFilter>>>;
  /** Search for imported users - list of user import ids */
  userImport?: InputMaybe<Array<InputMaybe<UserUserImportFilter>>>;
};

/** Filter for purchases user has made */
export type UserHasPurchasedFilter = {
  /** List of product ids */
  productIds: Array<InputMaybe<Scalars['String']['input']>>;
};

/** Tenant's user import */
export type UserImport = Node & {
  __typename?: 'UserImport';
  /** When the user import was completed */
  completedAt: Maybe<Scalars['DateTime']['output']>;
  /** When the user import was created */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The column number for the email */
  emailCol: Maybe<Scalars['Int']['output']>;
  /** Any errors from preparing user import */
  errorMessage: Maybe<Scalars['String']['output']>;
  /** The user import's file name */
  fileName: Maybe<Scalars['String']['output']>;
  /** The column number for the first name */
  firstNameCol: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  /** The column number for the last name */
  lastNameCol: Maybe<Scalars['Int']['output']>;
  /** Whether site welcome emails should be sent to new users */
  sendSiteWelcomeEmail: Maybe<Scalars['Boolean']['output']>;
  /** The spreadsheet columns from the uploaded file */
  spreadsheetCols: Maybe<Scalars['JSON']['output']>;
  /** The status of the user import */
  status: Maybe<Scalars['String']['output']>;
  /** When the user import was last updated */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** The connection type for UserImport. */
export type UserImportConnection = {
  __typename?: 'UserImportConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<UserImportEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<UserImport>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type UserImportEdge = {
  __typename?: 'UserImportEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<UserImport>;
};

/** Possible fields to filter user imports */
export type UserImportFilter = {
  /** Search by filename */
  fileName?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Search by id */
  id?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Search for ones that are in progress */
  inProgress?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Input fields to update a User Import */
export type UserImportPatchInput = {
  /** An object that represents CustomProfileFieldDefinition ids mapped to the spreadsheet column numbers */
  customFieldsCols?: InputMaybe<Scalars['JSON']['input']>;
  /** The column number that represents the Email of a user */
  emailCol?: InputMaybe<Scalars['Int']['input']>;
  /** Error message describing why the import failed */
  errorMessage?: InputMaybe<Scalars['String']['input']>;
  /** The name of the file to be used for the import */
  fileName?: InputMaybe<Scalars['String']['input']>;
  /** The column number that represents the First Name of a user */
  firstNameCol?: InputMaybe<Scalars['Int']['input']>;
  /** The column number that represents the Last Name of a user */
  lastNameCol?: InputMaybe<Scalars['Int']['input']>;
  /** The AWS S3 key of the file */
  s3Key?: InputMaybe<Scalars['String']['input']>;
  /** Whether site welcome emails should be sent to new users */
  sendSiteWelcomeEmail?: InputMaybe<Scalars['Boolean']['input']>;
};

/** User interface */
export type UserInterface = {
  /** User's amount spent */
  amountSpent: Maybe<Scalars['Float']['output']>;
  /**
   * User's avatar url
   * @deprecated Use profile field instead
   */
  avatarUrl: Maybe<Scalars['String']['output']>;
  /** User's billing country */
  billingCountry: Maybe<Scalars['String']['output']>;
  /** User's billing postal code */
  billingPostalCode: Maybe<Scalars['String']['output']>;
  /** User's billing region */
  billingRegion: Maybe<Scalars['String']['output']>;
  /** User's ability to access sidekiq */
  canAccessSidekiq: Maybe<Scalars['Boolean']['output']>;
  /**
   * User's company
   * @deprecated Use profile field instead
   */
  company: Maybe<Scalars['String']['output']>;
  /** User's created date */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** User's custom profile field */
  customProfileFields: Maybe<Array<Maybe<CustomField>>>;
  /** User's default payment card */
  defaultPaymentCard: Maybe<DefaultPaymentCard>;
  /** User's email */
  email: Maybe<Scalars['String']['output']>;
  /** A list of user's enrollments */
  enrollments: Maybe<EnrollmentConnection>;
  /** User's external source */
  externalSource: Maybe<Scalars['String']['output']>;
  /** User's first name */
  firstName: Maybe<Scalars['String']['output']>;
  /** User's full name */
  fullName: Maybe<Scalars['String']['output']>;
  /** A list of groups that user belongs */
  groups: Maybe<GroupConnection>;
  /** Whether this user has Payments tab enabled on User profile page */
  hasPaymentsTab: Scalars['Boolean']['output'];
  /** Whether user has set a thinkific password */
  hasThinkificPassword: Maybe<Scalars['Boolean']['output']>;
  /** Most recent identity provider user authenticated with */
  identityProvider: Maybe<IdentityProvider>;
  /** Users's IP address */
  ipAddress: Maybe<Scalars['String']['output']>;
  /** Whether the user is an admin */
  isAdmin: Maybe<Scalars['Boolean']['output']>;
  /** Whether the user is an unlimited admin ie. owner, site admin or partner */
  isUnlimitedAdmin: Maybe<Scalars['Boolean']['output']>;
  /** User's landing page */
  landingPage: Maybe<Scalars['String']['output']>;
  /** User's last name */
  lastName: Maybe<Scalars['String']['output']>;
  /** User's last sign in */
  lastSignIn: Maybe<Scalars['DateTime']['output']>;
  /** User's specified locale setting */
  locale: Maybe<Scalars['String']['output']>;
  /** MFA settings for user */
  mfaSettings: Maybe<MfaSettings>;
  /** User's total number of enrollments */
  numberOfEnrollments: Maybe<Scalars['Int']['output']>;
  /** User's sign in date for previous session */
  previousSignIn: Maybe<Scalars['DateTime']['output']>;
  /** User's profile */
  profile: Maybe<Profile>;
  /** A list of the user's quiz and survey responses */
  quizSurveyResponses: Maybe<UserQuizSurveyResponsesConnection>;
  /** User's subscriptions and payment plans */
  recurringPayments: Maybe<Array<RecurringPayment>>;
  /** Affiliate who has referred user */
  referredBy: Maybe<Affiliate>;
  /** User's referred from url */
  referredFrom: Maybe<Scalars['String']['output']>;
  /** User's roles */
  roles: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** User's sign in count */
  signInCount: Maybe<Scalars['Int']['output']>;
  /** User's updated date */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  /** A list of user's bundles */
  userBundles: Maybe<Bundle_EnrollmentConnection>;
};

/** User interface */
export type UserInterfaceDefaultPaymentCardArgs = {
  cartToken?: InputMaybe<Scalars['String']['input']>;
};

/** User interface */
export type UserInterfaceEnrollmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserEnrollmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<EnrollmentOrderBy>;
};

/** User interface */
export type UserInterfaceGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** User interface */
export type UserInterfaceQuizSurveyResponsesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ReportingOrderBy>;
  pagination: Pagination;
};

/** User interface */
export type UserInterfaceUserBundlesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserBundlesOrderBy>;
};

/** An event tracking mfa activity for a particular user */
export type UserMfaEvent = Node & {
  __typename?: 'UserMfaEvent';
  /** MFA action name */
  action: Scalars['String']['output'];
  /** User that triggered the event */
  actor: User;
  /** Event creation time */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Version ID */
  id: Scalars['ID']['output'];
  /** Note associated with the event */
  note: Maybe<Scalars['String']['output']>;
  /** User */
  user: User;
};

/** The connection type for UserMfaEvent. */
export type UserMfaEventConnection = {
  __typename?: 'UserMfaEventConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<UserMfaEventEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<UserMfaEvent>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type UserMfaEventEdge = {
  __typename?: 'UserMfaEventEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<UserMfaEvent>;
};

/** Possible fields to order users */
export enum UserOrderField {
  /** Order users by country_code */
  CountryCode = 'COUNTRY_CODE',
  /** Order users by created_at */
  CreatedAt = 'CREATED_AT',
  /** Order users by current_sign_in_at */
  CurrentSignInAt = 'CURRENT_SIGN_IN_AT',
  /** Order users by email */
  Email = 'EMAIL',
  /** Order users by external_source */
  ExternalSource = 'EXTERNAL_SOURCE',
  /** Order users by first_name */
  FirstName = 'FIRST_NAME',
  /** Order users by full_name */
  FullName = 'FULL_NAME',
  /** Order users by id */
  Id = 'ID',
  /** Order users by ip_address */
  IpAddress = 'IP_ADDRESS',
  /** Order users by last_name */
  LastName = 'LAST_NAME',
  /** Order users by postal_code */
  PostalCode = 'POSTAL_CODE',
  /** Order users by referral_code */
  ReferralCode = 'REFERRAL_CODE',
  /** Order users by region */
  Region = 'REGION',
  /** Order users by sign_in_count */
  SignInCount = 'SIGN_IN_COUNT',
  /** Order users by total_spent_in_cents */
  TotalSpentInCents = 'TOTAL_SPENT_IN_CENTS',
}

/** User quizzes */
export type UserQuiz = Node & {
  __typename?: 'UserQuiz';
  /** UserQuiz's completed at date */
  completedAt: Maybe<Scalars['DateTime']['output']>;
  /** UserQuiz's created at date */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** UserQuiz's updated at date */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** The connection type for UserQuiz. */
export type UserQuizConnection = {
  __typename?: 'UserQuizConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<UserQuizEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<UserQuiz>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type UserQuizEdge = {
  __typename?: 'UserQuizEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<UserQuiz>;
};

/** Possible fields to filter userQuizzes */
export type UserQuizFilter = {
  /** Search user quiz completed at or after */
  completedAfter?: InputMaybe<Scalars['Date']['input']>;
  /** Search user quiz completed at or before */
  completedBefore?: InputMaybe<Scalars['Date']['input']>;
};

/** Order for userQuizzes */
export type UserQuizOrderBy = {
  direction: OrderDirection;
  field: UserQuizOrderField;
};

/** Possible fields to order user quizzes */
export enum UserQuizOrderField {
  /** order user quizzes by completedAt date */
  CompletedAt = 'completedAt',
}

/** A user's quiz/survey response */
export type UserQuizSurveyResponses = {
  __typename?: 'UserQuizSurveyResponses';
  /** Date the quiz/survey was completed */
  completionDate: Maybe<Scalars['DateTime']['output']>;
  /** Content name */
  contentName: Maybe<Scalars['String']['output']>;
  /** Content type (Quiz or Survey) */
  contentType: Maybe<Scalars['String']['output']>;
  /** Course name */
  courseName: Maybe<Scalars['String']['output']>;
  /** Passing grade */
  passingGrade: Maybe<Scalars['Int']['output']>;
  /** Student grade */
  studentGrade: Maybe<Scalars['Int']['output']>;
  /** Total correct answers */
  totalCorrectAnswers: Maybe<Scalars['Int']['output']>;
  /** Total questions in content */
  totalQuestions: Maybe<Scalars['Int']['output']>;
};

/** The connection type for UserQuizSurveyResponses. */
export type UserQuizSurveyResponsesConnection = {
  __typename?: 'UserQuizSurveyResponsesConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<UserQuizSurveyResponsesEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<UserQuizSurveyResponses>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** all announcements count */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type UserQuizSurveyResponsesEdge = {
  __typename?: 'UserQuizSurveyResponsesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<UserQuizSurveyResponses>;
};

/** Filter for referred users */
export type UserReferredByFilter = {
  /** List of affiliate codes */
  affiliateCodes: Array<InputMaybe<Scalars['String']['input']>>;
};

/** User roles */
export enum UserRole {
  /** User who is an affiliate */
  Affiliate = 'AFFILIATE',
  /** User who admins a course */
  CourseAdmin = 'COURSE_ADMIN',
  /** User who admins a group */
  GroupAnalyst = 'GROUP_ANALYST',
  /** User who owns the site */
  Owner = 'OWNER',
  /** Partner managing the site */
  Partner = 'PARTNER',
  /** Users who are product payee */
  ProductPayee = 'PRODUCT_PAYEE',
  /** User who admins the site */
  SiteAdmin = 'SITE_ADMIN',
  /** Students users */
  Student = 'STUDENT',
}

/** User surveys */
export type UserSurvey = Node & {
  __typename?: 'UserSurvey';
  /** UserSurvey's completed at date */
  completedAt: Maybe<Scalars['DateTime']['output']>;
  /** UserSurvey's created at date */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** UserSurvey's updated at date */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** The connection type for UserSurvey. */
export type UserSurveyConnection = {
  __typename?: 'UserSurveyConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<UserSurveyEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<UserSurvey>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type UserSurveyEdge = {
  __typename?: 'UserSurveyEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<UserSurvey>;
};

/** Possible fields to filter userSurveys */
export type UserSurveyFilter = {
  /** Search user survey completed at or after */
  completedAfter?: InputMaybe<Scalars['Date']['input']>;
  /** Search user survey completed at or before */
  completedBefore?: InputMaybe<Scalars['Date']['input']>;
};

/** Order for userSurveys */
export type UserSurveyOrderBy = {
  direction: OrderDirection;
  field: UserSurveyOrderField;
};

/** Possible fields to order user surveys */
export enum UserSurveyOrderField {
  /** order user surveys by completedAt date */
  CompletedAt = 'completedAt',
}

/** Filter for users who have used coupon */
export type UserUsedCouponFilter = {
  /** List of coupon ids */
  couponIds: Array<InputMaybe<Scalars['String']['input']>>;
};

/** Filter for imported users */
export type UserUserImportFilter = {
  /** Search by user import ids */
  userImportIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

/** Fields relating to tenant VAT number from Stripe */
export type VatNumber = {
  __typename?: 'VatNumber';
  /** Verification status of the VAT number */
  status: Maybe<Scalars['String']['output']>;
  /** Tax ID type */
  type: Maybe<Scalars['String']['output']>;
  /** VAT number */
  value: Maybe<Scalars['String']['output']>;
};

/** Verified tenants that match credentials */
export type VerifiedTenants = Node & {
  __typename?: 'VerifiedTenants';
  /** Tenant's confirmed_by_customer */
  confirmedByCustomer: Maybe<Scalars['Boolean']['output']>;
  /** Tenant's country_code */
  countryCode: Maybe<Scalars['String']['output']>;
  /** Tenant's custom_domain */
  customDomain: Scalars['String']['output'];
  /** Tenant's hostname */
  hostname: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Tenant's site_name */
  siteName: Scalars['String']['output'];
  /** Is the Tenant locked to the current Stripe entity */
  stripeEntityLocked: Maybe<Scalars['Boolean']['output']>;
  /** Stripe Public API key associated with Tenant's Stripe customer */
  stripePublicApiKey: Maybe<Scalars['String']['output']>;
  /** Tenant's subdomain */
  subdomain: Scalars['String']['output'];
  /** Tenant's tax_exempt */
  taxExempt: Maybe<Scalars['Boolean']['output']>;
  /** Tenant's zip_code */
  zipCode: Maybe<Scalars['String']['output']>;
};

/** Verify if email matches existing tenants */
export type VerifyEmail = Node & {
  __typename?: 'VerifyEmail';
  /** Email associated with onboarding flow */
  email: Scalars['String']['output'];
  /** Flag for whether email is associated with multiple tenant ownership */
  hasMultipleTenants: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** Flag for whether email is associated with tenant ownership */
  tenancyVerified: Scalars['Boolean']['output'];
};

/** Email Field to verify tenancy */
export type VerifyEmailFilter = {
  /** Tenant Email */
  email: Scalars['String']['input'];
  /** Onboarding Flow ID */
  onboardingFlowId: Scalars['ID']['input'];
};

/** Autogenerated input type of VerifyPassword */
export type VerifyPasswordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Users account password */
  password: Scalars['String']['input'];
};

/** Autogenerated return type of VerifyPassword */
export type VerifyPasswordPayload = {
  __typename?: 'VerifyPasswordPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** Flag indicating password correctness */
  isCorrectPassword: Maybe<Scalars['Boolean']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Autogenerated input type of VerifySecondFactor */
export type VerifySecondFactorInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** OTP or backup code */
  otpAttempt: Scalars['String']['input'];
};

/** Autogenerated return type of VerifySecondFactor */
export type VerifySecondFactorPayload = {
  __typename?: 'VerifySecondFactorPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  userErrors: Maybe<Array<UserError>>;
};

/** Inputs to verify tenant credentials */
export type VerifyTenantCredentialsInput = {
  /** Tenant Email */
  email: Scalars['String']['input'];
  /** Onboarding Flow ID */
  onboardingFlowId: Scalars['ID']['input'];
  /** Tenant Password */
  password: Scalars['String']['input'];
};

/** Video */
export type Video = Node & {
  __typename?: 'Video';
  /** Video's caption */
  captions: Maybe<Scalars['JSON']['output']>;
  /** Video's content type */
  contentType: Maybe<Scalars['String']['output']>;
  /** Video's created at datetime */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** Video's length */
  durationInSeconds: Maybe<Scalars['Int']['output']>;
  /** Video's encoded state */
  encodedState: Maybe<Scalars['String']['output']>;
  /** Video file's name */
  fileName: Maybe<Scalars['String']['output']>;
  /** Video's human readable file size */
  humanReadableFileSize: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Video's Wistia identifier */
  identifier: Maybe<Scalars['String']['output']>;
  /** Video's storage location */
  storageLocation: Maybe<Scalars['String']['output']>;
  /** Video's thumbnail */
  thumbnail: Maybe<Scalars['String']['output']>;
  /** Video's updated at datetime */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  /** Video's uploaded state */
  uploadedState: Maybe<Scalars['String']['output']>;
  /** Video's S3 url */
  url: Maybe<Scalars['String']['output']>;
  /** Video's numeric id */
  videoId: Maybe<Scalars['Int']['output']>;
};

/** Video */
export type VideoUrlArgs = {
  hlsFormat?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The connection type for Video. */
export type VideoConnection = {
  __typename?: 'VideoConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<VideoEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Video>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type VideoEdge = {
  __typename?: 'VideoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Video>;
};

/** Video Engagement Total */
export type VideoEngagementTotals = {
  __typename?: 'VideoEngagementTotals';
  /** The number of captions */
  captionsCount: Maybe<Scalars['Int']['output']>;
  /** The average percentage of the video that gets viewed */
  engagement: Maybe<Scalars['Float']['output']>;
  /** Data for creating an engagement graph */
  engagementData: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** Total time spent watching this video */
  hours: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  /** The percentage of visitors who clicked play */
  playRate: Maybe<Scalars['Float']['output']>;
  /** The total number of times this video has been played */
  plays: Maybe<Scalars['Int']['output']>;
  /** Data for creating the rewatch block on an engagement graph */
  rewatchData: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** The status (archived/active) of the video */
  status: Maybe<Scalars['String']['output']>;
  /** The URL of the video's thumbnail image */
  thumbnailUrl: Maybe<Scalars['String']['output']>;
  /** Uploaded at date */
  uploadedAt: Maybe<Scalars['DateTime']['output']>;
  /** Video's length, in seconds */
  videoLength: Maybe<Scalars['Int']['output']>;
  /** The name of the video */
  videoName: Maybe<Scalars['String']['output']>;
  /** The total number of times this video has been loaded */
  views: Maybe<Scalars['Int']['output']>;
  /** The total number of unique people that have loaded this video */
  visitors: Maybe<Scalars['Int']['output']>;
};

/** Possible fields to filter videos */
export type VideoFilter = {
  /** Search by the course id */
  courseId?: InputMaybe<Scalars['String']['input']>;
  /** Search by the video encoded state */
  encodedState?: InputMaybe<Scalars['String']['input']>;
  /** Search by if the video has a Wistia identifier */
  hasIdentifier?: InputMaybe<Scalars['Boolean']['input']>;
  /** Search by the video id */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Search by Wistia identifier */
  identifier?: InputMaybe<Scalars['String']['input']>;
  /** Search by video name */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Search by the video uploaded state */
  uploadedState?: InputMaybe<Scalars['String']['input']>;
};

/** video lesson information */
export type VideoLesson = LessonInterface &
  Node & {
    __typename?: 'VideoLesson';
    /** Video lesson autoplay */
    autoplay: Scalars['Boolean']['output'];
    /** Content's chapter */
    chapter: Chapter;
    /** contentable id */
    contentableId: Scalars['ID']['output'];
    /** Content's course */
    course: Course;
    /** Content's created at date */
    createdAt: Scalars['DateTime']['output'];
    /** Lesson default icon */
    defaultLessonTypeIcon: Scalars['String']['output'];
    /** Is discussion enabled */
    discussionsEnabled: Maybe<Scalars['Boolean']['output']>;
    /** A list of the lesson's download files */
    downloadFiles: Maybe<DownloadFileConnection>;
    /** Video lesson downloadable */
    downloadable: Maybe<Scalars['Boolean']['output']>;
    /** Lesson draft status */
    draft: Scalars['Boolean']['output'];
    /** Lesson's associated Drip Schedule Item */
    dripScheduleItem: Maybe<DripScheduleItem>;
    /** lesson drip status */
    dripStatus: Maybe<Scalars['JSON']['output']>;
    /** Is lesson free */
    free: Maybe<Scalars['Boolean']['output']>;
    /** Whether or not the Video lesson has an HTML description */
    hasHtmlDescription: Maybe<Scalars['Boolean']['output']>;
    /** Lesson html text */
    htmlDescription: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    /** Current user lesson status */
    lessonCompleted: Maybe<Scalars['Boolean']['output']>;
    /** lesson drip schedule */
    lessonHasDripSchedule: Maybe<Scalars['String']['output']>;
    /** Lesson integer ID */
    lessonId: Scalars['ID']['output'];
    /** Lesson icon */
    lessonTypeIcon: Maybe<LessonIconEnum>;
    /** Lesson label */
    lessonTypeLabel: Maybe<Scalars['String']['output']>;
    /** Lesson url */
    lessonUrl: Maybe<Scalars['String']['output']>;
    /** Lesson name */
    name: Scalars['String']['output'];
    /** Lesson position */
    position: Scalars['Int']['output'];
    /** Is lesson prerequisite */
    prerequisite: Scalars['Boolean']['output'];
    /** Lesson's slug */
    slug: Maybe<Scalars['String']['output']>;
    /** Lesson Type */
    type: Scalars['String']['output'];
    /** Whether the lesson is still under construction */
    underConstruction: Scalars['Boolean']['output'];
    /** Content's updated at date */
    updatedAt: Scalars['DateTime']['output'];
    /** User content for lesson */
    userContents: Maybe<UserContentConnection>;
    /** User quizzes for lesson */
    userQuizzes: Maybe<UserQuizConnection>;
    /** User surveys for lesson */
    userSurveys: Maybe<UserSurveyConnection>;
    /** The lesson's video */
    video: Maybe<Video>;
  };

/** video lesson information */
export type VideoLessonDownloadFilesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** video lesson information */
export type VideoLessonUserContentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserContentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserContentOrderByFilter>;
};

/** video lesson information */
export type VideoLessonUserQuizzesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserQuizFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserQuizOrderBy>;
};

/** video lesson information */
export type VideoLessonUserSurveysArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserSurveyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserSurveyOrderBy>;
};

/** Order for videos */
export type VideoOrderBy = {
  direction: OrderDirection;
  field: VideoOrderField;
};

/** Possible fields to order videos */
export enum VideoOrderField {
  /** order videos by created at */
  CreatedAt = 'createdAt',
  /** order videos by length */
  DurationInSeconds = 'durationInSeconds',
  /** order videos by engagement */
  Engagement = 'engagement',
  /** order videos by file name */
  FileName = 'fileName',
  /** order videos by hours played */
  Hours = 'hours',
  /** order videos by file size */
  HumanReadableFileSize = 'humanReadableFileSize',
  /** order videos by play rate */
  PlayRate = 'playRate',
  /** order videos by number of plays */
  Plays = 'plays',
  /** order videos by updated at */
  UpdatedAt = 'updatedAt',
  /** order videos by number of views */
  Views = 'views',
  /** order videos by number of visitors */
  Visitors = 'visitors',
}

/** Video Statistic */
export type VideoStatistic = Node & {
  __typename?: 'VideoStatistic';
  /** Overall engagement metric */
  engagement: Maybe<Scalars['Float']['output']>;
  /** Video's name */
  fileName: Maybe<Scalars['String']['output']>;
  /** Hours played */
  hours: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  /** Video's Wistia identifier */
  identifier: Maybe<Scalars['String']['output']>;
  /** Overall play rate */
  playRate: Maybe<Scalars['Float']['output']>;
  /** Total plays */
  plays: Maybe<Scalars['Int']['output']>;
  /** Thumbnail URL */
  thumbnailUrl: Maybe<Scalars['String']['output']>;
  /** Video ID */
  videoId: Maybe<Scalars['String']['output']>;
  /** Total views */
  views: Maybe<Scalars['Int']['output']>;
  /** Total visitors */
  visitors: Maybe<Scalars['Int']['output']>;
};

/** The connection type for VideoStatistic. */
export type VideoStatisticConnection = {
  __typename?: 'VideoStatisticConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<VideoStatisticEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<VideoStatistic>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total count video stats */
  totalCount: Scalars['Int']['output'];
  /** total count pre filter */
  totalPreFilterCount: Maybe<Scalars['Int']['output']>;
};

/** An edge in a connection. */
export type VideoStatisticEdge = {
  __typename?: 'VideoStatisticEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<VideoStatistic>;
};

/** Video Statistics Total */
export type VideoStatsTotals = {
  __typename?: 'VideoStatsTotals';
  /** Total time spent watching this video */
  hours: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  /** The total number of times this video has been played */
  plays: Maybe<Scalars['Int']['output']>;
  /** The total number of times this video has been loaded */
  views: Maybe<Scalars['Int']['output']>;
  /** The total number of unique people that have loaded this video */
  visitors: Maybe<Scalars['Int']['output']>;
};

/** Autogenerated input type of ViewLesson */
export type ViewLessonInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the lesson (AKA content) */
  lessonId: Scalars['ID']['input'];
};

/** Autogenerated return type of ViewLesson */
export type ViewLessonPayload = {
  __typename?: 'ViewLessonPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** The lesson */
  lesson: Maybe<LessonUnion>;
  userErrors: Maybe<Array<UserError>>;
};

/** Geolocation data for onboarding flow */
export type VisitorGeoData = Node & {
  __typename?: 'VisitorGeoData';
  /** Visitor country extracted from Geocoder */
  country: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Visitor postal code extracted from Geocoder */
  postal: Maybe<Scalars['String']['output']>;
};

/** Tenant's webhooks */
export type Webhook = Node & {
  __typename?: 'Webhook';
  /** Webhook's created at date */
  createdAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** A list of webhook's deliveries */
  deliveries: Maybe<DeliveryConnection>;
  id: Scalars['ID']['output'];
  /** Webhook's status */
  status: Maybe<Scalars['String']['output']>;
  /** Webhook's target url */
  targetUrl: Maybe<Scalars['String']['output']>;
  /** Webhook's name */
  topic: Maybe<Scalars['String']['output']>;
  /** Webhook's updated at date */
  updatedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/** Tenant's webhooks */
export type WebhookDeliveriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for Webhook. */
export type WebhookConnection = {
  __typename?: 'WebhookConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<WebhookEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Webhook>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of webhooks */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type WebhookEdge = {
  __typename?: 'WebhookEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Webhook>;
};

/** Possible fields to filter webhooks */
export type WebhookFilter = {
  /** Search by id */
  id?: InputMaybe<Scalars['String']['input']>;
};

/** Input fields for a Webhook */
export type WebhookInput = {
  /** Webhook's target url */
  targetUrl?: InputMaybe<Scalars['String']['input']>;
  /** Webhook's topic */
  topic?: InputMaybe<Scalars['String']['input']>;
};

/** Sends a webhook test event */
export type WebhookTestEvent = ResponseInterface & {
  __typename?: 'WebhookTestEvent';
  /**
   * Response Extra Data
   * @deprecated response fields should be well defined following a object structure
   */
  data: Maybe<Scalars['JSON']['output']>;
  errors: Maybe<Array<Maybe<Error>>>;
  /** Response Message */
  message: Maybe<Scalars['String']['output']>;
  /** Return status: SUCCESS, PARTIAL_SUCCESS or FAILED */
  status: Maybe<ResponseStatus>;
};

/** Input fields to send a webhook test event */
export type WebhookTestEventInput = {
  /** Webhook's topic */
  topic: Scalars['String']['input'];
};

/** Webinar source providers for a live lesson */
export enum WebinarProviderEnum {
  /** zoom meeting type */
  Zoom = 'zoom',
}

/** Zoom Item information */
export type ZoomItem = Node & {
  __typename?: 'ZoomItem';
  /** Video lesson autoplay */
  agenda: Maybe<Scalars['String']['output']>;
  /** duration for zoom item in minutes */
  duration: Scalars['Int']['output'];
  /** Encrypted password for zoom item */
  encryptedPassword: Scalars['String']['output'];
  /** zoom item ended at */
  endedAt: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** zoom user is invalid */
  invalidZoomUser: Scalars['Boolean']['output'];
  /** Invitation URL */
  inviteUrl: Scalars['String']['output'];
  /** Join before host */
  joinBeforeHost: Maybe<Scalars['Boolean']['output']>;
  /** Join URL */
  joinUrl: Scalars['String']['output'];
  /** manage URL */
  manageUrl: Scalars['String']['output'];
  /** password for zoom item */
  password: Scalars['String']['output'];
  /** record storage type of zoom item */
  recordingStorageType: Scalars['String']['output'];
  /** start time of zoom item */
  startTime: Maybe<Scalars['DateTime']['output']>;
  /** Start URL */
  startUrl: Scalars['String']['output'];
  /** zoom item started at */
  startedAt: Maybe<Scalars['String']['output']>;
  /** timezone for zoom item */
  timezone: Scalars['String']['output'];
  /** Topic of zoom item */
  topic: Scalars['String']['output'];
  /** waiting room for zoom item */
  waitingRoom: Maybe<Scalars['Boolean']['output']>;
  /** zoom id for zoom item */
  zoomId: Scalars['String']['output'];
  /** zoom type */
  zoomType: Maybe<Scalars['String']['output']>;
  /** Host for the zoom call */
  zoomUser: Maybe<ZoomUser>;
};

/** Input for zoom */
export type ZoomItemInput = {
  /** agenda zoom item */
  agenda?: InputMaybe<Scalars['String']['input']>;
  /** duration for zoom item in minutes */
  duration: Scalars['Int']['input'];
  /** The ID of the zoom Item */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** allow to join before host */
  joinBeforeHost?: InputMaybe<Scalars['Boolean']['input']>;
  /** password for zoom item */
  password: Scalars['String']['input'];
  /** record storage type of a zoom item */
  recordingStorageType: AutoRecordingEnum;
  recurringMeeting?: InputMaybe<CreateBulkLiveLessonInput>;
  /** start time of zoom item */
  startTime: Scalars['DateTime']['input'];
  /** timezone for zoom item */
  timezone: Scalars['String']['input'];
  /** Topic of zoom item */
  topic: Scalars['String']['input'];
  /** waiting room for zoom item */
  waitingRoom?: InputMaybe<Scalars['Boolean']['input']>;
  /** zoom type e.g meeting */
  zoomType?: InputMaybe<ZoomTypeEnum>;
  /** ID of the zoom user */
  zoomUserId?: InputMaybe<Scalars['ID']['input']>;
};

export enum ZoomTypeEnum {
  /** zoom meeting type */
  Meeting = 'meeting',
  /** zoom webinar type */
  Webinar = 'webinar',
}

/** Zoom users information associated with a tenant */
export type ZoomUser = Node & {
  __typename?: 'ZoomUser';
  /** Email address */
  email: Scalars['String']['output'];
  /** First name */
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Preferred language */
  language: Maybe<Scalars['String']['output']>;
  /** Last name */
  lastName: Scalars['String']['output'];
  /** Personal meeting ID from Zoom */
  pmi: Maybe<Scalars['String']['output']>;
  /** Unique role ID from Zoom */
  roleId: Maybe<Scalars['String']['output']>;
  /** User status on Zoom */
  status: ZoomUserStatus;
  /** Preferred timezone */
  timezone: Maybe<Scalars['String']['output']>;
  /** User ID from Zoom */
  zoomId: Scalars['String']['output'];
};

/** The connection type for ZoomUser. */
export type ZoomUserConnection = {
  __typename?: 'ZoomUserConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ZoomUserEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<ZoomUser>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ZoomUserEdge = {
  __typename?: 'ZoomUserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<ZoomUser>;
};

/** Possible fields to filter Zoom Users */
export type ZoomUserFilter = {
  /** Search by email */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Search by first and last name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Search by Zoom user status */
  status?: InputMaybe<ZoomUserStatus>;
};

/** Order for Zoom users */
export type ZoomUserOrderByFilter = {
  direction: OrderDirection;
  field: ZoomUserOrderFieldFilter;
};

/** Possible fields to order Zoom users */
export enum ZoomUserOrderFieldFilter {
  /** order Zoom users by email */
  Email = 'EMAIL',
  /** order Zoom users by name */
  Name = 'NAME',
  /** order Zoom users by status */
  Status = 'STATUS',
}

/** Zoom user status types */
export enum ZoomUserStatus {
  /** An active zoom user */
  Active = 'ACTIVE',
  /** A deactivated zoom user */
  Inactive = 'INACTIVE',
  /** A pending zoom user */
  Pending = 'PENDING',
}

/** Union type to return search for product and pages */
export type SearchPagesAndProducts = CustomPage | CustomSiteThemeView | Product;

export type UpdateAccountNotificationMutationVariables = Exact<{
  input: UpdateAccountNotificationInput;
}>;

export type UpdateAccountNotificationMutation = {
  __typename?: 'Mutation';
  updateAccountNotification: {
    __typename?: 'UpdateAccountNotificationPayload';
    userErrors: Array<{
      __typename?: 'UserError';
      code: string | null;
      message: string | null;
    }> | null;
  } | null;
};

export type EventFieldsFragment = {
  __typename?: 'LiveEvent';
  id: string;
  title: string | null;
  description: string | null;
  imageUrl: string | null;
  imageAltText: string | null;
  startTime: any | null;
  endTime: any | null;
  timezone: string | null;
  meetingUrl: any | null;
  location: string | null;
};

export type LiveEventsEdgesNodeFieldsFragment = {
  __typename?: 'LiveEvent';
  isInterested: boolean | null;
  id: string;
  title: string | null;
  description: string | null;
  imageUrl: string | null;
  imageAltText: string | null;
  startTime: any | null;
  endTime: any | null;
  timezone: string | null;
  meetingUrl: any | null;
  location: string | null;
  interestedUsers: { __typename?: 'UserConnection'; totalCount: number } | null;
};

export type LiveEventsEdgesFieldsFragment = {
  __typename?: 'LiveEventEdge';
  node: {
    __typename?: 'LiveEvent';
    isInterested: boolean | null;
    id: string;
    title: string | null;
    description: string | null;
    imageUrl: string | null;
    imageAltText: string | null;
    startTime: any | null;
    endTime: any | null;
    timezone: string | null;
    meetingUrl: any | null;
    location: string | null;
    interestedUsers: {
      __typename?: 'UserConnection';
      totalCount: number;
    } | null;
  } | null;
};

export type InterestedUsersEdgesNodeFieldsFragment = {
  __typename?: 'User';
  id: string;
  fullName: string | null;
  profile: {
    __typename?: 'Profile';
    id: string;
    avatarUrl: string | null;
  } | null;
};

export type InterestedUsersEdgesFieldsFragment = {
  __typename?: 'UserEdge';
  node: {
    __typename?: 'User';
    id: string;
    fullName: string | null;
    profile: {
      __typename?: 'Profile';
      id: string;
      avatarUrl: string | null;
    } | null;
  } | null;
};

export type CreateLiveEventMutationVariables = Exact<{
  input: CreateLiveEventInput;
}>;

export type CreateLiveEventMutation = {
  __typename?: 'Mutation';
  createLiveEvent: {
    __typename?: 'CreateLiveEventPayload';
    userErrors: Array<{
      __typename?: 'UserError';
      code: string | null;
      message: string | null;
    }> | null;
    liveEvent: { __typename?: 'LiveEvent'; id: string } | null;
  } | null;
};

export type DuplicateLiveEventMutationVariables = Exact<{
  input: DuplicateLiveEventInput;
}>;

export type DuplicateLiveEventMutation = {
  __typename?: 'Mutation';
  duplicateLiveEvent: {
    __typename?: 'DuplicateLiveEventPayload';
    userErrors: Array<{
      __typename?: 'UserError';
      code: string | null;
      message: string | null;
    }> | null;
    liveEvent: { __typename?: 'LiveEvent'; id: string } | null;
  } | null;
};

export type UpdateLiveEventMutationVariables = Exact<{
  input: UpdateLiveEventInput;
}>;

export type UpdateLiveEventMutation = {
  __typename?: 'Mutation';
  updateLiveEvent: {
    __typename?: 'UpdateLiveEventPayload';
    liveEvent: { __typename?: 'LiveEvent'; id: string } | null;
    userErrors: Array<{
      __typename?: 'UserError';
      code: string | null;
      message: string | null;
    }> | null;
  } | null;
};

export type DeleteLiveEventMutationVariables = Exact<{
  input: DeleteLiveEventInput;
}>;

export type DeleteLiveEventMutation = {
  __typename?: 'Mutation';
  deleteLiveEvent: {
    __typename?: 'DeleteLiveEventPayload';
    userErrors: Array<{
      __typename?: 'UserError';
      code: string | null;
      message: string | null;
    }> | null;
  } | null;
};

export type CreateAttendanceMutationVariables = Exact<{
  input: CreateAttendanceInput;
}>;

export type CreateAttendanceMutation = {
  __typename?: 'Mutation';
  createAttendance: {
    __typename?: 'CreateAttendancePayload';
    userErrors: Array<{
      __typename?: 'UserError';
      code: string | null;
      message: string | null;
    }> | null;
    liveEvent: {
      __typename?: 'LiveEvent';
      id: string;
      isInterested: boolean | null;
      interestedUsers: {
        __typename?: 'UserConnection';
        totalCount: number;
      } | null;
    } | null;
  } | null;
};

export type DeleteAttendanceMutationVariables = Exact<{
  input: DeleteAttendanceInput;
}>;

export type DeleteAttendanceMutation = {
  __typename?: 'Mutation';
  deleteAttendance: {
    __typename?: 'DeleteAttendancePayload';
    userErrors: Array<{
      __typename?: 'UserError';
      code: string | null;
      message: string | null;
    }> | null;
    liveEvent: {
      __typename?: 'LiveEvent';
      id: string;
      isInterested: boolean | null;
      interestedUsers: {
        __typename?: 'UserConnection';
        totalCount: number;
      } | null;
    } | null;
  } | null;
};

export type LiveEventsQueryVariables = Exact<{
  eventStatus?: InputMaybe<EventStatusTypes>;
  eventSourceId: Scalars['ID']['input'];
  numberOfEvents?: InputMaybe<Scalars['Int']['input']>;
  startDateTime?: InputMaybe<Scalars['DateTime']['input']>;
  endDateTime?: InputMaybe<Scalars['DateTime']['input']>;
  sortDirection?: InputMaybe<OrderDirection>;
}>;

export type LiveEventsQuery = {
  __typename?: 'Query';
  site: {
    __typename?: 'Tenant';
    id: string;
    liveEvents: {
      __typename?: 'LiveEventConnection';
      totalCount: number;
      pageInfo: {
        __typename?: 'PageInfo';
        hasNextPage: boolean;
        endCursor: string | null;
      };
      edges: Array<{
        __typename?: 'LiveEventEdge';
        node: {
          __typename?: 'LiveEvent';
          isInterested: boolean | null;
          id: string;
          title: string | null;
          description: string | null;
          imageUrl: string | null;
          imageAltText: string | null;
          startTime: any | null;
          endTime: any | null;
          timezone: string | null;
          meetingUrl: any | null;
          location: string | null;
          interestedUsers: {
            __typename?: 'UserConnection';
            totalCount: number;
          } | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type EventInterestedUsersFieldsFragment = {
  __typename?: 'LiveEvent';
  isInterested: boolean | null;
  interestedUsers: {
    __typename?: 'UserConnection';
    totalCount: number;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor: string | null;
      hasNextPage: boolean;
    };
    edges: Array<{
      __typename?: 'UserEdge';
      node: {
        __typename?: 'User';
        id: string;
        fullName: string | null;
        profile: {
          __typename?: 'Profile';
          id: string;
          avatarUrl: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type LiveEventQueryVariables = Exact<{
  eventId: Scalars['ID']['input'];
  numberOfUsers?: InputMaybe<Scalars['Int']['input']>;
  endCursor?: InputMaybe<Scalars['String']['input']>;
}>;

export type LiveEventQuery = {
  __typename?: 'Query';
  site: {
    __typename?: 'Tenant';
    id: string;
    liveEvent: {
      __typename?: 'LiveEvent';
      id: string;
      title: string | null;
      description: string | null;
      imageUrl: string | null;
      imageAltText: string | null;
      startTime: any | null;
      endTime: any | null;
      timezone: string | null;
      meetingUrl: any | null;
      location: string | null;
      isInterested: boolean | null;
      interestedUsers: {
        __typename?: 'UserConnection';
        totalCount: number;
        pageInfo: {
          __typename?: 'PageInfo';
          endCursor: string | null;
          hasNextPage: boolean;
        };
        edges: Array<{
          __typename?: 'UserEdge';
          node: {
            __typename?: 'User';
            id: string;
            fullName: string | null;
            profile: {
              __typename?: 'Profile';
              id: string;
              avatarUrl: string | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
    };
  } | null;
};

export type LiveEventsCountQueryVariables = Exact<{
  eventSourceId: Scalars['ID']['input'];
}>;

export type LiveEventsCountQuery = {
  __typename?: 'Query';
  site: {
    __typename?: 'Tenant';
    id: string;
    liveEvents: {
      __typename?: 'LiveEventConnection';
      totalCount: number;
    } | null;
  } | null;
};

export type CreatePostMutationVariables = Exact<{
  discussionableId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
  content?: InputMaybe<Scalars['String']['input']>;
  spaceId?: InputMaybe<Scalars['ID']['input']>;
  postAttachment?: InputMaybe<PostAttachmentInput>;
  mentionedUserIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
}>;

export type CreatePostMutation = {
  __typename?: 'Mutation';
  createPost: {
    __typename?: 'CreatePostPayload';
    post: { __typename?: 'Post'; id: string } | null;
    userErrors: Array<{
      __typename?: 'UserError';
      code: string | null;
      message: string | null;
    }> | null;
  } | null;
};

export type EditPostMutationVariables = Exact<{
  input: EditPostInput;
  numberOfReactions: Scalars['Int']['input'];
}>;

export type EditPostMutation = {
  __typename?: 'Mutation';
  editPost: {
    __typename?: 'EditPostPayload';
    userErrors: Array<{
      __typename?: 'UserError';
      code: string | null;
      message: string | null;
    }> | null;
    post: {
      __typename?: 'Post';
      id: string;
      title: string | null;
      content: string | null;
      createdAt: any | null;
      pinnedAt: any | null;
      editedAt: any | null;
      isFollowing: boolean;
      hasReacted: boolean;
      communitySpace: {
        __typename?: 'CommunitySpace';
        id: string;
        name: string | null;
        iconName: string | null;
      } | null;
      postAttachments: Array<{
        __typename?: 'PostAttachment';
        attachmentType: PostAttachmentType | null;
        fileName: string | null;
        fileSizeInBytes: number | null;
        id: string;
        url: string | null;
        altText: string | null;
      }> | null;
      replies: {
        __typename?: 'ReplyConnection';
        totalCount: number;
        nodes: Array<{
          __typename?: 'Reply';
          id: string;
          replies: {
            __typename?: 'ReplyConnection';
            totalCount: number;
            nodes: Array<{ __typename?: 'Reply'; id: string } | null> | null;
          };
        } | null> | null;
      };
      mentionedUsers: {
        __typename?: 'UserConnection';
        edges: Array<{
          __typename?: 'UserEdge';
          node: {
            __typename?: 'User';
            id: string;
            fullName: string | null;
            avatarUrl: string | null;
          } | null;
        } | null> | null;
      } | null;
      postReactions: {
        __typename?: 'PostReactionConnection';
        totalCount: number;
        edges: Array<{
          __typename?: 'PostReactionEdge';
          node: {
            __typename?: 'PostReaction';
            user: {
              __typename?: 'User';
              id: string;
              fullName: string | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
      user: {
        __typename?: 'User';
        id: string;
        fullName: string | null;
        firstName: string | null;
        avatarUrl: string | null;
        roles: Array<string | null> | null;
      } | null;
    } | null;
  } | null;
};

export type DeletePostMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeletePostMutation = {
  __typename?: 'Mutation';
  deletePost: {
    __typename?: 'DeletePostPayload';
    userErrors: Array<{
      __typename?: 'UserError';
      code: string | null;
      message: string | null;
    }> | null;
  } | null;
};

export type FollowPostMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  numberOfReactions: Scalars['Int']['input'];
}>;

export type FollowPostMutation = {
  __typename?: 'Mutation';
  followPost: {
    __typename?: 'FollowPostPayload';
    userErrors: Array<{
      __typename?: 'UserError';
      code: string | null;
      message: string | null;
    }> | null;
    post: {
      __typename?: 'Post';
      id: string;
      title: string | null;
      content: string | null;
      createdAt: any | null;
      pinnedAt: any | null;
      editedAt: any | null;
      isFollowing: boolean;
      hasReacted: boolean;
      communitySpace: {
        __typename?: 'CommunitySpace';
        id: string;
        name: string | null;
        iconName: string | null;
      } | null;
      postAttachments: Array<{
        __typename?: 'PostAttachment';
        attachmentType: PostAttachmentType | null;
        fileName: string | null;
        fileSizeInBytes: number | null;
        id: string;
        url: string | null;
        altText: string | null;
      }> | null;
      replies: {
        __typename?: 'ReplyConnection';
        totalCount: number;
        nodes: Array<{
          __typename?: 'Reply';
          id: string;
          replies: {
            __typename?: 'ReplyConnection';
            totalCount: number;
            nodes: Array<{ __typename?: 'Reply'; id: string } | null> | null;
          };
        } | null> | null;
      };
      mentionedUsers: {
        __typename?: 'UserConnection';
        edges: Array<{
          __typename?: 'UserEdge';
          node: {
            __typename?: 'User';
            id: string;
            fullName: string | null;
            avatarUrl: string | null;
          } | null;
        } | null> | null;
      } | null;
      postReactions: {
        __typename?: 'PostReactionConnection';
        totalCount: number;
        edges: Array<{
          __typename?: 'PostReactionEdge';
          node: {
            __typename?: 'PostReaction';
            user: {
              __typename?: 'User';
              id: string;
              fullName: string | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
      user: {
        __typename?: 'User';
        id: string;
        fullName: string | null;
        firstName: string | null;
        avatarUrl: string | null;
        roles: Array<string | null> | null;
      } | null;
    } | null;
  } | null;
};

export type UnfollowPostMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  numberOfReactions: Scalars['Int']['input'];
}>;

export type UnfollowPostMutation = {
  __typename?: 'Mutation';
  unfollowPost: {
    __typename?: 'UnfollowPostPayload';
    userErrors: Array<{
      __typename?: 'UserError';
      code: string | null;
      message: string | null;
    }> | null;
    post: {
      __typename?: 'Post';
      id: string;
      title: string | null;
      content: string | null;
      createdAt: any | null;
      pinnedAt: any | null;
      editedAt: any | null;
      isFollowing: boolean;
      hasReacted: boolean;
      communitySpace: {
        __typename?: 'CommunitySpace';
        id: string;
        name: string | null;
        iconName: string | null;
      } | null;
      postAttachments: Array<{
        __typename?: 'PostAttachment';
        attachmentType: PostAttachmentType | null;
        fileName: string | null;
        fileSizeInBytes: number | null;
        id: string;
        url: string | null;
        altText: string | null;
      }> | null;
      replies: {
        __typename?: 'ReplyConnection';
        totalCount: number;
        nodes: Array<{
          __typename?: 'Reply';
          id: string;
          replies: {
            __typename?: 'ReplyConnection';
            totalCount: number;
            nodes: Array<{ __typename?: 'Reply'; id: string } | null> | null;
          };
        } | null> | null;
      };
      mentionedUsers: {
        __typename?: 'UserConnection';
        edges: Array<{
          __typename?: 'UserEdge';
          node: {
            __typename?: 'User';
            id: string;
            fullName: string | null;
            avatarUrl: string | null;
          } | null;
        } | null> | null;
      } | null;
      postReactions: {
        __typename?: 'PostReactionConnection';
        totalCount: number;
        edges: Array<{
          __typename?: 'PostReactionEdge';
          node: {
            __typename?: 'PostReaction';
            user: {
              __typename?: 'User';
              id: string;
              fullName: string | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
      user: {
        __typename?: 'User';
        id: string;
        fullName: string | null;
        firstName: string | null;
        avatarUrl: string | null;
        roles: Array<string | null> | null;
      } | null;
    } | null;
  } | null;
};

export type PinPostMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  numberOfReactions: Scalars['Int']['input'];
}>;

export type PinPostMutation = {
  __typename?: 'Mutation';
  pinPost: {
    __typename?: 'PinPostPayload';
    userErrors: Array<{
      __typename?: 'UserError';
      code: string | null;
      message: string | null;
    }> | null;
    post: {
      __typename?: 'Post';
      id: string;
      title: string | null;
      content: string | null;
      createdAt: any | null;
      pinnedAt: any | null;
      editedAt: any | null;
      isFollowing: boolean;
      hasReacted: boolean;
      communitySpace: {
        __typename?: 'CommunitySpace';
        id: string;
        name: string | null;
        iconName: string | null;
      } | null;
      postAttachments: Array<{
        __typename?: 'PostAttachment';
        attachmentType: PostAttachmentType | null;
        fileName: string | null;
        fileSizeInBytes: number | null;
        id: string;
        url: string | null;
        altText: string | null;
      }> | null;
      replies: {
        __typename?: 'ReplyConnection';
        totalCount: number;
        nodes: Array<{
          __typename?: 'Reply';
          id: string;
          replies: {
            __typename?: 'ReplyConnection';
            totalCount: number;
            nodes: Array<{ __typename?: 'Reply'; id: string } | null> | null;
          };
        } | null> | null;
      };
      mentionedUsers: {
        __typename?: 'UserConnection';
        edges: Array<{
          __typename?: 'UserEdge';
          node: {
            __typename?: 'User';
            id: string;
            fullName: string | null;
            avatarUrl: string | null;
          } | null;
        } | null> | null;
      } | null;
      postReactions: {
        __typename?: 'PostReactionConnection';
        totalCount: number;
        edges: Array<{
          __typename?: 'PostReactionEdge';
          node: {
            __typename?: 'PostReaction';
            user: {
              __typename?: 'User';
              id: string;
              fullName: string | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
      user: {
        __typename?: 'User';
        id: string;
        fullName: string | null;
        firstName: string | null;
        avatarUrl: string | null;
        roles: Array<string | null> | null;
      } | null;
    } | null;
  } | null;
};

export type UnpinPostMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  numberOfReactions: Scalars['Int']['input'];
}>;

export type UnpinPostMutation = {
  __typename?: 'Mutation';
  unpinPost: {
    __typename?: 'UnpinPostPayload';
    userErrors: Array<{
      __typename?: 'UserError';
      code: string | null;
      message: string | null;
    }> | null;
    post: {
      __typename?: 'Post';
      id: string;
      title: string | null;
      content: string | null;
      createdAt: any | null;
      pinnedAt: any | null;
      editedAt: any | null;
      isFollowing: boolean;
      hasReacted: boolean;
      communitySpace: {
        __typename?: 'CommunitySpace';
        id: string;
        name: string | null;
        iconName: string | null;
      } | null;
      postAttachments: Array<{
        __typename?: 'PostAttachment';
        attachmentType: PostAttachmentType | null;
        fileName: string | null;
        fileSizeInBytes: number | null;
        id: string;
        url: string | null;
        altText: string | null;
      }> | null;
      replies: {
        __typename?: 'ReplyConnection';
        totalCount: number;
        nodes: Array<{
          __typename?: 'Reply';
          id: string;
          replies: {
            __typename?: 'ReplyConnection';
            totalCount: number;
            nodes: Array<{ __typename?: 'Reply'; id: string } | null> | null;
          };
        } | null> | null;
      };
      mentionedUsers: {
        __typename?: 'UserConnection';
        edges: Array<{
          __typename?: 'UserEdge';
          node: {
            __typename?: 'User';
            id: string;
            fullName: string | null;
            avatarUrl: string | null;
          } | null;
        } | null> | null;
      } | null;
      postReactions: {
        __typename?: 'PostReactionConnection';
        totalCount: number;
        edges: Array<{
          __typename?: 'PostReactionEdge';
          node: {
            __typename?: 'PostReaction';
            user: {
              __typename?: 'User';
              id: string;
              fullName: string | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
      user: {
        __typename?: 'User';
        id: string;
        fullName: string | null;
        firstName: string | null;
        avatarUrl: string | null;
        roles: Array<string | null> | null;
      } | null;
    } | null;
  } | null;
};

export type ToggleReactionOnPostMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  reactionType: PostReactionType;
  numberOfReactions: Scalars['Int']['input'];
}>;

export type ToggleReactionOnPostMutation = {
  __typename?: 'Mutation';
  toggleReactionOnPost: {
    __typename?: 'ToggleReactionOnPostPayload';
    userErrors: Array<{
      __typename?: 'UserError';
      code: string | null;
      message: string | null;
    }> | null;
    post: {
      __typename?: 'Post';
      id: string;
      title: string | null;
      content: string | null;
      createdAt: any | null;
      pinnedAt: any | null;
      editedAt: any | null;
      isFollowing: boolean;
      hasReacted: boolean;
      communitySpace: {
        __typename?: 'CommunitySpace';
        id: string;
        name: string | null;
        iconName: string | null;
      } | null;
      postAttachments: Array<{
        __typename?: 'PostAttachment';
        attachmentType: PostAttachmentType | null;
        fileName: string | null;
        fileSizeInBytes: number | null;
        id: string;
        url: string | null;
        altText: string | null;
      }> | null;
      replies: {
        __typename?: 'ReplyConnection';
        totalCount: number;
        nodes: Array<{
          __typename?: 'Reply';
          id: string;
          replies: {
            __typename?: 'ReplyConnection';
            totalCount: number;
            nodes: Array<{ __typename?: 'Reply'; id: string } | null> | null;
          };
        } | null> | null;
      };
      mentionedUsers: {
        __typename?: 'UserConnection';
        edges: Array<{
          __typename?: 'UserEdge';
          node: {
            __typename?: 'User';
            id: string;
            fullName: string | null;
            avatarUrl: string | null;
          } | null;
        } | null> | null;
      } | null;
      postReactions: {
        __typename?: 'PostReactionConnection';
        totalCount: number;
        edges: Array<{
          __typename?: 'PostReactionEdge';
          node: {
            __typename?: 'PostReaction';
            user: {
              __typename?: 'User';
              id: string;
              fullName: string | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
      user: {
        __typename?: 'User';
        id: string;
        fullName: string | null;
        firstName: string | null;
        avatarUrl: string | null;
        roles: Array<string | null> | null;
      } | null;
    } | null;
  } | null;
};

export type PostListQueryVariables = Exact<{
  communityId: Scalars['ID']['input'];
  numOfPosts?: InputMaybe<Scalars['Int']['input']>;
  endCursor?: InputMaybe<Scalars['String']['input']>;
  postOrderField?: InputMaybe<PostOrderField>;
  filter?: InputMaybe<PostFilter>;
  numberOfReactions: Scalars['Int']['input'];
}>;

export type PostListQuery = {
  __typename?: 'Query';
  community: {
    __typename?: 'Community';
    id: string;
    posts: {
      __typename?: 'PostConnection';
      edges: Array<{
        __typename?: 'PostEdge';
        cursor: string;
        node: {
          __typename?: 'Post';
          id: string;
          title: string | null;
          content: string | null;
          createdAt: any | null;
          pinnedAt: any | null;
          editedAt: any | null;
          isFollowing: boolean;
          hasReacted: boolean;
          communitySpace: {
            __typename?: 'CommunitySpace';
            id: string;
            name: string | null;
            iconName: string | null;
          } | null;
          postAttachments: Array<{
            __typename?: 'PostAttachment';
            attachmentType: PostAttachmentType | null;
            fileName: string | null;
            fileSizeInBytes: number | null;
            id: string;
            url: string | null;
            altText: string | null;
          }> | null;
          replies: {
            __typename?: 'ReplyConnection';
            totalCount: number;
            nodes: Array<{
              __typename?: 'Reply';
              id: string;
              replies: {
                __typename?: 'ReplyConnection';
                totalCount: number;
                nodes: Array<{
                  __typename?: 'Reply';
                  id: string;
                } | null> | null;
              };
            } | null> | null;
          };
          mentionedUsers: {
            __typename?: 'UserConnection';
            edges: Array<{
              __typename?: 'UserEdge';
              node: {
                __typename?: 'User';
                id: string;
                fullName: string | null;
                avatarUrl: string | null;
              } | null;
            } | null> | null;
          } | null;
          postReactions: {
            __typename?: 'PostReactionConnection';
            totalCount: number;
            edges: Array<{
              __typename?: 'PostReactionEdge';
              node: {
                __typename?: 'PostReaction';
                user: {
                  __typename?: 'User';
                  id: string;
                  fullName: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
          user: {
            __typename?: 'User';
            id: string;
            fullName: string | null;
            firstName: string | null;
            avatarUrl: string | null;
            roles: Array<string | null> | null;
          } | null;
        } | null;
      } | null> | null;
      pageInfo: {
        __typename?: 'PageInfo';
        hasPreviousPage: boolean;
        hasNextPage: boolean;
        startCursor: string | null;
        endCursor: string | null;
      };
    } | null;
  } | null;
};

export type PostQueryVariables = Exact<{
  communityId: Scalars['ID']['input'];
  postId: Scalars['ID']['input'];
  numberOfReactions: Scalars['Int']['input'];
}>;

export type PostQuery = {
  __typename?: 'Query';
  community: {
    __typename?: 'Community';
    id: string;
    post: {
      __typename?: 'Post';
      id: string;
      title: string | null;
      content: string | null;
      createdAt: any | null;
      pinnedAt: any | null;
      editedAt: any | null;
      isFollowing: boolean;
      hasReacted: boolean;
      communitySpace: {
        __typename?: 'CommunitySpace';
        id: string;
        name: string | null;
        iconName: string | null;
      } | null;
      postAttachments: Array<{
        __typename?: 'PostAttachment';
        attachmentType: PostAttachmentType | null;
        fileName: string | null;
        fileSizeInBytes: number | null;
        id: string;
        url: string | null;
        altText: string | null;
      }> | null;
      replies: {
        __typename?: 'ReplyConnection';
        totalCount: number;
        nodes: Array<{
          __typename?: 'Reply';
          id: string;
          replies: {
            __typename?: 'ReplyConnection';
            totalCount: number;
            nodes: Array<{ __typename?: 'Reply'; id: string } | null> | null;
          };
        } | null> | null;
      };
      mentionedUsers: {
        __typename?: 'UserConnection';
        edges: Array<{
          __typename?: 'UserEdge';
          node: {
            __typename?: 'User';
            id: string;
            fullName: string | null;
            avatarUrl: string | null;
          } | null;
        } | null> | null;
      } | null;
      postReactions: {
        __typename?: 'PostReactionConnection';
        totalCount: number;
        edges: Array<{
          __typename?: 'PostReactionEdge';
          node: {
            __typename?: 'PostReaction';
            user: {
              __typename?: 'User';
              id: string;
              fullName: string | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
      user: {
        __typename?: 'User';
        id: string;
        fullName: string | null;
        firstName: string | null;
        avatarUrl: string | null;
        roles: Array<string | null> | null;
      } | null;
    } | null;
  } | null;
};

export type ProductsEdgeFieldsFragment = {
  __typename?: 'ProductEdge';
  node: {
    __typename?: 'Product';
    id: string;
    publishedStatus: string | null;
    productable:
      | { __typename: 'Bundle' }
      | { __typename: 'Community' }
      | {
          __typename: 'Course';
          id: string;
          name: string | null;
          description: string | null;
          cardImageUrl: string | null;
          enrollmentsCount?: number | null;
          resumeCourseUrl: string | null;
          percentageCompleted: number | null;
          isPrivate?: boolean | null;
          isHidden?: boolean | null;
          status?: string | null;
        }
      | null;
    communityProductRelation: {
      __typename?: 'CommunityProductRelation';
      id: string;
      relation: {
        __typename: 'CommunitySpace';
        id: string;
        name: string | null;
        community: {
          __typename?: 'Community';
          id: string;
          name: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type ProductsFieldsFragment = {
  __typename?: 'ProductConnection';
  edges: Array<{
    __typename?: 'ProductEdge';
    node: {
      __typename?: 'Product';
      id: string;
      publishedStatus: string | null;
      productable:
        | { __typename: 'Bundle' }
        | { __typename: 'Community' }
        | {
            __typename: 'Course';
            id: string;
            name: string | null;
            description: string | null;
            cardImageUrl: string | null;
            enrollmentsCount?: number | null;
            resumeCourseUrl: string | null;
            percentageCompleted: number | null;
            isPrivate?: boolean | null;
            isHidden?: boolean | null;
            status?: string | null;
          }
        | null;
      communityProductRelation: {
        __typename?: 'CommunityProductRelation';
        id: string;
        relation: {
          __typename: 'CommunitySpace';
          id: string;
          name: string | null;
          community: {
            __typename?: 'Community';
            id: string;
            name: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
};

export type CourseFieldsFragment = {
  __typename?: 'Course';
  id: string;
  name: string | null;
  description: string | null;
  cardImageUrl: string | null;
  enrollmentsCount?: number | null;
  resumeCourseUrl: string | null;
  percentageCompleted: number | null;
  isPrivate?: boolean | null;
  isHidden?: boolean | null;
  status?: string | null;
};

export type CommunityProductRelationsQueryVariables = Exact<{
  communityId: Scalars['ID']['input'];
  relatableId: Scalars['ID']['input'];
  isModerator: Scalars['Boolean']['input'];
  relatableType?: InputMaybe<Scalars['String']['input']>;
}>;

export type CommunityProductRelationsQuery = {
  __typename?: 'Query';
  community: {
    __typename?: 'Community';
    id: string;
    communityProductRelations: {
      __typename?: 'CommunityProductRelationConnection';
      edges: Array<{
        __typename?: 'CommunityProductRelationEdge';
        cursor: string;
        node: {
          __typename?: 'CommunityProductRelation';
          id: string;
          product: {
            __typename?: 'Product';
            id: string;
            slug: string | null;
            productable:
              | { __typename: 'Bundle' }
              | { __typename: 'Community' }
              | {
                  __typename: 'Course';
                  id: string;
                  name: string | null;
                  description: string | null;
                  cardImageUrl: string | null;
                  enrollmentsCount?: number | null;
                  resumeCourseUrl: string | null;
                  percentageCompleted: number | null;
                  isPrivate?: boolean | null;
                  isHidden?: boolean | null;
                  status?: string | null;
                }
              | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type ProductsQueryVariables = Exact<{
  isModerator: Scalars['Boolean']['input'];
}>;

export type ProductsQuery = {
  __typename?: 'Query';
  site: {
    __typename?: 'Tenant';
    id: string;
    products: {
      __typename?: 'ProductConnection';
      edges: Array<{
        __typename?: 'ProductEdge';
        node: {
          __typename?: 'Product';
          id: string;
          publishedStatus: string | null;
          productable:
            | { __typename: 'Bundle' }
            | { __typename: 'Community' }
            | {
                __typename: 'Course';
                id: string;
                name: string | null;
                description: string | null;
                cardImageUrl: string | null;
                enrollmentsCount?: number | null;
                resumeCourseUrl: string | null;
                percentageCompleted: number | null;
                isPrivate?: boolean | null;
                isHidden?: boolean | null;
                status?: string | null;
              }
            | null;
          communityProductRelation: {
            __typename?: 'CommunityProductRelation';
            id: string;
            relation: {
              __typename: 'CommunitySpace';
              id: string;
              name: string | null;
              community: {
                __typename?: 'Community';
                id: string;
                name: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type CreateReplyMutationVariables = Exact<{
  discussionableId: Scalars['ID']['input'];
  content?: InputMaybe<Scalars['String']['input']>;
  parentPostId?: InputMaybe<Scalars['ID']['input']>;
  parentReplyId?: InputMaybe<Scalars['ID']['input']>;
  postAttachmentInput?: InputMaybe<PostAttachmentInput>;
  mentionedUserIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
  numberOfReactions: Scalars['Int']['input'];
}>;

export type CreateReplyMutation = {
  __typename?: 'Mutation';
  createReply: {
    __typename?: 'CreateReplyPayload';
    userErrors: Array<{
      __typename?: 'UserError';
      code: string | null;
      message: string | null;
    }> | null;
    reply: {
      __typename?: 'Reply';
      content: string | null;
      createdAt: any | null;
      editedAt: any | null;
      isFollowing: boolean;
      id: string;
      hasReacted: boolean;
      postReactions: {
        __typename?: 'PostReactionConnection';
        totalCount: number;
        edges: Array<{
          __typename?: 'PostReactionEdge';
          node: {
            __typename?: 'PostReaction';
            user: {
              __typename?: 'User';
              id: string;
              fullName: string | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
      mentionedUsers: {
        __typename?: 'UserConnection';
        edges: Array<{
          __typename?: 'UserEdge';
          node: {
            __typename?: 'User';
            id: string;
            fullName: string | null;
            avatarUrl: string | null;
          } | null;
        } | null> | null;
      } | null;
      user: {
        __typename?: 'User';
        id: string;
        fullName: string | null;
        firstName: string | null;
        avatarUrl: string | null;
        roles: Array<string | null> | null;
      } | null;
      postAttachments: Array<{
        __typename?: 'PostAttachment';
        id: string;
        altText: string | null;
        attachmentType: PostAttachmentType | null;
        fileName: string | null;
        fileSizeInBytes: number | null;
        url: string | null;
      }> | null;
    } | null;
  } | null;
};

export type EditReplyMutationVariables = Exact<{
  input: EditReplyInput;
  numberOfReactions: Scalars['Int']['input'];
}>;

export type EditReplyMutation = {
  __typename?: 'Mutation';
  editReply: {
    __typename?: 'EditReplyPayload';
    userErrors: Array<{
      __typename?: 'UserError';
      code: string | null;
      message: string | null;
    }> | null;
    reply: {
      __typename?: 'Reply';
      content: string | null;
      createdAt: any | null;
      editedAt: any | null;
      isFollowing: boolean;
      id: string;
      hasReacted: boolean;
      postReactions: {
        __typename?: 'PostReactionConnection';
        totalCount: number;
        edges: Array<{
          __typename?: 'PostReactionEdge';
          node: {
            __typename?: 'PostReaction';
            user: {
              __typename?: 'User';
              id: string;
              fullName: string | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
      mentionedUsers: {
        __typename?: 'UserConnection';
        edges: Array<{
          __typename?: 'UserEdge';
          node: {
            __typename?: 'User';
            id: string;
            fullName: string | null;
            avatarUrl: string | null;
          } | null;
        } | null> | null;
      } | null;
      user: {
        __typename?: 'User';
        id: string;
        fullName: string | null;
        firstName: string | null;
        avatarUrl: string | null;
        roles: Array<string | null> | null;
      } | null;
      postAttachments: Array<{
        __typename?: 'PostAttachment';
        id: string;
        altText: string | null;
        attachmentType: PostAttachmentType | null;
        fileName: string | null;
        fileSizeInBytes: number | null;
        url: string | null;
      }> | null;
    } | null;
  } | null;
};

export type DeleteReplyMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteReplyMutation = {
  __typename?: 'Mutation';
  deleteReply: {
    __typename?: 'DeleteReplyPayload';
    userErrors: Array<{
      __typename?: 'UserError';
      code: string | null;
      message: string | null;
    }> | null;
  } | null;
};

export type ToggleReactionOnReplyMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  reactionType: PostReactionType;
  numberOfReactions: Scalars['Int']['input'];
}>;

export type ToggleReactionOnReplyMutation = {
  __typename?: 'Mutation';
  toggleReactionOnReply: {
    __typename?: 'ToggleReactionOnReplyPayload';
    userErrors: Array<{
      __typename?: 'UserError';
      code: string | null;
      message: string | null;
    }> | null;
    reply: {
      __typename?: 'Reply';
      content: string | null;
      createdAt: any | null;
      editedAt: any | null;
      isFollowing: boolean;
      id: string;
      hasReacted: boolean;
      postReactions: {
        __typename?: 'PostReactionConnection';
        totalCount: number;
        edges: Array<{
          __typename?: 'PostReactionEdge';
          node: {
            __typename?: 'PostReaction';
            user: {
              __typename?: 'User';
              id: string;
              fullName: string | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
      mentionedUsers: {
        __typename?: 'UserConnection';
        edges: Array<{
          __typename?: 'UserEdge';
          node: {
            __typename?: 'User';
            id: string;
            fullName: string | null;
            avatarUrl: string | null;
          } | null;
        } | null> | null;
      } | null;
      user: {
        __typename?: 'User';
        id: string;
        fullName: string | null;
        firstName: string | null;
        avatarUrl: string | null;
        roles: Array<string | null> | null;
      } | null;
      postAttachments: Array<{
        __typename?: 'PostAttachment';
        id: string;
        altText: string | null;
        attachmentType: PostAttachmentType | null;
        fileName: string | null;
        fileSizeInBytes: number | null;
        url: string | null;
      }> | null;
    } | null;
  } | null;
};

export type FollowReplyMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  numberOfReactions: Scalars['Int']['input'];
}>;

export type FollowReplyMutation = {
  __typename?: 'Mutation';
  followReply: {
    __typename?: 'FollowReplyPayload';
    userErrors: Array<{
      __typename?: 'UserError';
      code: string | null;
      message: string | null;
    }> | null;
    reply: {
      __typename?: 'Reply';
      content: string | null;
      createdAt: any | null;
      editedAt: any | null;
      isFollowing: boolean;
      id: string;
      hasReacted: boolean;
      postReactions: {
        __typename?: 'PostReactionConnection';
        totalCount: number;
        edges: Array<{
          __typename?: 'PostReactionEdge';
          node: {
            __typename?: 'PostReaction';
            user: {
              __typename?: 'User';
              id: string;
              fullName: string | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
      mentionedUsers: {
        __typename?: 'UserConnection';
        edges: Array<{
          __typename?: 'UserEdge';
          node: {
            __typename?: 'User';
            id: string;
            fullName: string | null;
            avatarUrl: string | null;
          } | null;
        } | null> | null;
      } | null;
      user: {
        __typename?: 'User';
        id: string;
        fullName: string | null;
        firstName: string | null;
        avatarUrl: string | null;
        roles: Array<string | null> | null;
      } | null;
      postAttachments: Array<{
        __typename?: 'PostAttachment';
        id: string;
        altText: string | null;
        attachmentType: PostAttachmentType | null;
        fileName: string | null;
        fileSizeInBytes: number | null;
        url: string | null;
      }> | null;
    } | null;
  } | null;
};

export type UnfollowReplyMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  numberOfReactions: Scalars['Int']['input'];
}>;

export type UnfollowReplyMutation = {
  __typename?: 'Mutation';
  unfollowReply: {
    __typename?: 'UnfollowReplyPayload';
    userErrors: Array<{
      __typename?: 'UserError';
      code: string | null;
      message: string | null;
    }> | null;
    reply: {
      __typename?: 'Reply';
      content: string | null;
      createdAt: any | null;
      editedAt: any | null;
      isFollowing: boolean;
      id: string;
      hasReacted: boolean;
      postReactions: {
        __typename?: 'PostReactionConnection';
        totalCount: number;
        edges: Array<{
          __typename?: 'PostReactionEdge';
          node: {
            __typename?: 'PostReaction';
            user: {
              __typename?: 'User';
              id: string;
              fullName: string | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
      mentionedUsers: {
        __typename?: 'UserConnection';
        edges: Array<{
          __typename?: 'UserEdge';
          node: {
            __typename?: 'User';
            id: string;
            fullName: string | null;
            avatarUrl: string | null;
          } | null;
        } | null> | null;
      } | null;
      user: {
        __typename?: 'User';
        id: string;
        fullName: string | null;
        firstName: string | null;
        avatarUrl: string | null;
        roles: Array<string | null> | null;
      } | null;
      postAttachments: Array<{
        __typename?: 'PostAttachment';
        id: string;
        altText: string | null;
        attachmentType: PostAttachmentType | null;
        fileName: string | null;
        fileSizeInBytes: number | null;
        url: string | null;
      }> | null;
    } | null;
  } | null;
};

export type ReplyQueryVariables = Exact<{
  communityId: Scalars['ID']['input'];
  postId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  startCursor?: InputMaybe<Scalars['String']['input']>;
  numOfNestedReplies?: InputMaybe<Scalars['Int']['input']>;
  numberOfReactions: Scalars['Int']['input'];
}>;

export type ReplyQuery = {
  __typename?: 'Query';
  community: {
    __typename?: 'Community';
    id: string;
    post: {
      __typename?: 'Post';
      id: string;
      title: string | null;
      content: string | null;
      createdAt: any | null;
      pinnedAt: any | null;
      editedAt: any | null;
      isFollowing: boolean;
      hasReacted: boolean;
      communitySpace: {
        __typename?: 'CommunitySpace';
        id: string;
        name: string | null;
        iconName: string | null;
      } | null;
      postAttachments: Array<{
        __typename?: 'PostAttachment';
        attachmentType: PostAttachmentType | null;
        fileName: string | null;
        fileSizeInBytes: number | null;
        id: string;
        url: string | null;
        altText: string | null;
      }> | null;
      replies: {
        __typename?: 'ReplyConnection';
        totalCount: number;
        nodes: Array<{
          __typename?: 'Reply';
          id: string;
          replies: {
            __typename?: 'ReplyConnection';
            totalCount: number;
            nodes: Array<{ __typename?: 'Reply'; id: string } | null> | null;
          };
        } | null> | null;
      };
      mentionedUsers: {
        __typename?: 'UserConnection';
        edges: Array<{
          __typename?: 'UserEdge';
          node: {
            __typename?: 'User';
            id: string;
            fullName: string | null;
            avatarUrl: string | null;
          } | null;
        } | null> | null;
      } | null;
      postReactions: {
        __typename?: 'PostReactionConnection';
        totalCount: number;
        edges: Array<{
          __typename?: 'PostReactionEdge';
          node: {
            __typename?: 'PostReaction';
            user: {
              __typename?: 'User';
              id: string;
              fullName: string | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
      user: {
        __typename?: 'User';
        id: string;
        fullName: string | null;
        firstName: string | null;
        avatarUrl: string | null;
        roles: Array<string | null> | null;
      } | null;
    } | null;
    reply: {
      __typename?: 'Reply';
      content: string | null;
      createdAt: any | null;
      editedAt: any | null;
      isFollowing: boolean;
      id: string;
      hasReacted: boolean;
      replies: {
        __typename?: 'ReplyConnection';
        totalCount: number;
        edges: Array<{
          __typename?: 'ReplyEdge';
          cursor: string;
          node: {
            __typename?: 'Reply';
            content: string | null;
            createdAt: any | null;
            editedAt: any | null;
            isFollowing: boolean;
            id: string;
            hasReacted: boolean;
            postReactions: {
              __typename?: 'PostReactionConnection';
              totalCount: number;
              edges: Array<{
                __typename?: 'PostReactionEdge';
                node: {
                  __typename?: 'PostReaction';
                  user: {
                    __typename?: 'User';
                    id: string;
                    fullName: string | null;
                  } | null;
                } | null;
              } | null> | null;
            } | null;
            mentionedUsers: {
              __typename?: 'UserConnection';
              edges: Array<{
                __typename?: 'UserEdge';
                node: {
                  __typename?: 'User';
                  id: string;
                  fullName: string | null;
                  avatarUrl: string | null;
                } | null;
              } | null> | null;
            } | null;
            user: {
              __typename?: 'User';
              id: string;
              fullName: string | null;
              firstName: string | null;
              avatarUrl: string | null;
              roles: Array<string | null> | null;
            } | null;
            postAttachments: Array<{
              __typename?: 'PostAttachment';
              id: string;
              altText: string | null;
              attachmentType: PostAttachmentType | null;
              fileName: string | null;
              fileSizeInBytes: number | null;
              url: string | null;
            }> | null;
          } | null;
        } | null> | null;
        pageInfo: {
          __typename?: 'PageInfo';
          hasPreviousPage: boolean;
          hasNextPage: boolean;
          startCursor: string | null;
          endCursor: string | null;
        };
      };
      postReactions: {
        __typename?: 'PostReactionConnection';
        totalCount: number;
        edges: Array<{
          __typename?: 'PostReactionEdge';
          node: {
            __typename?: 'PostReaction';
            user: {
              __typename?: 'User';
              id: string;
              fullName: string | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
      mentionedUsers: {
        __typename?: 'UserConnection';
        edges: Array<{
          __typename?: 'UserEdge';
          node: {
            __typename?: 'User';
            id: string;
            fullName: string | null;
            avatarUrl: string | null;
          } | null;
        } | null> | null;
      } | null;
      user: {
        __typename?: 'User';
        id: string;
        fullName: string | null;
        firstName: string | null;
        avatarUrl: string | null;
        roles: Array<string | null> | null;
      } | null;
      postAttachments: Array<{
        __typename?: 'PostAttachment';
        id: string;
        altText: string | null;
        attachmentType: PostAttachmentType | null;
        fileName: string | null;
        fileSizeInBytes: number | null;
        url: string | null;
      }> | null;
    } | null;
  } | null;
};

export type RepliesQueryVariables = Exact<{
  communityId: Scalars['ID']['input'];
  postId: Scalars['ID']['input'];
  numOfReplies?: InputMaybe<Scalars['Int']['input']>;
  numOfNestedReplies?: InputMaybe<Scalars['Int']['input']>;
  startCursor?: InputMaybe<Scalars['String']['input']>;
  numberOfReactions: Scalars['Int']['input'];
}>;

export type RepliesQuery = {
  __typename?: 'Query';
  community: {
    __typename?: 'Community';
    id: string;
    post: {
      __typename?: 'Post';
      id: string;
      replies: {
        __typename?: 'ReplyConnection';
        totalCount: number;
        edges: Array<{
          __typename?: 'ReplyEdge';
          cursor: string;
          node: {
            __typename?: 'Reply';
            content: string | null;
            createdAt: any | null;
            editedAt: any | null;
            isFollowing: boolean;
            id: string;
            hasReacted: boolean;
            replies: {
              __typename?: 'ReplyConnection';
              totalCount: number;
              edges: Array<{
                __typename?: 'ReplyEdge';
                cursor: string;
                node: {
                  __typename?: 'Reply';
                  content: string | null;
                  createdAt: any | null;
                  editedAt: any | null;
                  isFollowing: boolean;
                  id: string;
                  hasReacted: boolean;
                  postReactions: {
                    __typename?: 'PostReactionConnection';
                    totalCount: number;
                    edges: Array<{
                      __typename?: 'PostReactionEdge';
                      node: {
                        __typename?: 'PostReaction';
                        user: {
                          __typename?: 'User';
                          id: string;
                          fullName: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                  mentionedUsers: {
                    __typename?: 'UserConnection';
                    edges: Array<{
                      __typename?: 'UserEdge';
                      node: {
                        __typename?: 'User';
                        id: string;
                        fullName: string | null;
                        avatarUrl: string | null;
                      } | null;
                    } | null> | null;
                  } | null;
                  user: {
                    __typename?: 'User';
                    id: string;
                    fullName: string | null;
                    firstName: string | null;
                    avatarUrl: string | null;
                    roles: Array<string | null> | null;
                  } | null;
                  postAttachments: Array<{
                    __typename?: 'PostAttachment';
                    id: string;
                    altText: string | null;
                    attachmentType: PostAttachmentType | null;
                    fileName: string | null;
                    fileSizeInBytes: number | null;
                    url: string | null;
                  }> | null;
                } | null;
              } | null> | null;
              pageInfo: {
                __typename?: 'PageInfo';
                hasPreviousPage: boolean;
                hasNextPage: boolean;
                startCursor: string | null;
                endCursor: string | null;
              };
            };
            postReactions: {
              __typename?: 'PostReactionConnection';
              totalCount: number;
              edges: Array<{
                __typename?: 'PostReactionEdge';
                node: {
                  __typename?: 'PostReaction';
                  user: {
                    __typename?: 'User';
                    id: string;
                    fullName: string | null;
                  } | null;
                } | null;
              } | null> | null;
            } | null;
            mentionedUsers: {
              __typename?: 'UserConnection';
              edges: Array<{
                __typename?: 'UserEdge';
                node: {
                  __typename?: 'User';
                  id: string;
                  fullName: string | null;
                  avatarUrl: string | null;
                } | null;
              } | null> | null;
            } | null;
            user: {
              __typename?: 'User';
              id: string;
              fullName: string | null;
              firstName: string | null;
              avatarUrl: string | null;
              roles: Array<string | null> | null;
            } | null;
            postAttachments: Array<{
              __typename?: 'PostAttachment';
              id: string;
              altText: string | null;
              attachmentType: PostAttachmentType | null;
              fileName: string | null;
              fileSizeInBytes: number | null;
              url: string | null;
            }> | null;
          } | null;
        } | null> | null;
        pageInfo: {
          __typename?: 'PageInfo';
          hasPreviousPage: boolean;
          hasNextPage: boolean;
          startCursor: string | null;
          endCursor: string | null;
        };
      };
    } | null;
  } | null;
};

export type NestedRepliesQueryVariables = Exact<{
  communityId: Scalars['ID']['input'];
  parentReplyId: Scalars['ID']['input'];
  numOfReplies?: InputMaybe<Scalars['Int']['input']>;
  startCursor?: InputMaybe<Scalars['String']['input']>;
  numberOfReactions: Scalars['Int']['input'];
}>;

export type NestedRepliesQuery = {
  __typename?: 'Query';
  community: {
    __typename?: 'Community';
    id: string;
    reply: {
      __typename?: 'Reply';
      id: string;
      replies: {
        __typename?: 'ReplyConnection';
        totalCount: number;
        edges: Array<{
          __typename?: 'ReplyEdge';
          cursor: string;
          node: {
            __typename?: 'Reply';
            content: string | null;
            createdAt: any | null;
            editedAt: any | null;
            isFollowing: boolean;
            id: string;
            hasReacted: boolean;
            postReactions: {
              __typename?: 'PostReactionConnection';
              totalCount: number;
              edges: Array<{
                __typename?: 'PostReactionEdge';
                node: {
                  __typename?: 'PostReaction';
                  user: {
                    __typename?: 'User';
                    id: string;
                    fullName: string | null;
                  } | null;
                } | null;
              } | null> | null;
            } | null;
            mentionedUsers: {
              __typename?: 'UserConnection';
              edges: Array<{
                __typename?: 'UserEdge';
                node: {
                  __typename?: 'User';
                  id: string;
                  fullName: string | null;
                  avatarUrl: string | null;
                } | null;
              } | null> | null;
            } | null;
            user: {
              __typename?: 'User';
              id: string;
              fullName: string | null;
              firstName: string | null;
              avatarUrl: string | null;
              roles: Array<string | null> | null;
            } | null;
            postAttachments: Array<{
              __typename?: 'PostAttachment';
              id: string;
              altText: string | null;
              attachmentType: PostAttachmentType | null;
              fileName: string | null;
              fileSizeInBytes: number | null;
              url: string | null;
            }> | null;
          } | null;
        } | null> | null;
        pageInfo: {
          __typename?: 'PageInfo';
          hasPreviousPage: boolean;
          hasNextPage: boolean;
          startCursor: string | null;
          endCursor: string | null;
        };
      };
    } | null;
  } | null;
};

export type ReplyDepthQueryVariables = Exact<{
  communityId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}>;

export type ReplyDepthQuery = {
  __typename?: 'Query';
  community: {
    __typename?: 'Community';
    id: string;
    reply: {
      __typename?: 'Reply';
      id: string;
      depth: number | null;
      parentPost: { __typename?: 'Post'; id: string } | null;
    } | null;
  } | null;
};

export type PostSearchQueryVariables = Exact<{
  communityId: Scalars['ID']['input'];
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  searchAuthor?: InputMaybe<Scalars['String']['input']>;
}>;

export type PostSearchQuery = {
  __typename?: 'Query';
  community: {
    __typename?: 'Community';
    id: string;
    name: string | null;
    searchableCommunityPosts: {
      __typename?: 'SearchablePostConnection';
      edges: Array<{
        __typename?: 'SearchablePostEdge';
        cursor: string;
        node: {
          __typename?: 'SearchablePost';
          id: string;
          depth: number | null;
          title: string | null;
          content: string | null;
          createdAt: any | null;
          editedAt: any | null;
          rootPost: {
            __typename?: 'Post';
            id: string;
            title: string | null;
          } | null;
          communitySpace: {
            __typename?: 'CommunitySpace';
            id: string;
            name: string | null;
            iconName: string | null;
          } | null;
          postAttachments: Array<{
            __typename?: 'PostAttachment';
            attachmentType: PostAttachmentType | null;
            fileName: string | null;
            fileSizeInBytes: number | null;
            id: string;
            url: string | null;
            altText: string | null;
          }> | null;
          mentionedUsers: {
            __typename?: 'UserConnection';
            edges: Array<{
              __typename?: 'UserEdge';
              node: {
                __typename?: 'User';
                id: string;
                fullName: string | null;
                avatarUrl: string | null;
              } | null;
            } | null> | null;
          } | null;
          user: {
            __typename?: 'User';
            id: string;
            fullName: string | null;
            firstName: string | null;
            avatarUrl: string | null;
            roles: Array<string | null> | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type SectionFieldsFragment = {
  __typename?: 'CommunitySection';
  id: string;
  name: string | null;
  communitySpaces: {
    __typename?: 'CommunitySpaceConnection';
    totalCount: number;
    edges: Array<{
      __typename?: 'CommunitySpaceEdge';
      node: {
        __typename?: 'CommunitySpace';
        id: string;
        name: string | null;
        description: string | null;
        iconName: string | null;
        position: number | null;
        imageUrl: string | null;
        imageAltText: string | null;
        s3UploadPath: string | null;
        isExclusive: boolean | null;
        posts: {
          __typename?: 'PostConnection';
          totalCount: number;
          edges: Array<{
            __typename?: 'PostEdge';
            node: { __typename?: 'Post'; id: string } | null;
          } | null> | null;
        } | null;
        communityProductRelation?: {
          __typename?: 'CommunityProductRelation';
          id: string;
          product: { __typename?: 'Product'; id: string } | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type SectionListQueryVariables = Exact<{
  communityId: Scalars['ID']['input'];
  isCoursesToCommunitiesEnabled: Scalars['Boolean']['input'];
}>;

export type SectionListQuery = {
  __typename?: 'Query';
  community: {
    __typename?: 'Community';
    id: string;
    communitySections: {
      __typename?: 'CommunitySectionConnection';
      edges: Array<{
        __typename?: 'CommunitySectionEdge';
        node: {
          __typename?: 'CommunitySection';
          id: string;
          name: string | null;
          communitySpaces: {
            __typename?: 'CommunitySpaceConnection';
            totalCount: number;
            edges: Array<{
              __typename?: 'CommunitySpaceEdge';
              node: {
                __typename?: 'CommunitySpace';
                id: string;
                name: string | null;
                description: string | null;
                iconName: string | null;
                position: number | null;
                imageUrl: string | null;
                imageAltText: string | null;
                s3UploadPath: string | null;
                isExclusive: boolean | null;
                posts: {
                  __typename?: 'PostConnection';
                  totalCount: number;
                  edges: Array<{
                    __typename?: 'PostEdge';
                    node: { __typename?: 'Post'; id: string } | null;
                  } | null> | null;
                } | null;
                communityProductRelation?: {
                  __typename?: 'CommunityProductRelation';
                  id: string;
                  product: { __typename?: 'Product'; id: string } | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type SiteFeaturesQueryVariables = Exact<{
  communityId: Scalars['ID']['input'];
}>;

export type SiteFeaturesQuery = {
  __typename?: 'Query';
  site: {
    __typename?: 'Tenant';
    id: string;
    features: {
      __typename?: 'Features';
      communities: {
        __typename?: 'CommunitiesFeature';
        maxCommunitySpaces: number;
      } | null;
      communityEvents: {
        __typename?: 'Feature';
        isEnabled: boolean | null;
      } | null;
      communityEventNotifications: {
        __typename?: 'Feature';
        isEnabled: boolean | null;
      } | null;
    } | null;
  } | null;
  community: {
    __typename?: 'Community';
    id: string;
    communitySections: {
      __typename?: 'CommunitySectionConnection';
      edges: Array<{
        __typename?: 'CommunitySectionEdge';
        node: {
          __typename?: 'CommunitySection';
          id: string;
          communitySpaces: {
            __typename?: 'CommunitySpaceConnection';
            totalCount: number;
          } | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type CreateCommunitySpaceMutationVariables = Exact<{
  input: CreateCommunitySpaceInput;
}>;

export type CreateCommunitySpaceMutation = {
  __typename?: 'Mutation';
  createCommunitySpace: {
    __typename?: 'CreateCommunitySpacePayload';
    userErrors: Array<{
      __typename?: 'UserError';
      code: string | null;
      message: string | null;
    }> | null;
    communitySpace: { __typename?: 'CommunitySpace'; id: string } | null;
  } | null;
};

export type UpdateCommunitySpaceMutationVariables = Exact<{
  input: UpdateCommunitySpaceInput;
}>;

export type UpdateCommunitySpaceMutation = {
  __typename?: 'Mutation';
  updateCommunitySpace: {
    __typename?: 'UpdateCommunitySpacePayload';
    userErrors: Array<{
      __typename?: 'UserError';
      code: string | null;
      message: string | null;
    }> | null;
    communitySpace: { __typename?: 'CommunitySpace'; id: string } | null;
  } | null;
};

export type DeleteCommunitySpaceMutationVariables = Exact<{
  input: DeleteCommunitySpaceInput;
}>;

export type DeleteCommunitySpaceMutation = {
  __typename?: 'Mutation';
  deleteCommunitySpace: {
    __typename?: 'DeleteCommunitySpacePayload';
    userErrors: Array<{
      __typename?: 'UserError';
      code: string | null;
      message: string | null;
    }> | null;
  } | null;
};

export type ReorderCommunitySpaceMutationVariables = Exact<{
  input: ReorderCommunitySpaceInput;
}>;

export type ReorderCommunitySpaceMutation = {
  __typename?: 'Mutation';
  reorderCommunitySpace: {
    __typename?: 'ReorderCommunitySpacePayload';
    userErrors: Array<{
      __typename?: 'UserError';
      code: string | null;
      message: string | null;
    }> | null;
  } | null;
};

export type SpaceFieldsFragment = {
  __typename?: 'CommunitySpace';
  id: string;
  name: string | null;
  description: string | null;
  iconName: string | null;
  position: number | null;
  imageUrl: string | null;
  imageAltText: string | null;
  s3UploadPath: string | null;
  isExclusive: boolean | null;
  posts: {
    __typename?: 'PostConnection';
    totalCount: number;
    edges: Array<{
      __typename?: 'PostEdge';
      node: { __typename?: 'Post'; id: string } | null;
    } | null> | null;
  } | null;
  communityProductRelation?: {
    __typename?: 'CommunityProductRelation';
    id: string;
    product: { __typename?: 'Product'; id: string } | null;
  } | null;
};

export type CommunitySpaceQueryVariables = Exact<{
  communityId: Scalars['ID']['input'];
  spaceId: Scalars['ID']['input'];
  isCoursesToCommunitiesEnabled: Scalars['Boolean']['input'];
}>;

export type CommunitySpaceQuery = {
  __typename?: 'Query';
  community: {
    __typename?: 'Community';
    id: string;
    communitySpace: {
      __typename?: 'CommunitySpace';
      id: string;
      name: string | null;
      description: string | null;
      iconName: string | null;
      position: number | null;
      imageUrl: string | null;
      imageAltText: string | null;
      s3UploadPath: string | null;
      isExclusive: boolean | null;
      posts: {
        __typename?: 'PostConnection';
        totalCount: number;
        edges: Array<{
          __typename?: 'PostEdge';
          node: { __typename?: 'Post'; id: string } | null;
        } | null> | null;
      } | null;
      communityProductRelation?: {
        __typename?: 'CommunityProductRelation';
        id: string;
        product: { __typename?: 'Product'; id: string } | null;
      } | null;
    } | null;
  } | null;
};

export type CommunityEdgesNodeUserFieldsFragment = {
  __typename?: 'User';
  id: string;
  fullName: string | null;
  roles: Array<string | null> | null;
  profile: {
    __typename?: 'Profile';
    id: string;
    avatarUrl: string | null;
  } | null;
};

export type CommunityEdgesNodeFieldsFragment = {
  __typename?: 'CommunityUser';
  id: string;
  user: {
    __typename?: 'User';
    id: string;
    fullName: string | null;
    roles: Array<string | null> | null;
    profile: {
      __typename?: 'Profile';
      id: string;
      avatarUrl: string | null;
    } | null;
  } | null;
};

export type CommunityEdgesFieldsFragment = {
  __typename?: 'CommunityUserEdge';
  node: {
    __typename?: 'CommunityUser';
    id: string;
    user: {
      __typename?: 'User';
      id: string;
      fullName: string | null;
      roles: Array<string | null> | null;
      profile: {
        __typename?: 'Profile';
        id: string;
        avatarUrl: string | null;
      } | null;
    } | null;
  } | null;
};

export type UserEnrollmentFieldsFragment = {
  __typename?: 'EnrollmentConnection';
  pageInfo: {
    __typename?: 'PageInfo';
    hasNextPage: boolean;
    endCursor: string | null;
  };
  edges: Array<{
    __typename?: 'EnrollmentEdge';
    cursor: string;
    node: {
      __typename?: 'Enrollment';
      id: string;
      completedAt: any | null;
      course: {
        __typename?: 'Course';
        id: string;
        name: string | null;
        cardImageUrl: string | null;
        resumeCourseUrl: string | null;
        landingPageUrl: string | null;
      } | null;
    } | null;
  } | null> | null;
};

export type UsersQueryVariables = Exact<{
  communityId: Scalars['ID']['input'];
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  numberOfUsers?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type UsersQuery = {
  __typename?: 'Query';
  community: {
    __typename?: 'Community';
    id: string;
    communityUsers: {
      __typename?: 'CommunityUserConnection';
      totalCount: number;
      pageInfo: {
        __typename?: 'PageInfo';
        endCursor: string | null;
        startCursor: string | null;
      };
      edges: Array<{
        __typename?: 'CommunityUserEdge';
        node: {
          __typename?: 'CommunityUser';
          id: string;
          user: {
            __typename?: 'User';
            id: string;
            fullName: string | null;
            roles: Array<string | null> | null;
            profile: {
              __typename?: 'Profile';
              id: string;
              avatarUrl: string | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
    mentionableUsers: {
      __typename?: 'CommunityUserConnection';
      edges: Array<{
        __typename?: 'CommunityUserEdge';
        node: {
          __typename?: 'CommunityUser';
          id: string;
          user: {
            __typename?: 'User';
            id: string;
            fullName: string | null;
            profile: {
              __typename?: 'Profile';
              id: string;
              avatarUrl: string | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type UserQueryVariables = Exact<{
  communityId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
  numOfCourses?: InputMaybe<Scalars['Int']['input']>;
  endCursor?: InputMaybe<Scalars['String']['input']>;
}>;

export type UserQuery = {
  __typename?: 'Query';
  community: {
    __typename?: 'Community';
    id: string;
    communityUser: {
      __typename?: 'CommunityUser';
      id: string;
      user: {
        __typename?: 'User';
        id: string;
        fullName: string | null;
        firstName: string | null;
        roles: Array<string | null> | null;
        profile: {
          __typename?: 'Profile';
          id: string;
          avatarUrl: string | null;
          company: string | null;
          headline: string | null;
          timezone: string | null;
        } | null;
        enrollments: {
          __typename?: 'EnrollmentConnection';
          pageInfo: {
            __typename?: 'PageInfo';
            hasNextPage: boolean;
            endCursor: string | null;
          };
          edges: Array<{
            __typename?: 'EnrollmentEdge';
            cursor: string;
            node: {
              __typename?: 'Enrollment';
              id: string;
              completedAt: any | null;
              course: {
                __typename?: 'Course';
                id: string;
                name: string | null;
                cardImageUrl: string | null;
                resumeCourseUrl: string | null;
                landingPageUrl: string | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type UserFieldsFragment = {
  __typename?: 'User';
  id: string;
  fullName: string | null;
  firstName: string | null;
  avatarUrl: string | null;
  roles: Array<string | null> | null;
};

export type MentionedUsersFieldFragment = {
  __typename?: 'UserConnection';
  edges: Array<{
    __typename?: 'UserEdge';
    node: {
      __typename?: 'User';
      id: string;
      fullName: string | null;
      avatarUrl: string | null;
    } | null;
  } | null> | null;
};

export type PostAttachmentsFieldsFragment = {
  __typename?: 'PostAttachment';
  attachmentType: PostAttachmentType | null;
  fileName: string | null;
  fileSizeInBytes: number | null;
  id: string;
  url: string | null;
  altText: string | null;
};

export type PostFieldsFragment = {
  __typename?: 'Post';
  id: string;
  title: string | null;
  content: string | null;
  createdAt: any | null;
  pinnedAt: any | null;
  editedAt: any | null;
  isFollowing: boolean;
  hasReacted: boolean;
  communitySpace: {
    __typename?: 'CommunitySpace';
    id: string;
    name: string | null;
    iconName: string | null;
  } | null;
  postAttachments: Array<{
    __typename?: 'PostAttachment';
    attachmentType: PostAttachmentType | null;
    fileName: string | null;
    fileSizeInBytes: number | null;
    id: string;
    url: string | null;
    altText: string | null;
  }> | null;
  replies: {
    __typename?: 'ReplyConnection';
    totalCount: number;
    nodes: Array<{
      __typename?: 'Reply';
      id: string;
      replies: {
        __typename?: 'ReplyConnection';
        totalCount: number;
        nodes: Array<{ __typename?: 'Reply'; id: string } | null> | null;
      };
    } | null> | null;
  };
  mentionedUsers: {
    __typename?: 'UserConnection';
    edges: Array<{
      __typename?: 'UserEdge';
      node: {
        __typename?: 'User';
        id: string;
        fullName: string | null;
        avatarUrl: string | null;
      } | null;
    } | null> | null;
  } | null;
  postReactions: {
    __typename?: 'PostReactionConnection';
    totalCount: number;
    edges: Array<{
      __typename?: 'PostReactionEdge';
      node: {
        __typename?: 'PostReaction';
        user: {
          __typename?: 'User';
          id: string;
          fullName: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
  user: {
    __typename?: 'User';
    id: string;
    fullName: string | null;
    firstName: string | null;
    avatarUrl: string | null;
    roles: Array<string | null> | null;
  } | null;
};

export type ReplyFieldsFragment = {
  __typename?: 'Reply';
  content: string | null;
  createdAt: any | null;
  editedAt: any | null;
  isFollowing: boolean;
  id: string;
  hasReacted: boolean;
  postReactions: {
    __typename?: 'PostReactionConnection';
    totalCount: number;
    edges: Array<{
      __typename?: 'PostReactionEdge';
      node: {
        __typename?: 'PostReaction';
        user: {
          __typename?: 'User';
          id: string;
          fullName: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
  mentionedUsers: {
    __typename?: 'UserConnection';
    edges: Array<{
      __typename?: 'UserEdge';
      node: {
        __typename?: 'User';
        id: string;
        fullName: string | null;
        avatarUrl: string | null;
      } | null;
    } | null> | null;
  } | null;
  user: {
    __typename?: 'User';
    id: string;
    fullName: string | null;
    firstName: string | null;
    avatarUrl: string | null;
    roles: Array<string | null> | null;
  } | null;
  postAttachments: Array<{
    __typename?: 'PostAttachment';
    id: string;
    altText: string | null;
    attachmentType: PostAttachmentType | null;
    fileName: string | null;
    fileSizeInBytes: number | null;
    url: string | null;
  }> | null;
};

export type ReplyEdgeNodeReliesFieldsFragment = {
  __typename?: 'ReplyConnection';
  totalCount: number;
  edges: Array<{
    __typename?: 'ReplyEdge';
    cursor: string;
    node: {
      __typename?: 'Reply';
      content: string | null;
      createdAt: any | null;
      editedAt: any | null;
      isFollowing: boolean;
      id: string;
      hasReacted: boolean;
      postReactions: {
        __typename?: 'PostReactionConnection';
        totalCount: number;
        edges: Array<{
          __typename?: 'PostReactionEdge';
          node: {
            __typename?: 'PostReaction';
            user: {
              __typename?: 'User';
              id: string;
              fullName: string | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
      mentionedUsers: {
        __typename?: 'UserConnection';
        edges: Array<{
          __typename?: 'UserEdge';
          node: {
            __typename?: 'User';
            id: string;
            fullName: string | null;
            avatarUrl: string | null;
          } | null;
        } | null> | null;
      } | null;
      user: {
        __typename?: 'User';
        id: string;
        fullName: string | null;
        firstName: string | null;
        avatarUrl: string | null;
        roles: Array<string | null> | null;
      } | null;
      postAttachments: Array<{
        __typename?: 'PostAttachment';
        id: string;
        altText: string | null;
        attachmentType: PostAttachmentType | null;
        fileName: string | null;
        fileSizeInBytes: number | null;
        url: string | null;
      }> | null;
    } | null;
  } | null> | null;
  pageInfo: {
    __typename?: 'PageInfo';
    hasPreviousPage: boolean;
    hasNextPage: boolean;
    startCursor: string | null;
    endCursor: string | null;
  };
};

export type ReplyEdgeFieldsFragment = {
  __typename?: 'Reply';
  content: string | null;
  createdAt: any | null;
  editedAt: any | null;
  isFollowing: boolean;
  id: string;
  hasReacted: boolean;
  replies: {
    __typename?: 'ReplyConnection';
    totalCount: number;
    edges: Array<{
      __typename?: 'ReplyEdge';
      cursor: string;
      node: {
        __typename?: 'Reply';
        content: string | null;
        createdAt: any | null;
        editedAt: any | null;
        isFollowing: boolean;
        id: string;
        hasReacted: boolean;
        postReactions: {
          __typename?: 'PostReactionConnection';
          totalCount: number;
          edges: Array<{
            __typename?: 'PostReactionEdge';
            node: {
              __typename?: 'PostReaction';
              user: {
                __typename?: 'User';
                id: string;
                fullName: string | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
        mentionedUsers: {
          __typename?: 'UserConnection';
          edges: Array<{
            __typename?: 'UserEdge';
            node: {
              __typename?: 'User';
              id: string;
              fullName: string | null;
              avatarUrl: string | null;
            } | null;
          } | null> | null;
        } | null;
        user: {
          __typename?: 'User';
          id: string;
          fullName: string | null;
          firstName: string | null;
          avatarUrl: string | null;
          roles: Array<string | null> | null;
        } | null;
        postAttachments: Array<{
          __typename?: 'PostAttachment';
          id: string;
          altText: string | null;
          attachmentType: PostAttachmentType | null;
          fileName: string | null;
          fileSizeInBytes: number | null;
          url: string | null;
        }> | null;
      } | null;
    } | null> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      hasPreviousPage: boolean;
      hasNextPage: boolean;
      startCursor: string | null;
      endCursor: string | null;
    };
  };
  postReactions: {
    __typename?: 'PostReactionConnection';
    totalCount: number;
    edges: Array<{
      __typename?: 'PostReactionEdge';
      node: {
        __typename?: 'PostReaction';
        user: {
          __typename?: 'User';
          id: string;
          fullName: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
  mentionedUsers: {
    __typename?: 'UserConnection';
    edges: Array<{
      __typename?: 'UserEdge';
      node: {
        __typename?: 'User';
        id: string;
        fullName: string | null;
        avatarUrl: string | null;
      } | null;
    } | null> | null;
  } | null;
  user: {
    __typename?: 'User';
    id: string;
    fullName: string | null;
    firstName: string | null;
    avatarUrl: string | null;
    roles: Array<string | null> | null;
  } | null;
  postAttachments: Array<{
    __typename?: 'PostAttachment';
    id: string;
    altText: string | null;
    attachmentType: PostAttachmentType | null;
    fileName: string | null;
    fileSizeInBytes: number | null;
    url: string | null;
  }> | null;
};

export type MainQueryVariables = Exact<{
  communityId: Scalars['ID']['input'];
}>;

export type MainQuery = {
  __typename?: 'Query';
  site: {
    __typename?: 'Tenant';
    id: string;
    favicon: string | null;
    featureFlags: Array<any> | null;
    subdomain: string;
    trackingKey: string | null;
    whiteLabellingActivated: boolean | null;
    googleAnalyticsKey: string | null;
    appcuesDisabled: boolean | null;
    filestackPolicy: {
      __typename?: 'FilestackSecurity';
      policy: string | null;
      signature: string | null;
    } | null;
    integrations: {
      __typename?: 'Integrations';
      facebookPixelCode: string | null;
      gaMeasurementId: string | null;
    } | null;
  } | null;
  community: {
    __typename?: 'Community';
    id: string;
    name: string | null;
    description: string | null;
    primaryColor: string | null;
    font: string | null;
    imageUrl: string | null;
    s3UploadPath: string | null;
    published: boolean | null;
    status: string;
    communityUsers: {
      __typename?: 'CommunityUserConnection';
      totalCount: number;
    } | null;
    mentionableUsers: {
      __typename?: 'CommunityUserConnection';
      edges: Array<{
        __typename?: 'CommunityUserEdge';
        node: {
          __typename?: 'CommunityUser';
          id: string;
          user: {
            __typename?: 'User';
            id: string;
            fullName: string | null;
            profile: {
              __typename?: 'Profile';
              id: string;
              avatarUrl: string | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
  me: {
    __typename?: 'User';
    id: string;
    fullName: string | null;
    email: string | null;
    avatarUrl: string | null;
    roles: Array<string | null> | null;
    locale: string | null;
    profile: {
      __typename?: 'Profile';
      id: string;
      timezone: string | null;
    } | null;
  } | null;
};

export type AccountNotificationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AccountNotificationsQuery = {
  __typename?: 'Query';
  accountNotifications: Array<{
    __typename?: 'AccountNotification';
    isEnabled: boolean | null;
  }> | null;
};

export const EventFieldsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EventFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LiveEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageAltText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'meetingUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<EventFieldsFragment, unknown>;
export const LiveEventsEdgesNodeFieldsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LiveEventsEdgesNodeFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LiveEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interestedUsers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isInterested' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'EventFields' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EventFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LiveEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageAltText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'meetingUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<LiveEventsEdgesNodeFieldsFragment, unknown>;
export const LiveEventsEdgesFieldsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LiveEventsEdgesFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LiveEventEdge' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'node' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LiveEventsEdgesNodeFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EventFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LiveEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageAltText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'meetingUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LiveEventsEdgesNodeFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LiveEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interestedUsers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isInterested' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'EventFields' },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<LiveEventsEdgesFieldsFragment, unknown>;
export const InterestedUsersEdgesNodeFieldsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InterestedUsersEdgesNodeFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<InterestedUsersEdgesNodeFieldsFragment, unknown>;
export const InterestedUsersEdgesFieldsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InterestedUsersEdgesFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserEdge' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'node' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'InterestedUsersEdgesNodeFields',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InterestedUsersEdgesNodeFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<InterestedUsersEdgesFieldsFragment, unknown>;
export const EventInterestedUsersFieldsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EventInterestedUsersFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LiveEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'isInterested' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interestedUsers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'numberOfUsers' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endCursor' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'InterestedUsersEdgesFields',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InterestedUsersEdgesNodeFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InterestedUsersEdgesFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserEdge' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'node' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'InterestedUsersEdgesNodeFields',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<EventInterestedUsersFieldsFragment, unknown>;
export const CourseFieldsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CourseFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Course' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardImageUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enrollmentsCount' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'isModerator' },
                    },
                  },
                ],
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'resumeCourseUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageCompleted' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isPrivate' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'isModerator' },
                    },
                  },
                ],
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isHidden' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'isModerator' },
                    },
                  },
                ],
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'status' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'isModerator' },
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<CourseFieldsFragment, unknown>;
export const ProductsEdgeFieldsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductsEdgeFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductEdge' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'node' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'publishedStatus' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productable' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'Course' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'CourseFields' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'communityProductRelation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'relation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: '__typename' },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'CommunitySpace' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'community' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CourseFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Course' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardImageUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enrollmentsCount' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'isModerator' },
                    },
                  },
                ],
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'resumeCourseUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageCompleted' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isPrivate' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'isModerator' },
                    },
                  },
                ],
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isHidden' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'isModerator' },
                    },
                  },
                ],
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'status' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'isModerator' },
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<ProductsEdgeFieldsFragment, unknown>;
export const ProductsFieldsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductsFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'edges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProductsEdgeFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CourseFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Course' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardImageUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enrollmentsCount' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'isModerator' },
                    },
                  },
                ],
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'resumeCourseUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageCompleted' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isPrivate' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'isModerator' },
                    },
                  },
                ],
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isHidden' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'isModerator' },
                    },
                  },
                ],
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'status' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'isModerator' },
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductsEdgeFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductEdge' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'node' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'publishedStatus' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productable' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'Course' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'CourseFields' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'communityProductRelation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'relation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: '__typename' },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'CommunitySpace' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'community' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<ProductsFieldsFragment, unknown>;
export const SpaceFieldsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SpaceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CommunitySpace' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iconName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'position' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageAltText' } },
          { kind: 'Field', name: { kind: 'Name', value: 's3UploadPath' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isExclusive' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'posts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'communityProductRelation' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: {
                        kind: 'Name',
                        value: 'isCoursesToCommunitiesEnabled',
                      },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'product' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<SpaceFieldsFragment, unknown>;
export const SectionFieldsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SectionFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CommunitySection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'communitySpaces' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'communitySpaces',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'SpaceFields' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'posts' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'edges' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'node' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'totalCount' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SpaceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CommunitySpace' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iconName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'position' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageAltText' } },
          { kind: 'Field', name: { kind: 'Name', value: 's3UploadPath' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isExclusive' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'posts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'communityProductRelation' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: {
                        kind: 'Name',
                        value: 'isCoursesToCommunitiesEnabled',
                      },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'product' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<SectionFieldsFragment, unknown>;
export const CommunityEdgesNodeUserFieldsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CommunityEdgesNodeUserFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<CommunityEdgesNodeUserFieldsFragment, unknown>;
export const CommunityEdgesNodeFieldsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CommunityEdgesNodeFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CommunityUser' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CommunityEdgesNodeUserFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CommunityEdgesNodeUserFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<CommunityEdgesNodeFieldsFragment, unknown>;
export const CommunityEdgesFieldsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CommunityEdgesFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CommunityUserEdge' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'node' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CommunityEdgesNodeFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CommunityEdgesNodeUserFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CommunityEdgesNodeFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CommunityUser' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CommunityEdgesNodeUserFields' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<CommunityEdgesFieldsFragment, unknown>;
export const UserEnrollmentFieldsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserEnrollmentFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EnrollmentConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pageInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'edges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'completedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'course' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cardImageUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'resumeCourseUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'landingPageUrl' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<UserEnrollmentFieldsFragment, unknown>;
export const PostAttachmentsFieldsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PostAttachmentsFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PostAttachment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'attachmentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileSizeInBytes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'altText' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<PostAttachmentsFieldsFragment, unknown>;
export const MentionedUsersFieldFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentionedUsersField' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'edges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatarUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<MentionedUsersFieldFragment, unknown>;
export const UserFieldsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<UserFieldsFragment, unknown>;
export const PostFieldsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PostFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Post' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pinnedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFollowing' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasReacted' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'communitySpace' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postAttachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PostAttachmentsFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replies' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'replies',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'replies' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalCount' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentionedUsers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'mentionedUsers',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentionedUsersField' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postReactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'numberOfReactions' },
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'postReactions',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fullName' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PostAttachmentsFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PostAttachment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'attachmentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileSizeInBytes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'altText' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentionedUsersField' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'edges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatarUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<PostFieldsFragment, unknown>;
export const ReplyFieldsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReplyFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Reply' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFollowing' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postReactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'numberOfReactions' },
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'postReactions',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fullName' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentionedUsers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'mentionedUsers',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentionedUsersField' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'hasReacted' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postAttachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'altText' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attachmentType' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fileSizeInBytes' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentionedUsersField' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'edges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatarUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<ReplyFieldsFragment, unknown>;
export const ReplyEdgeNodeReliesFieldsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReplyEdgeNodeReliesFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReplyConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'edges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ReplyFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pageInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hasPreviousPage' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentionedUsersField' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'edges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatarUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReplyFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Reply' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFollowing' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postReactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'numberOfReactions' },
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'postReactions',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fullName' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentionedUsers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'mentionedUsers',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentionedUsersField' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'hasReacted' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postAttachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'altText' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attachmentType' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fileSizeInBytes' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<ReplyEdgeNodeReliesFieldsFragment, unknown>;
export const ReplyEdgeFieldsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReplyEdgeFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Reply' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ReplyFields' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'last' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'numOfNestedReplies' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'field' },
                      value: { kind: 'EnumValue', value: 'CREATED_AT' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'direction' },
                      value: { kind: 'EnumValue', value: 'ASC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReplyEdgeNodeReliesFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentionedUsersField' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'edges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatarUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReplyFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Reply' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFollowing' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postReactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'numberOfReactions' },
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'postReactions',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fullName' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentionedUsers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'mentionedUsers',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentionedUsersField' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'hasReacted' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postAttachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'altText' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attachmentType' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fileSizeInBytes' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReplyEdgeNodeReliesFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReplyConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'edges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ReplyFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pageInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hasPreviousPage' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<ReplyEdgeFieldsFragment, unknown>;
export const UpdateAccountNotificationDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAccountNotification' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateAccountNotificationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAccountNotification' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userErrors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateAccountNotificationMutation,
  UpdateAccountNotificationMutationVariables
>;
export const CreateLiveEventDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateLiveEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateLiveEventInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createLiveEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userErrors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'liveEvent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  CreateLiveEventMutation,
  CreateLiveEventMutationVariables
>;
export const DuplicateLiveEventDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DuplicateLiveEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DuplicateLiveEventInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duplicateLiveEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userErrors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'liveEvent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  DuplicateLiveEventMutation,
  DuplicateLiveEventMutationVariables
>;
export const UpdateLiveEventDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateLiveEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateLiveEventInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateLiveEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'liveEvent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userErrors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateLiveEventMutation,
  UpdateLiveEventMutationVariables
>;
export const DeleteLiveEventDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteLiveEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteLiveEventInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteLiveEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userErrors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  DeleteLiveEventMutation,
  DeleteLiveEventMutationVariables
>;
export const CreateAttendanceDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateAttendance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateAttendanceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAttendance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userErrors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'liveEvent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isInterested' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'interestedUsers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalCount' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  CreateAttendanceMutation,
  CreateAttendanceMutationVariables
>;
export const DeleteAttendanceDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteAttendance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteAttendanceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteAttendance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userErrors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'liveEvent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isInterested' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'interestedUsers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalCount' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  DeleteAttendanceMutation,
  DeleteAttendanceMutationVariables
>;
export const LiveEventsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LiveEvents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'eventStatus' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EventStatusTypes' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'eventSourceId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'numberOfEvents' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDateTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DateTime' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDateTime' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DateTime' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sortDirection' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'OrderDirection' },
          },
          defaultValue: { kind: 'EnumValue', value: 'ASC' },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'site' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'liveEvents' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'eventSourceType' },
                      value: { kind: 'EnumValue', value: 'Community' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'eventSourceId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'eventSourceId' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'numberOfEvents' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'orderBy' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'field' },
                            value: { kind: 'EnumValue', value: 'start_time' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'direction' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'sortDirection' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eventStatus' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'eventStatus' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'startTime' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'startDateTime' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'endTime' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'endDateTime' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pageInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hasNextPage' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endCursor' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'LiveEventsEdgesFields',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EventFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LiveEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageAltText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'meetingUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LiveEventsEdgesNodeFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LiveEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interestedUsers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isInterested' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'EventFields' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LiveEventsEdgesFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LiveEventEdge' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'node' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LiveEventsEdgesNodeFields' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<LiveEventsQuery, LiveEventsQueryVariables>;
export const LiveEventDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LiveEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'eventId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'numberOfUsers' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          defaultValue: { kind: 'IntValue', value: '5' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endCursor' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'site' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'liveEvent' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'eventId' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'EventFields' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'EventInterestedUsersFields',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InterestedUsersEdgesNodeFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InterestedUsersEdgesFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserEdge' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'node' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'InterestedUsersEdgesNodeFields',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EventFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LiveEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageAltText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'meetingUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EventInterestedUsersFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LiveEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'isInterested' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interestedUsers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'numberOfUsers' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endCursor' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'InterestedUsersEdgesFields',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<LiveEventQuery, LiveEventQueryVariables>;
export const LiveEventsCountDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LiveEventsCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'eventSourceId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'site' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'liveEvents' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'eventSourceType' },
                      value: { kind: 'EnumValue', value: 'Community' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'eventSourceId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'eventSourceId' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalCount' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  LiveEventsCountQuery,
  LiveEventsCountQueryVariables
>;
export const CreatePostDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePost' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'discussionableId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'title' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'content' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'spaceId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'postAttachment' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'PostAttachmentInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'mentionedUserIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPost' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'discussionableId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'discussionableId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'title' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'content' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'content' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'communitySpaceId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'spaceId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'postAttachmentInput' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'postAttachment' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'mentionedUserIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'mentionedUserIds' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'post' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userErrors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<CreatePostMutation, CreatePostMutationVariables>;
export const EditPostDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EditPost' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EditPostInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'numberOfReactions' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editPost' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userErrors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'post' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PostFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PostAttachmentsFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PostAttachment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'attachmentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileSizeInBytes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'altText' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentionedUsersField' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'edges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatarUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PostFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Post' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pinnedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFollowing' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasReacted' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'communitySpace' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postAttachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PostAttachmentsFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replies' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'replies',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'replies' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalCount' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentionedUsers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'mentionedUsers',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentionedUsersField' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postReactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'numberOfReactions' },
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'postReactions',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fullName' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserFields' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<EditPostMutation, EditPostMutationVariables>;
export const DeletePostDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeletePost' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePost' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userErrors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<DeletePostMutation, DeletePostMutationVariables>;
export const FollowPostDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FollowPost' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'numberOfReactions' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'followPost' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userErrors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'post' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PostFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PostAttachmentsFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PostAttachment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'attachmentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileSizeInBytes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'altText' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentionedUsersField' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'edges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatarUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PostFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Post' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pinnedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFollowing' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasReacted' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'communitySpace' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postAttachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PostAttachmentsFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replies' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'replies',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'replies' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalCount' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentionedUsers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'mentionedUsers',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentionedUsersField' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postReactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'numberOfReactions' },
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'postReactions',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fullName' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserFields' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<FollowPostMutation, FollowPostMutationVariables>;
export const UnfollowPostDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UnfollowPost' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'numberOfReactions' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unfollowPost' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userErrors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'post' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PostFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PostAttachmentsFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PostAttachment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'attachmentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileSizeInBytes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'altText' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentionedUsersField' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'edges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatarUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PostFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Post' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pinnedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFollowing' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasReacted' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'communitySpace' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postAttachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PostAttachmentsFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replies' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'replies',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'replies' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalCount' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentionedUsers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'mentionedUsers',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentionedUsersField' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postReactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'numberOfReactions' },
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'postReactions',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fullName' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserFields' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UnfollowPostMutation,
  UnfollowPostMutationVariables
>;
export const PinPostDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PinPost' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'numberOfReactions' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pinPost' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userErrors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'post' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PostFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PostAttachmentsFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PostAttachment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'attachmentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileSizeInBytes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'altText' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentionedUsersField' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'edges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatarUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PostFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Post' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pinnedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFollowing' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasReacted' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'communitySpace' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postAttachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PostAttachmentsFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replies' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'replies',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'replies' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalCount' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentionedUsers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'mentionedUsers',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentionedUsersField' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postReactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'numberOfReactions' },
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'postReactions',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fullName' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserFields' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<PinPostMutation, PinPostMutationVariables>;
export const UnpinPostDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UnpinPost' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'numberOfReactions' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unpinPost' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userErrors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'post' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PostFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PostAttachmentsFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PostAttachment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'attachmentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileSizeInBytes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'altText' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentionedUsersField' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'edges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatarUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PostFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Post' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pinnedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFollowing' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasReacted' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'communitySpace' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postAttachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PostAttachmentsFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replies' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'replies',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'replies' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalCount' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentionedUsers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'mentionedUsers',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentionedUsersField' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postReactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'numberOfReactions' },
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'postReactions',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fullName' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserFields' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<UnpinPostMutation, UnpinPostMutationVariables>;
export const ToggleReactionOnPostDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ToggleReactionOnPost' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reactionType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PostReactionType' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'numberOfReactions' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'toggleReactionOnPost' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'reactionType' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'reactionType' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userErrors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'post' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PostFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PostAttachmentsFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PostAttachment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'attachmentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileSizeInBytes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'altText' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentionedUsersField' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'edges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatarUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PostFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Post' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pinnedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFollowing' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasReacted' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'communitySpace' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postAttachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PostAttachmentsFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replies' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'replies',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'replies' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalCount' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentionedUsers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'mentionedUsers',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentionedUsersField' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postReactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'numberOfReactions' },
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'postReactions',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fullName' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserFields' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  ToggleReactionOnPostMutation,
  ToggleReactionOnPostMutationVariables
>;
export const PostListDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PostList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'communityId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'numOfPosts' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endCursor' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'postOrderField' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'PostOrderField' },
          },
          defaultValue: { kind: 'EnumValue', value: 'PINNED_AT' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'PostFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'numberOfReactions' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'community' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'communityId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'posts' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'numOfPosts' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'after' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filter' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'orderBy' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'field' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'postOrderField' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'direction' },
                            value: { kind: 'EnumValue', value: 'DESC' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'PostFields' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pageInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hasPreviousPage' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hasNextPage' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startCursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endCursor' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PostAttachmentsFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PostAttachment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'attachmentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileSizeInBytes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'altText' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentionedUsersField' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'edges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatarUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PostFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Post' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pinnedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFollowing' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasReacted' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'communitySpace' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postAttachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PostAttachmentsFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replies' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'replies',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'replies' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalCount' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentionedUsers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'mentionedUsers',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentionedUsersField' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postReactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'numberOfReactions' },
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'postReactions',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fullName' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserFields' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<PostListQuery, PostListQueryVariables>;
export const PostDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Post' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'communityId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'postId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'numberOfReactions' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'community' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'communityId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'post' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'postId' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PostFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PostAttachmentsFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PostAttachment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'attachmentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileSizeInBytes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'altText' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentionedUsersField' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'edges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatarUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PostFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Post' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pinnedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFollowing' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasReacted' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'communitySpace' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postAttachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PostAttachmentsFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replies' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'replies',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'replies' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalCount' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentionedUsers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'mentionedUsers',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentionedUsersField' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postReactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'numberOfReactions' },
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'postReactions',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fullName' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserFields' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<PostQuery, PostQueryVariables>;
export const CommunityProductRelationsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CommunityProductRelations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'communityId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'relatableId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isModerator' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'relatableType' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'community' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'communityId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'communityProductRelations' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'relatableId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'relatableId' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'relatableType' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'relatableType' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'product' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'slug' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'productable',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: '__typename',
                                                },
                                              },
                                              {
                                                kind: 'InlineFragment',
                                                typeCondition: {
                                                  kind: 'NamedType',
                                                  name: {
                                                    kind: 'Name',
                                                    value: 'Course',
                                                  },
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'FragmentSpread',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'CourseFields',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CourseFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Course' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardImageUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enrollmentsCount' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'isModerator' },
                    },
                  },
                ],
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'resumeCourseUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageCompleted' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isPrivate' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'isModerator' },
                    },
                  },
                ],
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isHidden' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'isModerator' },
                    },
                  },
                ],
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'status' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'isModerator' },
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  CommunityProductRelationsQuery,
  CommunityProductRelationsQueryVariables
>;
export const ProductsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Products' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isModerator' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'site' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'products' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'publicationStatus' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                { kind: 'EnumValue', value: 'published' },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'type' },
                            value: { kind: 'EnumValue', value: 'Course' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ProductsFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CourseFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Course' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardImageUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enrollmentsCount' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'isModerator' },
                    },
                  },
                ],
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'resumeCourseUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentageCompleted' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isPrivate' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'isModerator' },
                    },
                  },
                ],
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isHidden' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'isModerator' },
                    },
                  },
                ],
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'status' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'isModerator' },
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductsEdgeFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductEdge' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'node' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'publishedStatus' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productable' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'Course' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'CourseFields' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'communityProductRelation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'relation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: '__typename' },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'CommunitySpace' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'community' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductsFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'edges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProductsEdgeFields' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<ProductsQuery, ProductsQueryVariables>;
export const CreateReplyDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateReply' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'discussionableId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'content' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'parentPostId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'parentReplyId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'postAttachmentInput' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'PostAttachmentInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'mentionedUserIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'numberOfReactions' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createReply' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'discussionableId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'discussionableId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'content' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'content' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'parentPostId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'parentPostId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'parentReplyId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'parentReplyId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'postAttachmentInput' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'postAttachmentInput' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'mentionedUserIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'mentionedUserIds' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userErrors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reply' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ReplyFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentionedUsersField' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'edges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatarUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReplyFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Reply' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFollowing' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postReactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'numberOfReactions' },
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'postReactions',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fullName' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentionedUsers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'mentionedUsers',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentionedUsersField' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'hasReacted' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postAttachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'altText' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attachmentType' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fileSizeInBytes' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  CreateReplyMutation,
  CreateReplyMutationVariables
>;
export const EditReplyDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EditReply' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EditReplyInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'numberOfReactions' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editReply' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userErrors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reply' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ReplyFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentionedUsersField' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'edges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatarUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReplyFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Reply' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFollowing' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postReactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'numberOfReactions' },
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'postReactions',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fullName' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentionedUsers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'mentionedUsers',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentionedUsersField' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'hasReacted' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postAttachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'altText' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attachmentType' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fileSizeInBytes' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<EditReplyMutation, EditReplyMutationVariables>;
export const DeleteReplyDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteReply' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteReply' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userErrors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  DeleteReplyMutation,
  DeleteReplyMutationVariables
>;
export const ToggleReactionOnReplyDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ToggleReactionOnReply' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reactionType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PostReactionType' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'numberOfReactions' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'toggleReactionOnReply' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'reactionType' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'reactionType' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userErrors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reply' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ReplyFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentionedUsersField' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'edges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatarUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReplyFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Reply' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFollowing' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postReactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'numberOfReactions' },
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'postReactions',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fullName' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentionedUsers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'mentionedUsers',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentionedUsersField' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'hasReacted' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postAttachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'altText' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attachmentType' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fileSizeInBytes' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  ToggleReactionOnReplyMutation,
  ToggleReactionOnReplyMutationVariables
>;
export const FollowReplyDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FollowReply' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'numberOfReactions' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'followReply' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userErrors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reply' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ReplyFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentionedUsersField' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'edges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatarUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReplyFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Reply' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFollowing' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postReactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'numberOfReactions' },
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'postReactions',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fullName' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentionedUsers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'mentionedUsers',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentionedUsersField' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'hasReacted' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postAttachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'altText' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attachmentType' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fileSizeInBytes' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  FollowReplyMutation,
  FollowReplyMutationVariables
>;
export const UnfollowReplyDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UnfollowReply' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'numberOfReactions' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unfollowReply' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userErrors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reply' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ReplyFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentionedUsersField' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'edges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatarUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReplyFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Reply' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFollowing' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postReactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'numberOfReactions' },
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'postReactions',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fullName' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentionedUsers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'mentionedUsers',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentionedUsersField' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'hasReacted' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postAttachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'altText' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attachmentType' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fileSizeInBytes' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UnfollowReplyMutation,
  UnfollowReplyMutationVariables
>;
export const ReplyDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Reply' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'communityId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'postId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startCursor' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'numOfNestedReplies' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'numberOfReactions' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'community' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'communityId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'post' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'postId' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PostFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reply' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ReplyFields' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'replies' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'orderBy' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'field' },
                                  value: {
                                    kind: 'EnumValue',
                                    value: 'CREATED_AT',
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'direction' },
                                  value: { kind: 'EnumValue', value: 'ASC' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'before' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'startCursor' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'last' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'numOfNestedReplies',
                              },
                            },
                          },
                        ],
                        directives: [
                          {
                            kind: 'Directive',
                            name: { kind: 'Name', value: 'connection' },
                            arguments: [
                              {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'key' },
                                value: {
                                  kind: 'StringValue',
                                  value: 'replies',
                                  block: false,
                                },
                              },
                            ],
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'edges' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cursor' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'node' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'ReplyFields',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageInfo' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'hasPreviousPage',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'hasNextPage',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'startCursor',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'endCursor' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalCount' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PostAttachmentsFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PostAttachment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'attachmentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileSizeInBytes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'altText' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentionedUsersField' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'edges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatarUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PostFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Post' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pinnedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFollowing' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasReacted' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'communitySpace' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postAttachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PostAttachmentsFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replies' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'replies',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'replies' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalCount' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentionedUsers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'mentionedUsers',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentionedUsersField' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postReactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'numberOfReactions' },
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'postReactions',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fullName' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReplyFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Reply' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFollowing' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postReactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'numberOfReactions' },
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'postReactions',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fullName' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentionedUsers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'mentionedUsers',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentionedUsersField' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'hasReacted' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postAttachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'altText' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attachmentType' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fileSizeInBytes' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<ReplyQuery, ReplyQueryVariables>;
export const RepliesDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Replies' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'communityId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'postId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'numOfReplies' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'numOfNestedReplies' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startCursor' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'numberOfReactions' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'community' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'communityId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'post' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'postId' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'replies' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'last' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'numOfReplies' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'before' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'startCursor' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'orderBy' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'field' },
                                  value: {
                                    kind: 'EnumValue',
                                    value: 'CREATED_AT',
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'direction' },
                                  value: { kind: 'EnumValue', value: 'ASC' },
                                },
                              ],
                            },
                          },
                        ],
                        directives: [
                          {
                            kind: 'Directive',
                            name: { kind: 'Name', value: 'connection' },
                            arguments: [
                              {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'key' },
                                value: {
                                  kind: 'StringValue',
                                  value: 'replies',
                                  block: false,
                                },
                              },
                            ],
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'edges' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cursor' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'node' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'ReplyEdgeFields',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageInfo' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'hasPreviousPage',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'hasNextPage',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'startCursor',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'endCursor' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalCount' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentionedUsersField' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'edges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatarUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReplyFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Reply' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFollowing' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postReactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'numberOfReactions' },
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'postReactions',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fullName' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentionedUsers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'mentionedUsers',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentionedUsersField' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'hasReacted' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postAttachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'altText' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attachmentType' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fileSizeInBytes' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReplyEdgeNodeReliesFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReplyConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'edges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ReplyFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pageInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hasPreviousPage' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReplyEdgeFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Reply' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ReplyFields' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'last' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'numOfNestedReplies' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'field' },
                      value: { kind: 'EnumValue', value: 'CREATED_AT' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'direction' },
                      value: { kind: 'EnumValue', value: 'ASC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReplyEdgeNodeReliesFields' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<RepliesQuery, RepliesQueryVariables>;
export const NestedRepliesDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'NestedReplies' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'communityId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'parentReplyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'numOfReplies' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startCursor' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'numberOfReactions' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'community' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'communityId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reply' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'parentReplyId' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'replies' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'last' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'numOfReplies' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'before' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'startCursor' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'orderBy' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'field' },
                                  value: {
                                    kind: 'EnumValue',
                                    value: 'CREATED_AT',
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'direction' },
                                  value: { kind: 'EnumValue', value: 'ASC' },
                                },
                              ],
                            },
                          },
                        ],
                        directives: [
                          {
                            kind: 'Directive',
                            name: { kind: 'Name', value: 'connection' },
                            arguments: [
                              {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'key' },
                                value: {
                                  kind: 'StringValue',
                                  value: 'replies',
                                  block: false,
                                },
                              },
                            ],
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'edges' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cursor' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'node' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'ReplyFields',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageInfo' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'hasPreviousPage',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'hasNextPage',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'startCursor',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'endCursor' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalCount' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentionedUsersField' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'edges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatarUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReplyFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Reply' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFollowing' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postReactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'numberOfReactions' },
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'postReactions',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fullName' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentionedUsers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'mentionedUsers',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentionedUsersField' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'hasReacted' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postAttachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'altText' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attachmentType' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fileSizeInBytes' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<NestedRepliesQuery, NestedRepliesQueryVariables>;
export const ReplyDepthDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ReplyDepth' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'communityId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'community' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'communityId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reply' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'depth' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parentPost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<ReplyDepthQuery, ReplyDepthQueryVariables>;
export const PostSearchDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PostSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'communityId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchTerm' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchAuthor' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'community' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'communityId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'searchableCommunityPosts' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'searchTerm' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'searchTerm' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'searchAuthor' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'searchAuthor' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'depth' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'content' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'createdAt' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'editedAt' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rootPost' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'title',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'communitySpace',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'iconName',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'postAttachments',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'PostAttachmentsFields',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mentionedUsers',
                                    },
                                    directives: [
                                      {
                                        kind: 'Directive',
                                        name: {
                                          kind: 'Name',
                                          value: 'connection',
                                        },
                                        arguments: [
                                          {
                                            kind: 'Argument',
                                            name: {
                                              kind: 'Name',
                                              value: 'key',
                                            },
                                            value: {
                                              kind: 'StringValue',
                                              value: 'mentionedUsers',
                                              block: false,
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'edges',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'node',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'id',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'fullName',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'avatarUrl',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'fullName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'firstName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'avatarUrl',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'roles',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PostAttachmentsFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PostAttachment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'attachmentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileSizeInBytes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'altText' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<PostSearchQuery, PostSearchQueryVariables>;
export const SectionListDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SectionList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'communityId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isCoursesToCommunitiesEnabled' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'community' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'communityId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'communitySections' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'SectionFields',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SpaceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CommunitySpace' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iconName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'position' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageAltText' } },
          { kind: 'Field', name: { kind: 'Name', value: 's3UploadPath' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isExclusive' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'posts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'communityProductRelation' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: {
                        kind: 'Name',
                        value: 'isCoursesToCommunitiesEnabled',
                      },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'product' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SectionFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CommunitySection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'communitySpaces' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'communitySpaces',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'SpaceFields' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'posts' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'edges' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'node' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'totalCount' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<SectionListQuery, SectionListQueryVariables>;
export const SiteFeaturesDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SiteFeatures' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'communityId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'site' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'features' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'communities' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'maxCommunitySpaces',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'communityEvents' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isEnabled' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'communityEventNotifications',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isEnabled' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'community' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'communityId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'communitySections' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'communitySpaces',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'totalCount',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<SiteFeaturesQuery, SiteFeaturesQueryVariables>;
export const CreateCommunitySpaceDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCommunitySpace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateCommunitySpaceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCommunitySpace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userErrors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'communitySpace' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  CreateCommunitySpaceMutation,
  CreateCommunitySpaceMutationVariables
>;
export const UpdateCommunitySpaceDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCommunitySpace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCommunitySpaceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCommunitySpace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userErrors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'communitySpace' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateCommunitySpaceMutation,
  UpdateCommunitySpaceMutationVariables
>;
export const DeleteCommunitySpaceDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteCommunitySpace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteCommunitySpaceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCommunitySpace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userErrors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  DeleteCommunitySpaceMutation,
  DeleteCommunitySpaceMutationVariables
>;
export const ReorderCommunitySpaceDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ReorderCommunitySpace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ReorderCommunitySpaceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reorderCommunitySpace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userErrors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  ReorderCommunitySpaceMutation,
  ReorderCommunitySpaceMutationVariables
>;
export const CommunitySpaceDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CommunitySpace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'communityId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'spaceId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isCoursesToCommunitiesEnabled' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'community' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'communityId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'communitySpace' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'spaceId' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SpaceFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SpaceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CommunitySpace' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iconName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'position' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageAltText' } },
          { kind: 'Field', name: { kind: 'Name', value: 's3UploadPath' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isExclusive' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'posts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'communityProductRelation' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: {
                        kind: 'Name',
                        value: 'isCoursesToCommunitiesEnabled',
                      },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'product' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  CommunitySpaceQuery,
  CommunitySpaceQueryVariables
>;
export const UsersDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Users' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'communityId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchTerm' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          defaultValue: { kind: 'StringValue', value: '', block: false },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'numberOfUsers' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          defaultValue: { kind: 'IntValue', value: '5' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'community' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'communityId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'communityUsers' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'numberOfUsers' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'after' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'after' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'active' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pageInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endCursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startCursor' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'CommunityEdgesFields',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentionableUsers' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'searchTerm' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'searchTerm' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'numberOfUsers' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'fullName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'profile',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'avatarUrl',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CommunityEdgesNodeUserFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CommunityEdgesNodeFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CommunityUser' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CommunityEdgesNodeUserFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CommunityEdgesFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CommunityUserEdge' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'node' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CommunityEdgesNodeFields' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<UsersQuery, UsersQueryVariables>;
export const UserDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'User' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'communityId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'numOfCourses' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endCursor' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'community' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'communityId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'communityUser' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'userId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fullName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'roles' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'avatarUrl' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'company' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'headline' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'timezone' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'enrollments' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'filter' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'status' },
                                        value: {
                                          kind: 'EnumValue',
                                          value: 'COMPLETED',
                                        },
                                      },
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'productStatus',
                                        },
                                        value: {
                                          kind: 'ListValue',
                                          values: [
                                            {
                                              kind: 'EnumValue',
                                              value: 'published',
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'productVisible',
                                        },
                                        value: {
                                          kind: 'BooleanValue',
                                          value: true,
                                        },
                                      },
                                    ],
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'orderBy' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'field' },
                                        value: {
                                          kind: 'EnumValue',
                                          value: 'COMPLETED_AT',
                                        },
                                      },
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'direction',
                                        },
                                        value: {
                                          kind: 'EnumValue',
                                          value: 'DESC',
                                        },
                                      },
                                    ],
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'first' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'numOfCourses',
                                    },
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'after' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'endCursor' },
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'UserEnrollmentFields',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserEnrollmentFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EnrollmentConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pageInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'edges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'completedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'course' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cardImageUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'resumeCourseUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'landingPageUrl' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<UserQuery, UserQueryVariables>;
export const MainDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Main' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'communityId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'site' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'favicon' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filestackPolicy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'policy' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'signature' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'featureFlags' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'subdomain' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trackingKey' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'whiteLabellingActivated' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'googleAnalyticsKey' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'appcuesDisabled' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'integrations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'facebookPixelCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gaMeasurementId' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'community' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'communityId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'primaryColor' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'font' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3UploadPath' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'published' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'communityUsers' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'active' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalCount' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentionableUsers' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'searchTerm' },
                      value: { kind: 'StringValue', value: '', block: false },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'IntValue', value: '5' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'fullName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'profile',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'avatarUrl',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locale' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timezone' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<MainQuery, MainQueryVariables>;
export const AccountNotificationsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AccountNotifications' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountNotifications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  AccountNotificationsQuery,
  AccountNotificationsQueryVariables
>;
