import { Card } from '@thinkific/toga-react';

import styled from 'styled-components';

export const togaFooterBlockName = 'card__footer';

// Toga's react version of this card is missing border-box 🙃
// Card footers render twice as large without it
const wrapped = styled(Card)`
  > div[class*='footer'] {
    box-sizing: border-box;
  }
`;

export default wrapped;
