import React from 'react';
import classNames from 'classnames/bind';

import styles from './Card.module.scss';

const cx = classNames.bind(styles);

const Card: React.FC = ({ children }) => {
  return <div className={cx('card')}>{children}</div>;
};

export default Card;
