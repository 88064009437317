import { EmptyState } from '@thinkific/toga-react';

import styled from 'styled-components';

const wrapped = styled(EmptyState)`
  h2 {
    font-family: inherit;
  }
`;

export default wrapped;
