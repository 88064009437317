import React from 'react';
import classNames from 'classnames/bind';

import Card from 'components/Card';
import { IconButton } from '@thinkific/toga-react';

import { useTranslation } from 'react-i18next';
import useLocalStorage from 'hooks/useLocalStorage';

import styles from './InfoCard.module.scss';

const cx = classNames.bind(styles);

interface InfoCardProps {
  isDismissable?: boolean;
  title: string;
  isCompact?: boolean;
  localStorageKey?: string;
  dataQA?: string;
  onClose?: () => void;
  wrapper?: React.FC;
}

const InfoCard: React.FC<InfoCardProps> = ({
  isDismissable,
  title,
  isCompact,
  children,
  localStorageKey,
  dataQA,
  onClose,
  wrapper,
}) => {
  const { t } = useTranslation();

  const { isSaved, saveKeyInLocalStorage } = useLocalStorage(
    localStorageKey || ''
  );

  if (isDismissable && isSaved) return null;

  // If no localStorageKey is provided, then we know
  // that the component should be shown back if the user
  // refreshes/goes away and comes back to the page where
  // the section shown.
  const handleHideSection = () => {
    if (localStorageKey) saveKeyInLocalStorage(true);
    onClose?.();
  };

  const dismissButton = (
    <IconButton
      appearance="utility"
      className={cx('info-card__dismiss-button')}
      name="x-dismiss"
      title={t('common-dismiss-empty-state', 'Dismiss the {{title}} section', {
        title,
      })}
      onClick={handleHideSection}
    />
  );

  const Wrapper = wrapper || Card;

  return (
    <Wrapper>
      <section
        className={cx('info-card', { 'info-card--compact': isCompact })}
        data-qa={dataQA}
      >
        <div className={cx('info-card__dismiss-wrapper')}>
          {isDismissable && dismissButton}
        </div>
        <div
          className={cx('info-card__header', {
            'info-card__header--dismissable': isDismissable,
          })}
        >
          <h3
            className={cx('info-card__title', {
              'info-card__title--compact': isCompact,
            })}
          >
            <span>{title}</span>
          </h3>
        </div>
        <div>{children}</div>
      </section>
    </Wrapper>
  );
};

export default InfoCard;
