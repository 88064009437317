import React from 'react';
import Head from 'next/head';
import { useCommunityContext } from 'components/CommunityContext';

interface CommunityHeaderProps {
  pageTitle: string;
}

const CommunityHeader: React.FC<CommunityHeaderProps> = ({ pageTitle }) => {
  const { community } = useCommunityContext();
  return (
    <Head>
      <title>
        {pageTitle}
        {' | '}
        {community.name}
      </title>
    </Head>
  );
};

export default CommunityHeader;
