import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { InlineActions } from '@thinkific/toga-react';
import { Card } from 'toga';

import { useCommunityContext } from 'components/CommunityContext';
import { EVENT_IMAGE_PLACEHOLDER } from 'utils/constants';
import {
  localeEventsDate,
  isEventLive,
  isEventStartingSoon,
} from 'helpers/dateHelpers';
import { isAdmin } from 'utils/userHelpers';
import { goToEditEvent } from 'utils/routerHelpers';
import { features } from 'constants/featureFlags';
import useEventInterest from 'components/Events/hooks/useEventInterest';
import { useSiteFeaturesContext } from 'components/SiteFeaturesContext';

import styles from './EventCard.module.scss';

const cx = classNames.bind(styles);

interface EventCardProps {
  id?: string;
  title: string | null;
  startTime: string;
  endTime: string;
  imageUrl: string | null;
  imageAltText?: string | null;
  timezone?: string;
  isPreview?: boolean;
  interestedUserCount?: number;
  isInterested?: boolean;
}

const EventCard: React.FC<EventCardProps> = ({
  id,
  title,
  imageUrl,
  imageAltText,
  startTime,
  endTime,
  timezone,
  isPreview,
  interestedUserCount,
  isInterested,
}) => {
  const { t } = useTranslation();
  const { currentUser, community, featureFlags } = useCommunityContext();
  const { siteFeatures } = useSiteFeaturesContext();
  const eventNotificationsEnabled =
    siteFeatures?.communityEventNotifications?.isEnabled;
  const router = useRouter();
  const { addInterest, removeInterest, loading } = useEventInterest(id);

  const rsvpEnabled = featureFlags[features.COMMUNITIES_EVENTS_RSVP];

  const formattedDate = localeEventsDate(
    startTime,
    currentUser.locale,
    timezone
  );

  const getBadgeText = () => {
    if (isEventLive(startTime, endTime))
      return t('components-events-eventCard-live', 'Live');
    if (isEventStartingSoon(startTime, 15))
      return t('components-events-eventCard-starting_soon', 'Starting soon');
    return '';
  };

  const actions: Array<{
    name: string;
    text: string;
    onClick(): void;
    iconLeft?: string;
  }> = [];
  const statusList: Array<{ text: string; type?: string } | string> = [];
  const isPastEvent = new Date() > new Date(endTime);

  if (rsvpEnabled && eventNotificationsEnabled && id) {
    if (isAdmin(currentUser)) {
      actions.push({
        name: 'Edit',
        text: t('common-EDIT', 'EDIT'),
        onClick: () => {
          goToEditEvent(router, id, community.id);
        },
      });
      if (interestedUserCount) {
        statusList.push(
          t('components-events-interested_total', '{{total}} Interested', {
            total: interestedUserCount,
          })
        );
      }
    } else if (!isPastEvent) {
      if (isInterested) {
        // Interested Student

        actions.push({
          name: 'Remove Interest',
          iconLeft: 'minus',
          text: t(
            'components-events-eventCard-REMOVE_INTEREST',
            'REMOVE INTEREST'
          ),
          onClick: () => {
            removeInterest();
          },
        });

        if (interestedUserCount === 1) {
          // There is only one interested student and it is you
          statusList.push({
            text: t('components-events-interested', 'Interested'),
            type: 'active',
          });
        } else if (interestedUserCount) {
          // There are many interested including you
          statusList.push({
            text: t(
              'components-events-you_and_others_interested',
              'You & {{total}} Interested',
              { total: interestedUserCount - 1 }
            ),
            type: 'active',
          });
        }
      } else {
        // Student and they are not interested
        actions.push({
          name: 'Express Interest',
          iconLeft: 'plus',
          text: t('components-events-eventCard-INTERESTED', 'INTERESTED'),
          onClick: () => {
            addInterest();
          },
        });
        if (interestedUserCount) {
          statusList.push({
            text: t(
              'components-events-interested_total',
              '{{total}} Interested',
              {
                total: interestedUserCount,
              }
            ),
            type: 'inactive',
          });
        }
      }
    }
  }

  const showFooter = !!actions.length;

  return (
    <Card
      badgeText={isPreview ? '' : getBadgeText()}
      badgeType={isEventLive(startTime, endTime) ? 'danger' : 'success'}
      caption={formattedDate}
      className={cx('event-card')}
      footerActions={
        showFooter && (
          <InlineActions
            actions={actions}
            ariaLabel={t(
              'components-events-eventCard-dropdown_aria_label',
              'Event Card Dropdown'
            )}
            buttonAppearance="knockout"
          />
        )
      }
      image={imageUrl || EVENT_IMAGE_PLACEHOLDER}
      imageAlt={imageAltText || ''}
      isDisabled={loading}
      name={title}
      statusList={statusList}
      invertedCaption
    />
  );
};

export default EventCard;
