import { Input } from '@thinkific/toga-react';

import styled from 'styled-components';

const wrapped = styled(Input)`
  input {
    box-sizing: border-box;
  }
`;

export default wrapped;
